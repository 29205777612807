import React, { useRef, useState, useEffect, useMemo } from "react"
import { arrayToRGBA } from "../../common/utils"
import {
  SHROUDED_OPACITY,
  FILL_UNSELECTED,
  DEFAULT_POLYGON_STROKE_WIDTH,
  DEFAULT_HANDLE_RADIUS,
  DEFAULT_PRIMARY_HANDLE_SIZE,
  SELECTED_HIGHLIGHT,
} from "./Constants"

const RenderPolygon = ({
  override_pts,
  zTest,
  polygon,
  onPointSelected,
  selected,
  selectedObject,
  overrideProperty,
  showHandle,
  editMode,
}) => {
  const ptsRef = useRef([])
  const [loaded, setLoaded] = useState(false)
  const [pts, setPts] = useState("")
  useEffect(() => {
    if (pts) {
      setLoaded(true)
    } else {
      setLoaded(false)
    }
  }, [pts])
  useEffect(() => {
    if (override_pts) {
      setPts(override_pts)
    } else if (polygon && !(loaded && overrideProperty)) {
      let arr = []
      for (let i = 0; i < polygon.points.length; i++) {
        arr.push(
          `${
            polygon.points[i].points[0] * (overrideProperty?.deltaRatio_x ?? 1)
          },${
            polygon.points[i].points[1] * (overrideProperty?.deltaRatio_y ?? 1)
          }`
        )
      }
      setPts(arr.join(" "))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygon])
  const mouseOver = (event) => {
    if (overrideProperty) {
      event.target.setAttribute("stroke", "red")
      event.target.setAttribute("stroke-width", "3px")
    } else {
      event.target.setAttribute("fill", "red")
    }
    if (onPointSelected) onPointSelected(event.target.id)
  }
  const mouseOut = (event) => {
    if (overrideProperty) {
      event.target.removeAttribute("stroke")
      event.target.removeAttribute("stroke-width")
    } else {
      event.target.removeAttribute("fill")
    }
    if (onPointSelected) onPointSelected(null)
  }
  const mouseClick = (event) => {
    overrideProperty.clickCallback && overrideProperty.clickCallback()
  }
  useEffect(() => {
    const fxPts = ptsRef.current
    fxPts.forEach((p) => {
      if (p && !overrideProperty?.noEvent) {
        p.addEventListener("mouseenter", mouseOver)
        p.addEventListener("mouseleave", mouseOut)
        overrideProperty && p.addEventListener("mousedown", mouseClick)
      }
    })
    return () => {
      fxPts.forEach((p) => {
        if (p && !overrideProperty?.noEvent) {
          p.removeEventListener("mouseenter", mouseOver)
          p.removeEventListener("mouseleave", mouseOut)
          overrideProperty && p.removeEventListener("mousedown", mouseClick)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptsRef.current, editMode, onPointSelected, showHandle, loaded])

  const barycenter = useMemo(() => {
    if (polygon.points?.length) {
      let x = 0
      let y = 0
      for (let i = 0; i < polygon.points.length; i++) {
        x += polygon.points[i].points[0]
        y += polygon.points[i].points[1]
      }
      x *= 1 / polygon.points.length
      y *= 1 / polygon.points.length
      return [x, y]
    } else {
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pts])

  let color = "white"
  if (polygon.color) {
    color = arrayToRGBA(polygon.color)
  }

  return (
    <g
      style={{
        filter: selected ? SELECTED_HIGHLIGHT : "",
        opacity:
          !selected && editMode && editMode !== "polygon"
            ? SHROUDED_OPACITY
            : 1,
      }}
    >
      {loaded && (
        <polyline
          points={pts}
          style={{
            fill: polygon.unselected
              ? "none"
              : polygon.bg_color
              ? arrayToRGBA(polygon.bg_color)
              : "none",
            stroke: polygon.unselected ? FILL_UNSELECTED : color,
            strokeWidth:
              overrideProperty?.strokeWidth ??
              (polygon.stroke_width ?? DEFAULT_POLYGON_STROKE_WIDTH) + "px",
            strokeDasharray: overrideProperty || zTest ? "" : "5",
            pointerEvents: "none",
          }}
        />
      )}
      {loaded && (showHandle || editMode === "polygon") && (
        <>
          {polygon.points.map((p, i) => (
            <g
              key={p.id}
              stroke="black"
              style={{ cursor: overrideProperty ? "pointer" : "grabbing" }}
              fill={polygon.unselected ? FILL_UNSELECTED : "white"}
              strokeWidth={1}
            >
              <circle
                className="hmk_handle"
                id={`${polygon.id}#${i}`}
                ref={(el) => (ptsRef.current[i] = el)}
                cx={p.points[0] * (overrideProperty?.deltaRatio_x ?? 1)}
                cy={p.points[1] * (overrideProperty?.deltaRatio_y ?? 1)}
                r={overrideProperty ? 4 : DEFAULT_HANDLE_RADIUS}
              />
            </g>
          ))}
          {!overrideProperty && polygon.points?.length && barycenter.length ? (
            <g
              stroke="black"
              style={{ cursor: "grabbing" }}
              fill={polygon.unselected ? FILL_UNSELECTED : "white"}
              strokeWidth={1}
            >
              <rect
                className="hmk_handle"
                id={`${polygon.id}#${polygon.points.length}#M`}
                x={barycenter[0] - DEFAULT_PRIMARY_HANDLE_SIZE / 2}
                y={barycenter[1] - DEFAULT_PRIMARY_HANDLE_SIZE / 2}
                width={DEFAULT_PRIMARY_HANDLE_SIZE}
                height={DEFAULT_PRIMARY_HANDLE_SIZE}
                ref={(el) => (ptsRef.current[polygon.points.length] = el)}
              />
            </g>
          ) : (
            <></>
          )}
        </>
      )}
    </g>
  )
}

export default RenderPolygon
