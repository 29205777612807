import React, { useRef } from "react"

const HmkLoader = ({ loader }) => (
  <div className={loader ? "loader rotate-center" : "loader"}>
    <h1>H</h1>
  </div>
)

const SvgHmkLoader = ({ loader, rotate }) => {
  const bar0 = useRef(null)
  const bar1 = useRef(null)
  const bar2 = useRef(null)
  const bar3 = useRef(null)
  const bar4 = useRef(null)
  const bar5 = useRef(null)

  /*useEffect(() => {
    if (!loader) {
      bar0.current.endElement()
      bar1.current.endElement()
      bar2.current.endElement()
      bar3.current.endElement()
      bar4.current.endElement()
      bar5.current.endElement()
    }
  }, [loader])*/

  // force animation
  loader = true
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 170 170"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rotate && "rotate-center"}`}
    >
      <g id="layer1" transform="translate(0,-882.36218)">
        <g id="g3012" transform="translate(36.474491,14.28575)">
          <g
            id="g4168"
            transform="matrix(1.7973729,0,0,1.7973729,-61.435792,-673.07774)"
          >
            <path
              id="object_bar_5"
              fill="#666666"
              fillOpacity={1}
              fillRule="nonzero"
              stroke="none"
              strokeWidth={0.35010424}
              d="M 32.877219,939.46388 95.652652,876.9899 v -6.47975 l -68.954435,68.95373 z"
            >
              <animate
                ref={bar5}
                id="animbar5"
                attributeName="fill"
                from="#666666ff"
                to="#666666ff"
                values="#666666ff;#666666ff;#666666ff;#ffa500ff;#666666ff;#666666ff;#666666ff"
                begin={loader ? "animbar4.begin+0.2s" : "indefinite"}
                end={loader ? "indefinite" : "0.0s"}
                dur="2.5s"
                fill="freeze"
                repeatCount="indefinite"
              ></animate>
            </path>
            <g
              fill="#666666"
              fillOpacity={1}
              id="object_bar_4"
              transform="matrix(0.35010424,0,0,-0.35010424,40.268861,939.46388)"
            >
              <path
                d="M 12.956168,0 158.212,144.32639 l 0,19.20761 L -5.324,0 Z"
                fill="#666666"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path3043"
              >
                <animate
                  ref={bar4}
                  id="animbar4"
                  attributeName="fill"
                  from="#666666ff"
                  to="#666666ff"
                  values="#666666ff;#666666ff;#666666ff;#ffa500ff;#666666ff;#666666ff;#666666ff"
                  begin={loader ? "animbar3.begin+0.2s" : "indefinite"}
                  end={loader ? "indefinite" : "0.0s"}
                  dur="2.5s"
                  fill="freeze"
                  repeatCount="indefinite"
                ></animate>
              </path>
            </g>
            <g
              fill="#666666"
              fillOpacity={1}
              id="object_bar_3"
              transform="matrix(0.35010424,0,0,-0.35010424,51.9683,939.46388)"
            >
              <path
                d="M 13.881608,0 124.795,111.37311 l 0,18.74289 L -5.324,0 Z"
                fill="#666666"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path3047"
              >
                <animate
                  ref={bar3}
                  id="animbar3"
                  attributeName="fill"
                  from="#666666ff"
                  to="#666666ff"
                  values="#666666ff;#666666ff;#666666ff;#ffa500ff;#666666ff;#666666ff;#666666ff"
                  begin={loader ? "animbar2.begin+0.2s" : "indefinite"}
                  end={loader ? "indefinite" : "0.0s"}
                  dur="2.5s"
                  fill="freeze"
                  repeatCount="indefinite"
                ></animate>
              </path>
            </g>
            <g
              fill="#666666"
              fillOpacity={1}
              id="object_bar_2"
              transform="matrix(0.35010424,0,0,-0.35010424,63.667696,939.46388)"
            >
              <path
                d="M 12.493447,0 91.378,77.493392 91.378,96.71 -5.324,0 Z"
                fill="#666666"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path3051"
              >
                <animate
                  ref={bar2}
                  id="animbar2"
                  attributeName="fill"
                  from="#666666ff"
                  to="#666666ff"
                  values="#666666ff;#666666ff;#666666ff;#ffa500ff;#666666ff;#666666ff;#666666ff"
                  begin={loader ? "animbar1.begin+0.2s" : "indefinite"}
                  end={loader ? "indefinite" : "0.0s"}
                  dur="2.5s"
                  fill="freeze"
                  repeatCount="indefinite"
                ></animate>
              </path>
            </g>
            <g
              fill="#666666"
              fillOpacity={1}
              id="object_bar_1"
              transform="matrix(0.35010424,0,0,-0.35010424,75.364395,939.46388)"
            >
              <path
                d="M 12.956168,0 57.969,44.550112 57.969,63.293 -5.325,0 Z"
                fill="#666666"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path3055"
              >
                <animate
                  ref={bar1}
                  id="animbar1"
                  attributeName="fill"
                  from="#666666ff"
                  to="#666666ff"
                  values="#666666ff;#666666ff;#666666ff;#ffa500ff;#666666ff;#666666ff;#666666ff"
                  begin={loader ? "animbar0.begin+0.2s" : "indefinite"}
                  end={loader ? "indefinite" : "0.0s"}
                  dur="2.5s"
                  fill="freeze"
                  repeatCount="indefinite"
                ></animate>
              </path>
            </g>
            <g
              fill="#ffa500"
              fillOpacity={1}
              id="object_bar_0"
              transform="matrix(0.35010424,0,0,-0.35010424,87.190008,939.46388)"
            >
              <desc id="desc838">color = 666666ff</desc>
              <path
                d="M 11.452327,0 24.191,13.194394 24.191,29.518 -5.332,0 Z"
                fill="#666666ff"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path3059"
              >
                <animate
                  ref={bar0}
                  id="animbar0"
                  attributeName="fill"
                  from="#666666ff"
                  to="#666666ff"
                  values="#666666ff;#666666ff;#666666ff;#ffa500ff;#666666ff;#666666ff;#666666ff"
                  begin={loader ? "0.0s" : "indefinite"}
                  end={loader ? "indefinite" : "0.0s"}
                  dur="2.5s"
                  fill="freeze"
                  repeatCount="indefinite"
                ></animate>
              </path>
            </g>
            <text
              id="object_h"
              x="21.293613"
              y="939.46387"
              fontSize="90.88458252px"
              fill="#f2f2f2"
              fillOpacity={1}
              strokeWidth={7.57371521}
            >
              <tspan
                fontStyle="normal"
                fontVariant="normal"
                fontWeight="normal"
                fontStretch="condensed"
                fontSize="90.88258362px"
                fontFamily="Agency FB"
                writingMode="lr-tb"
                textAnchor="start"
                fill="#f2f2f2"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                strokeWidth={7.57371521}
                x="21.293613"
                y="939.46387"
                id="tspan3011"
              >
                H
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { HmkLoader, SvgHmkLoader }
