import { stepPostPro } from "./StepPostPro"
import objPostPro from "./ObjPostPro"
import blendPostPro from "./BlendPostPro"
import gerberPostPro from "./GerberPostPro"
import svg2paths from "./Svg2PathsPostPro"
import dxfPostPro from "./DxfPostPro"

export const postProSelector = (file) => {
  let postpro = null
  if (
    file["src"].toUpperCase().endsWith("STEP") ||
    file["src"].toUpperCase().endsWith("STP")
  ) {
    postpro = stepPostPro
  } else if (file["src"].toUpperCase().endsWith("OBJ")) {
    postpro = objPostPro
  } else if (file["src"].toUpperCase().endsWith("BLEND")) {
    postpro = blendPostPro
  } else if (
    file["src"].toUpperCase().endsWith("GBX") ||
    file["src"].toUpperCase().endsWith("GBR") ||
    file["src"].toUpperCase().endsWith("GDO") ||
    file["src"].toUpperCase().endsWith("PHO")
  ) {
    postpro = gerberPostPro
  } else if (file["src"].toUpperCase().endsWith("SVG")) {
    //postpro = svg2obj
    postpro = svg2paths
  } else if (
    file["src"].toUpperCase().endsWith("DXF") ||
    file["src"].toUpperCase().endsWith("DWG")
  ) {
    postpro = dxfPostPro
  }
  return postpro
}
