import { cramerRule } from "./CircleComp"

class ImagePicker {
  constructor(partView) {
    this.partView = partView
    this.imageDepth = null
    this.camera = null
    this.object3D = null
    this.width = 0
    this.height = 0
  }

  /**
   *
   * @param {*} circle
   * @returns a list of dict: [ {point2d, dist}, ... ]
   */
  computeDiscreteCircle2DFrom3D(circle) {
    return this.object3D.computeDiscreteCircle2DFrom3D(
      circle,
      this.width,
      this.height,
      this.camera
    )
  }

  computeDiscreteCircle2DFrom3DZTest(circle) {
    const points = this.object3D.computeDiscreteCircle2DFrom3D(
      circle,
      this.width,
      this.height,
      this.camera
    )
    // if all points are hidden, the whole circle is hidden
    const zTest = points.reduce(
      (res, val) => res && this.compareDist(val.point2d, val.dist),
      true
    )
    const pts = points.map((p) => p.point2d)
    return { points: pts, zTest }
  }

  computeCircle2DFrom3D(circle) {
    return this.object3D.computeCircle2DFrom3D(
      circle,
      this.width,
      this.height,
      this.camera
    )
  }

  compareDist(pt2d, dist3d) {
    const EPS = 1e-3
    const dist = this.getDist(pt2d[0], pt2d[1])
    return dist < 0 ? true : dist - dist3d > -EPS * dist
  }

  compute2DFrom3DZTest(pt) {
    const { point2d, dist: dist3d } = this.compute2DFrom3D(pt)
    const zTest = this.compareDist(point2d, dist3d)
    return { point2d, zTest }
  }

  compute2DFrom3D(pt) {
    if (this.object3D) {
      return this.object3D.compute2DFrom3D(
        pt,
        this.width,
        this.height,
        this.camera
      )
    }
    return { point2d: [pt[0], pt[1]], dist: -1 }
  }

  compute3DFrom2D(x, y) {
    let point3d = null
    let dist = this.getDist(x, y)
    if (dist >= 0 && dist < 1e10) {
      point3d = this.object3D.compute3DFrom2D(
        x,
        y,
        dist,
        this.width,
        this.height,
        this.camera
      )
    }
    return point3d
  }

  getNearestPoint3d(point3d) {
    return this.object3D.getNearestPoint3d(point3d)
  }

  computeCircle(pointList) {
    return cramerRule(pointList)
  }

  getDepthPixel(x, y) {
    let pixelData = null
    if (this.imageDepth) {
      // test
      let id = this.imageDepth
      /*
      console.log(
        `ImageDepth = [${id.width}, ${id.height}] Depth=${id.depth} Color=${id.ctype}`
      )
      console.log("Image data size : " + id.data.length)
      */
      // only 8 bits managed and rgb or rgba colour type
      let pixId
      switch (id.ctype) {
        case 0:
          // greyscale : one pixel = one greyscale sample
          console.error("WARN: greyscale image not managed")
          break
        case 2:
          // truecolour : one pixel = one triple R, G, B
          if (id.depth !== 8) {
            console.error("WARN: 16 bits image not managed")
            break
          }
          pixelData = new Uint8Array(4)
          pixId = x + y * id.width
          pixelData[0] = id.data[3 * pixId]
          pixelData[1] = id.data[3 * pixId + 1]
          pixelData[2] = id.data[3 * pixId + 2]
          pixelData[3] = 0
          break
        case 3:
          // indexed-colour : one pixel = one palette index
          console.error("WARN: indexed-colour image not managed")
          break
        case 4:
          // greyscale + alpha : one greyscale sample + alpha sample
          console.error("WARN: greyscale image not managed")
          break
        case 6:
          // truecolour + alpha : one triple R, G, B + alpha sample
          if (id.depth !== 8) {
            console.error("WARN: 16 bits image not managed")
            break
          }
          pixelData = new Uint8Array(4)
          pixId = x + y * id.width
          pixelData[0] = id.data[4 * pixId]
          pixelData[1] = id.data[4 * pixId + 1]
          pixelData[2] = id.data[4 * pixId + 2]
          pixelData[3] = id.data[4 * pixId + 3]
          break
        default:
          console.error("WARN: PNG type not managed: " + id.ctype)
      }
    }
    return pixelData
  }

  getDist(x, y) {
    // convert 4-bytes array back to float value (cf exr encoded to rgba png)
    let dist = -1
    let buf = new ArrayBuffer(4)
    let bufView = new DataView(buf)
    let data = this.getDepthPixel(x, y)
    if (data) {
      data.forEach((b, i) => {
        bufView.setUint8(i, b)
      })
      dist = bufView.getFloat32(0)
    }
    return dist
  }

  setImageDepth(imageObj) {
    this.imageDepth = imageObj
    this.width = this.imageDepth.width
    this.height = this.imageDepth.height
  }

  setCamera(camera) {
    this.camera = camera
  }

  setObject3D(obj) {
    this.object3D = obj
  }
}

export default ImagePicker
