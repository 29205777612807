import { HMFileIO } from "../io/HMFileIO"
import { toast } from "react-toastify"

import { trackProgress } from "../common/ProgressMonitor"

const svg2paths = ({ remoteFile }, app) => {
  const DEBUG = false
  return new Promise((resolve, reject) => {
    const hmFile = HMFileIO()
    if (DEBUG)
      console.log(
        `Will launch SVG Conversion to Paths on file ${remoteFile["src"]}`
      )
    hmFile
      .convert("svg2paths", remoteFile["id"])
      .then((res) => {
        if (DEBUG) console.log(`Launched Svg2Paths: ${JSON.stringify(res)}`)
        toast("Launched Svg2Paths process...")
        return trackProgress(
          app,
          remoteFile["id"],
          res.task_id,
          "SVG2PATHS_DONE"
        )
      })
      .then((res) => {
        if (DEBUG)
          console.log(
            `Result of Svg2Paths is: ${JSON.stringify(res["result"])}`
          )
        toast("All processes done, refreshing workspace ...")
        resolve(res)
      })
      .catch((err) => {
        console.log(
          `ERROR IN POSTPROCESSING OF FILE ${remoteFile["src"]} : ${err.message}`
        )
        reject(err)
      })
  })
}

export default svg2paths
