import React, { useState, useEffect, useContext, useRef } from "react"
import { FiSquare, FiCircle } from "react-icons/fi"
import { TbPointFilled } from "react-icons/tb";
import { CgMiniPlayer } from "react-icons/cg"
import { FaDrawPolygon } from "react-icons/fa"
import { MdOutlineDone, MdUndo } from "react-icons/md"
import ReactTooltip from "react-tooltip"
import { ComposerContext } from "../../Contexts"
import "./ControllerObject.css"

const ControllerObject = ({
  object_id,
  object_type,
  object_name,
  onFocus,
  focusedObject,
  checkedObjects,
  onCheck,
  onSelect,
  onBlurTextInput,
}) => {
  const composerContext = useContext(ComposerContext)
  const [name_cache, setNameCache] = useState("")
  const [name, setName] = useState("")
  const checkboxRef = useRef(null)
  useEffect(() => {
    setNameCache(object_name)
    setName(object_name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object_name])
  const updateName = (event) => {
    setNameCache(name)
    console.log("cache is updated to be : ", name)
    if (event.target?.name && event.target?.value) {
      composerContext.updateName(event.target.name, event.target.value)
    }
    // force focus removal after edit
    if (event.target?.blur) {
      event.target.blur()
    }
  }

  useEffect(() => {
    if (checkedObjects && checkedObjects.length >= 0 && checkboxRef?.current) {
      checkboxRef.current.checked = Boolean(checkedObjects.includes(object_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedObjects, checkboxRef?.current])
  const RenderIcon = () => {
    switch (object_type) {
      case "square":
        return <FiSquare />
      case "circle":
        return <FiCircle />
      case "point":
        return <TbPointFilled />
      case "mask":
        return <CgMiniPlayer />
      case "polygon":
        return <FaDrawPolygon />
      default:
        return <></>
    }
  }
  /*const tooltip = () => {
    switch (object_type) {
      case "square":
        return "Rectangle"
      case "circle":
        return "Circle"
      case "point":
        return "Point"
      case "mask":
        return "Mask"
      case "polygon":
        return "Polygon"
      default:
        return ""
    }
  }*/
  const handleFocus = (event) => {
    onSelect(event.target.getAttribute("name"), event.type === "focus")
  }
  return (
    <div
      className="controller-object-row"
      onMouseEnter={() => {
        if (!focusedObject) {
          onSelect(object_id)
        }
      }}
      onMouseLeave={() => {
        if (!focusedObject) {
          onSelect(null)
        }
      }}
    >
      <div className="controller-object-category" data-tip data-for={object_id}>
        <RenderIcon className="controller-icon" />
        {/** This tooltip is bugging often; sometimes it does not disappear on mouseOut */}
        {/*<ReactTooltip id={object_id}>
          <span>{tooltip()}</span>
        </ReactTooltip>*/}
      </div>
      <div className="controller-checkbox-wrapper">
        <input
          ref={checkboxRef}
          type={"checkbox"}
          onChange={(e) => {
            onCheck(
              object_id,
              Boolean(e.nativeEvent?.ctrlKey || e.nativeEvent?.metaKey),
              e.target.checked,
              true
            )
          }}
        />
      </div>
      <input
        name={object_id}
        type="text"
        className="controller-textinput"
        style={{
          width: `calc(100% - ${Boolean(object_name !== name) ? 160 : 80}px)`,
        }}
        value={name}
        onFocus={(e) => {
          onCheck(object_id, false, true)
          onFocus(object_id)
          handleFocus(e)
        }}
        onBlur={() => {
          onFocus("")
          setName(name_cache)
          onSelect(null)
          onBlurTextInput()
        }}
        onChange={(e) => {
          setName(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (Boolean(object_name !== name)) {
              updateName(e)
            } else {
              e.target.blur()
            }
          }
        }}
      />
      {object_name !== name && (
        <>
          <div
            className="controller-object-confirm"
            data-tip
            data-for={`revert_${object_id}`}
            onMouseDown={() => {
              setName(name_cache)
            }}
          >
            <MdUndo className="controller-icon" />
            <ReactTooltip id={`revert_${object_id}`}>
              <span>undo</span>
            </ReactTooltip>
          </div>
          <div
            className="controller-object-confirm"
            data-tip
            data-for={`confirm_${object_id}`}
            onMouseDown={() => {
              updateName({
                target: {
                  name: object_id,
                  value: name,
                },
              })
            }}
          >
            <MdOutlineDone className="controller-icon" />
            <ReactTooltip id={`confirm_${object_id}`}>
              <span>confirm</span>
            </ReactTooltip>
          </div>
        </>
      )}
    </div>
  )
}

export default ControllerObject
