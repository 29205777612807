import React, { useRef, useState, useEffect } from "react"
import {
  DEFAULT_HANDLE_RADIUS,
  DEFAULT_PRIMARY_HANDLE_SIZE,
  SHROUDED_OPACITY,
  FILL_UNSELECTED,
  SELECTED_HIGHLIGHT,
} from "./Constants"

const RenderMask = ({
  mask,
  editMode,
  selected,
  onPointSelected,
  selectedObject,
  overrideProperty,
}) => {
  const pts = useRef([])
  const [loaded, setLoaded] = useState(false)
  const [paths, setPaths] = useState(null)
  const [moveNodes, setMoveNodes] = useState(null)
  const [nodes, setNodes] = useState(null)
  useEffect(() => {
    if (paths && moveNodes && nodes) {
      setLoaded(true)
    } else {
      setLoaded(false)
    }
  }, [paths, moveNodes, nodes])
  useEffect(() => {
    let paths = []
    const nodes_source = [
      ...mask.points.concat(mask.holes).map((p) => p.points),
    ]
    const nodes = []
    if (!(loaded && overrideProperty)) {
      for (let l = 0; l < nodes_source.length; l++) {
        nodes.push([nodes_source[l][0], nodes_source[l][1], nodes_source[l][2]])
      }
      if (overrideProperty) {
        for (let j = 0; j < nodes.length; j++) {
          nodes[j][0] *= overrideProperty.deltaRatio_x ?? 1
          nodes[j][1] *= overrideProperty.deltaRatio_y ?? 1
        }
      }
      setNodes(nodes)
      var moveNodes = []
      // build polygon mask for outer limit and inner holes
      for (let i = 0; i < nodes.length; i += 2) {
        let x1 = nodes[i][0]
        let y1 = nodes[i][1]
        let x2 = nodes[i + 1][0]
        let y2 = nodes[i + 1][1]
        paths.push(`M${x1} ${y1} H ${x2} V ${y2} H ${x1} Z`)
        let x = nodes[i + 1][0]
        let y = nodes[i][1]
        moveNodes.push([x, y])
      }
      setPaths(paths)
      setMoveNodes(moveNodes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mask])
  const mouseClick = (event) => {
    overrideProperty.clickCallback && overrideProperty.clickCallback()
  }
  // update ref size if a change in number of points
  useEffect(() => {
    if (editMode !== "mask" && !overrideProperty) {
      pts.current.length = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])
  useEffect(() => {
    pts.current = pts.current.slice(
      0,
      mask.points.length + mask.holes.length + mask.holes.length / 2 + 1
    )
  }, [mask])
  useEffect(() => {
    pts?.current?.forEach((p) => {
      if (p && !overrideProperty?.noEvent) {
        p.addEventListener("mouseenter", mouseOver)
        p.addEventListener("mouseleave", mouseOut)
        overrideProperty && p.addEventListener("mousedown", mouseClick)
      }
    })
    return () => {
      pts?.current?.forEach((p) => {
        if (p && !overrideProperty?.noEvent) {
          p.removeEventListener("mouseenter", mouseOver)
          p.removeEventListener("mouseleave", mouseOut)
          overrideProperty && p.removeEventListener("mousedown", mouseClick)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pts.current, editMode, loaded])
  const mouseOver = (event) => {
    event.target.setAttribute("fill", "red")
    if (onPointSelected) onPointSelected(event.target.id)
  }
  const mouseOut = (event) => {
    event.target.removeAttribute("fill")
    if (onPointSelected) onPointSelected(null)
  }

  return (
    <g
      className="hmk_mask"
      stroke={
        overrideProperty && selected
          ? "red"
          : editMode === "mask"
          ? "white"
          : "none"
      }
      style={{
        filter: selected ? SELECTED_HIGHLIGHT : "",
        opacity:
          !selected && editMode && editMode !== "mask" ? SHROUDED_OPACITY : 1,
        pointerEvents: "none",
      }}
      strokeWidth={2}
      fill={mask.unselected ? FILL_UNSELECTED : "black"}
      opacity={0.8}
    >
      {paths && <path fillRule="evenodd" d={paths.join(" ")} />}
      {(editMode === "mask" || overrideProperty) && loaded && (
        <g
          stroke="black"
          strokeWidth={1}
          style={{
            cursor: overrideProperty ? "pointer" : "grabbing",
            pointerEvents: "fill",
          }}
          fill={mask.unselected ? FILL_UNSELECTED : "white"}
        >
          {nodes.map((p, i) => (
            <circle
              className="hmk_handle"
              id={`${mask.id}#${i}`}
              key={i}
              ref={(el) => (pts.current[i] = el)}
              cx={p[0]}
              cy={p[1]}
              r={overrideProperty ? 4 : DEFAULT_HANDLE_RADIUS}
            />
          ))}
          {moveNodes.map((p, i) => (
            <rect
              className="hmk_handle"
              id={`${mask.id}#${i}#M`}
              key={i}
              ref={(el) => (pts.current[nodes.length + i] = el)}
              x={
                p[0] - (overrideProperty ? 4 : DEFAULT_PRIMARY_HANDLE_SIZE / 2)
              }
              y={
                p[1] - (overrideProperty ? 4 : DEFAULT_PRIMARY_HANDLE_SIZE / 2)
              }
              width={overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE}
              height={overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE}
            />
          ))}
        </g>
      )}
    </g>
  )
}

export default RenderMask
