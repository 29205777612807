import { Circle, Polygon, Mask, Square, Point3d } from "../editor/Geometries"
import {
  GEOCOMPO_API,
  CIRCLE_API,
  POLYGON_API,
  MASK_API,
  POINT3D_API,
  SQUARE_API,
} from "./UrlConfig"
import {
  LABEL_BG_DEFAULT_ARR,
  DEFAULT_FILL_COLOR,
} from "../editor/SVGRenderer/Constants"

const DEBUG = false

/*
   Here it's the Promise Paradise !
 */

export const HMCom = () => {
  let geoCompoId = ""
  let pointIds = []
  let polygonIds = []
  let maskIds = []
  let circleIds = []
  let squareIds = []
  const header = {
    accept: "application/json",
    "content-type": "application/json",
  }

  const initGeoCompo = async (usecase) => {
    let geocompo = await getGeoCompoByName(usecase)
    if (!geocompo) {
      geocompo = await createGeoCompo(usecase)
    }
    DEBUG && console.log(`Init GeoCompo `, geocompo)
    geoCompoId = geocompo.id
    pointIds = geocompo.points
    polygonIds = geocompo.polygons
    maskIds = geocompo.masks
    circleIds = geocompo.circles
    squareIds = geocompo.squares
    return geocompo
  }

  const getGeoCompoByName = async (name) => {
    const url = GEOCOMPO_API + "?name=" + name
    const cred = { method: "GET", headers: header }
    DEBUG && console.log(`Retrieve a GeoCompo : ${url}`)
    try {
      const resp = await fetch(url, cred)
      const data = await resp.json()
      return !data || !data.length ? "" : data[0]
    } catch (err) {
      console.warn(`ERROR RETRIEVING GEOCOMPO : ${err.message}`)
      return await Promise.reject(err)
    }
  }

  const createGeoCompo = async (name) => {
    const text = JSON.stringify({
      name: name,
      points: [],
      polygons: [],
      masks: [],
      circles: [],
      squares: [],
    })
    const url = GEOCOMPO_API
    const cred = { method: "POST", headers: header, body: text }
    DEBUG && console.log(`Create a GeoCompo : ${text} to ${url}`)
    try {
      const resp = await fetch(url, cred)
      const data = await resp.json()
      DEBUG && console.log(`Sent data : `, data)
      return data
    } catch (err) {
      console.warn(`ERROR CREATING GEOCOMPO : ${err.message}`)
      return await Promise.reject(err)
    }
  }

  const duplicateGeoCompo = (gcId, newName) => {
    return getGeoCompoByName(gcId)
      .then((geocompo) => {
        const url = GEOCOMPO_API + geocompo.id + "/duplicate/"
        const formData = new FormData()
        formData.append("name", newName)
        DEBUG && console.log(`Will send form data:`)
        DEBUG && console.log(...formData)
        return fetch(`${url}`, {
          method: "POST",
          body: formData,
        })
      })
      .then((res) => res.json())
      .then((res) => {
        DEBUG && console.log(`duplicated geocompo : `, res)
        return res
      })
      .catch((err) =>
        console.warn(`ERROR DURING GEOCOMPO DUPLICATION : ${err.message}`)
      )
  }

  const updateAndCleanGeoCompo = async (gc, data) => {
    geoCompoId = gc.id
    const [pts, polygons, masks, circles, squares] = data
    const ptToDelete = gc.points.filter((p) => !pts.includes(p))
    const poToDelete = gc.polygons.filter((p) => !polygons.includes(p))
    const maToDelete = gc.masks.filter((m) => !masks.includes(m))
    const ciToDelete = gc.circles.filter((c) => !circles.includes(c))
    const sqToDelete = gc.squares.filter((s) => !squares.includes(s))
    await Promise.all(ptToDelete.map((p) => deleteObject("point", p, false)))
    await Promise.all(poToDelete.map((p) => deleteObject("polygon", p, false)))
    await Promise.all(maToDelete.map((m) => deleteObject("mask", m, false)))
    await Promise.all(ciToDelete.map((c) => deleteObject("circle", c, false)))
    await Promise.all(sqToDelete.map((s) => deleteObject("square", s, false)))
    const text = JSON.stringify({
      points: pts,
      polygons: polygons,
      masks: masks,
      circles: circles,
      squares: squares,
    })
    await updateGeoCompo(text)
  }

  const updateGeoCompo = (patch) => {
    const url = GEOCOMPO_API + geoCompoId + "/"
    const cred = { method: "PATCH", headers: header, body: patch }
    DEBUG && console.log(`Update a GeoCompo : ${url} with ${patch}`)
    return fetch(url, cred).catch((err) => {
      DEBUG && console.log(`ERROR UPDATING GEOCOMPO : ${err.message}`)
      return Promise.reject(err)
    })
  }

  const addPointToGeoCompo = (key) => {
    // add point id to local list and update GeoCompo on server
    pointIds.push(key)
    const text = JSON.stringify({
      points: pointIds,
    })
    return updateGeoCompo(text).then(() => key)
  }

  const addPolygonToGeoCompo = (key) => {
    // add point id to local list and update GeoCompo on server
    polygonIds.push(key)
    const text = JSON.stringify({
      polygons: polygonIds,
    })
    return updateGeoCompo(text).then(() => key)
  }

  const addMaskToGeoCompo = (key) => {
    // add point id to local list and update GeoCompo on server
    maskIds.push(key)
    const text = JSON.stringify({
      masks: maskIds,
    })
    return updateGeoCompo(text).then(() => key)
  }

  const addSquareToGeoCompo = (key) => {
    // add point id to local list and update GeoCompo on server
    squareIds.push(key)
    const text = JSON.stringify({
      squares: squareIds,
    })
    return updateGeoCompo(text).then(() => key)
  }

  const addCircleToGeoCompo = (key) => {
    // add point id to local list and update GeoCompo on server
    circleIds.push(key)
    const text = JSON.stringify({
      circles: circleIds,
    })
    return updateGeoCompo(text).then(() => key)
  }

  const removeObjFromGeoCompo = (type, key) => {
    let text = ""
    if (type === "polygon") {
      polygonIds = polygonIds.filter((el) => el !== key)
      text = JSON.stringify({
        polygons: polygonIds,
      })
    } else if (type === "mask") {
      maskIds = maskIds.filter((el) => el !== key)
      text = JSON.stringify({
        masks: maskIds,
      })
    } else if (type === "circle") {
      circleIds = circleIds.filter((el) => el !== key)
      text = JSON.stringify({
        circles: circleIds,
      })
    } else if (type === "point") {
      pointIds = pointIds.filter((el) => el !== key)
      text = JSON.stringify({
        points: pointIds,
      })
    } else if (type === "square") {
      squareIds = squareIds.filter((el) => el !== key)
      text = JSON.stringify({
        squares: squareIds,
      })
    }
    return text ? updateGeoCompo(text).then(() => key) : key
  }

  const getObjectByAPI = async (api, key) => {
    const url = api + key + "/"
    const cred = { method: "GET", headers: header }
    try {
      const resp = await fetch(url, cred)
      const as_json = await resp.json()
      return as_json
    } catch (err) {
      return console.log(`ERROR GETTING ${key} : ${err.message}`)
    }
  }

  const loadGeoCompoData = () => {
    DEBUG && console.log(`Load data from GC ${geoCompoId}`)
    return Promise.all([
      loadPoints(pointIds),
      loadPolygons(polygonIds),
      loadMasks(maskIds),
      loadCircles(circleIds),
      loadSquares(squareIds),
    ])
  }

  const loadPoints = (pointIdList) => {
    return Promise.all(
      pointIdList.map((ptId) =>
        getObjectByAPI(POINT3D_API, ptId).then((point) => {
          DEBUG && console.log(`load point ${JSON.stringify(point)}`)
          let constructor = {
            id: point.id,
            name: point.name,
            color: point.color,
            label_position: point.label_position,
            label_bg_color: point.label_bg_color,
            label_size: point.label_size,
            points: [point.x, point.y, point.z],
          }
          return new Point3d(constructor)
        })
      )
    )
  }

  const loadCoords = (pointId) => {
    return getObjectByAPI(POINT3D_API, pointId).then((point) => {
      DEBUG && console.log(`load coord ${JSON.stringify(point)}`)
      return point //[point.x, point.y, point.z]
    })
  }

  const loadCoordsList = (pointIdList) => {
    return Promise.all(pointIdList.map((ptId) => loadCoords(ptId)))
  }

  const loadPolygon = async (polygon) => {
    DEBUG && console.log(`load polygon ${JSON.stringify(polygon)}`)
    let pointlist = await loadPoints(polygon.points)
    let constructor = {
      id: polygon.id,
      name: polygon.name,
      color: polygon.color,
      stroke_width: polygon.stroke_width,
      bg_color: polygon.bg_color ?? DEFAULT_FILL_COLOR,
      points: pointlist.sort((a, b) => a.name.localeCompare(b.name)),
    }
    return new Polygon(constructor)
  }

  const loadPolygons = (polygonIdList) => {
    return Promise.all(
      polygonIdList.map((id) =>
        getObjectByAPI(POLYGON_API, id).then((polygon) => loadPolygon(polygon))
      )
    )
  }

  const loadMask = async (mask) => {
    DEBUG && console.log(`load mask ${JSON.stringify(mask)}`)
    const pointlist = await loadPoints(mask.points)
    const [p1, p2, ...holes] = pointlist.sort((a, b) =>
      a.name.localeCompare(b.name)
    )
    let constructor = {
      id: mask.id,
      name: mask.name,
      points: [p1, p2],
      holes: holes,
    }
    return new Mask(constructor)
  }

  const loadMasks = (maskIdList) => {
    return Promise.all(
      maskIdList.map((id) =>
        getObjectByAPI(MASK_API, id).then((mask) => loadMask(mask))
      )
    )
  }

  const loadSquare = async (square) => {
    DEBUG && console.log(`load square ${JSON.stringify(square)}`)
    let pointlist = await loadPoints(square.points)
    let constructor = {
      id: square.id,
      name: square.name,
      color: square.color,
      label_position: square.label_position,
      label_bg_color: square.label_bg_color,
      label_size: square.label_size,
      stroke_width: square.stroke_width,
      bg_color: square.bg_color,
      points: pointlist.sort((a, b) => a.name.localeCompare(b.name)),
    }
    return new Square(constructor)
  }

  const loadSquares = (squareIdList) => {
    return Promise.all(
      squareIdList.map((id) =>
        getObjectByAPI(SQUARE_API, id).then((square) => loadSquare(square))
      )
    )
  }

  const loadCircle = async (circle) => {
    DEBUG && console.log(`load circle ${JSON.stringify(circle)}`)
    let center_point = await getObjectByAPI(POINT3D_API, circle.center)
    DEBUG && console.log(`load center point ${JSON.stringify(center_point)}`)
    let center_point_constructor = {
      id: center_point.id,
      name: center_point.name,
      color: center_point.color,
      label_position: center_point.label_position,
      label_bg_color: center_point.label_bg_color,
      label_size: center_point.label_size,
      points: [center_point.x, center_point.y, center_point.z],
    }
    let center = new Point3d(center_point_constructor)
    let constructor = {
      id: circle.id,
      name: circle.name,
      center: center,
      radius: circle.radius,
      normal: circle.noraml,
      color: circle.color,
      label_position: circle.label_position,
      label_bg_color: circle.label_bg_color,
      label_size: circle.label_size,
      stroke_width: circle.stroke_width,
      bg_color: circle.bg_color,
    }
    return new Circle(constructor)
  }

  const loadCircles = (circleIdList) => {
    return Promise.all(
      circleIdList.map((id) =>
        getObjectByAPI(CIRCLE_API, id).then((circle) => loadCircle(circle))
      )
    )
  }

  const saveGeoCompoData = async (
    geoCompoId,
    points,
    polygons,
    masks,
    circles,
    squares
  ) => {
    DEBUG && console.log(`Save/Merge geom data to GC ${geoCompoId}`)
    try {
      let geocompo = await initGeoCompo(geoCompoId)
      let res = await Promise.all([
        savePoints(geocompo, points),
        savePolygons(geocompo, polygons),
        saveMasks(geocompo, masks),
        saveCircles(geocompo, circles),
        saveSquares(geocompo, squares),
      ])
      await updateAndCleanGeoCompo(geocompo, res)
      DEBUG && console.log("SaveGeoCompoDataSuccessful")
    } catch (error) {
      console.warn("SaveGeoCompoDataError : ", error)
    }
  }

  const savePoints = (gc, points) => {
    return Promise.all(
      points.map((pt) =>
        gc.points.includes(pt.id) ? updatePoint(pt) : sendPoint(pt, false)
      )
    )
  }

  const savePolygons = (gc, polygons) => {
    return Promise.all(
      polygons.map((p) =>
        gc.polygons.includes(p.id) ? updatePolygon(p) : sendPolygon(p, false)
      )
    )
  }

  const saveMasks = (gc, masks) => {
    return Promise.all(
      masks.map((m) =>
        gc.masks.includes(m.id) ? updateMask(m) : sendMask(m, false)
      )
    )
  }

  const saveCircles = (gc, circles) => {
    return Promise.all(
      circles.map((c) =>
        gc.circles.includes(c.id) ? updateCircle(c) : sendCircle(c, false)
      )
    )
  }

  const saveSquares = (gc, squares) => {
    return Promise.all(
      squares.map((s) =>
        gc.squares.includes(s.id) ? updateSquare(s) : sendSquare(s, false)
      )
    )
  }

  const sendPoint = async (point, updateGc = true, pos = undefined) => {
    let [x, y, z] = [...point.points]
    let [name, color, label_position, label_bg_color, label_size] = [
      point.name,
      point.color,
      point.label_position,
      point.label_bg_color,
      point.label_size,
    ]
    z = z ?? 0
    // remove already existing leading numbers
    if (pos !== undefined) {
      if (name[4] === "#") name = name.slice(5)
      name = ("000" + pos).slice(-4) + "#" + name
    }
    let payload = {
      name: name,
      color: color ?? [255, 255, 255, 1],
      label_position: label_position ?? "NE",
      label_bg_color: label_bg_color ?? LABEL_BG_DEFAULT_ARR,
      label_size: label_size ?? 48,
      x: x,
      y: y,
      z: z,
    }
    const text = JSON.stringify(payload)

    const url = POINT3D_API
    const cred = { method: "POST", headers: header, body: text }
    DEBUG && console.log(`Send a Point : ${text} to ${url}`)
    try {
      const resp = await fetch(url, cred)
      const data = await resp.json()
      DEBUG && console.log(`Sent data : ${JSON.stringify(data)}`)
      const id = data.id
      return updateGc ? addPointToGeoCompo(id) : id
    } catch (err) {
      console.warn(`ERROR SENDING POINT : ${err.message}`)
      return await Promise.reject(err)
    }
  }

  const sendCoords = (name, coords) => {
    let [x, y, z] = [...coords]
    z = z ? z : 0
    const text = JSON.stringify({ name: name, x: x, y: y, z: z })

    const url = POINT3D_API
    const cred = { method: "POST", headers: header, body: text }
    DEBUG && console.log(`Send a Point : ${text} to ${url}`)
    return fetch(url, cred)
      .then((resp) => resp.json())
      .then((data) => {
        DEBUG && console.log(`Sent data : ${JSON.stringify(data)}`)
        return data.id
      })
      .catch((err) => {
        console.warn(`ERROR SENDING COORDS : ${err.message}`)
        return Promise.reject(err)
      })
  }

  const sendPolygon = (polygon, updateGc = true) => {
    DEBUG &&
      console.log(`SendPolygon: Prepare polygon ${JSON.stringify(polygon)}`)
    return (
      Promise.all(
        // send all polygon points to server
        polygon.points.map((point, i) => sendPoint(point, false, i))
      )
        // then send the polygon definition to server
        .then((pointIdList) => {
          const text = JSON.stringify({
            name: polygon.name,
            points: pointIdList,
            color: polygon.color ?? [255, 255, 255, 1],
            stroke_width: polygon.stroke_width ?? 5,
            bg_color: polygon.bg_color ?? DEFAULT_FILL_COLOR,
          })
          const url = POLYGON_API
          const cred = { method: "POST", headers: header, body: text }
          DEBUG && console.log(`Send a Polygon : ${text} to ${url}`)
          return fetch(url, cred)
        })
        .then((resp) => resp.json())
        .then((data) => {
          DEBUG && console.log(`Sent data : ${JSON.stringify(data)}`)
          return data.id
        })
        .then((id) => (updateGc ? addPolygonToGeoCompo(id) : id))
        .catch((err) => {
          console.warn(`ERROR SENDING POLYGON : ${err.message}`)
          return Promise.reject(err)
        })
    )
  }

  const sendCircle = async (circle, updateGc = true) => {
    DEBUG && console.log(`SendCircle: Prepare circle ${JSON.stringify(circle)}`)
    try {
      const [name, normal, radius] = [circle.name, circle.normal, circle.radius]
      let centerPtId = await sendPoint(circle.center, false)
      const text = JSON.stringify({
        name: name,
        center: centerPtId,
        normal: normal ?? [0, 0, 1],
        radius: radius,
        color: circle.color ?? [255, 255, 255, 1],
        label_position: circle.label_position ?? "NE",
        label_bg_color: circle.label_bg_color ?? LABEL_BG_DEFAULT_ARR,
        label_size: circle.label_size ?? 48,
        stroke_width: circle.stroke_width ?? 5,
        bg_color: circle.bg_color ?? DEFAULT_FILL_COLOR,
      })
      const url = CIRCLE_API
      const cred = { method: "POST", headers: header, body: text }
      DEBUG && console.log(`Send a Circle : ${text} to ${url}`)
      let resp = await fetch(url, cred)
      let resp_json = await resp.json()
      DEBUG && console.log(`Sent data : ${JSON.stringify(resp_json)}`)
      let id = resp_json.id
      if (updateGc) {
        return addCircleToGeoCompo(id)
      } else {
        return id
      }
    } catch (err) {
      console.warn(`ERROR SENDING CIRCLE : ${err.message}`)
      return Promise.reject(err)
    }
  }

  const sendMask = (mask, updateGc = true) => {
    DEBUG && console.log(`SendMask: Prepare mask ${JSON.stringify(mask)}`)
    return (
      Promise.all(
        // send all mask points to server
        mask.points
          .concat(mask.holes)
          .map((point, i) => sendPoint(point, false, i))
      )
        // then send the polygon definition to server
        .then((pointIdList) => {
          const text = JSON.stringify({
            name: mask.name,
            points: pointIdList,
          })
          const url = MASK_API
          const cred = { method: "POST", headers: header, body: text }
          DEBUG && console.log(`Send a Mask : ${text} to ${url}`)
          return fetch(url, cred)
        })
        .then((resp) => resp.json())
        .then((data) => {
          DEBUG && console.log(`Sent data : ${JSON.stringify(data)}`)
          return data.id
        })
        .then((id) => (updateGc ? addMaskToGeoCompo(id) : id))
        .catch((err) => {
          console.warn(`ERROR SENDING MASK : ${err.message}`)
          return Promise.reject(err)
        })
    )
  }

  const sendSquare = (square, updateGc = true) => {
    //console.log(`SendSquare: Prepare square ${JSON.stringify(square)}`)
    const [name, points] = [square.name, square.points]
    DEBUG &&
      console.log(
        `SendSquare: Prepare square ${JSON.stringify(
          square
        )} with name : ${name} and points : ${points}`
      )
    return (
      Promise.all(
        // send all polygon points to server
        square.points.map((point, i) => sendPoint(point, false, i))
      )
        // then send the polygon definition to server
        .then((pointIdList) => {
          const text = JSON.stringify({
            name: square.name,
            points: pointIdList,
            color: square.color ?? [255, 255, 255, 1],
            label_position: square.label_position ?? "NE",
            label_bg_color: square.label_bg_color ?? LABEL_BG_DEFAULT_ARR,
            label_size: square.label_size ?? 48,
            stroke_width: square.stroke_width ?? 5,
            bg_color: square.bg_color ?? DEFAULT_FILL_COLOR,
          })
          const url = SQUARE_API
          const cred = { method: "POST", headers: header, body: text }
          DEBUG && console.log(`Send a Square : ${text} to ${url}`)
          return fetch(url, cred)
        })
        .then((resp) => resp.json())
        .then((data) => {
          DEBUG && console.log(`Sent data : ${JSON.stringify(data)}`)
          return data.id
        })
        .then((id) => (updateGc ? addSquareToGeoCompo(id) : id))
        .catch((err) => {
          console.warn(`ERROR SENDING SQUARE : ${err.message}`)
          return Promise.reject(err)
        })
    )
  }

  const updatePoint = (point, pos = undefined) => {
    let [x, y, z] = [...point.points]
    let [name, color, label_position, label_bg_color, label_size] = [
      point.name,
      point.color,
      point.label_position,
      point.label_bg_color,
      point.label_size,
    ]
    z = z ?? 0
    // remove already existing leading numbers
    if (pos !== undefined) {
      if (name[4] === "#") name = name.slice(5)
      name = ("000" + pos).slice(-4) + "#" + name
    }
    const text = JSON.stringify({
      name: name,
      x: x,
      y: y,
      z: z,
      color: color ?? [255, 255, 255, 1],
      label_position: label_position ?? "NE",
      label_bg_color: label_bg_color ?? LABEL_BG_DEFAULT_ARR,
      label_size: label_size ?? 48,
    })

    const url = POINT3D_API + point.id + "/"
    const cred = {
      method: "PATCH",
      headers: header,
      body: text,
    }
    DEBUG && console.log(`Update a Point : ${text} to ${url}`)
    return fetch(url, cred)
      .then((resp) => resp.json())
      .then((data) => data.id)
      .catch((err) => {
        console.warn(`ERROR UPDATING POINT : ${err.message}`)
        return Promise.reject(err)
      })
  }

  const updatePolygon = (polygon) => {
    DEBUG &&
      console.log(`UpdatePolygon: Prepare polygon ${JSON.stringify(polygon)}`)
    let poFromDb = undefined
    return (
      getObjectByAPI(POLYGON_API, polygon.id)
        // firstly check points that are for deletion and delete them
        .then((po) => {
          poFromDb = po
          const ptToDelete = po.points.filter(
            (p) => !polygon.points.find((crtp) => crtp.id === p)
          )
          Promise.all(ptToDelete.map((p) => deleteObject("point", p, false)))
        })
        // then update or send all polygon points to server
        .then(() =>
          Promise.all(
            polygon.points.map((point, i) =>
              poFromDb.points.includes(point.id)
                ? updatePoint(point, i)
                : sendPoint(point, false, i)
            )
          )
        )
        // then update the polygon definition on the server
        .then((pointIdList) => {
          const text = JSON.stringify({
            name: polygon.name,
            points: pointIdList,
            color: polygon.color ?? [255, 255, 255, 1],
            stroke_width: polygon.stroke_width ?? 5,
            bg_color: polygon.bg_color ?? DEFAULT_FILL_COLOR,
          })
          const url = POLYGON_API + polygon.id + "/"
          const cred = { method: "PATCH", headers: header, body: text }
          DEBUG && console.log(`Update a Polygon : ${text} to ${url}`)
          return fetch(url, cred)
        })
        .then((resp) => resp.json())
        .then((data) => data.id)
        .catch((err) => {
          console.warn(`ERROR UPDATING POLYGON : ${err.message}`)
          return Promise.reject(err)
        })
    )
  }

  const updateMask = (mask) => {
    DEBUG && console.log(`UpdateMask: Prepare mask ${JSON.stringify(mask)}`)
    let maFromDb = undefined
    const allPts = mask.points.concat(mask.holes)
    return (
      getObjectByAPI(MASK_API, mask.id)
        // firstly check points that are for deletion and delete them
        .then((ma) => {
          maFromDb = ma
          const ptToDelete = ma.points.filter(
            (p) => !allPts.find((crtp) => crtp.id === p)
          )
          Promise.all(ptToDelete.map((p) => deleteObject("point", p, false)))
        })
        // then update or send all mask points to server
        .then(() =>
          Promise.all(
            allPts.map((point, i) =>
              maFromDb.points.includes(point.id)
                ? updatePoint(point, i)
                : sendPoint(point, false, i)
            )
          )
        )
        // then send the polygon definition to server
        .then((pointIdList) => {
          const text = JSON.stringify({
            name: mask.name,
            points: pointIdList,
          })
          const url = MASK_API + mask.id + "/"
          const cred = { method: "PATCH", headers: header, body: text }
          DEBUG && console.log(`Send a Mask : ${text} to ${url}`)
          return fetch(url, cred)
        })
        .then((resp) => resp.json())
        .then((data) => data.id)
        .catch((err) => {
          console.warn(`ERROR UPDATING MASK : ${err.message}`)
          return Promise.reject(err)
        })
    )
  }

  const updateSquare = (square) => {
    DEBUG &&
      console.log(`UpdateSquare: Prepare square ${JSON.stringify(square)}`)
    let sqFromDb = undefined
    return (
      getObjectByAPI(SQUARE_API, square.id)
        // firstly check points that are for deletion and delete them
        .then((sq) => {
          sqFromDb = sq
          const ptToDelete = sq.points.filter(
            (p) => !square.points.find((crtp) => crtp.id === p)
          )
          Promise.all(ptToDelete.map((p) => deleteObject("square", p, false)))
        })
        // then update or send all points to server
        .then(() =>
          Promise.all(
            square.points.map((point, i) =>
              sqFromDb.points.includes(point.id)
                ? updatePoint(point, i)
                : sendPoint(point, false, i)
            )
          )
        )
        // then update the definition on the server
        .then((pointIdList) => {
          const text = JSON.stringify({
            name: square.name,
            points: pointIdList,
            // TODO : color format is incompatible with the server database
            color: square.color ?? [255, 255, 255, 1],
            label_position: square.label_position ?? "NE",
            label_bg_color: square.label_bg_color ?? LABEL_BG_DEFAULT_ARR,
            label_size: square.label_size ?? 48,
            stroke_width: square.stroke_width ?? 5,
            bg_color: square.bg_color ?? DEFAULT_FILL_COLOR,
          })
          const url = SQUARE_API + square.id + "/"
          const cred = { method: "PATCH", headers: header, body: text }
          DEBUG && console.log(`Update a Square : ${text} to ${url}`)
          return fetch(url, cred)
        })
        .then((resp) => resp.json())
        .then((data) => data.id)
        .catch((err) => {
          console.warn(`ERROR UPDATING SQUARE : ${err.message}`)
          return Promise.reject(err)
        })
    )
  }

  const updateMaskAddHole = (maskId, points) => {
    console.log(`updateMask: Add Hole in ${maskId}`)
    return getObjectByAPI(MASK_API, maskId).then((mask) =>
      Promise.all(
        // send all mask points to server
        points.map((point, i) => sendCoords(mask.name + " point " + i, point))
      )
        // then send the polygon definition to server
        .then((pointIdList) => {
          const text = JSON.stringify({
            points: mask.points.concat(pointIdList),
          })
          const url = MASK_API + maskId + "/"
          const cred = { method: "PATCH", headers: header, body: text }
          console.log(`Update a Mask : ${text} to ${url}`)
          return fetch(url, cred)
        })
        .catch((err) => {
          console.log(`ERROR UPDATING MASK HOLES : ${err.message}`)
          return Promise.reject(err)
        })
    )
  }

  const updateCircle = (circle) => {
    DEBUG &&
      console.log(`UpdateCircle: Prepare circle ${JSON.stringify(circle)}`)
    const [name, normal, radius] = [circle.name, circle.normal, circle.radius]
    return updatePoint(circle.center)
      .then((centerPtId) => {
        const text = JSON.stringify({
          name: name,
          center: centerPtId,
          normal: normal,
          radius: radius,
          color: circle.color ?? [255, 255, 255, 1],
          label_position: circle.label_position ?? "NE",
          label_bg_color: circle.label_bg_color ?? LABEL_BG_DEFAULT_ARR,
          label_size: circle.label_size ?? 48,
          stroke_width: circle.stroke_width ?? 5,
          bg_color: circle.bg_color ?? DEFAULT_FILL_COLOR,
        })
        const url = CIRCLE_API + circle.id + "/"
        const cred = { method: "PATCH", headers: header, body: text }
        DEBUG && console.log(`Update a Circle : ${text} to ${url}`)
        return fetch(url, cred)
      })
      .then((resp) => resp.json())
      .then((data) => data.id)
      .catch((err) => {
        console.warn(`ERROR UPDATING CIRCLE : ${err.message}`)
        return Promise.reject(err)
      })
  }

  const updateName = (type, key, name) => {
    const api =
      type === "point"
        ? POINT3D_API
        : type === "polygon"
        ? POLYGON_API
        : type === "mask"
        ? MASK_API
        : type === "square"
        ? SQUARE_API
        : CIRCLE_API
    const url = api + key + "/"
    const text = JSON.stringify({
      name: name,
    })
    const cred = { method: "PATCH", headers: header, body: text }
    console.log(`Update a ${type} : ${url} with ${text}`)
    return fetch(url, cred)
      .then(() => {
        return type === "polygon"
          ? postUpdatePolygon(key, name)
          : type === "mask"
          ? postUpdateMask(key, name)
          : type === "circle"
          ? postUpdateCircle(key, name)
          : type === "square"
          ? postUpdateSquare(key, name)
          : Promise.resolve()
      })
      .catch((err) => {
        console.log(`ERROR UPDATING ${type.toUpperCase()} : ${err.message}`)
        return Promise.reject(err)
      })
  }

  const postUpdatePolygon = (key, name) => {
    const url = POLYGON_API + key + "/"
    const cred = { method: "GET", headers: header }
    return fetch(url, cred)
      .then((resp) => resp.json())
      .then((polygon) => loadCoordsList(polygon.points))
      .then((pointlist) =>
        Promise.all(
          pointlist.map((p, i) =>
            updateName("point", p.id, name + " point " + i)
          )
        )
      )
  }

  const postUpdateMask = (key, name) => {
    const url = MASK_API + key + "/"
    const cred = { method: "GET", headers: header }
    return fetch(url, cred)
      .then((resp) => resp.json())
      .then((mask) => loadCoordsList(mask.points))
      .then((pointlist) =>
        Promise.all(
          pointlist.map((p, i) =>
            updateName("point", p.id, name + " point " + i)
          )
        )
      )
  }

  const postUpdateSquare = (key, name) => {
    const url = SQUARE_API + key + "/"
    const cred = { method: "GET", headers: header }
    return fetch(url, cred)
      .then((resp) => resp.json())
      .then((square) => loadCoordsList(square.points))
      .then((pointlist) =>
        Promise.all(
          pointlist.map((p, i) =>
            updateName("point", p.id, name + " point " + i)
          )
        )
      )
  }

  const postUpdateCircle = (key, name) => {
    const url = CIRCLE_API + key + "/"
    const cred = { method: "GET", headers: header }
    return fetch(url, cred)
      .then((resp) => resp.json())
      .then((data) => updateName("point", data.center, name + " center"))
  }

  const deleteObject = (type, key, removeFromGC = true) => {
    const api =
      type === "point"
        ? POINT3D_API
        : type === "polygon"
        ? POLYGON_API
        : type === "mask"
        ? MASK_API
        : type === "circle"
        ? CIRCLE_API
        : type === "square"
        ? SQUARE_API
        : GEOCOMPO_API
    const url = api + key + "/"
    const cred = { method: "DELETE", headers: header }
    DEBUG && console.log(`Delete a ${type} : ${url}`)
    return fetch(url, cred)
      .then(() => (removeFromGC ? removeObjFromGeoCompo(type, key) : ""))
      .catch((err) => {
        console.error(`ERROR DELETING ${type.toUpperCase()} : ${err.message}`)
        return Promise.reject(err)
      })
  }

  return {
    getGeoCompoByName,
    initGeoCompo,
    loadGeoCompoData,
    saveGeoCompoData,
    duplicateGeoCompo,
    sendPoint,
    sendPolygon,
    sendMask,
    updateMaskAddHole,
    sendCircle,
    sendSquare,
    updateName,
    deleteObject,
  }
}
