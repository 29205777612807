import React, { useEffect, useState, useRef } from "react"
import ReactTooltip from "react-tooltip"
import { FaUndo } from "react-icons/fa"
import { readJson } from "../common/utils"

// JSON Modal usage mode
export const JSONModalMode = { JSON_FILE: "JSON_FILE", METADATA: "METADATA" }

// textarea value for JSON editor
const default_text = { value: "", caret: -1, target: null }

export const JSONModal = ({ params, spaces = 4 }) => {
  // Cache of the initial JSON by which the user can undo everything
  const [cache, setCache] = useState()
  const [text, setText] = useState({ value: "", caret: -1, target: null })
  // Did the JSON file origianlly had the command or command_label key ?
  const [hadCommandKey, setHadCommandKey] = useState(false)
  const [msg, setMsg] = useState(<></>)
  const [processing, setProcessing] = useState(false)
  const textareaRef = useRef(null)

  const handleTab = (e) => {
    let content = e.target.value
    let caret = e.target.selectionStart
    if (e.key === "Tab") {
      e.preventDefault()
      let newText =
        content.substring(0, caret) +
        " ".repeat(spaces) +
        content.substring(caret)
      setText({ value: newText, caret: caret, target: e.target })
    }
  }
  const handleText = (e) =>
    setText({ value: e.target.value, caret: -1, target: e.target })

  const validate = async () => {
    try {
      const as_JSON = JSON.parse(text.value)
      if (as_JSON) {
        setProcessing(true)
        // Update current file
        const hasCommandKey = Boolean(as_JSON.command || as_JSON.command_label)
        const rerenderCards = hadCommandKey !== hasCommandKey
        const result = await params.updateJSON(
          as_JSON,
          params.source,
          rerenderCards
        )
        if (result === "success") {
          setMsg(
            <span style={{ color: "#49C990" }}>
              Successfully saved JSON data!
            </span>
          )
          // close modal on save success
          setTimeout(() => params.onCancel(), 1000)
        } else {
          setMsg(
            <span style={{ color: "#FA4B52" }}>
              Internal Error - could not update JSON data
            </span>
          )
        }
      }
    } catch (e) {
      setMsg(
        <>
          <span style={{ color: "#FA4B52" }}>Failed to parse JSON file!</span>
          <span style={{ color: "#FA4B52" }}>Please verify the format.</span>
          <span style={{ color: "#FA4B52" }}>
            (Trailing commas are not allowed)
          </span>
        </>
      )
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
    if (params.mode === JSONModalMode.JSON_FILE && params.source?.src) {
      readJson(params.source.src).then((obj) => {
        if (obj) {
          const data = JSON.stringify(obj, null, "    ") //\t works too
          setCache(data)
          setText((prev) => ({
            ...prev,
            value: data,
          }))
          setHadCommandKey(Boolean(obj.command || obj.command_label))
          const area = document.getElementById("json-textarea")
          area.style.width =
            Math.max(
              680,
              Math.min(1080, textareaRef.current.scrollWidth + 150)
            ) + "px"
          area.style.padding = "5px 50px 5px 15px"
        }
      })
    } else if (params.mode === JSONModalMode.METADATA) {
      const data = JSON.stringify(
        params.source.metadata.user ? params.source.metadata.user : {},
        null,
        4
      )
      setCache(data)
      setText((prev) => ({
        ...prev,
        value: data,
      }))
    }
    return () => {
      setText({ value: "", caret: -1, target: null })
    }
  }, [params])

  useEffect(() => {
    if (text.caret >= 0) {
      text.target.setSelectionRange(text.caret + spaces, text.caret + spaces)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  return (
    <>
      {msg ? <div className="json-editor-message-container">{msg}</div> : <></>}
      {text.value ? (
        <>
          <textarea
            ref={textareaRef}
            id="json-textarea"
            className="json-wrapper"
            data-role="none"
            value={text.value}
            onKeyDown={handleTab}
            onChange={handleText}
            // Override CSS Color for readability
            style={{ backgroundColor: "#111111" }}
          ></textarea>
          {cache ? (
            <div className="json-editor-undo" data-tip data-for={"undo_json"}>
              <FaUndo
                onClick={() => {
                  // initialize
                  setText({ ...default_text, value: cache })
                }}
              />
              <ReactTooltip id={"undo_json"}>
                <span>Undo All</span>
              </ReactTooltip>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <div className="button-wrapper">
        <button
          className="text-center btn col-4"
          onClick={() => {
            validate(false)
          }}
          disabled={false}
          type="button"
        >
          {processing ? "Processing" : "Save"}
        </button>
        <button className="text-center btn col-4" onClick={params.onCancel}>
          Cancel
        </button>
      </div>
    </>
  )
}
//
