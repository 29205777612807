// LABELS
export const DEFAULT_LABEL_SIZE = 48
export const LABEL_PADDING_TOP = 7
export const DEFAULT_LABEL_OFFSET_X = 10
export const DEFAULT_LABEL_OFFSET_Y = 10
export const LABEL_BG_SHROUDED = "rgba(255,255,255,0.25)"
export const LABEL_BG_DEFAULT = "rgba(0,0,0,0.65)"
export const LABEL_BG_MAX_OPACITY = 1
export const DEFAULT_LABEL_FONT = {
  fontFamily: "Calibri",
  //fontSize: "48px" = 3em in FHD, now overriden by database,
  fontWeight: "bold",
  strokeWidth: "1",
  stroke: "none",
  strokeOpacity: "1.0",
  //point of interest : colorpicker target
  fill: "white",
  fillOpacity: "1.0",
}

// COLORS
export const SELECTED_HIGHLIGHT = "drop-shadow(0px 0 4px orange)"
export const DEFAULT_FILL_COLOR = [127, 127, 127, 0]
export const LABEL_BG_DEFAULT_ARR = [0, 0, 0, 0.65]
export const SHROUDED_OPACITY = 0.66
export const FILL_UNSELECTED = "rgba(0,0,0,0.17)" // for copy&paste tool

// GLOBAL
export const DEFAULT_PRIMARY_HANDLE_SIZE = 16
export const DEFAULT_HANDLE_RADIUS = 8

// POINTS
export const DEFAULT_POINT_RADIUS = 8

// CIRCLES
export const DEFAULT_CIRCLE_STROKE_WIDTH = 5

// SQUARES
export const DEFAULT_SQUARE_STROKE_WIDTH = 5
export const DEFAULT_SQUARE_ROUNDED = 5

// POLYGONS
export const DEFAULT_POLYGON_STROKE_WIDTH = 5
