import UPNG from "upng-js"
import { HMFileIO } from "../io/HMFileIO"

const DEBUG = false

const processTree = (tree, objIdx, result, ext_idx) => {
  for (let i = 0; i < tree.components.length; i++) {
    let c = tree.components[i]
    if (c.components && c.components.length > 0)
      processTree(c, objIdx, result, ext_idx)
    else {
      if (DEBUG) console.log(`Found a leaf: ${c.name} parent is ${tree.name}`)
      let idxFound = objIdx.findIndex(
        (v) => c.name.slice(0, -ext_idx) === v.name.slice(0, -ext_idx)
      )
      // warning: some objects could be missing because removed from the render
      if (idxFound >= 0) {
        if (!result[tree.name]) result[tree.name] = []
        result[tree.name].push(idxFound)
      }
    }
  }
  return result
}
/**
 * fabrique le catalog qui est la liste des pixels pour chaque valeur d'indice d'objet
 */
const processIndexes = (im) => {
  let catalog = {}
  for (let i = 0; i < im.data.length; i += 4) {
    if (im.data[i + 3] === 255) {
      const v = im.data[i] + (im.data[i + 1] << 8) + (im.data[i + 2] << 16)
      if (!catalog[v]) catalog[v] = []
      catalog[v].push(i)
    }
  }
  //console.log(`Done processing indexes: `, catalog)
  return catalog
}
const initObjSelector = (picture) => {
  if (DEBUG) console.log(`Init Obj Selector for picture ${picture.src}`)
  let imageIndex = undefined
  let objComponents = undefined
  let objTree = undefined
  let compounds = {}
  return (
    HMFileIO()
      // load global descriptor from this picture render
      .compose(picture.id)
      .then((json) => {
        if (!json) throw new Error(`No data on server for this picture`)
        if (!json.index_uri) return Promise.reject("No linked index picture.")
        if (DEBUG) console.log(`Found config data, content = `, json)
        objComponents = json.descriptor["components"]
        objTree = json.stp2tree
        if (DEBUG)
          console.log(`Found components in pic json desc: `, objComponents)
        return fetch(json.index_uri)
      })
      // load index picture file
      .then((response) => response.blob())
      .then((myBlob) => myBlob.arrayBuffer())
      .then((buffer) => {
        // Use UPNG lib: https://github.com/photopea/UPNG.js
        imageIndex = UPNG.decode(buffer)
        if (DEBUG)
          console.log(
            `Mon objet image = ${imageIndex.width}x${imageIndex.height} depth ${imageIndex.depth} and ctype ${imageIndex.ctype}`
          )
        if (DEBUG) console.log("Image data size : " + imageIndex.data.length)
        const catalog = processIndexes(imageIndex)
        // recuperer l'extension des fichiers references dans la liste des components pour l'utiliser dans le filtrage du processTree
        const ext_idx =
          objComponents[0].name.length - objComponents[0].name.lastIndexOf(".")
        if (objTree) processTree(objTree, objComponents, compounds, ext_idx)
        return {
          index: imageIndex,
          components: objComponents,
          indexPixels: catalog,
          compounds: compounds,
        }
      })
      .catch((error) => {
        if (DEBUG) console.log(`initObjSelector error : `, error)
      })
  )
}

const getPixelValue = (im, x, y) => {
  let pixelData = null
  // only 8 bits managed and rgb or rgba colour type
  let pixId
  switch (im.ctype) {
    case 0:
      // greyscale : one pixel = one greyscale sample
      console.error("WARN: greyscale image not managed")
      break
    case 2:
      // truecolour : one pixel = one triple R, G, B
      if (im.depth !== 8) {
        console.error("WARN: 16 bits image not managed")
        break
      }
      pixelData = new Uint8Array(4)
      pixId = x + y * im.width
      pixelData[0] = im.data[3 * pixId]
      pixelData[1] = im.data[3 * pixId + 1]
      pixelData[2] = im.data[3 * pixId + 2]
      pixelData[3] = 0
      break
    case 3:
      // indexed-colour : one pixel = one palette index
      console.error("WARN: indexed-colour image not managed")
      break
    case 4:
      // greyscale + alpha : one greyscale sample + alpha sample
      console.error("WARN: greyscale image not managed")
      break
    case 6:
      // truecolour + alpha : one triple R, G, B + alpha sample
      if (im.depth !== 8) {
        console.error("WARN: 16 bits image not managed")
        break
      }
      pixelData = new Uint8Array(4)
      pixId = x + y * im.width
      pixelData[0] = im.data[4 * pixId]
      pixelData[1] = im.data[4 * pixId + 1]
      pixelData[2] = im.data[4 * pixId + 2]
      pixelData[3] = im.data[4 * pixId + 3]
      break
    default:
      console.error("WARN: PNG type not managed: " + im.ctype)
  }
  return pixelData
}

const getIndexValue = (imageIndex, coords) => {
  let value = undefined
  if (
    imageIndex &&
    coords[0] > 0 &&
    coords[0] < imageIndex.width &&
    coords[1] > 0 &&
    coords[1] < imageIndex.height
  ) {
    value = getPixelValue(imageIndex, coords[0], coords[1])
    if (value[3] === 255) {
      const idx = value[0] + (value[1] << 8) + (value[2] << 16)
      //console.log(`For rgba ${value} resolve idx ${idx}`)
      value = idx
    } else value = -1
  }
  return value
}

export { processIndexes, initObjSelector, getPixelValue, getIndexValue }
