// HoloMake backend server
const DEV_URL = "http://127.0.0.1:8000/"
const WS_DEV_URL = "ws://127.0.0.1:8000/" //WS_PROD_URL //"ws://127.0.0.1:8000/"

const SSL_VM_URL = "https://server.holomake.fr/"
//const WS_PROD_URL = "ws://server.holomake.fr/"
const WSS_PROD_URL = "wss://server.holomake.fr/"

const WS_URL = process.env.NODE_ENV === "production" ? WSS_PROD_URL : WS_DEV_URL
const PROD_URL = process.env.NODE_ENV === "production" ? SSL_VM_URL : DEV_URL

// UPLOAD_API
const UPLOAD_URL = PROD_URL + "upload/"
const DIRECTORY_URL = PROD_URL + "directory/"

// CONVERT_API
const CONVERT_URL = PROD_URL + "convert/"
const COMPOSE_URL = PROD_URL + "compose/"
const ROTATE_URL = PROD_URL + "rotate/"
const THUMBNAIL_URL = PROD_URL + "thumbnail/"

// GEOM_API
const POINT3D_API = PROD_URL + "points3d/"
const POLYGON_API = PROD_URL + "polygons/"
const MASK_API = PROD_URL + "masks/"
const CIRCLE_API = PROD_URL + "circles/"
const GEOCOMPO_API = PROD_URL + "geocompo/"
const SQUARE_API = PROD_URL + "squares/"

// CALIBRATION API
const CALIBRATION_URL = PROD_URL + "calibration/"
const POSE_ESTIMATION_URL = PROD_URL + "pose_estimation/"

// HOLOMAKE STUDIO
const DEV_STUDIO_URL = "http://localhost:3000"
const PROD_STUDIO_URL = "https://studio.holomake.fr/"
const STUDIO_URL =
  process.env.NODE_ENV === "production" ? PROD_STUDIO_URL : DEV_STUDIO_URL

// HOLOMAKE VIEWER
const DEV_VIEWER_URL = "http://localhost:3002"
const PROD_VIEWER_URL = "https://webview.holomake.fr/" //"https://www.holomake.fr/webapp/"
const VIEWER_URL =
  process.env.NODE_ENV === "production" ? PROD_VIEWER_URL : DEV_VIEWER_URL

export {
  PROD_URL,
  WS_URL,
  UPLOAD_URL,
  DIRECTORY_URL,
  CONVERT_URL,
  COMPOSE_URL,
  ROTATE_URL,
  THUMBNAIL_URL,
  POINT3D_API,
  POLYGON_API,
  MASK_API,
  CIRCLE_API,
  GEOCOMPO_API,
  SQUARE_API,
  CALIBRATION_URL,
  POSE_ESTIMATION_URL,
  VIEWER_URL,
  STUDIO_URL,
}
