import React from "react"

export const HELP_TIP = {
  SELECTION: "Pick and drag to select object(s) in zone",
  CROP: "Pick and drag to select object(s) out of selection",
  POINT: "Pick free points on picture",
  POLYGON: "Pick points and hit P to stop polygon",
  CIRCLE: "Pick and drag to define a circle from its center",
  CIRCLE3P: "Pick 3 points to create a circle",
  MASK: "Pick and drag to define a mask on the areas",
  SQUARE: "Pick and drag to define a rectangle",
}

export const addLineBreaks = (string) =>
  string.split("\n").map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
      {text}
      <br />
    </React.Fragment>
  ))

export const pointDistance = (pt1, pt2) => {
  return (
    (pt1[0] - pt2[0]) ** 2 + (pt1[1] - pt2[1]) ** 2 + (pt1[2] - pt2[2]) ** 2
  )
}
