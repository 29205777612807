const TextBackground = ({ bbox, xOffset, fill }) => {
  return (
    <rect
      width={bbox.width}
      height={bbox.height}
      x={bbox.x + (xOffset ?? 0)}
      rx={bbox.height / 8}
      ry={bbox.height / 8}
      stroke="none"
      fill={fill}
    />
  )
}

export default TextBackground
