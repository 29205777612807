import { create, all } from "mathjs"

const config = {}
const math = create(all, config)

/**
 * Some 3D geometry computation using mathjs library:
 * https://mathjs.org/
 */
class Object3D {
  constructor(objCenter, objRotation) {
    this.position = objCenter
    this.rotMat = this.quatToMatrix(objRotation, false)
    this.invRotMat = this.quatToMatrix(objRotation, true)
    this.tree = null
  }

  quatToMatrix(quat, inv) {
    let x = quat[0]
    let y = quat[1]
    let z = quat[2]
    let w = quat[3]
    if (inv) {
      return [
        [
          1 - 2 * y ** 2 - 2 * z ** 2,
          2 * x * y + 2 * z * w,
          2 * x * z - 2 * y * w,
        ],
        [
          2 * x * y - 2 * z * w,
          1 - 2 * x ** 2 - 2 * z ** 2,
          2 * y * z + 2 * x * w,
        ],
        [
          2 * x * z + 2 * y * w,
          2 * y * z - 2 * x * w,
          1 - 2 * x ** 2 - 2 * y ** 2,
        ],
      ]
    } else {
      return [
        [
          1 - 2 * y ** 2 - 2 * z ** 2,
          2 * x * y - 2 * z * w,
          2 * x * z + 2 * y * w,
        ],
        [
          2 * x * y + 2 * z * w,
          1 - 2 * x ** 2 - 2 * z ** 2,
          2 * y * z - 2 * x * w,
        ],
        [
          2 * x * z - 2 * y * w,
          2 * y * z + 2 * x * w,
          1 - 2 * x ** 2 - 2 * y ** 2,
        ],
      ]
    }
  }

  computeDiscreteCircle2DFrom3D(circle, width, height, camera) {
    const EPS = 1.0e-5
    const NB_SEG = 50
    let points = []
    let vi = math.cross([1, 0, 0], circle.normal)
    if (math.norm(vi) < EPS) {
      vi = math.cross([0, 1, 0], circle.normal)
      if (math.norm(vi) < EPS) {
        vi = math.cross([0, 0, 1], circle.normal)
      }
    }
    let vj = math.cross(circle.normal, vi)
    let dAngle = (2 * math.pi) / NB_SEG
    let r = circle.radius
    let c = circle.center.point
    let angle = 0
    for (let i = 0; i < NB_SEG; i++) {
      let pt = math.add(
        c,
        math.multiply(
          r,
          math.add(
            math.multiply(math.cos(angle), vi),
            math.multiply(math.sin(angle), vj)
          )
        )
      )
      angle += dAngle
      points.push(this.compute2DFrom3D(pt, width, height, camera))
    }
    return points
  }

  /*
      CAO to Screen transformation
      */
  computeCircle2DFrom3D(circle, width, height, camera) {
    let camSensorWidth = camera.sensorWidth
    let camLens = camera.lens
    let camDistance = camera.distance

    let coord_zero = math.subtract(circle.center.point, this.position)
    let coord_rotated = [
      math.dot(this.rotMat[0], coord_zero),
      math.dot(this.rotMat[1], coord_zero),
      math.dot(this.rotMat[2], coord_zero),
    ]
    let coord_prise_de_vue = math.subtract(coord_rotated, [0, 0, camDistance])
    let ratio = -camLens / coord_prise_de_vue[2]

    let { point2d: center2D } = this.compute2DFrom3D(
      circle.center.point,
      width,
      height,
      camera
    )

    // transform circle normal and compute scale factors
    let n = circle.normal
    let n_rotated = [
      math.dot(this.rotMat[0], n),
      math.dot(this.rotMat[1], n),
      math.dot(this.rotMat[2], n),
    ]
    let x_proj = math.dot([1, 0, 0], n_rotated)
    let y_proj = math.dot([0, 1, 0], n_rotated)
    let scale_x = math.sqrt(1.0 - math.square(x_proj))
    let scale_y = math.sqrt(1.0 - math.square(y_proj))

    // compute radius length
    let radius = circle.radius
    radius = (radius * ratio * width) / camSensorWidth
    let rx = math.abs(radius * scale_x)
    let ry = math.abs(radius * scale_y)

    return [center2D, radius, rx, ry]
  }

  /*
    CAO to Screen transformation
    */
  compute2DFrom3D(pt, width, height, camera) {
    let camSensorWidth = camera.sensorWidth
    let camLens = camera.lens
    let camDistance = camera.distance

    let coord_zero = math.subtract(pt, this.position)
    let coord_rotated = [
      math.dot(this.rotMat[0], coord_zero),
      math.dot(this.rotMat[1], coord_zero),
      math.dot(this.rotMat[2], coord_zero),
    ]
    let coord_prise_de_vue = math.subtract(coord_rotated, [0, 0, camDistance])
    let dist = math.norm(coord_prise_de_vue)

    //let dist = math.norm(coord_prise_de_vue)

    let ratio = -camLens / coord_prise_de_vue[2]
    let vector = math.multiply(coord_prise_de_vue, ratio)
    let clic_center = math.multiply(vector, width / camSensorWidth)
    let center = [width / 2.0, height / 2.0, 0]
    let clic = math.add(clic_center, center)
    let point2D = [parseInt(clic[0]), height - parseInt(clic[1]) - 1]

    return { point2d: point2D, dist: dist }
  }

  /**
   * Screen to CAO transformation
   * this part is to compute 3D point on the model from the 2D picked point on screen
   * it intensively use https://mathjs.org/ library
   *
   * @param {*} x
   * @param {*} y
   * @param {*} picker
   * @param {*} camera
   */
  compute3DFrom2D(x, y, dist, width, height, camera) {
    // this part is to compute 3D point on the model from the 2D picked point on screen
    // it intensively use https://mathjs.org/ library

    let camSensorWidth = camera.sensorWidth
    let camLens = camera.lens
    let camDistance = camera.distance

    let ptClick = [x, height - y - 1, 0]
    let center = [width / 2.0, height / 2.0, 0]

    let ptClickCenter = math.subtract(ptClick, center)
    let ptClick_mm = math.multiply(camSensorWidth / width, ptClickCenter)
    let vector = math.add(ptClick_mm, [0, 0, -camLens])
    let vNorm = math.norm(vector)
    if (vNorm > 0) {
      //console.log(`Vector : ${vector}`)
      let coord_prise_de_vue = math.multiply(dist, math.divide(vector, vNorm))
      let coord_en_zero = math.add(coord_prise_de_vue, [0, 0, camDistance])

      let coord_rot_inv = [
        math.dot(this.invRotMat[0], coord_en_zero),
        math.dot(this.invRotMat[1], coord_en_zero),
        math.dot(this.invRotMat[2], coord_en_zero),
      ]
      let coord_finale = math.add(coord_rot_inv, this.position)
      //console.log(`Coord 3D : ${coord_finale}`)
      return coord_finale
    }
    return
  }

  getNearestPoint3d(point3d) {
    let nearest = this.tree.nearest(point3d, 1)
    if (nearest.length > 0) {
      return nearest[0][0]
    }
    return []
  }

  toString() {
    return `Obj position = ${this.position}`
  }
}

export default Object3D
