import React from "react"

import Menu from "./menu/Menu"
import ComposerFC from "./ComposerFC"
import PartBoard from "./board/PartBoard"
import Modal from "./common/HMKModal"
import { trackProgress } from "./common/ProgressMonitor"
import PictureViewer from "./view/PictureViewer"
import Viewer3D from "./view/Viewer3D"
import { HMFileIO } from "./io/HMFileIO"
import { Calibration } from "./io/HMCalibration"
import { postProSelector } from "./fileproc/PostProSelector"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { isPicture, isSvgFile, isJSON } from "./common/CheckFileType"
import { ViewMode, SelectionMode, StorageKeys } from "./common/AppMode"
import {
  CalibrationModal,
  DefaultCalibrationParams,
} from "./calibration/CalibrationModal"
import { MetadataModal, DefaultMetadataParams } from "./metadata/MetadataModal"
import { Convert3dModal, DefaultConvert3dParams } from "./modals/Convert3dModal"
import {
  RenderModal,
  getDefaultRevolveRenderParams,
  getDefaultHDRenderParams,
  RenderResolution,
  RenderRatio,
  RenderQuality,
  RenderLOD,
  RenderType,
} from "./modals/RenderModal"
import { PasteModal } from "./modals/PasteModal"
import { JSONModal, JSONModalMode } from "./modals/JsonModal"
import { DeleteModal, FolderModal, TrainModal } from "./modals/Modals"
import { STUDIO_URL } from "./io/UrlConfig"

import { clamp, readJson, getURLParam, loadImage } from "./common/utils"
import { downloadUrl } from "./AppTools"
import { launchViewer } from "./view/ViewerLauncher"
import { clearClipboard, copyPictureGeom } from "./common/Clipboard"

class MainApp extends React.Component {
  DEBUG = false
  DEFAULT_UPLOAD_THUMBNAIL_SIZE = null //[200, 200]
  BACKWARD_DIRECTORY = {
    id: "00000000-0000-0000-0000-000000000000",
    src: "..",
    parent: "",
  }

  SORT_TYPE = {
    by_name_asc: "By Name Ascending",
    by_date_asc: "By Date Ascending",
    by_name_desc: "By Name Descending",
    by_date_desc: "By Date Descending",
  }

  constructor(props) {
    super(props)
    this.state = {
      fileSortType: this.SORT_TYPE.by_name_asc, //this.SORT_TYPE.by_date_desc,
      folderSortType: this.SORT_TYPE.by_date_desc,
      rootDirId: props.projectId,
      token: props.token,
      currentPath: [],
      displayColor: false, //PictureViewer
      displayResizingTool: false, //PictureViewer - FHD/UHD resizing tool
      displayImageEditor: false, //PictureViewer - brightness/contrast tool
      viewablePicList: [],
      displayModal: null,
      displayMenu: true,
      viewMode: ViewMode.CONVERTER,
      selectMode: SelectionMode.SINGLE,
      selectedItem: null,
      selectBox: null,
      selectedObjParts: null,
      coloredObjParts: null,
      picViewDirty: false,
      directories: [],
      pictures: [],
      filterTags: [
        "rgba_depth",
        "geom_compo",
        "normals_depth",
        //"normals_edges",
        "index_colors",
        "index_depth",
        //"index_outline",
        "index_histogram",
        "hide",
      ],
      loading: false,
      saving: false, // prevent saving on composer twice
      progress: [],
      uploadProgress: {},
      compoKey: 0,
      devices: {},
      projectName: null,
    }
    this.hmFile = HMFileIO(props.token)
    this.composerRef = React.createRef()
    this.picViewRef = React.createRef()
  }

  componentDidMount() {
    // load the usecase file listing images to display
    if (this.props.projectId) {
      // clean clipboard session storage keys
      clearClipboard()
      if (this.DEBUG)
        console.log(
          `Shoud use project id ${this.props.projectId} as root folder`
        )
      let folderMetadata = {}
      this.hmFile
        .loadDir(this.props.projectId)
        .then((dir) => {
          if (this.DEBUG) console.log("LOADED PROJECT FOLDER : ", dir)
          folderMetadata = dir.metadata
          this.setState({ projectName: folderMetadata.projectName })
          return this.hmFile.getDevicesFile(this.props.projectId)
        })
        .then((res) => {
          if (res) {
            const jj = JSON.parse(res)
            // sort devices by type
            jj.sort((a, b) => {
              if (a.type === b.type) return 0
              if (a.type === "MONITOR" || b.type === "PROJECTOR") return -1
              if (a.type === "PROJECTOR" || b.type === "MONITOR") return 1
              return 0
            })
            jj.unshift({ key: "None", name: "None" })
            this.setState({
              devices: { list: jj, default: folderMetadata.deviceId },
            })
          }
        })
    }
    let folderToDisplay = this.state.rootDirId
    let crtPath = ""
    if (sessionStorage.getItem(StorageKeys(this.props.projectId).FOLDER_KEY)) {
      folderToDisplay = sessionStorage.getItem(
        StorageKeys(this.props.projectId).FOLDER_KEY
      )
      crtPath = JSON.parse(
        sessionStorage.getItem(
          StorageKeys(this.props.projectId).CURRENT_PATH_KEY
        )
      )
      this.setState({
        currentPath: crtPath,
      })
    }
    this.updateDirFiles(folderToDisplay, crtPath)
  }

  /**
   * Sort file array for display in PartBoard
   * @param {array} files  as in State.pictures
   */
  fileSorter(files) {
    if (this.state.fileSortType === this.SORT_TYPE.by_name_asc)
      return files.sort((a, b) =>
        a.src
          .substring(a.src.lastIndexOf("/") + 1)
          .localeCompare(b.src.substring(b.src.lastIndexOf("/") + 1))
      )
    else if (this.state.fileSortType === this.SORT_TYPE.by_name_desc)
      return files.sort(
        (a, b) =>
          -1 *
          a.src
            .substring(a.src.lastIndexOf("/") + 1)
            .localeCompare(b.src.substring(b.src.lastIndexOf("/") + 1))
      )
    else if (this.state.fileSortType === this.SORT_TYPE.by_date_asc)
      return files.sort((a, b) => a.created.localeCompare(b.created))
    else if (this.state.fileSortType === this.SORT_TYPE.by_date_desc)
      return files.sort((a, b) => -1 * a.created.localeCompare(b.created))
    else return files
  }

  /**
   * Sort folder array for display in PartBoard
   * @param {array} folders  as in State.directories
   */
  folderSorter(folders) {
    if (this.state.folderSortType === this.SORT_TYPE.by_name_asc)
      return folders.sort((a, b) => a.src.localeCompare(b.src))
    else if (this.state.folderSortType === this.SORT_TYPE.by_name_desc)
      return folders.sort((a, b) => -1 * a.src.localeCompare(b.src))
    else if (this.state.folderSortType === this.SORT_TYPE.by_date_asc)
      return folders.sort((a, b) => a.created.localeCompare(b.created))
    else if (this.state.folderSortType === this.SORT_TYPE.by_date_desc)
      return folders.sort((a, b) => -1 * a.created.localeCompare(b.created))
    else return folders
  }

  submitRenderModal = (e) => {
    e.preventDefault()
    if (this.DEBUG) {
      console.log(`End Render Modal with parameters `)
      Object.keys(e.target).map((k) =>
        console.log(
          `key=${k} id=${e.target[k].id} name=${e.target[k].name} value=${e.target[k].value} checked=${e.target[k].checked}`
        )
      )
    }
    const item = { ...this.state.displayModal.params.item }
    // only render picture types are checkbox
    const picsToRender = Object.keys(e.target)
      .filter((key) => e.target[key].checked)
      .map((key) => e.target[key].value)
    // add to it the default index and depth pictures (always rendered)
    if (!picsToRender.length) picsToRender.push("Colors")
    picsToRender.push("Depth", "Index")
    // basic parameters from form
    let params = {
      type: RenderType[e.target.render_type.value],
      reso: RenderResolution[e.target.render_reso.value].reso,
      ratio: RenderRatio[e.target.render_ratio.value].ratio,
      quality: RenderQuality[e.target.render_qual.value].samples,
      renderPics: picsToRender,
      metadata: { deviceKey: e.target.render_reso.value },
    }
    params.type.type = e.target.render_type.value
    // get the LOD info if defined
    if (e.target.render_lod)
      params = {
        ...params,
        lod: RenderLOD[e.target.render_lod.value].ratio,
      }
    // get the ambient occlusion flag if defined
    if (e.target.render_ambient_occlusion)
      params = {
        ...params,
        ambient_occlusion: e.target.render_ambient_occlusion.checked,
      }
    // get the color type if defined
    if (e.target.render_color) {
      params = {
        ...params,
        colorType: e.target.render_color.value,
      }
    }
    // case if obj parts are colored
    if (this.state.coloredObjParts) {
      params = {
        ...params,
        coloredObjParts: this.state.coloredObjParts,
      }
    }
    // get the render modal form resolution parameter
    if (RenderResolution[e.target.render_reso.value].camera) {
      params = {
        ...params,
        camera: RenderResolution[e.target.render_reso.value].camera,
      }
    }
    // case for revolve, set nb phi theta
    if (e.target.render_type.value === "revolve34") {
      params = {
        ...params,
        nb_phi: 3,
        nb_theta: 4,
      }
    } else if (e.target.render_type.value === "revolve58") {
      params = {
        ...params,
        nb_phi: 5,
        nb_theta: 8,
      }
    }
    // case for obj part selection
    if (e.target.render_selection) {
      let selection = this.state.selectedObjParts
      const selectType = e.target.render_selection.value
      if (
        selectType === "copy_cb" ||
        selectType === "add_cb" ||
        selectType === "substract_cb"
      ) {
        selection = sessionStorage.getItem(StorageKeys().CLIPBOARD_OBJ_KEY)
        if (selection) selection = JSON.parse(selection)
      }

      params = {
        ...params,
        selectedObjParts: selection,
        selectedObjPartsInclusion: selectType,
      }
    }
    // case for object moved along screen x/y
    let translate = [0, 0, 0]
    if (e.target.render_camera_left || e.target.render_camera_right) {
      translate[0] = parseInt(e.target.render_camera_left.value, 10)
      translate[0] -= parseInt(e.target.render_camera_right.value, 10)
    }
    if (e.target.render_camera_up || e.target.render_camera_down) {
      translate[1] = parseInt(e.target.render_camera_down.value, 10)
      translate[1] -= parseInt(e.target.render_camera_up.value, 10)
    }
    if (e.target.render_zoom_in || e.target.render_zoom_out) {
      translate[2] = parseInt(e.target.render_zoom_out.value, 10)
      translate[2] -= parseInt(e.target.render_zoom_in.value, 10)
    }
    // case for zoom render mode
    /*
    if (e.target.render_zoom_factor) {
      const zoomFactor = parseFloat(e.target.render_zoom_factor.value)
      translate[2] = zoomFactor
      params = {
        ...params,
        factor: zoomFactor,
      }
      if (this.state.selectBox)
        params = { ...params, select: this.state.selectBox, factor: zoomFactor }
      else
        params = {
          ...params,
          factor: zoomFactor,
        }
      }
       */
    params = {
      ...params,
      translate: translate,
    }
    // case for object manual rotation
    if (e.target.render_rotate_angle)
      params = {
        ...params,
        rotate_axis: e.target.render_rotate_axis.value,
        rotate_angle: parseInt(e.target.render_rotate_angle.value, 10),
      }
    // case for cut render mode
    if (e.target.render_nb_cut_x) {
      const cutX = parseInt(e.target.render_nb_cut_x.value, 10)
      const cutY = parseInt(e.target.render_nb_cut_y.value, 10)
      const cutZ = parseInt(e.target.render_nb_cut_z.value, 10)
      if (cutX > 0 || cutY > 0 || cutZ > 0) {
        params = {
          ...params,
          nb_cut: [cutX, cutY, cutZ],
        }
      }
    }
    if (
      e.target.render_cut_x_plane ||
      e.target.render_cut_y_plane ||
      e.target.render_cut_z_plane
    ) {
      const cutPlaneX = parseFloat(e.target.render_cut_x_plane.value)
      const cutPlaneY = parseFloat(e.target.render_cut_y_plane.value)
      const cutPlaneZ = parseFloat(e.target.render_cut_z_plane.value)
      if (cutPlaneX || cutPlaneY || cutPlaneZ) {
        params = {
          ...params,
          cut_plane: [cutPlaneX, cutPlaneY, cutPlaneZ],
        }
      }
    }
    // case for explode render mode
    if (e.target.render_explode_factor_x) {
      const explodeFactorX = parseFloat(e.target.render_explode_factor_x.value)
      const explodeFactorY = parseFloat(e.target.render_explode_factor_y.value)
      const explodeFactorZ = parseFloat(e.target.render_explode_factor_z.value)
      if (explodeFactorX !== 0.0)
        params = { ...params, explode_factor_x: explodeFactorX }
      if (explodeFactorY !== 0.0)
        params = { ...params, explode_factor_y: explodeFactorY }
      if (explodeFactorZ !== 0.0)
        params = { ...params, explode_factor_z: explodeFactorZ }
    }
    // case for inking render option
    // currently disabled
    if (e.target.render_inking_width) {
      const inkingWidth = parseFloat(e.target.render_inking_width.value)
      if (!isNaN(inkingWidth)) params = { ...params, inking_width: inkingWidth }
    }

    // case for advanced parameters
    if (e.target.render_quat_x && e.target.render_quat_x.value) {
      params = {
        ...params,
        quaternion: [
          parseFloat(e.target.render_quat_x.value),
          parseFloat(e.target.render_quat_y.value),
          parseFloat(e.target.render_quat_z.value),
          parseFloat(e.target.render_quat_w.value),
        ],
      }
    }
    if (e.target.render_loc_x && e.target.render_loc_x.value) {
      params = {
        ...params,
        location: [
          parseFloat(e.target.render_loc_x.value),
          parseFloat(e.target.render_loc_y.value),
          parseFloat(e.target.render_loc_z.value),
        ],
      }
    }
    if (e.target.render_center_x && e.target.render_center_x.value) {
      params = {
        ...params,
        center: [
          parseFloat(e.target.render_center_x.value),
          parseFloat(e.target.render_center_y.value),
          parseFloat(e.target.render_center_z.value),
        ],
      }
    }
    if (e.target.render_type.value === "clipboard") {
      let viewpoint = sessionStorage.getItem(
        StorageKeys().CLIPBOARD_VIEWPOINT_KEY
      )
      if (viewpoint) {
        viewpoint = JSON.parse(viewpoint)
        params = {
          ...params,
          ...viewpoint,
        }
      }
    }

    //e.target.map((t) => console.log(`name= ${t.name} value= ${t.value}`))
    this.setState({ displayModal: null })
    // if we launch a render from PictureViewer then this one is no more dirty
    if (this.state.viewMode === ViewMode.VIEW2D)
      this.setState({ picViewDirty: false })

    this.hmFile
      .convert("render", item.id, params)
      .then((res) => {
        if (this.DEBUG) console.log(`Launched Rendering: `, res)
        toast(`Launched Rendering on ${item.name}`)
        return trackProgress(this, item.id, res.task_id, "RENDER_DONE")
      })
      .then((res) => {
        if (this.DEBUG) console.log(`Result of Rendering is: `, res.result)
        toast(`Rendering done on ${item.name}`)
      })
      .catch((err) => {
        toast(`Error rendering file ${item.name} : ${err.message}`)
        console.log(`ERROR IN RENDERING OF FILE ${item.name} : ${err.message}`)
      })
  }

  submitConvert3dModal = (e) => {
    e.preventDefault()
    const dim_width_value = parseFloat(e.target.dim_width.value)
    const dim_height_value = parseFloat(e.target.dim_height.value)
    const flip_y = e.target.flip_y.checked
    const item = { ...this.state.displayModal.params.item }
    // basic parameters from form
    let params = {
      dimension: [dim_width_value, dim_height_value],
      flip_y: flip_y,
    }
    this.hmFile
      .convert("convert3d", item.id, params)
      .then((res) => {
        if (this.DEBUG) console.log(`Launched Convert3d: `, res)
        toast(`Launched Convert 3D on ${item.name}`)
        return trackProgress(this, item.id, res.task_id, "CONVERT3D_DONE")
      })
      .then((res) => {
        if (this.DEBUG) console.log(`Result of Convert 3D is: `, res.result)
        toast(`Convert 3D done on ${item.name}`)
      })
      .catch((err) => {
        toast(`Error converting file ${item.name} : ${err.message}`)
        console.log(`ERROR IN CONVERT3D OF FILE ${item.name} : ${err.message}`)
      })
    this.setState({ displayModal: null })
  }

  submitMetadataModal = (e) => {
    const updateThicknessValueMD = (item) => {
      const params = {
        ...item.metadata,
        thickness: thickness_value,
      }
      this.setState((prevState) => ({
        pictures: prevState.pictures.map((p) =>
          p.id === item.id ? { ...p, metadata: params } : p
        ),
        displayModal: null,
      }))
      this.hmFile.updateFileMetadata(params, item.id).then((res) => {
        if (this.DEBUG) console.log(`Result of Metadata is: `, res.result)
        toast(`Thickness done on ${item.name}`)
      })
    }
    e.preventDefault()
    const thickness_value = parseFloat(e.target.thickness.value)
    const apply_to_folder = e.target.thickness_folder.checked
    let items = [{ ...this.state.displayModal.params.item }]
    if (apply_to_folder) {
      // build item list in items
      items = this.state.pictures.filter((pic) => isSvgFile(pic.src))
    }
    items.forEach((item) => updateThicknessValueMD(item))
  }

  submitCalibrationModal = (e) => {
    e.preventDefault()
    const currentFolder = this.getCurrentDir()
    const params = {
      squareNx: e.target.square_nx.value,
      squareNy: e.target.square_ny.value,
      squareSize: e.target.square_size.value,
      squarePXY: e.target.square_pxy.value,
    }
    this.setState({ loading: true, displayModal: null })
    Calibration()
      .calibration(currentFolder.id, params)
      .then((res) => {
        if (this.DEBUG)
          console.log(
            `Launched Calibration on folder ${
              currentFolder.name
            }: ${JSON.stringify(res)}`
          )
        toast("Launched Calibration process...")
        return trackProgress(
          this,
          currentFolder.id,
          res.task_id,
          "CALIBRATION_DONE"
        )
      })
      .then((res) => {
        toast("Calibration done.")
        this.setState({ loading: false })
      })
      .catch((err) => {
        console.log(`ERROR IN RUNNING CALIBRATION : ${err.message}`)
      })
  }

  // This function for the directory renaming validation
  submitDirModal = (e) => {
    e.preventDefault()
    if (this.DEBUG) console.log(...e.target)
    const newName = e.target.textfield.value
    const desc = e.target.description.value
    const folder = this.state.displayModal.params
    const oldName = folder["src"]
    const folderId = folder["id"]
    if (folderId) {
      // If folderId already defined, its a renaming
      if (this.DEBUG)
        console.log(
          `Rename Submition old name=${oldName}  new name=${newName} on folder id ${folderId}`
        )
      this.hmFile.renameFolder(newName, folderId, desc).then((folder) => {
        if (this.DEBUG)
          console.log("After PATCH, folder is : " + JSON.stringify(folder))
        toast("Renamed folder to " + newName)
        this.setState((prevState) => ({
          directories: this.folderSorter(
            prevState.directories.map((d) =>
              d.id === folderId
                ? { ...d, src: newName, metadata: folder.metadata }
                : d
            )
          ),
          displayModal: null,
        }))
      })
    } else {
      // if no folderId then its a folder creation
      this.hmFile.createFolder(newName, folder.parent, desc).then((folder) => {
        if (this.DEBUG)
          console.log("After POST, folder is : " + JSON.stringify(folder))
        toast("Added folder " + newName)
        this.setState({
          directories: this.folderSorter([...this.state.directories, folder]),
          displayModal: null,
        })
      })
    }
  }

  cancelModal = () => {
    this.setState({ displayModal: null })
  }

  downloadUrl = async (id, name) => downloadUrl(this, id, name)

  copyLink = (link) => {
    return navigator.clipboard.writeText(link).then(() => {
      // copy link also to browser sessionStorage
      sessionStorage.setItem(StorageKeys().CLIPBOARD_LINK_KEY, link)
      toast("Link copied to clipboard")
      if (this.state.viewMode === ViewMode.COMPOSER) {
        this.setState({
          compoKey: this.state.compoKey + 1,
        })
      }
    })
  }

  duplicateCard = async (item, newNamePrefix, noOverwrite, params) => {
    const files = await HMFileIO().duplicateFile(
      item.id,
      newNamePrefix,
      noOverwrite,
      params
    )
    toast("Duplicated file " + item.name)
    // filter out files not displayed
    let newFiles = files.filter((f) => this.checkTag(f, this.state.filterTags))
    newFiles.forEach((newFile) => {
      newFile.src = newFile.datafile
      if (newFile.metadata && newFile.metadata.geom_compo_id) {
        const geomSrc = files.find(
          (f_1) => f_1.id === newFile.metadata.geom_compo_id
        )
        newFile.geomSrc = geomSrc
      }
    })
    if (this.DEBUG) console.log(`Add newFile to pictures state: `, newFiles)
    this.setState(
      {
        pictures: this.fileSorter([...this.state.pictures, ...newFiles]),
      },
      this.updateViewablePicList
    )
    if (this.state.viewMode === ViewMode.COMPOSER) {
      // hack to force reload of Composer component
      this.setState({
        selectedItem: newFiles[0],
        compoKey: this.state.compoKey + 1,
      })
    }
    return files
  }

  displayCalibrationPicture = () => {
    var win = window.open("", "holomake_calibration_pattern")
    win.document.write(
      `<html><head><title>HoloMake|Studio - Calibration Pattern</title></head><body style="background-color: black; margin: auto; width: 100%; text-align: center;">`
    )
    win.document.write(
      `<image style="height: 100%;" src="generate_circles.svg" alt="Calibration pattern" />`
    )
    win.document.write(`</body></html>`)
    win.document.title = "HoloMake|Studio - Calibration Pattern"
    win.document.close()
  }

  handleMenu = (e, action) => {
    //e.stopPropagation()
    // in the special composer case, dispatch menu action to Composer component
    // this is because of a lack of global state management
    if (this.state.viewMode === ViewMode.COMPOSER) {
      if (action.type === "copyGeom") {
        this.onGeomCopy(action.payload.id)
      } else if (action.type === "pasteGeom") {
        this.onGeomPaste(action.payload.id, e)
      } else if (action.type === "track") {
        this.onTrack(action.payload.id)
      } else {
        this.composerRef.current.handleMenu(e, action)
      }
      return
    }

    const item = action.payload
    switch (action.type) {
      case "addFolder": {
        this.setState({
          displayModal: {
            title: "ADD FOLDER",
            params: {
              id: null,
              src: "New Folder",
              parent: this.getCurrentDir()["id"],
            },
            onSubmit: this.submitDirModal,
            content: FolderModal,
          },
        })
        break
      }
      case "clearFolder": {
        const currentFolder =
          this.state.currentPath[this.state.currentPath.length - 1]
        console.log("Remove all files from current folder: ", currentFolder)
        this.setState({
          displayModal: {
            title: `Cleaning current folder : ${currentFolder.name}`,
            params: { type: "folder" },
            onSubmit: (e) => {
              this.setState({ loading: true })
              this.hmFile.deleteFolder(currentFolder.id).then((folder) => {
                if (this.DEBUG)
                  console.log(
                    "After DELETE, folder is : " + JSON.stringify(folder)
                  )
                toast("Deleted folder " + currentFolder.src)
                this.setState({
                  loading: false,
                  displayModal: null,
                })
                this.onBack()
              })
            },
            content: DeleteModal,
          },
        })
        break
      }
      case "jsonCommand":
        // at this step, the command metadata is defined
        this.jsonCommand(item.metadata.command, item)
        break
      case "calibration": {
        this.setState({
          displayModal: {
            title: "Camera Calibration",
            params: DefaultCalibrationParams,
            onSubmit: this.submitCalibrationModal,
            content: CalibrationModal,
          },
        })
        break
      }
      case "calibration_pattern": {
        this.displayCalibrationPicture()
        break
      }
      case "pv_color": {
        this.setState({
          displayColor: !this.state.displayColor,
          displayResizingTool: false,
          displayImageEditor: false,
        })
        break
      }
      case "pv_resize": {
        this.setState({
          displayColor: false,
          displayResizingTool: !this.state.displayResizingTool,
          displayImageEditor: false,
        })
        break
      }
      case "pv_imgedit": {
        this.setState({
          displayColor: false,
          displayResizingTool: false,
          displayImageEditor: !this.state.displayImageEditor,
        })
        break
      }
      case "copy2clipboard": {
        this.hmFile.getFileDescriptor(item.id).then((res) => {
          let selectionToCopy =
            this.state.selectedObjParts && this.state.selectedObjParts.length
              ? this.state.selectedObjParts
              : res.components
          // apply colors to selection if needed
          if (this.state.coloredObjParts) {
            for (const coloredPart of this.state.coloredObjParts) {
              const sel = selectionToCopy.find(
                (selectPart) => selectPart.name === coloredPart.name
              )
              if (sel) {
                sel.rgba = [
                  coloredPart.color[0] / 255.0,
                  coloredPart.color[1] / 255.0,
                  coloredPart.color[2] / 255.0,
                  coloredPart.color[3] / 255.0,
                ]
              }
            }
          }
          // copy selections to browser sessionStorage
          sessionStorage.setItem(
            StorageKeys().CLIPBOARD_OBJ_KEY,
            JSON.stringify(selectionToCopy)
          )
          const viewpointToCopy = {
            location: res.object_location,
            center: res.object_rotation_center,
            quaternion: res.quaternion_xyzw,
          }
          sessionStorage.setItem(
            StorageKeys().CLIPBOARD_VIEWPOINT_KEY,
            JSON.stringify(viewpointToCopy)
          )
          if (this.state.selectedObjParts && this.state.selectedObjParts.length)
            toast("Copied current selection and viewpoint to clipboard")
          else toast("Copied all parts and viewpoint to clipboard")
        })
        break
      }
      case "copyFile": {
        if (
          this.state.viewMode === ViewMode.VIEW2D &&
          this.state.picViewDirty
        ) {
          if (this.picViewRef?.current?.onCopyFile) {
            this.picViewRef.current.onCopyFile(item, this.state.coloredObjParts)
          }
        } else this.duplicateCard(item)
        break
      }
      case "copyGeom": {
        this.onGeomCopy(item.id)
        break
      }
      case "copyLink": {
        if (
          this.state.viewMode === ViewMode.VIEW2D &&
          this.state.picViewDirty
        ) {
          if (this.picViewRef?.current?.onCopyLink) {
            this.picViewRef.current.onCopyLink(item, this.state.coloredObjParts)
          }
        } else this.copyLink(item.src + "#" + item.id)
        break
      }
      case "crop": {
        if (this.picViewRef?.current?.onCrop) {
          this.picViewRef?.current?.onCrop(item, this.state.selectBox)
        }
        break
      }
      case "delete": {
        this.onDelete(item.id)
        break
      }
      case "downloadFile": {
        const isDir = this.state.directories.find((dir) => dir.id === item.id)
        if (isDir) {
          this.hmFile
            .createZipFile(item.id)
            .then((res) => {
              if (this.DEBUG) console.log("Launched ZipFile: ", res)
              toast("Preparing zip file...")
              return trackProgress(this, item.id, res.task_id, "ZIPFILE_DONE")
            })
            .then((res) => this.hmFile.dlZipFile(res.result))
            .catch((err) =>
              console.log(`ERROR BUILDING ZIP FILE : ${err.message}`)
            )
        } else if (
          this.state.viewMode === ViewMode.VIEW2D &&
          this.state.picViewDirty
        ) {
          if (this.picViewRef?.current?.onDownload) {
            this.picViewRef?.current?.onDownload(
              item,
              this.state.coloredObjParts
            )
          }
        } else this.downloadUrl(item.id, item.name)
        break
      }
      case "edition": {
        this.onEdit(item.id)
        break
      }
      case "getObj": {
        this.hmFile
          .convert("img2obj", item.id)
          .then((res) => {
            if (this.DEBUG)
              console.log(`Launched Img2Obj: ${JSON.stringify(res)}`)
            toast("Launched Img2Obj process...")
            return trackProgress(this, item.id, res.task_id, "IMG2OBJ_DONE")
          })
          .then((res) => {
            if (this.DEBUG) console.log(`Result of Img2Obj is: `, res["result"])
          })
        break
      }
      case "getCao": {
        this.hmFile
          .convert("obj2cao", item.id)
          .then((res) => {
            if (this.DEBUG)
              console.log(`Launched Obj2Cao: ${JSON.stringify(res)}`)
            toast("Launched Obj2Cao process...")
            return trackProgress(this, item.id, res.task_id, "OBJ2CAO_DONE")
          })
          .then((res) => {
            if (this.DEBUG) console.log(`Result of Obj2Cao is: `, res["result"])
          })
        break
      }
      case "hdRender": {
        this.hmFile.getFileDescriptor(item.id).then((res) =>
          this.setState({
            displayModal: {
              title: "Render settings",
              params: {
                ...getDefaultHDRenderParams(item),
                item: item,
                // disable select box to render settings for now
                select: null, // this.state.selectBox,
                selectedObjParts: this.state.selectedObjParts,
                descriptor: res,
                devices: this.state.devices,
              },
              onSubmit: this.submitRenderModal,
              content: RenderModal,
            },
          })
        )
        break
      }
      case "extractPics": {
        this.hmFile
          .convert("video2pics", item.id)
          .then((res) => {
            if (this.DEBUG)
              console.log(`Launched Video2Pics: ${JSON.stringify(res)}`)
            toast("Launched Image Extraction process...")
            return trackProgress(this, item.id, res.task_id, "VIDEO2PICS_DONE")
          })
          .then((res) => {
            this.refreshCrtDirFiles()
            if (this.DEBUG)
              console.log(`Result of Video2Pics is: `, res["result"])
          })
        break
      }
      case "track": {
        this.onTrack(item.id)
        break
      }
      case "launchViewer": {
        // retrieve device id
        const deviceId =
          "device" in action
            ? action.device.key
            : "deviceId" in action
            ? action.deviceId
            : ""
        launchViewer(
          item,
          deviceId,
          this.state.devices.list,
          this.state.rootDirId
        )
        break
      }
      case "convert3d": {
        this.setState({
          displayModal: {
            title: "Convert to 3D Model",
            params: { ...DefaultConvert3dParams, item: item },
            onSubmit: this.submitConvert3dModal,
            content: Convert3dModal,
          },
        })
        break
      }
      case "thickness": {
        this.setState({
          displayModal: {
            title: "Material Thickness",
            params: { ...DefaultMetadataParams, item: item },
            onSubmit: this.submitMetadataModal,
            content: MetadataModal,
          },
        })
        break
      }
      case "metadata":
        // at this step, the command metadata is defined
        this.openJSONModal(
          item,
          JSONModalMode.METADATA,
          this.updateUserMetadata
        )
        break
      case "pasteGeom": {
        this.onGeomPaste(item.id)
        break
      }
      case "point_interactor": {
        const ptSelectMode =
          this.state.selectMode === SelectionMode.POINT_SELECTOR
            ? SelectionMode.SINGLE
            : SelectionMode.POINT_SELECTOR
        this.setState({ selectMode: ptSelectMode })
        break
      }
      case "rotateLeft": {
        const newRot = item.rot ? (item.rot - 1) % 4 : -1
        this.hmFile.rotate(item.id, newRot, false)
        this.setState(
          (prevState) => ({
            pictures: prevState.pictures.map((p) =>
              p.id === item.id ? { ...p, rot: newRot } : p
            ),
            selectedItem: { ...item, rot: newRot },
          }),
          this.updateViewablePicList
        )
        break
      }
      case "rotateRight": {
        const newRot = item.rot ? (item.rot + 1) % 4 : 1
        this.hmFile.rotate(item.id, newRot, false)
        this.setState(
          (prevState) => ({
            pictures: prevState.pictures.map((p) =>
              p.id === item.id ? { ...p, rot: newRot } : p
            ),
            selectedItem: { ...item, rot: newRot },
          }),
          this.updateViewablePicList
        )
        break
      }
      case "revolve": {
        this.setState({
          displayModal: {
            title: "Render settings",
            params: {
              ...getDefaultRevolveRenderParams(item),
              item: item,
              // disable select box to render settings for now
              select: null, // this.state.selectBox,
              selectedObjParts: this.state.selectedObjParts,
            },
            onSubmit: this.submitRenderModal,
            content: RenderModal,
          },
        })
        break
      }
      case "select_group": {
        const groupSelectMode =
          this.state.selectMode === SelectionMode.SINGLE
            ? SelectionMode.PARENT_GROUP
            : SelectionMode.SINGLE
        this.setState({ selectMode: groupSelectMode })
        break
      }
      case "trainModel": {
        this.setState({
          displayModal: {
            title: `Train ${item.name}`,
            params: { name: item.name },
            onSubmit: (e) => {
              e.preventDefault()
              this.setState({
                loading: false,
                displayModal: null,
              })
              toast("Launch training on folder " + item.name)
              this.hmFile.trainModel(item.id).then((result) => {
                if (this.DEBUG)
                  console.log("Train Model result = ", result)
                // serialize to JSON and pretty print with indent of 4
                this.updatePictureState(result)
              })
            },
            content: TrainModal,
          },
        })
        break
      }
      default:
        console.log(`Dunno how to handle action ${action.type}`)
    }
  }

  /**
   * Check TAGS on given file: returns true if at least one tag remains.
   * By default all files are 'visible'
   */
  checkTag = (file, tagList) => {
    // by default, if no tag on file, all files are visible
    let result = ["visible"]
    try {
      if (file["metadata"] && file["metadata"]["tags"]) {
        result = file["metadata"]["tags"].filter(
          (e) => tagList.indexOf(e) === -1
        )
      }
    } catch (error) {
      console.log(`Error while checking tags: ${error}`)
    }
    return result.length > 0
  }

  /**
   * Main refresh display method that get file data from server to display it as a virtual drive as file 'cards' in the PartBoard
   * @param {string} dirId Folder ID for the display update
   */
  updateDirFiles(dirId, forceCrtPath = null) {
    let { currentPath, filterTags } = this.state
    if (forceCrtPath) currentPath = forceCrtPath
    if (this.DEBUG) console.log(`UpdateDirFiles current path is `, currentPath)
    this.setState({
      selectedItem: null,
      directories: [],
      pictures: [],
      loading: true,
    })
    // read the file_list.json file
    // and create a card component for each picture]
    let backward = false
    let refresh = false
    if (dirId && dirId.length) {
      // the backward case
      if (dirId === this.BACKWARD_DIRECTORY["id"]) {
        dirId = currentPath.slice(-1)[0]["parent"]
        backward = true
      }
      // refresh case: reload the current directory
      if (currentPath.length > 0 && dirId === currentPath.slice(-1)[0].id) {
        refresh = true
      }
    } else {
      this.setState({
        loading: false,
      })
      return
    }
    return this.hmFile
      .getFilesAndFolders(dirId, filterTags, false)
      .then((dir) => {
        if (this.DEBUG)
          console.log(`updateDirFiles on folder: ${dir.name}`)
        let newCurrentPath = currentPath
        if (!refresh) {
          // if not a refresh then update the current directory list
          newCurrentPath = backward
            ? currentPath.slice(0, -1)
            : [...currentPath, dir]
          this.setState({
            currentPath: newCurrentPath,
          })
        }
        // apply some filter on folder metadata
        // folder with contentId metadata are filtered out
        // (folder from slide export)
        dir.dirs = dir.dirs.filter(
          (folder) => folder.metadata && !("contentId" in folder.metadata)
        )
        this.setState(
          {
            pictures: this.fileSorter(dir.files),
            directories: this.folderSorter(dir.dirs),
          },
          this.onUpdateDirFiles
        )
        sessionStorage.setItem(
          StorageKeys(this.props.projectId).FOLDER_KEY,
          dirId
        )
        sessionStorage.setItem(
          StorageKeys(this.props.projectId).CURRENT_PATH_KEY,
          JSON.stringify(newCurrentPath)
        )
      })
  }

  refreshCrtDirFiles() {
    let crtDir = this.getCurrentDir()
    let { filterTags } = this.state
    return this.hmFile
      .getFilesAndFolders(crtDir.id, filterTags, false)
      .then((dir) => {
        // filter directory to display
        dir.dirs = dir.dirs.filter(
          (folder) => folder.metadata && !("contentId" in folder.metadata)
        )
        this.setState(
          {
            pictures: this.fileSorter(dir.files),
            directories: this.folderSorter(dir.dirs),
          },
          this.onUpdateDirFiles
        )
      })
  }

  getCurrentDir() {
    return this.state.currentPath.slice(-1)[0]
  }

  onTrack = async (cardId, label=undefined) => {
    // Save composer if dirty
    await this.cleanUpComposer(true)
    this.setState({ saving: false })
    // Run track process on next picture
    const res = await this.hmFile.track(cardId, {"label": label})
    if (this.DEBUG)
      console.log("Launched Label Tracking: ", res)
    toast("Processed Label Tracking, switch to next picture")
    // update next picture metadata, and updatable picture list
    // then switch to next picture in composer (callback)
    this.setState((prevState) => ({
      pictures: prevState.pictures.map((p) => {
        const updated_pic = res.result.rois.find((roi) => roi?.file_id === p.id)
        return updated_pic ? { ...p, metadata: updated_pic.metadata, geomSrc: updated_pic.geomSrc } : p
      }),
    }), () => this.updateViewablePicList(() => this.setCurrentIndex("right")))
    // here force save the composer ? No, a next track will do the job
  }

  onGeomCopy = (cardId) => {
    const pic = this.state.pictures.find((item) => item["id"] === cardId)
    if (pic) {
      copyPictureGeom(pic)
      this.DEBUG && console.log("copied geometries from : ", pic.id)
      toast("Geometries copied to clipboard")
    }
  }

  onGeomPaste = (cardId, e = null) => {
    const pic = this.state.pictures.find((item) => item["id"] === cardId)
    if (pic) {
      let source_id = sessionStorage.getItem(
        StorageKeys().CLIPBOARD_GEOM_ID_KEY
      )
      let source_name = sessionStorage.getItem(
        StorageKeys().CLIPBOARD_GEOM_NAME_KEY
      )
      let source_imgsrc = sessionStorage.getItem(
        StorageKeys().CLIPBOARD_GEOM_SRC_KEY
      )
      if (source_id) {
        this.DEBUG && console.log("Can paste from : ", source_id)
        const crtDir = this.getCurrentDir()
        const crtDirId = crtDir ? crtDir["id"] : ""
        if (!crtDir) {
          this.DEBUG && console.log("warning, crtDirId is undefined !")
        }
        let metadata = pic.metadata
        if (metadata) {
          this.DEBUG && console.log("pic metadata : ", metadata)
        }
        if (e) {
          this.composerRef.current.pasteGeometry({
            source_id: source_id,
            source_name: source_name,
            source_imgsrc: source_imgsrc,
          })
        } else {
          this.setState({
            displayModal: {
              title: `Pasting geometries from : ${source_name}`,
              params: {
                source_id: source_id,
                source_name: source_name,
                source_imgsrc: source_imgsrc,
                target_pic: pic,
                target_metadata: metadata,
                crtDirId: crtDirId,
                updatePictureGeomCompo: this.updatePictureGeomCompo,
                onCancel: this.cancelModal,
              },
              onSubmit: (e) => {
                e.preventDefault()
              },
              override: true,
              content: PasteModal,
            },
          })
        }
      } else {
        this.DEBUG && console.log("Nothing to paste")
      }
    } else {
      this.DEBUG && console.log("Picture unidentified")
    }
  }

  /**
   * event handler declared as arrow function for binding
   */
  onSelect = (cardId) => {
    if (cardId === "back") {
      this.onBack()
    } else if (cardId === "root") {
      this.updateDirFiles(this.state.currentPath[0].id, [])
    } else if (cardId === "studio") {
      window.open(STUDIO_URL, "studio.holomake.fr")
    } else if (cardId === "addfolder") {
      this.handleMenu(null, { type: "addFolder" })
    } else if (this.state.directories.find((item) => item["id"] === cardId)) {
      this.updateDirFiles(cardId)
    } else {
      const pic = this.state.pictures.find((item) => item["id"] === cardId)
      // edit a picture file then go to composer mode
      if (pic) {
        if (isPicture(pic.src)) {
          this.setState({
            selectedItem: pic,
            displayMenu: true,
            viewMode: ViewMode.VIEW2D,
          })
        } else if (isJSON(pic.src)) {
          this.openJSONModal(pic, JSONModalMode.JSON_FILE, this.updateJSON)
        }
      } else {
        if (this.DEBUG)
          console.log(
            "Uncaught error - failed to access directories. There is no ID as :",
            cardId,
            "in",
            this.state.directories
          )
      }
      /*
       else if (isRenderableFile(pic.src)) {
        this.setState({
          selectedItem: pic,
          displayMenu: true,
          viewMode: ViewMode.VIEW3D,
        })
      }
      */
    }
  }

  onPictureViewerChangePic = (pic) => {
    if (isPicture(pic.src)) {
      this.setState({
        selectMode: SelectionMode.SINGLE,
        selectedItem: pic,
        selectBox: null,
        selectedObjParts: null,
        coloredObjParts: null,
      })
    }
  }
  onPictureViewerSelectBox = (coords) => this.setState({ selectBox: coords })
  onPictureViewerChangeSelection = (list) => {
    if (this.DEBUG) console.log(`Update selected obj parts : `, list)
    this.setState({ selectedObjParts: list })
  }
  onPictureViewerChangeObjColor = (list) => {
    if (this.DEBUG) console.log(`Update colored obj parts : `, list)
    this.setState({ coloredObjParts: list })
  }
  onPictureViewerChangeKeypoints = (pic, keypoints) => {
    const params = {
      ...pic.metadata,
      keypoints: keypoints,
    }
    this.setState(
      (prevState) => ({
        pictures: prevState.pictures.map((p) =>
          p.id === pic.id ? { ...p, metadata: params } : p
        ),
      }),
      () => this.updateViewablePicList()
    )
    this.hmFile.updateFileMetadata(params, pic.id)
  }
  setPicViewDirty = (dirty) => {
    if (dirty !== this.state.picViewDirty) {
      this.setState({ picViewDirty: dirty })
    }
  }

  /**
   * this function is called as callback after updateDirFiles states change
   * (should be replaced by an effect in future functional version)
   */
  onUpdateDirFiles = async () => {
    this.setState({
      loading: false,
    })
    this.updateViewablePicList()
    // Apply some effect if special arguments are found on location address
    const composer = getURLParam("composer")
    if (composer) {
      this.DEBUG && console.log("got url param : ", composer)
      const pic = this.state.pictures.find((item) => item["id"] === composer)
      // instantiating a closure that cleans up the location address by overwriting current history
      // edit a picture file then go to composer mode
      if (pic) {
        // picture is in the current directory (root)
        if (pic.src && isPicture(pic.src)) {
          this.setState({
            displayColor: false,
            displayResizingTool: false,
            displayImageEditor: false,
            selectedItem: pic,
            displayMenu: true,
            viewMode: ViewMode.COMPOSER,
          })
        }
        const current_full_url = window.location.href
        let in_arr = current_full_url.split("&")
        in_arr = in_arr.slice(0, in_arr.length - 1)
        window.history.replaceState({}, null, in_arr.join("&"))
      } else {
        try {
          let newCurrentPath = []
          const getParentDir = async (dirId) => {
            const found_folder = await this.hmFile.loadDir(dirId)
            newCurrentPath.unshift(found_folder)
            if (found_folder.parent) {
              await getParentDir(found_folder.parent)
            }
          }
          const found_file = await this.hmFile.loadFile(composer)
          if (found_file.parent) {
            await getParentDir(found_file.parent)
            this.setState({
              currentPath: newCurrentPath,
            })
            await this.updateDirFiles(found_file.parent)
          } else {
            throw new Error("Error - no parent property")
          }
        } catch (e) {
          console.warn(
            `URL Parameter Error - image id ${composer} could not be found in this project.`
          )
          this.DEBUG && console.log(JSON.stringify(e))
        }
      }
    }
  }

  updateViewablePicList = (callback) => {
    const updateSizeValueMD = (item, size_values) => {
      const params = {
        ...item.metadata,
        size: size_values,
      }
      this.setState((prevState) => ({
        pictures: prevState.pictures.map((p) =>
          p.id === item.id ? { ...p, metadata: params } : p
        ),
      }))
      this.hmFile.updateFileMetadata(params, item.id)
    }
    const viewablePicList = this.state.pictures.filter((pic) =>
      isPicture(pic.src)
    )
    viewablePicList.forEach((pic) => {
      if (!pic.metadata.size)
        loadImage(pic.src).then((img) => {
          let w = img.width
          let h = img.height
          if (isSvgFile(pic.src)) {
            // apply web inverse pixel conversion to real unit
            // there is 96px in 1 inch
            // and 1 inch is 25.4mms
            w = Math.round((w / 96) * 25.4)
            h = Math.round((h / 96) * 25.4)
          }
          updateSizeValueMD(pic, [w, h])
        })
    })
    this.setState({
      viewablePicList: viewablePicList,
    }, callback)
  }

  updatePictureState = (file, callback) => {
    const folder = this.getCurrentDir()
    if (file.parent === folder.id) {
      // refresh the pictures state by adding file
      if (this.checkTag(file, this.state.filterTags)) {
        this.setState(
          {
            displayImageEditor: false,
            pictures: this.fileSorter([...this.state.pictures, file]),
          },
          () => this.updateViewablePicList(callback)
        )
      }
    }
    return file
  }

  openJSONModal = (pic, mode, updateJson) => {
    const titleMD = mode === JSONModalMode.METADATA ? " Metadata" : ""
    this.setState({
      displayModal: {
        title: `Editing ${pic.name} ${titleMD}`,
        params: {
          source: pic,
          mode: mode,
          updateJSON: updateJson,
          onCancel: this.cancelModal,
        },
        onSubmit: (e) => {
          e.preventDefault()
        },
        override: true,
        content: JSONModal,
      },
    })
  }

  onEdit = (cardId) => {
    if (this.DEBUG) console.log(`On Edit Card id ${JSON.stringify(cardId)}`)
    // is it an edition on a folder ?
    const dir = this.state.directories.find((item) => item["id"] === cardId)
    if (dir) {
      this.setState({
        displayModal: {
          title: "EDIT FOLDER",
          params: dir,
          onSubmit: this.submitDirModal,
          content: FolderModal,
        },
      })
    } else {
      // else its an edition on a file
      const pic = this.state.pictures.find((item) => item["id"] === cardId)
      // edit a picture file then go to composer mode
      if (isPicture(pic.src)) {
        this.setState({
          displayColor: false,
          displayResizingTool: false,
          displayImageEditor: false,
          selectedItem: pic,
          displayMenu: true,
          viewMode: ViewMode.COMPOSER,
        })
      } else if (isJSON(pic.src)) {
        this.openJSONModal(pic, JSONModalMode.JSON_FILE, this.updateJSON)
      }
    }
  }

  cleanUpComposer = async (save) => {
    if (this.composerRef.current) {
      this.setState({ saving: true })
      return await this.composerRef.current.cleanUp(save)
    }
  }

  onBack = (save) => {
    if (this.DEBUG) console.log("Back from Editor/Viewer I guess !")
    if (this.state.viewMode === ViewMode.COMPOSER) {
      if (this.state.saving) {
        // prevent saving multiple times
        return
      }
      this.cleanUpComposer(save).then((result) => {
        console.log("COMPOSER SAVE ALL RESULT = ", result)
        this.setState({
          displayMenu: true,
          selectBox: null,
          selectedObjParts: null,
          coloredObjParts: null,
          saving: false,
          // going back from COMPOSER display the VIEWER on current selectedItem
          viewMode: ViewMode.VIEW2D,
        })
      })
      // copy the just create geom if any
      if (
        this.state.selectedItem.metadata &&
        this.state.selectedItem.metadata.geom_compo_id
      ) {
        copyPictureGeom(this.state.selectedItem)
      }
    } else if (this.state.viewMode === ViewMode.CONVERTER) {
      if (this.state.currentPath.length > 1) {
        this.updateDirFiles(this.BACKWARD_DIRECTORY.id)
      }
    } else {
      this.setState({
        displayMenu: true,
        //selectedItem: null,
        selectMode: SelectionMode.SINGLE,
        selectBox: null,
        selectedObjParts: null,
        coloredObjParts: null,
        viewMode: ViewMode.CONVERTER,
        // initialize pictureViewer related state
        displayColor: false,
        displayResizingTool: false,
        displayImageEditor: false,
        canUseResizingTool: false,
      })
    }
  }

  updatePictureGeomCompo = async (picture, gcId) => {
    const md = { ...picture.metadata, geom_compo_id: gcId }
    try {
      const file = await this.hmFile.loadFile(gcId)
      this.setState(
        (prevState) => ({
          pictures: prevState.pictures.map((p) =>
            p.id === picture.id ? { ...p, metadata: md, geomSrc: file } : p
          ),
        }),
        this.updateViewablePicList
      )
      return { ...picture, metadata: md }
    } catch (e) {
      console.log(JSON.stringify(e))
    }
  }

  triggerDelete = (e, dir, file, cardId) => {
    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e preventDefault
     * @param {Object|undefined} dir the selected item of directories:Object[] using cardId
     * @param {Object|undefined} file the selected item of pictures:Object[] using cardId
     * @param {string} cardId card ID
     */
    e.preventDefault()
    if (file !== undefined) {
      // remove a file
      this.setState({ loading: true })
      const { viewMode, viewablePicList } = this.state
      if (viewMode === ViewMode.VIEW2D) {
        if (viewablePicList.length <= 1) {
          this.onBack(false)
        } else {
          this.setCurrentIndex("left")
        }
      }
      this.hmFile.deleteFile(cardId).then((resp) => {
        toast(
          "Deleted file " +
            file["src"].substring(file["src"].lastIndexOf("/") + 1)
        )
        // update the picture list by removing deleted file
        const newPicList = this.state.pictures.filter(
          (pic) => pic["id"] !== cardId
        )
        // check only picture files and go back to converter mode if no more pictures
        const onlyPics = newPicList.filter((pic) => isPicture(pic.src))
        let newStates = {}
        if (!onlyPics.length) {
          // no more picture to display, go back to Converter view mode
          newStates = {
            displayMenu: true,
            viewMode: ViewMode.CONVERTER,
          }
        }
        // compute new selectedItem if relevant
        let newSelectedItemPos = 0
        if (this.state.selectedItem) {
          newSelectedItemPos = this.state.viewablePicList.findIndex(
            (pic) => pic["id"] === this.state.selectedItem.id
          )
          if (newSelectedItemPos > onlyPics.length)
            newSelectedItemPos = onlyPics.length - 1
          if (newSelectedItemPos < 0) newSelectedItemPos = 0
        }
        // update global states
        this.setState(
          {
            ...newStates,
            pictures: newPicList,
            selectedItem: !onlyPics.length
              ? null
              : this.state.selectedItem
              ? onlyPics[newSelectedItemPos]
              : this.state.selectedItem,
            selectMode: SelectionMode.SINGLE,
            selectBox: null,
            selectedObjParts: null,
            coloredObjParts: null,
            loading: false,
            displayModal: null,
          },
          this.updateViewablePicList
        )
      })
    } else if (dir !== undefined) {
      // remove a folder
      this.setState({ loading: true })
      this.hmFile.deleteFolder(cardId).then((folder) => {
        if (this.DEBUG)
          console.log("After DELETE, folder is : " + JSON.stringify(folder))
        toast("Deleted folder " + dir["src"])
        this.setState({
          directories: this.state.directories.filter(
            (dir) => dir["id"] !== cardId
          ),
          loading: false,
          displayModal: null,
        })
      })
    } else {
      if (this.DEBUG)
        console.log(
          `Uncaught error : On Delete Card id ${JSON.stringify(cardId)}`
        )
    }
  }

  onDelete = (cardId) => {
    /**
     * @param {string} cardId card ID
     */
    if (this.DEBUG) console.log(`On Delete Card id ${JSON.stringify(cardId)}`)
    const dir = this.state.directories.find((item) => item["id"] === cardId)
    const file = this.state.pictures.find((item) => item["id"] === cardId)
    let type = dir ? "folder" : "file"
    let name = dir ? dir.src : file ? file.src : "unknown"
    if (name.includes("/")) {
      name = name.split("/")
      name = name[name.length - 1]
    }
    this.setState({
      displayModal: {
        title: `Deleting ${type} : ${name}`,
        params: { type: type },
        onSubmit: (e) => this.triggerDelete(e, dir, file, cardId),
        content: DeleteModal,
      },
    })
  }

  jsonCommand = async (command, item) => {
    const params = await readJson(item.src)
    if (params) {
      this.hmFile
        .convert(command, item.id, params)
        .then((res) => {
          if (!res.task_id) {
            console.warn(
              `Failed to launch the backend command ${command}: `,
              res
            )
            toast(`Failed launching command ${command}: ${res}`)
          } else toast(`Successfully launched command ${command}`)
        })
        .catch((err) => {
          toast(`Failure launching command ${command} : ${err}`)
          console.warn(err)
        })
    }
  }

  uploadFile = (file, folderId, metadata, thumbnail) => {
    const folder = folderId ? folderId : this.getCurrentDir().id
    this.setState((prevState) => ({
      uploadProgress: { ...prevState.uploadProgress, [folder]: file.name },
    }))
    const endProgress = (folder) =>
      this.setState((prevState) => {
        delete prevState.uploadProgress[folder]
        return {
          uploadProgress: { ...prevState.uploadProgress },
        }
      })
    return HMFileIO()
      .uploadFileXHR(file, folder, metadata, false, thumbnail)
      .then((file) => {
        if (this.DEBUG) console.log(`Successful upload of file id `, file.id)
        toast(
          "Uploaded file " + file.src.substring(file.src.lastIndexOf("/") + 1)
        )
        endProgress(folder)
        return this.updatePictureState(file)
      })
      .then((picData) => {
        if (this.state.viewMode === ViewMode.VIEW2D)
          this.onPictureViewerChangePic(picData)
        return picData
      })
      .catch((error) => {
        endProgress(folder)
        return Promise.reject(error)
      })
  }

  updateUserMetadata = (json, item) => {
    try {
      const params = {
        ...item.metadata,
        user: json,
      }
      this.setState(
        (prevState) => ({
          pictures: prevState.pictures.map((p) =>
            p.id === item.id ? { ...p, metadata: params } : p
          ),
        }),
        () => this.updateViewablePicList()
      )
      this.hmFile.updateFileMetadata(params, item.id)
      return "success"
    } catch (e) {
      return "failed"
    }
  }

  updateJSON = async (json, item, rerender) => {
    const folderId = this.getCurrentDir().id
    const folder = folderId ? folderId : this.getCurrentDir().id
    this.setState({ loading: true })
    const overwrite = true
    const timestamp = new Date().getTime().toString().substring(0, 10)
    const uploadFile = async (_file) => {
      const __file = await HMFileIO().uploadFileXHR(
        _file,
        folder,
        {},
        overwrite,
        this.DEFAULT_UPLOAD_THUMBNAIL_SIZE,
        `${overwrite ? "" : `edited_${timestamp}_`}${item.name}`
      )
      return __file
    }
    try {
      const file = new Blob([JSON.stringify(json)], {
        type: "application/json",
      })
      const resp = await uploadFile(
        file,
        this.getCurrentDir().id,
        {},
        this.DEFAULT_UPLOAD_THUMBNAIL_SIZE
      )
      toast("Update JSON " + resp.src.substring(resp.src.lastIndexOf("/") + 1))
      //update card if needed
      if (!overwrite || rerender) {
        let crtDir = this.getCurrentDir()
        this.updateDirFiles(crtDir.id)
      }
      return "success"
    } catch (e) {
      return "failed"
    } finally {
      this.setState({ loading: false })
    }
  }

  handleUpload = (e) => {
    if (this.DEBUG) console.log(`Will handle upload of files ...`)
    let postproc = false
    this.setState({ loading: true })
    const files = Array.from(e.target.files)
    Promise.all(
      files.map((file) =>
        this.uploadFile(
          file,
          this.getCurrentDir().id,
          {},
          this.DEFAULT_UPLOAD_THUMBNAIL_SIZE
        )
          .then((file_res) => {
            const proc = postProSelector(file_res)
            if (proc) {
              // if postpro founded continue promise with it
              const param = {
                remoteFile: file_res,
                localFile: file,
                folderId: this.getCurrentDir()["id"],
              }
              return proc(param, this)
            }
          })
          .then((res) => {
            if (!res) {
              return
            }
            if (res.action && res.action === "ADD_FILE") {
              //this.updateFiles(undefined, res.data)
            }
            // after a postpro, refresh the current directory
            //let crtDir = this.getCurrentDir()
            //this.updateDirFiles(crtDir.id)
            if (this.DEBUG)
              console.log(
                `Done postprocessing of ${JSON.stringify(
                  file
                )} : ${JSON.stringify(res)}`
              )
            postproc = true
          })
          .catch((err) => {
            console.log(
              `ERROR UPLOADING FILE ${JSON.stringify(file)} : ${err.message}`
            )
            toast("Error uploading file: " + err)
          })
      )
    )
      .then(() => {
        this.setState({ loading: false })
        if (postproc) {
          let crtDir = this.getCurrentDir()
          if (this.DEBUG) console.log(`WILL REFRESH CRT DIR ${crtDir.name}`)
          this.updateDirFiles(crtDir.id)
        }
      })
      .catch((err) => {
        this.setState({ loading: false })
      })
  }

  /**
   * For Picture Viewer State
   * @param {number|string} indicator number:index? string:first?,last?,left?,right?
   */
  setCurrentIndex = (indicator) => {
    const { selectedItem, viewablePicList } = this.state
    if (!viewablePicList.length) {
      return
    }
    if (typeof indicator === "string") {
      let currentIndex = selectedItem
        ? viewablePicList.findIndex((pic) => pic["id"] === selectedItem.id)
        : -1
      switch (indicator) {
        case "last":
          this.setState({
            selectMode: SelectionMode.SINGLE,
            selectedItem: viewablePicList.at(-1),
          })
          return
        case "left":
        case "right":
          currentIndex = clamp(
            currentIndex + (indicator === "left" ? -1 : 1),
            0,
            viewablePicList.length - 1
          )
          this.setState({
            selectMode: SelectionMode.SINGLE,
            selectedItem: viewablePicList[currentIndex],
          })
          return
        default:
          this.setState({
            selectMode: SelectionMode.SINGLE,
            selectedItem: viewablePicList[0],
          })
          return
      }
    } else if (typeof indicator === "number") {
      indicator = clamp(indicator, 0, viewablePicList.length - 1)
      this.setState({
        selectMode: SelectionMode.SINGLE,
        selectedItem: viewablePicList[indicator],
      })
    }
  }

  onChangePic = async (direction) => {
    await this.cleanUpComposer(true)
    this.setState({ saving: false })
    this.setCurrentIndex(direction)
  }

  render() {
    const {
      displayColor,
      displayResizingTool,
      displayImageEditor,
      displayMenu,
      displayModal,
      viewablePicList,
      viewMode,
      selectMode,
      selectedItem,
      loading,
      pictures,
      picViewDirty,
      directories,
      currentPath,
      progress,
      uploadProgress,
      compoKey,
      devices,
      projectName,
      selectBox,
    } = this.state
    const crtDir = this.getCurrentDir()
    const crtDirId = crtDir ? crtDir["id"] : ""
    const showBack = viewMode !== ViewMode.CONVERTER || currentPath.length > 1
    const showStudio = true

    const currentIndex = selectedItem
      ? viewablePicList.findIndex((pic) => pic["id"] === selectedItem.id)
      : -1

    // build the title info
    let titleInfo = ""
    if (viewMode === ViewMode.CONVERTER) {
      if (currentPath.length > 0) {
        // try to get project name from state (init at loading)
        let prjName = projectName
        // or try to get it from root folder
        if (!prjName && currentPath[0].metadata)
          prjName = currentPath[0].metadata.projectName
        titleInfo = prjName || "root"
      }
      if (currentPath.length > 1) {
        titleInfo = titleInfo.concat(
          " / ",
          currentPath
            .slice(1)
            .map((d) => d["name"])
            .join(" / ")
        )
      }
    } else if (viewMode === ViewMode.COMPOSER || viewMode === ViewMode.VIEW2D) {
      titleInfo = selectedItem.name
      if (isPicture(selectedItem.src) && selectedItem.metadata.size)
        titleInfo += ` [${selectedItem.metadata.size[0]}x${selectedItem.metadata.size[1]}]`
    }

    return (
      <div>
        {/** Display Top Menu bar / Navigation bar */}
        {displayMenu && (
          <Menu
            title={viewMode}
            titleInfo={titleInfo}
            mode={viewMode}
            selectBox={selectBox} //PictureViewer
            selectMode={selectMode}
            handleMenu={this.handleMenu}
            handleUpload={this.handleUpload}
            handleBack={this.onBack}
            showBack={showBack}
            item={selectedItem}
            devices={devices}
            currentPath={currentPath}
            onNavigate={(id, index) =>
              this.updateDirFiles(id, currentPath.slice(0, index))
            }
          />
        )}
        {/** Display MainApp Modal Dialog (folder renaming) */}
        {displayModal && (
          <Modal
            onSubmit={displayModal.onSubmit}
            onCancel={this.cancelModal}
            title={displayModal.title}
            buttonLabel="OK"
            override={displayModal.override ?? false}
          >
            {React.createElement(displayModal.content, {
              params: displayModal.params,
            })}
          </Modal>
        )}
        {/** Display MainApp Content */}
        {viewMode === ViewMode.COMPOSER ? (
          <ComposerFC
            key={compoKey}
            ref={this.composerRef}
            picturesLength={viewablePicList.length}
            picture={selectedItem}
            currentIndex={currentIndex}
            dirId={crtDirId}
            onChangePic={this.onChangePic}
            updatePictureState={this.updatePictureState}
            updatePicGeoCompo={this.updatePictureGeomCompo}
            copyLink={this.copyLink}
            copyFile={this.duplicateCard}
            handleMenu={this.handleMenu}
          />
        ) : viewMode === ViewMode.CONVERTER ? (
          <PartBoard
            lastSelected={selectedItem}
            onSelect={this.onSelect}
            pictures={pictures}
            directories={directories}
            progress={progress}
            uploadProgress={uploadProgress[crtDirId]}
            handleMenu={this.handleMenu}
            handleUpload={this.handleUpload}
            showBack={showBack}
            showStudio={showStudio}
            devices={devices}
            currentPath={
              currentPath[clamp(currentPath.length - 1, 0, Infinity)]
            } //prevent accessing index -1
          />
        ) : viewMode === ViewMode.VIEW2D ? (
          <PictureViewer
            ref={this.picViewRef}
            pictures={viewablePicList}
            currentIndex={currentIndex}
            setCurrentIndex={this.setCurrentIndex}
            selectMode={selectMode}
            displayColor={displayColor}
            displayResizingTool={displayResizingTool}
            displayImageEditor={displayImageEditor}
            dirty={picViewDirty}
            onChangePic={this.onPictureViewerChangePic}
            onSelectBox={this.onPictureViewerSelectBox}
            onChangeSelection={this.onPictureViewerChangeSelection}
            onChangeObjColor={this.onPictureViewerChangeObjColor}
            onChangeKeypoints={this.onPictureViewerChangeKeypoints}
            onDuplicateCard={this.duplicateCard}
            setDirty={this.setPicViewDirty}
            handleMenu={this.handleMenu}
            copyLink={this.copyLink}
            downloadUrl={this.downloadUrl}
            updatePictureGeomCompo={this.updatePictureGeomCompo}
            updatePictureState={this.updatePictureState}
            crtDir={crtDir}
          />
        ) : (
          viewMode === ViewMode.VIEW3D && <Viewer3D objUrl={selectedItem.src} />
        )}
        {loading ? <div className="loading-spinner" /> : <></>}
        {/** Display MainApp Toaster */}
        <ToastContainer
          autoClose={2000}
          position={toast.POSITION.BOTTOM_RIGHT}
          hideProgressBar={true}
          /*className="dark-toast"*/
        />
      </div>
    )
  }
}

export default MainApp
