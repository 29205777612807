import React from "react"
import "./ControllerButton.css"
import ReactTooltip from "react-tooltip"

const default_size = 40

const ControllerButton = ({
  icon,
  overrideColor,
  overrideSize,
  overrideMargin,
  tooltip_id,
  tooltip_message,
  clickCallback,
  disabled,
}) => {
  return (
    <div
      className={`controller-button-${disabled ? "disabled" : "enabled"} ${
        overrideColor
          ? "controller-override-color-orange"
          : overrideColor === false
          ? "controller-override-color-white"
          : ""
      }`}
      style={{
        width: `${overrideSize ?? default_size}px`,
        height: `${overrideSize ?? default_size}px`,
        margin: `${overrideMargin ?? "5px"}`,
      }}
      data-tip
      data-for={tooltip_id}
      onMouseDown={() => {
        if (clickCallback && !disabled) {
          clickCallback()
        }
      }}
    >
      {icon}
      <ReactTooltip id={tooltip_id}>
        <span>{tooltip_message}</span>
      </ReactTooltip>
    </div>
  )
}
export default ControllerButton
