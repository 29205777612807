import uuid from "react-uuid"
import { LABEL_BG_DEFAULT_ARR } from "./SVGRenderer/Constants"

// threshold to determine whether the new point is close enough to the initial point
export const polygonThreshold = 18
// minimum radius for circles
export const min_circle_radius = 30
// minimum side length (either width or height) for rectangles
export const min_rect_size = 30

/**
 * Abstract class for all persistent HoloMake objects
 * Property constructor parameter should be a destructured object.
 * @param {string} id unique ID
 * @param {string} name name of the object
 * @param {number[]} color array of 4 numbers as RGBA values
 * @param {string} label_position label orientation defined by 2 chars
 */
class HMAbstractObject {
  id = null
  name = ""
  constructor(params) {
    let { id, name, color, label_position, label_bg_color, label_size } = params
    color = color ?? [255, 255, 255, 1]
    label_position = label_position ?? "N"
    label_bg_color = label_bg_color ?? LABEL_BG_DEFAULT_ARR
    label_size = label_size ?? 48
    Object.assign(this, {
      id,
      name,
      color,
      label_position,
      label_bg_color,
      label_size,
    })
  }
}

/**
 * Un Point3d est représenté par un triplet [x, y, z]
 */
class Point3d extends HMAbstractObject {
  points = []

  constructor(params) {
    let {
      id,
      name,
      points,
      color,
      label_position,
      label_bg_color,
      label_size,
    } = params
    super({ id, name, color, label_position, label_bg_color, label_size })
    Object.assign(this, { points })
  }
}

/**
 * Un polygon est représenté par une liste de points, il peut-être fermé ou ouvert
 */
class Polygon extends HMAbstractObject {
  points = []
  closed = false

  constructor(params) {
    let { id, name, points, closed, color, bg_color, stroke_width } = params
    closed = closed ?? false
    super({ id, name, color })
    Object.assign(this, { points, closed, bg_color, stroke_width })
    //console.log(`created polygon ${JSON.stringify(this)}`)
  }
}

/**
 * Un cercle est représenté par 3 points
 * A partir de ces 3 points on en deduit un centre, un rayon et une normale au cercle
 */
class Circle extends HMAbstractObject {
  points = []
  center = undefined
  radius = 0
  normal = [0, 0, 1]
  ptOnCircle = undefined

  constructor(params) {
    let {
      id,
      name,
      center,
      radius,
      normal,
      ptOnCircle,
      color,
      label_position,
      label_bg_color,
      bg_color,
      stroke_width,
      label_size,
    } = params
    super({ id, name, color, label_position, label_bg_color, label_size })
    Object.assign(this, { center, radius, normal, bg_color, stroke_width })
    if (ptOnCircle) this.ptOnCircle = ptOnCircle
    else
      this.ptOnCircle = new Point3d({
        id: uuid(),
        name: name + " ptOnCircle",
        points: [center.points[0] + radius, center.points[1], 0],
      })
  }
}

/**
 * Un masque est représenté par ses deux extrémités x/y min et x/y max.
 * Il possède aussi une liste de 'trou' représenté chacun par leurs extrémités
 */
class Mask extends HMAbstractObject {
  // two points
  points = []
  // holes = 2 points per holes
  holes = []

  constructor(params) {
    let { id, name, points, holes, color, label_position } = params
    holes = holes ?? []
    super({ id, name, color, label_position })
    Object.assign(this, { points, holes })
  }
}

/**
 * Un masque est représenté par ses deux extrémités x/y min et x/y max.
 * Il possède aussi une liste de 'trou' représenté chacun par leurs extrémités
 */
class Square extends HMAbstractObject {
  // two points
  points = []

  constructor(params) {
    let {
      id,
      name,
      points,
      color,
      label_position,
      label_bg_color,
      bg_color,
      stroke_width,
      label_size,
    } = params
    super({ id, name, color, label_position, label_bg_color, label_size })
    Object.assign(this, { points, bg_color, stroke_width })
  }
}

function updateMaskPointDelta(mask, id, dx, dy, move) {
  // move whole element
  if (move) {
    if (id === 0) {
      mask.points[0].points[0] += dx
      mask.points[0].points[1] += dy
      mask.points[1].points[0] += dx
      mask.points[1].points[1] += dy
    } else {
      id = id - 1
      mask.holes[id * 2].points[0] += dx
      mask.holes[id * 2].points[1] += dy
      mask.holes[id * 2 + 1].points[0] += dx
      mask.holes[id * 2 + 1].points[1] += dy
    }
    return
  }
  // move only one node
  if (id === 0 || id === 1) {
    mask.points[id].points[0] += dx
    mask.points[id].points[1] += dy
  } else {
    mask.holes[id - 2].points[0] += dx
    mask.holes[id - 2].points[1] += dy
  }
}

function updateSquarePointDelta(square, id, dx, dy, move) {
  // move whole element
  if (move) {
    square.points[0].points[0] += dx
    square.points[0].points[1] += dy
    square.points[1].points[0] += dx
    square.points[1].points[1] += dy
    return
  }
  // move only one node
  if (id === 0 || id === 1) {
    square.points[id].points[0] += dx
    square.points[id].points[1] += dy
  }
}

function updateCircleDelta(circle, id, dx, dy, move) {
  // move whole element
  if (move) {
    circle.center.points[0] += dx
    circle.center.points[1] += dy
    circle.ptOnCircle.points[0] += dx
    circle.ptOnCircle.points[1] += dy
  }
  // update circle radius
  else if (id === 0) {
    circle.radius = Math.sqrt(
      Math.pow(circle.ptOnCircle.points[0] - circle.center.points[0], 2) +
        Math.pow(circle.ptOnCircle.points[1] - circle.center.points[1], 2)
    )
    circle.ptOnCircle.points[0] += dx
    circle.ptOnCircle.points[1] += dy
    if (circle.radius < 10) {
      circle.radius = 10
      circle.ptOnCircle.points[0] = circle.center.points[0] + 10
      circle.ptOnCircle.points[1] = circle.center.points[1]
    }
  }
}

function updatePointDelta(point, id, dx, dy, move) {
  // move whole element
  if (move) {
    point.points[0] += dx
    point.points[1] += dy
  }
}

function updatePolygonData(polygon, dx, dy, isClosurePoint) {
  if (isClosurePoint) {
    polygon.points[0].points[0] += dx
    polygon.points[0].points[1] += dy
    polygon.points[polygon.points.length - 1].points[0] += dx
    polygon.points[polygon.points.length - 1].points[1] += dy
  } else {
    for (let i = 0; i < polygon.points.length; i++) {
      polygon.points[i].points[0] += dx
      polygon.points[i].points[1] += dy
    }
  }
}

export {
  Point3d,
  Polygon,
  Circle,
  Mask,
  Square,
  updateMaskPointDelta,
  updateCircleDelta,
  updatePointDelta,
  updateSquarePointDelta,
  updatePolygonData,
}
