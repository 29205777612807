import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useReducer,
  useImperativeHandle,
} from "react"
import {
  IoIosArrowDropleft,
  IoIosArrowDropright,
  IoIosSkipBackward,
  IoIosSkipForward,
} from "react-icons/io"
import { HMCom } from "../io/HMCom"
import { HMFileIO } from "../io/HMFileIO"
import {
  initStates,
  init,
  pvInit,
  pvReducer,
  SelectionAddMode,
} from "./PictureViewerStates"
import { getIndexValue } from "./Picture3DSelector"
import {
  RenderSVGSelector,
  RenderPointInteractor,
} from "../editor/SVGRenderer/SVGRenderer"
import { SelectionMode } from "../common/AppMode"
import { isPicFromStep } from "../common/CheckFileType"
import { HMKColorPicker } from "../common/HMKColorPicker"
import "./PictureViewer.css"
import { pvCrop, pvSave } from "./pvTools"
import ReactTooltip from "react-tooltip"
import { loadImage, canvasToBlob, getWindowDimensions } from "../common/utils"
import uuid from "react-uuid"
import { copyPictureGeom, getPictureGeom, hasPictureGeom } from "../common/Clipboard"

const DEBUG = false
const SELECT_ONLY_FULL_PIXELS = false
const RANGE_STEPS = 200
const INITIAL_BRIGHTNESS = Math.floor(RANGE_STEPS / 2) // 100 as percentage
const INITIAL_CONTRAST = Math.floor(RANGE_STEPS / 2) // 100 as percentage
const PAINT_MODES = {
  normal: "normal",
  selected: "selected",
  hover: "hover",
}
const SELECT_COLOR = [0, 0, 0, 250] // [255, 165, 0, 80]
const HOVER_COLOR = [0, 0, 0, 255] // [255, 165, 0, 150]
const KEYPOINTS_INTERACTOR_COUNT = 6

const PictureViewer = React.forwardRef(
  (
    {
      pictures,
      currentIndex,
      setCurrentIndex,
      selectMode,
      displayColor,
      displayResizingTool,
      displayImageEditor,
      dirty,
      onChangePic,
      onSelectBox,
      onChangeSelection,
      onChangeObjColor,
      onChangeKeypoints,
      onDuplicateCard,
      setDirty,
      handleMenu,
      copyLink,
      downloadUrl,
      crtDir,
      updatePictureGeomCompo,
      updatePictureState,
      toast,
    },
    innerRef
  ) => {
    const pic = useRef(null)
    const picCanvas = useRef(null)
    const svg = useRef(null)
    const svgPt = useRef(null)
    const [state, dispatch] = useReducer(pvReducer, initStates, init)

    const [brightness, setBrightness] = useState(INITIAL_BRIGHTNESS) // ex: if 20 => 20 steps of (100/20)%
    const [contrast, setContrast] = useState(INITIAL_CONTRAST) // ex: if 20 => 20 steps of (100/20)%
    const [imgeditButtonRect, setImgeditButtonRect] = useState({})
    const [colorPickerButtonRect, setColorPickerButtonRect] = useState({})
    const [resizeButtonRect, setResizeButtonRect] = useState({})
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    )
    const [original_img_size, setOriginalIMGSize] = useState({
      width: 0,
      height: 0,
    })
    const [keypoints, setKeypoints] = useState([])
    const [processing, setProcessing] = useState(false)

    const onColorChange = (color, event) => {
      if (DEBUG) console.log(`Changed color: `, color)
      dispatch({
        action: "setSelectedObjColor",
        payload: { color: color },
      })
      setDirty(true)
    }

    // get part index in components array from its name
    const getPartIndex = (partName) =>
      state.objComponents.findIndex((c) => c.name === partName)

    // find the compound which includes the selected part
    const getSiblingParts = (partId) =>
      Object.values(state.idxCompounds).find((v) => v.includes(partId))

    const refreshSelectedObjCanvas = (id, objList, coloredObjList) => {
      if (DEBUG) {
        console.log(`Refresh Canvas selected obj list: `, objList)
        console.log(`Refresh Canvas colored obj list: `, coloredObjList)
      }
      const ctx = picCanvas.current.getContext("2d")
      //ctx.drawImage(imageLoaded.current, 0, 0)
      ctx.clearRect(0, 0, picCanvas.current.width, picCanvas.current.height)
      if (picCanvas.current.width && picCanvas.current.height) {
        const imData = ctx.getImageData(
          0,
          0,
          picCanvas.current.width,
          picCanvas.current.height
        )
        // paint colorized objects
        coloredObjList.forEach((o) =>
          paintCanvasId(
            PAINT_MODES.normal,
            imData,
            getPartIndex(o.name) + 1,
            ...o.color
          )
        )
        // paint already selected objects
        if (objList.length) {
          objList.forEach((o) =>
            paintCanvasId(
              PAINT_MODES.selected,
              imData,
              getPartIndex(o.name) + 1,
              ...SELECT_COLOR
            )
          )
        }
        // paint the current hovering object
        if (id >= 0) {
          // selection by compound
          if (selectMode === SelectionMode.PARENT_GROUP) {
            // find the compound which includes the selected part
            const compound = getSiblingParts(id - 1)
            if (compound)
              compound.forEach((l) =>
                paintCanvasId(PAINT_MODES.hover, imData, l + 1, ...HOVER_COLOR)
              )
            // FIXME display key name in status bar
          } else {
            // or selection for single object
            paintCanvasId(PAINT_MODES.hover, imData, id, ...HOVER_COLOR)
          }
        }
        ctx.putImageData(imData, 0, 0)
      }
    }

    const paintCanvasId = (mode, imData, id, r, g, b, a) => {
      if (DEBUG)
        console.log(`Will paint ID ${id} with color [${r} ${g} ${b} ${a}]`)
      let x, y
      let stip_size = 6
      let stip_thick = 3
      if (id in state.idxPixels) {
        state.idxPixels[id].forEach((pix, i) => {
          pix = pixSrcToDest(pix, imData.width, imData.height)
          if (mode === PAINT_MODES.normal) {
            imData.data[pix] = r
            imData.data[pix + 1] = g
            imData.data[pix + 2] = b
            imData.data[pix + 3] = a
          } else {
            if (mode === PAINT_MODES.selected) {
              stip_size = 4
              stip_thick = 2
            }
            x = (pix / 4) % imData.width
            y = (pix / 4 - x) / imData.width
            if (x % stip_size <= stip_thick && y % stip_size <= stip_thick) {
              //imData.data[pix] = 255 - imData.data[pix]
              //imData.data[pix + 1] = 255 - imData.data[pix + 1]
              //imData.data[pix + 2] = 255 - imData.data[pix + 2]
              imData.data[pix] = r
              imData.data[pix + 1] = g
              imData.data[pix + 2] = b
              imData.data[pix + 3] = a
            }
          }
        })
      }
    }

    /**
     * Transform a linear pixel index from one picture to another picture.
     *
     * @param {integer} pix the given linear pixel index in source picture
     * @param {integer} dest_w the destination picture width
     * @param {integer} dest_h the destination picture height
     */
    const pixSrcToDest = (pix, dest_w, dest_h) => {
      const src_w = pic.current.naturalWidth
      const src_h = pic.current.naturalHeight
      // rgba picture data
      pix = pix / 4
      const src_x = pix % src_w
      const src_y = Math.floor((pix - src_x) / src_w)
      let dest_pix =
        dest_w * Math.round((src_y * dest_h) / src_h) +
        Math.round((src_x * dest_w) / src_w)
      dest_pix = dest_pix * 4
      return Math.round(dest_pix)
    }

    const getCoordOnPic = (coords) => {
      const bbPic = picCanvas.current.getBoundingClientRect()
      //console.log(`GetMousePos, pic bbrect is `, bbPic)
      //let realW = imageLoaded.current.width
      //let realH = imageLoaded.current.height
      let realW = pic.current.naturalWidth
      let realH = pic.current.naturalHeight
      //console.log(`Pic natural w/h: ${realW} ${realH}`)
      let x = coords[0] - bbPic.x
      let y = coords[1] - bbPic.y
      x = Math.round((x * realW) / bbPic.width)
      y = Math.round((y * realH) / bbPic.height)
      return [x, y]
    }

    const getMousePosition = (evt) => {
      svgPt.current.x = evt.clientX
      svgPt.current.y = evt.clientY
      svgPt.current = svgPt.current.matrixTransform(
        svg.current.getScreenCTM().inverse()
      )
      //console.log(`Client mouse pos: ${evt.clientX} ${evt.clientY}`)
      //console.log(`SVG mouse pos: ${svgPt.current.x} ${svgPt.current.y}`)
      return [svgPt.current.x, svgPt.current.y]
    }

    const getCoordPicToScreen = (coords) => {
      // bbox.x + picx * bbox.width / width = screenx
      const bbPic = picCanvas.current.getBoundingClientRect()
      let realW = pic.current.naturalWidth
      let realH = pic.current.naturalHeight
      let x = bbPic.x + Math.round((coords[0] * bbPic.width) / realW)
      let y = bbPic.y + Math.round((coords[1] * bbPic.height) / realH)
      return [x, y]
    }

    const setSelectBox = (pointList) => {
      if (pointList) {
        let c1 = getCoordOnPic(pointList[0])
        let c2 = getCoordOnPic(pointList[1])
        dispatch({ action: "setSelectBox", payload: [...pointList] })
        onSelectBox([c1, c2])
        if (window.event.ctrlKey) {
          // select all parts that are inside select box
          let selection = {}
          let idxVal = -1
          for (let cx = c1[0]; cx < c2[0]; cx++) {
            for (let cy = c1[1]; cy < c2[1]; cy++) {
              idxVal = getIndexValue(state.imageIndex, [cx, cy]) - 1
              if (idxVal >= 0) {
                if (!(idxVal.toString() in selection)) selection[idxVal] = 0
                selection[idxVal]++
              }
            }
          }
          // check if all part pixels are inside the select box
          if (SELECT_ONLY_FULL_PIXELS) {
            Object.keys(selection).forEach((partId) => {
              if (
                selection[partId] < state.idxPixels[parseInt(partId) + 1].length
              )
                delete selection[partId]
            })
          }
          dispatch({
            action: "addSelectedObj",
            payload: {
              val: Object.keys(selection).map((k) => parseInt(k)),
              mode: SelectionAddMode.ADD,
            },
          })
        }
      } else {
        dispatch({ action: "setSelectBox", payload: [] })
        onSelectBox(null)
      }
    }

    const setPointsInteractor = (pointList) => {
      if (pointList) {
        const coordsOnPic = pointList.map((point) => getCoordOnPic(point))
        onChangeKeypoints(pictures[currentIndex], coordsOnPic)
      }
    }

    useEffect(() => {
      let keypoints = []
      if (selectMode === SelectionMode.POINT_SELECTOR) {
        const crtPic = pictures[currentIndex]
        if (crtPic.metadata.keypoints) {
          keypoints = crtPic.metadata.keypoints.map((point) =>
            getCoordPicToScreen(point)
          )
        } else {
          // initialize keypoints to specific positions
          const imgW = pic.current.naturalWidth
          const imgH = pic.current.naturalHeight
          const line1H = (imgH * 3) / 4
          const line2H = imgH / 4
          keypoints = Array(KEYPOINTS_INTERACTOR_COUNT)
          keypoints[0] = [imgW / 4, line1H]
          keypoints[1] = [imgW / 2, line1H]
          keypoints[2] = [(imgW * 3) / 4, line1H]
          keypoints[3] = [(imgW * 3) / 4, line2H]
          keypoints[4] = [imgW / 2, line2H]
          keypoints[5] = [imgW / 4, line2H]
          keypoints = keypoints.map((point) => getCoordPicToScreen(point))
        }
      }
      setKeypoints(keypoints)
    }, [selectMode, pictures, currentIndex])

    const setCanvasRect = (image) => {
      picCanvas.current.width = image.width
      picCanvas.current.height = image.height
      DEBUG &&
        console.log(`set picCanvas size : ${image.width}x${image.height}`)
    }

    const getRealtimeCSSFilter = useMemo(
      () =>
        `brightness(${
          displayImageEditor ? brightness / (RANGE_STEPS / 2) : 1
        }) contrast(${displayImageEditor ? contrast / (RANGE_STEPS / 2) : 1})`,
      [displayImageEditor, brightness, contrast]
    )

    const getImageBase64WithCurrentFilter = async () => {
      let canvas = document.createElement("canvas")
      try {
        const primary_layer = await loadImage(pictures[currentIndex].src)
        // The width & height of picCanvas (Ref) is a resizedx size in the browser display resolution.
        // It is thus necessary to use the freshly loaded source image (primary_layer in this case) in order to keep the original image size.
        canvas.width = primary_layer.width
        canvas.height = primary_layer.height
        let ctx = canvas.getContext("2d")
        ctx.filter = getRealtimeCSSFilter
        ctx.drawImage(primary_layer, 0, 0)
        if (pictures[currentIndex]?.geomSrc?.datafile) {
          let geom_canvas = document.createElement("canvas")
          const geometry_layer = await loadImage(
            pictures[currentIndex].geomSrc.datafile
          )
          geom_canvas.width = geometry_layer.width
          geom_canvas.height = geometry_layer.height
          let geom_ctx = geom_canvas.getContext("2d")
          geom_ctx.drawImage(geometry_layer, 0, 0)
          return { primary_layer: canvas, geom_layer: geom_canvas }
        } else {
          return { primary_layer: canvas }
        }
      } catch (e) {
        console.warn(JSON.stringify(e))
      }
    }

    const applyFilterAndDuplicate = async () => {
      setProcessing(true)
      try {
        const item = pictures[currentIndex]
        // eslint-disable-next-line no-unused-vars
        const [name_root, format] = await HMFileIO().getNewName(item.id)
        const { primary_layer, geom_layer } =
          await getImageBase64WithCurrentFilter()
        const primary_blob = await canvasToBlob(
          primary_layer,
          name_root + ".png"
        )
        const folderId = crtDir.id
        // WARN: copy of item.metadata to new file
        const file = await HMFileIO().uploadFileXHR(
          primary_blob,
          folderId,
          item.metadata
        )
        if (geom_layer) {
          // Register Geometry Objects
          const hmCom_source = HMCom()
          const hmCom_target = HMCom()
          await hmCom_source.initGeoCompo(item.id)
          await hmCom_target.initGeoCompo(file.id)
          const hmComRes_source = await hmCom_source.loadGeoCompoData()
          const [
            pts_source,
            polygons_source,
            masks_source,
            circles_source,
            squares_source,
          ] = hmComRes_source
          await hmCom_target.saveGeoCompoData(
            file.id,
            pts_source ?? [],
            polygons_source ?? [],
            masks_source ?? [],
            circles_source ?? [],
            squares_source ?? []
          )
          // Create gc bitmap
          let metadata = { tags: ["geom_compo"] }
          const geom_blob = await canvasToBlob(
            geom_layer,
            name_root + "_gc.png"
          )
          const resp = await HMFileIO().uploadFileXHR(
            geom_blob,
            folderId,
            metadata
          )
          console.log(resp)
          const md = { ...file.metadata, geom_compo_id: resp.id }
          await HMFileIO().updateFileMetadata(md, file.id)
          updatePictureState(file, () => {
            onChangePic(file)
          })
          await updatePictureGeomCompo(file, resp.id)
        } else {
          updatePictureState(file, () => {
            onChangePic(file)
          })
        }
      } catch (e) {
        console.warn(JSON.stringify(e))
      } finally {
        toast(`Successfully duplicated image with a new visual filter.`)
        //setProcessing(false)
      }
    }

    const initializeEditionState = () => {
      onSelectBox(null)
      setBrightness(INITIAL_BRIGHTNESS)
      setContrast(INITIAL_CONTRAST)
    }

    const resizeCurrentImage = async (size) => {
      if (!pictures[currentIndex]?.id) {
        console.error("Resize error - picture id is missing!")
        return
      }
      let size_target = [1920, 1080]
      switch (size) {
        case "FHD":
          break
        case "UHD":
          size_target[0] = 3840
          size_target[1] = 2160
          break
        default:
          console.error("Resize error - size parameter missing!")
          return
      }
      DEBUG &&
        console.log(
          `Resizing Image (${pictures[currentIndex].id}) to a new resolution: ${size_target}`
        )
      setProcessing(true)
      let newfileId = await HMFileIO().thumbnail(
        pictures[currentIndex].id,
        size_target,
        true
      )
      const newfile = await HMFileIO().loadFile(newfileId)
      try {
        if (pictures[currentIndex]?.geomSrc?.datafile) {
          const hmCom_source = HMCom()
          const hmCom_target = HMCom()
          await hmCom_source.initGeoCompo(pictures[currentIndex].id)
          const hmComRes_source = await hmCom_source.loadGeoCompoData()
          const [
            pts_source,
            polygons_source,
            masks_source,
            circles_source,
            squares_source,
          ] = hmComRes_source
          let source_geoms = {
            points: pts_source ?? [],
            polygons: polygons_source ?? [],
            masks: masks_source ?? [],
            circles: circles_source ?? [],
            squares: squares_source ?? [],
          }
          const img_target = await loadImage(newfile.src)
          let geomLength = 0
          let target_width = img_target.width
          let target_height = img_target.height
          let deltaRatio_x = target_width / original_img_size.width
          let deltaRatio_y = target_height / original_img_size.height
          if (deltaRatio_x && deltaRatio_y) {
            for (let i = 0; i < source_geoms.points.length; i++) {
              geomLength++
              source_geoms.points[i].id = uuid()
              source_geoms.points[i].points[0] *= deltaRatio_x
              source_geoms.points[i].points[1] *= deltaRatio_y
            }
            for (let i = 0; i < source_geoms.polygons.length; i++) {
              geomLength++
              source_geoms.polygons[i].id = uuid()
              for (let j = 0; j < source_geoms.polygons[i].points.length; j++) {
                source_geoms.polygons[i].points[j].id = uuid()
                source_geoms.polygons[i].points[j].points[0] *= deltaRatio_x
                source_geoms.polygons[i].points[j].points[1] *= deltaRatio_y
              }
            }
            for (let i = 0; i < source_geoms.masks.length; i++) {
              geomLength++
              source_geoms.masks[i].id = uuid()
              for (let j = 0; j < source_geoms.masks[i].points.length; j++) {
                source_geoms.masks[i].points[j].id = uuid()
                source_geoms.masks[i].points[j].points[0] *= deltaRatio_x
                source_geoms.masks[i].points[j].points[1] *= deltaRatio_y
              }
              for (let k = 0; k < source_geoms.masks[i].holes.length; k++) {
                source_geoms.masks[i].holes[k].id = uuid()
                source_geoms.masks[i].holes[k].points[0] *= deltaRatio_x
                source_geoms.masks[i].holes[k].points[1] *= deltaRatio_y
              }
            }
            for (let i = 0; i < source_geoms.circles.length; i++) {
              geomLength++
              source_geoms.circles[i].id = uuid()
              source_geoms.circles[i].normal = source_geoms.circles[i]
                .normal ?? [0, 0, 1]
              source_geoms.circles[i].center.id = uuid()
              source_geoms.circles[i].center.points[0] *= deltaRatio_x
              source_geoms.circles[i].center.points[1] *= deltaRatio_y
              source_geoms.circles[i].radius *= deltaRatio_x
              source_geoms.circles[i].ptOnCircle.id = uuid()
              source_geoms.circles[i].ptOnCircle.points[0] *= deltaRatio_x
              source_geoms.circles[i].ptOnCircle.points[1] *= deltaRatio_y
            }
            for (let i = 0; i < source_geoms.squares.length; i++) {
              geomLength++
              source_geoms.squares[i].id = uuid()
              for (let j = 0; j < source_geoms.squares[i].points.length; j++) {
                source_geoms.squares[i].points[j].id = uuid()
                source_geoms.squares[i].points[j].points[0] *= deltaRatio_x
                source_geoms.squares[i].points[j].points[1] *= deltaRatio_y
              }
            }
          }
          let payload = {
            points: [...source_geoms.points],
            polygons: [...source_geoms.polygons],
            masks: [...source_geoms.masks],
            circles: [...source_geoms.circles],
            squares: [...source_geoms.squares],
          }
          await hmCom_target.saveGeoCompoData(
            newfile.id,
            payload.points,
            payload.polygons,
            payload.masks,
            payload.circles,
            payload.squares
          )
          DEBUG && console.log(`successfully pasted ${geomLength} geometries`)
          let newfile_gc_id = await HMFileIO().thumbnail(
            pictures[currentIndex].geomSrc.id,
            size_target,
            true,
            true
          )
          const md = { ...newfile.metadata, geom_compo_id: newfile_gc_id }
          updatePictureState(newfile, () => {
            HMFileIO()
              .updateFileMetadata(md, newfile.id)
              .then(() => {
                updatePictureGeomCompo(newfile, newfile_gc_id)
              })
            onChangePic(newfile)
          })
          toast(
            `Successfully duplicated image in ${size} size with ${geomLength} geometries.`
          )
        } else {
          updatePictureState(newfile, () => {
            onChangePic(newfile)
          })
          toast(`Successfully duplicated image in ${size} size.`)
        }
      } catch (error) {
        console.log(error)
      } finally {
        //setProcessing(false)
        handleMenu(undefined, { type: "pv_resize", payload: null })
      }
    }

    useEffect(() => {
      dispatch({ action: "setNeedSave", payload: dirty })
    }, [dirty])

    useEffect(() => {
      //image changed, initialize crop selection
      initializeEditionState()

      if (currentIndex >= pictures.length) {
        // prevent error when delete last picture
        setCurrentIndex("last")
      } else {
        pvInit(pictures[currentIndex]).then((props) =>
          dispatch({
            action: "init3DSelector",
            payload: { ...props },
          })
        )
      }

      const onKeyDown = (evt) => {
        switch (evt.key) {
          case "PageUp": {
            setCurrentIndex("left")
            break
          }
          case "PageDown": {
            setCurrentIndex("right")
            break
          }
          case "Home": {
            setCurrentIndex("first")
            break
          }
          case "End": {
            setCurrentIndex("last")
            break
          }
          case "R":
          case "r": {
            if (isPicFromStep(pictures[currentIndex]))
              handleMenu(undefined, {
                type: "hdRender",
                payload: pictures[currentIndex],
              })
            break
          }
          case "A":
          case "a": {
            dispatch({ action: "selectAllObj" })
            break
          }
          case "c": {
            if (evt.ctrlKey) {
              const picture = pictures[currentIndex]
              if (picture.metadata && picture.metadata.geom_compo_id) {
                console.log("COPY GEOM")
                copyPictureGeom(picture)
              }
            }
            break
          }
          case "v": {
            if (evt.ctrlKey && hasPictureGeom()) {
              const source = getPictureGeom()
              console.log("PASTE GEOM: ", source)
              HMCom().duplicateGeoCompo(source.id, pictures[currentIndex].id).then((result)=>console.log("DUplicate restult = ", result))
              // FIXME call updatePictureGeomCompo from mainApp ???
            }
            break
          }
          default:
            break
        }
        //evt.preventDefault()
      }
      if (DEBUG) console.log(`UseFX(reinit, key listener) on current change`)
      dispatch({ action: "setSelectBox", payload: [] })
      dispatch({ action: "clearSelectedObj" })
      dispatch({ action: "clearColoredObj" })
      const listenOn = document // svgElt
      listenOn.addEventListener("keydown", onKeyDown)
      if (pictures[currentIndex]?.src) {
        // register original image size => if smaller than FHD or UHD, do not allow resizing.
        // it is necessary to load the source image here in the separate code to get the real size,
        // because the <img> element resizes itself to fit the screen viewport.
        const pic = pictures[currentIndex]
        if (pic.metadata.size)
          setOriginalIMGSize({
            width: pic.metadata.size[0],
            height: pic.metadata.size[1],
          })
        else
          loadImage(pic.src).then((img) => {
            setOriginalIMGSize({ width: img.width, height: img.height })
          })
        if (processing) {
          return new Promise((r) => setTimeout(r, 100)).then(() => {
            setProcessing(false)
          })
        }
      }
      return () => {
        listenOn.removeEventListener("keydown", onKeyDown)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex, pictures])

    useEffect(() => {
      if (DEBUG) console.log(`UseFX(mouse listener) on imageIndex change`)
      const listenOn = svg.current //document // svgElt
      const onMouseMove = (event) => {
        if (
          (selectMode === SelectionMode.SINGLE ||
            selectMode === SelectionMode.PARENT_GROUP) &&
          state.imageIndex &&
          state.objComponents
        ) {
          const newPos = getMousePosition(event)
          const coords = getCoordOnPic(newPos)
          //console.log(`Coord on Pic : `, coords)
          const idxVal = getIndexValue(state.imageIndex, coords)
          dispatch({ action: "update3DSelector", payload: idxVal })
        }
      }
      const onMouseEnd = (event) => {
        if (event.button !== 0) {
          return
        }
        const ctrlDown = event.ctrlKey || event.metaKey
        if (state.imageIndex && state.objComponents) {
          if (ctrlDown) {
            const newPos = getMousePosition(event)
            const coords = getCoordOnPic(newPos)
            const idxVal = getIndexValue(state.imageIndex, coords)
            if (idxVal >= 0) {
              let payload = idxVal - 1
              // selection by compound
              if (selectMode !== SelectionMode.SINGLE) {
                const siblings = getSiblingParts(idxVal - 1)
                if (siblings) payload = siblings
                if (DEBUG)
                  console.log(`PV: payload for multi selection is `, payload)
                // or selection for single object
              }
              dispatch({
                action: "addSelectedObj",
                payload: { val: payload },
              })
            }
          } else dispatch({ action: "clearSelectedObj" })
        }
      }
      listenOn.addEventListener("mousemove", onMouseMove)
      listenOn.addEventListener("mouseup", onMouseEnd)
      return () => {
        listenOn.removeEventListener("mousemove", onMouseMove)
        listenOn.removeEventListener("mouseup", onMouseEnd)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.imageIndex, selectMode])

    useEffect(() => {
      const { currentObjIndex, selectedObj, idxColor } = state
      refreshSelectedObjCanvas(currentObjIndex, selectedObj, idxColor)
      onChangeSelection(selectedObj)
      onChangeObjColor(idxColor)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentObjIndex, state.selectedObj, state.idxColor])

    useEffect(() => {
      if (!displayImageEditor) {
        setBrightness(INITIAL_BRIGHTNESS)
        setContrast(INITIAL_CONTRAST)
      }
    }, [displayImageEditor])

    // first time use effect to register mouse listener
    useEffect(() => {
      const svgElt = svg.current
      svgPt.current = svgElt.createSVGPoint()
      /*
       * widgets (brightness, color...) should reposition themselves
       * by listening to the viewport resize event if that happens.
       */
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }
      window.addEventListener("resize", handleResize)

      // DO NOT SAVE ON EXIT (change 17/05/2021 for SW)
      return () => window.removeEventListener("resize", handleResize)
    }, [])

    useEffect(() => {
      if (!windowDimensions?.width) {
        return
      }
      const getButtonRect = (str) => {
        const imgeditButton = document.getElementById(str)
        if (!imgeditButton) {
          // HTML element named id ${str} does not exist.
          // return fallback value
          return {
            top: 64,
            right: 240,
            width: 120,
          }
        }
        const rect = imgeditButton.getBoundingClientRect()
        if (imgeditButton.offsetParent) {
          const parentRect = imgeditButton.offsetParent.getBoundingClientRect()
          return {
            height: rect.height,
            width: rect.width,
            top: rect.top, //rect.top - parentRect.top,
            right: parentRect.right - rect.right,
            bottom: parentRect.bottom - rect.bottom,
            left: rect.left, //rect.left - parentRect.left,
          }
        } else {
          return rect
        }
      }
      // calculate imgedit (brightness & contrast sliders) widget position
      setImgeditButtonRect(getButtonRect("menu_imgedit"))
      // calculate color picker widget position
      setColorPickerButtonRect(getButtonRect("menu_color"))
      // calculate FHD/UHD resizing tool widget position
      setResizeButtonRect(getButtonRect("menu_resize"))
    }, [windowDimensions?.width])

    useImperativeHandle(innerRef, () => ({
      onCrop: async (item, selectBox) => {
        if (!selectBox) {
          return
        }
        const data = {
          imgs: document.querySelectorAll(".export-img"),
          canvas: document.getElementById("export-canvas"),
        }
        setProcessing(true)
        try {
          const _newName = await HMFileIO().getNewName(item.id)
          let newname = _newName.join("")
          // this is a bitmap crop, result is always a png file
          newname = newname.substring(0, newname.lastIndexOf(".")) + ".png"
          const postCrop = async (file) => {
            const folderId = crtDir.id
            if (DEBUG) {
              console.log(
                `Now upload blob ${file.name} to folder ${folderId} on server: `,
                file
              )
            }
            try {
              const _file = await HMFileIO().uploadFileXHR(file, folderId, {
                cropped: `from file ${item.name}`,
              })
              DEBUG && console.log(`Successful upload of file id `, _file.id)
              const picData = await updatePictureState(_file)
              toast(`Image crop & duplicate successful.`)
              onChangePic(picData)
            } catch (e) {
              console.log(e)
            }
          }
          await pvCrop(data, newname, selectBox, postCrop)
        } catch (e) {
          console.log(e)
        } finally {
          //setProcessing(false)
        }
      },
      onDownload: (item, coloredObjParts) => {
        const data = {
          imgs: document.querySelectorAll(".export-img"),
          canvas: document.getElementById("export-canvas"),
        }
        let newFile = undefined
        pvSave(data, item, (file, newname) => {
          newFile = file
          return onDuplicateCard(item, newname[0], true, {
            coloredObjParts: coloredObjParts,
          })
        }).then(() => {
          downloadUrl(newFile.id, newFile.name)
          setDirty(false)
        })
      },
      onCopyLink: (item, coloredObjParts) => {
        const data = {
          imgs: document.querySelectorAll(".export-img"),
          canvas: document.getElementById("export-canvas"),
        }
        let newFile = undefined
        pvSave(data, item, (file, newname) => {
          newFile = file
          return onDuplicateCard(item, newname[0], true, {
            coloredObjParts: coloredObjParts,
          })
        }).then(() => {
          copyLink(newFile.src + "#" + newFile.id)
          setDirty(false)
        })
      },
      onCopyFile: (item, coloredObjParts) => {
        const data = {
          imgs: document.querySelectorAll(".export-img"),
          canvas: document.getElementById("export-canvas"),
        }
        pvSave(data, item, (file, newname) =>
          onDuplicateCard(item, newname[0], true, {
            coloredObjParts: coloredObjParts,
          })
        ).then(() => setDirty(false))
      },
    }))

    return (
      <>
        <div
          className="picture-viewer-wrapper"
          style={{ cursor: processing ? "wait" : "auto" }}
        >
          <div className="pv-relative-correction">
            {/** Add arrows vertically centered to the left and right of the screen at fixed position */}
            {pictures.length > 1 ? (
              <>
                {currentIndex > 0 ? (
                  <div className="picture-view-left">
                    <div className="picture-view-left-button">
                      <IoIosArrowDropleft
                        size="3em"
                        onClick={() => {
                          setCurrentIndex("left")
                        }}
                      />
                    </div>
                    <div className="picture-view-left-button">
                      <IoIosSkipBackward
                        size="2em"
                        onClick={() => {
                          setCurrentIndex("first")
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {currentIndex < pictures.length - 1 ? (
                  <div className="picture-view-right">
                    <div className="picture-view-right-button">
                      <IoIosArrowDropright
                        size="3em"
                        onClick={() => {
                          setCurrentIndex("right")
                        }}
                      />
                    </div>
                    <div className="picture-view-right-button">
                      <IoIosSkipForward
                        size="2em"
                        onClick={() => {
                          setCurrentIndex("last")
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {/** picture name as footer */}
            <div className="picture-view-footer">
              {state.currentObjName ? <p>{state.currentObjName}</p> : ""}
            </div>
            {/** Main picture displayed at the center of the screen  */}
            <div
              className="picture-view-container"
              style={
                pictures[currentIndex]?.rot
                  ? {
                      transform:
                        "translate(-50%, -50%) rotate(" +
                        pictures[currentIndex].rot * 90 +
                        "deg)",
                    }
                  : {
                      transform: "translate(-50%, -50%)",
                    }
              }
            >
              <img
                ref={pic}
                style={{
                  filter: getRealtimeCSSFilter,
                  opacity: processing ? 0 : 1,
                }}
                id="picture-view-img"
                className="picture-view-img export-img"
                src={pictures[currentIndex]?.src ?? ""}
                alt={pictures[currentIndex]?.name ?? ""}
                onLoad={(e) => {
                  setCanvasRect(e.target)
                }}
              />
              <canvas
                ref={picCanvas}
                id="export-canvas"
                className="picture-view-canvas"
              ></canvas>
              {pictures[currentIndex]?.geomSrc?.datafile ? (
                <img
                  id="picture-view-img-overlay"
                  className="picture-view-svg export-img"
                  src={pictures[currentIndex].geomSrc?.datafile ?? ""}
                  alt={pictures[currentIndex]?.name ?? ""}
                />
              ) : (
                <></>
              )}
              {processing ? <div className="loading-spinner" /> : <></>}
            </div>
            <svg ref={svg} className="picture-view-interact-svg">
              {svg.current && selectMode === SelectionMode.POINT_SELECTOR ? (
                <RenderPointInteractor
                  svg={svg.current}
                  setPoints={setPointsInteractor}
                  points={keypoints}
                  image_id={pictures[currentIndex]?.id}
                />
              ) : svg.current && !processing ? (
                <RenderSVGSelector
                  svg={svg.current}
                  setSelector={setSelectBox}
                  image_id={pictures[currentIndex]?.id}
                />
              ) : (
                <></>
              )}
            </svg>
          </div>
        </div>
        {displayColor && Boolean(colorPickerButtonRect) ? (
          <HMKColorPicker
            onColorClick={() => {}}
            onColorChange={onColorChange}
            className="picture-view-colorpicker"
            style={{
              right: colorPickerButtonRect.right + "px",
            }}
          />
        ) : (
          <></>
        )}
        {displayResizingTool && Boolean(resizeButtonRect) ? (
          <div
            className="picture-view-widget-container"
            style={{
              right: resizeButtonRect.right + resizeButtonRect.width / 2 + "px",
            }}
          >
            {processing ? (
              <button
                className="picture-view-widget-button picture-view-processing-button"
                style={{
                  cursor: "wait",
                }}
              >
                Processing
              </button>
            ) : (
              <>
                {original_img_size.width > 1920 ||
                original_img_size.height > 1080 ? (
                  <button
                    className="picture-view-widget-button picture-view-resizing-tool-button"
                    onClick={() => {
                      resizeCurrentImage("FHD")
                    }}
                  >
                    FHD 1080
                  </button>
                ) : (
                  <></>
                )}
                {original_img_size.width > 3840 ||
                original_img_size.height > 2160 ? (
                  <button
                    className="picture-view-widget-button picture-view-resizing-tool-button"
                    onClick={() => {
                      resizeCurrentImage("UHD")
                    }}
                  >
                    UHD 2160
                  </button>
                ) : (
                  <></>
                )}
                <button
                  className="picture-view-widget-button"
                  onClick={() => {
                    handleMenu(undefined, {
                      type: "pv_resize",
                      payload: null,
                    })
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        ) : (
          <></>
        )}
        {displayImageEditor && Boolean(imgeditButtonRect) ? (
          <div
            className="picture-view-widget-container picture-view-slider-container"
            style={{
              right:
                imgeditButtonRect.right + imgeditButtonRect.width / 2 + "px",
            }}
          >
            <div data-tip data-for={"brightness_slider"}>
              <input
                id="brightness"
                name="brightness"
                min="50"
                max={RANGE_STEPS}
                value={brightness}
                type="range"
                onChange={(e) => {
                  setBrightness(e.target.value)
                }}
              />
              <ReactTooltip id={"brightness_slider"} place="bottom">
                <span>
                  brightness:
                  {brightness >= 100
                    ? `+${brightness - 100}`
                    : `${brightness - 100}`}
                  %
                </span>
              </ReactTooltip>
            </div>
            <div data-tip data-for={"contrast_slider"} place="left">
              <input
                id="contrast"
                name="contrast"
                min="50"
                max={RANGE_STEPS}
                value={contrast}
                type="range"
                onChange={(e) => {
                  setContrast(e.target.value)
                }}
              />
              <ReactTooltip id={"contrast_slider"}>
                <span>
                  contrast:
                  {contrast >= 100 ? `+${contrast - 100}` : `${contrast - 100}`}
                  %
                </span>
              </ReactTooltip>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {processing ? (
                <button
                  className="picture-view-widget-button picture-view-processing-button"
                  style={{
                    cursor: "wait",
                  }}
                >
                  Processing
                </button>
              ) : (
                <>
                  <button
                    className="picture-view-widget-button picture-view-imgedit-button"
                    onClick={() => {
                      handleMenu(undefined, {
                        type: "pv_imgedit",
                        payload: null,
                      })
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="picture-view-widget-button picture-view-imgedit-button"
                    onClick={() => {
                      applyFilterAndDuplicate()
                    }}
                  >
                    Apply
                  </button>
                </>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    )
  }
)

export default PictureViewer
