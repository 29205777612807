import React from "react"

import "./HMKCheckbox.css"

const CheckBoxBtn = ({ id, name, checked, onChange }) => (
  <div className="col hmk_checkbox">
    <input
      type="checkbox"
      id={id}
      className="form-control"
      name={name}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={id} />
  </div>
)

export default CheckBoxBtn
