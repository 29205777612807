import { create, all } from "mathjs"

const config = {}
const math = create(all, config)

/**
 * Given 3 3d points, this methode compute the only 3d circle passing through the 3 points.
 * It returns the circle definition in the form { center, radius, normal }
 * @param {*} pts
 */
const cramerRule = (pts) => {
  let v1 = math.subtract(pts[1], pts[0])
  let v2 = math.subtract(pts[2], pts[0])
  let v_plan = math.cross(v1, v2)

  let A = [
    [...math.subtract(pts[0], pts[1])],
    [...math.subtract(pts[0], pts[2])],
    [...v_plan],
  ]
  let detA = math.det(A)

  let b = [
    (math.sum(math.square(pts[0])) - math.sum(math.square(pts[1]))) / 2.0,
    (math.sum(math.square(pts[0])) - math.sum(math.square(pts[2]))) / 2.0,
    math.sum(math.multiply(v_plan, pts[0])),
  ]
  let x = [0.0, 0.0, 0.0]

  for (let i = 0; i < 3; i++) {
    let B = A.map((a) => a.slice())
    B[0][i] = b[0]
    B[1][i] = b[1]
    B[2][i] = b[2]
    x[i] = math.det(B) / detA
  }

  let radius_vect = math.subtract(pts[0], x)
  let R = math.norm(radius_vect)
  let normal = math.divide(v_plan, math.norm(v_plan))
  return [x, R, normal]
}

export { cramerRule }
