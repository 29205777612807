import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react"
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io"
import UPNG from "upng-js"
import kdTree from "./kdTree"
import ImagePicker from "./ImagePicker"
import Camera from "./Camera"
import Object3D from "./Object3D"
import Controller from "../controller/Controller"
import { HmkLoader } from "../../common/HMKLoader"
import { RenderGeometries, DebugRenderVisibleVertices } from "../SVGRenderer/SVGRenderer"
import RenderSvgPoint from "../SVGRenderer/RenderSVGPoint"
import LoadSVGFile from "../SVGFileLoader"
import "./PartView.css"
import { defaultFilteredGeometries } from "../controller/Resources"
import { polygonThreshold, min_circle_radius } from "../Geometries"
import { ComposerContext } from "../../Contexts"
import { HELP_TIP, addLineBreaks, pointDistance } from "./Resources"

const DEBUG = false
const DISPLAY_DEBUGGER = false
let vertices = null // this is for Debug only
let svgPt = null
let dragOffset = null
let selectedObject = null
let disableComponent = false
const imagePicker = new ImagePicker(null)

/**
 * The main component for viewing a picture and editing 3D geometries on it.
 * It's based on a colored picture, a PNG depth picture of the same size,
 * the quaternion rotation definition and all the 3D vertices of the object to pick on.
 */
const PartView3DFC = forwardRef(
  ({ picture, loader, data, force2DCallback, resetSquareRatio, toLeft, toRight, onTrack }, ref) => {
    //context
    const composerContext = useContext(ComposerContext)
    //state
    const [loading, setLoading] = useState(true)
    const [focusEditName, setFocusEditName] = useState(false)
    const [focusedObject, setFocusedObject] = useState("")
    const [svgEdited, setSvgEdited] = useState(false)
    const [compoEdited, setCompoEdited] = useState(false)
    //UI
    const [infoContent, setInfoContent] = useState("")

    //magnet
    const [magnetPoint, setMagnetPoint] = useState([])
    const [magnetPoint3d, setMagnetPoint3d] = useState([])
    const [magnetObjIndex, setMagnetObjIndex] = useState(null)

    // references
    const imgCanvas = useRef(null)
    const svgCanvas = useRef(null)
    const cardViewRef = useRef(null)
    const controllerRef = useRef(null)

    //rendering
    const [crntPoints, setCrntPoints] = useState([])
    const [filteredGeometries, setFilteredGeometries] = useState(defaultFilteredGeometries)
    //-//search keyword from controller
    const [keyword, setKeyword] = useState("")
    const [checkedObjects, setCheckedObjects] = useState([])
    const [displaySvgCanvas, setDisplaySvgCanvas] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [incrementalRef, setIncrementalRef] = useState(0)

    const getPictureName = (picture) => {
      return picture.src.slice(picture.src.lastIndexOf("/") + 1, picture.src.lastIndexOf("."))
    }

    const getPictureUrl = (picture) => {
      return picture.src
    }

    const updateFilteredGeometries = (geomType) => {
      if (geomType === "init") {
        setFilteredGeometries(defaultFilteredGeometries)
      } else {
        let state = { ...filteredGeometries }
        state[geomType] = !state[geomType]
        setFilteredGeometries(state)
      }
    }
    const svgEltOver = (e) => {
      e.target.classList.add(`holo_element`)
    }
    const svgEltOut = (e) => {
      e.target.classList.remove(`holo_element`)
    }

    const cleanUpObjects = () => {
      setCrntPoints([])
      selectedObject = null
      setCheckedObjects([])
      setFilteredGeometries(defaultFilteredGeometries)
      setKeyword("")
    }
    const getMousePosition = (evt, svg) => {
      if (composerContext?.menuState?.mode3D) {
        return {
          x: evt.offsetX,
          y: evt.offsetY,
        }
      } else {
        if (!svgPt) {
          console.error("svgPt missing !")
          return { x: 0, y: 0 }
        }
        svgPt.x = evt.clientX
        svgPt.y = evt.clientY
        svgPt = svgPt.matrixTransform(svg.getScreenCTM().inverse())
        return { x: svgPt.x, y: svgPt.y }
      }
    }

    const clearSelectedElementClass = (root) => {
      const holoSvg = root.getElementsByClassName("holo_svg")
      if (holoSvg?.length) {
        const elements = holoSvg[0].getElementsByTagName("*")
        Array.from(elements).forEach((e) => {
          e.classList.remove(`holo_selected`)
        })
      }
    }

    const checkSVGEltEnclosed = (elt, x1, y1, x2, y2) => {
      const rect = elt.getBBox ? elt.getBBox() : elt.correspondingUseElement.getBBox()
      //console.log(`Check svg elt: `, elt)
      //console.log(`with bbox = `, rect)
      const sctm = elt.getScreenCTM()
      svgPt.x = rect.x
      svgPt.y = rect.y
      svgPt = svgPt.matrixTransform(sctm)
      let ex1 = svgPt.x
      let ey1 = svgPt.y
      svgPt.x = rect.x + rect.width
      svgPt.y = rect.y + rect.height
      svgPt = svgPt.matrixTransform(sctm)
      let ex2 = Math.max(ex1, svgPt.x)
      let ey2 = Math.max(ey1, svgPt.y)
      ex1 = Math.min(ex1, svgPt.x)
      ey1 = Math.min(ey1, svgPt.y)
      return ex1 >= x1 && ey1 >= y1 && ex2 <= x2 && ey2 <= y2
    }

    const getSVGAllEnclosed = (svg, x1, y1, x2, y2) => {
      let result = []
      const l = svg.getElementsByTagName("*")
      Array.from(l).forEach((e) => {
        if (checkSVGEltEnclosed(e, x1, y1, x2, y2)) result.push(e)
      })
      return result
    }

    const getSVGNotEnclosed = (svg, x1, y1, x2, y2) => {
      let result = []
      const l = svg.getElementsByTagName("*")
      Array.from(l).forEach((e) => {
        if (!checkSVGEltEnclosed(e, x1, y1, x2, y2)) result.push(e)
      })
      return result
    }

    const checkInsideMask = (x, y) => {
      const { masks } = composerContext.stateData
      return masks.find(
        (m) =>
          x > m.points[0].points[0] &&
          x < m.points[1].points[0] &&
          y > m.points[0].points[1] &&
          y < m.points[1].points[1]
      )
    }

    const updateObject = (selectedObject, dx, dy, objectType) => {
      const [objId, ptId] = selectedObject.split("#")
      if (!objId) {
        // in this case we move the crtPoints data
        let newCrtPoints = [...crntPoints]
        if (newCrtPoints[ptId]?.length > 0) {
          newCrtPoints[ptId][0] += dx
          newCrtPoints[ptId][1] += dy
          setCrntPoints(newCrtPoints)
        }
      } else {
        composerContext.updateObject(selectedObject, dx, dy, objectType)
      }
    }

    // click function called
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const moveClick = (event) => {
      if (disableComponent) {
        return
      }
      const { magnet } = composerContext.menuState.viewModes
      const { selection, crop, point, circle, circle3p, polygon, mask, square } =
        composerContext.menuState.editModes

      let click = event.type === "mousedown"
      let move = event.type === "mousemove"

      // check left mouse button clicked or magnet-mode
      if (click && (event.button !== 0 || !cardViewRef.current.contains(event.target))) {
        return
      }

      event.preventDefault()
      let { x, y } = getMousePosition(event, svgCanvas.current)
      // case of Selection mode
      if (selectedObject) {
        // case if selectedObject comes from the form ... stop the drag
        if (!selectedObject.includes("#")) {
          dragOffset = null
          //setDragOffset(null)
          return
        }
        if (click) {
          // update controller form selection status of object
          if (controllerRef?.current?.toggleObjectSelectionById) {
            controllerRef.current.toggleObjectSelectionById(
              selectedObject,
              Boolean(event.ctrlKey || event.metaKey)
            )
          }
          // Force Perfect Square ratio if Ctrl pressed
          if (Boolean(event.ctrlKey || event.metaKey)) {
            let otherPoint
            let id_array = selectedObject.split("#")
            let id = id_array[0]
            let index = id_array.length > 1 ? Number(id_array[1]) : ""
            if (square) {
              const { squares } = composerContext.stateData
              if (index === 0 || index === 1) {
                for (let i = 0; i < squares.length; i++) {
                  if (id === squares[i].id) {
                    let otherIndex = Boolean(index === 0) ? 1 : 0
                    otherPoint = squares[i].points[otherIndex].points
                    break
                  }
                }
              }
            }
            if (mask) {
              const { masks } = composerContext.stateData
              if (index === 0 || index === 1) {
                for (let i = 0; i < masks.length; i++) {
                  if (id === masks[i].id) {
                    let otherIndex = Boolean(index === 0) ? 1 : 0
                    otherPoint = masks[i].points[otherIndex].points
                    break
                  }
                }
              }
            }
            if (otherPoint) {
              let x1 = Boolean(index === 0) ? x : otherPoint[0]
              let y1 = Boolean(index === 0) ? y : otherPoint[1]
              let x2 = Boolean(index === 1) ? x : otherPoint[0]
              let y2 = Boolean(index === 1) ? y : otherPoint[1]
              let deltaX = x1 - x2
              let deltaY = y1 - y2
              let minSize = 30
              if (Math.abs(deltaX) > minSize && Math.abs(deltaY) > minSize) {
                if (Math.abs(deltaX) > Math.abs(deltaY)) {
                  if (index === 0) {
                    if (deltaX < 0) {
                      x2 = x1 + Math.abs(deltaY)
                    } else if (deltaX > 0) {
                      //flipped horizontally
                      x2 = x1 - Math.abs(deltaY)
                    }
                  } else {
                    if (deltaX < 0) {
                      x1 = x2 - Math.abs(deltaY)
                    } else if (deltaX > 0) {
                      //flipped horizontally
                      x1 = x2 + Math.abs(deltaY)
                    }
                  }
                } else if (Math.abs(deltaX) < Math.abs(deltaY)) {
                  if (index === 0) {
                    if (deltaY < 0) {
                      y2 = y1 + Math.abs(deltaX)
                    } else if (deltaY > 0) {
                      //flipped vertically
                      y2 = y1 - Math.abs(deltaX)
                    }
                  } else {
                    if (deltaY < 0) {
                      y1 = y2 - Math.abs(deltaX)
                    } else if (deltaY > 0) {
                      //flipped vertically
                      y1 = y2 + Math.abs(deltaX)
                    }
                  }
                }
              } else {
                if (index === 0) {
                  if (deltaX < 0) {
                    x2 = x1 + minSize
                  } else if (deltaX > 0) {
                    //flipped horizontally
                    x2 = x1 - minSize
                  }
                  if (deltaY < 0) {
                    y2 = y1 + minSize
                  } else if (deltaY > 0) {
                    //flipped vertically
                    y2 = y1 - minSize
                  }
                } else {
                  if (deltaX < 0) {
                    x1 = x2 - minSize
                  } else if (deltaX > 0) {
                    //flipped horizontally
                    x1 = x2 + minSize
                  }
                  if (deltaY < 0) {
                    y1 = y2 - minSize
                  } else if (deltaY > 0) {
                    //flipped vertically
                    y1 = y2 + minSize
                  }
                }
              }
              resetSquareRatio(selectedObject, [x1, y1], [x2, y2])
            }
          }
          dragOffset = [x, y]
          //setDragOffset([x, y])
        } else if (dragOffset && move) {
          // move the object
          if (DEBUG) console.log(`PartView3D: move mouse, update selectedObject position...`)
          !compoEdited && setCompoEdited(true)

          if (
            Boolean(event.ctrlKey || event.metaKey) &&
            Boolean(square || mask) &&
            !selectedObject.includes("#M")
          ) {
            let deltaX = x - dragOffset[0]
            updateObject(selectedObject, deltaX, deltaX, square ? "s" : "m")
          } else {
            updateObject(
              selectedObject,
              x - dragOffset[0],
              y - dragOffset[1],
              mask ? "m" : square ? "s" : point ? "p" : circle ? "c" : polygon ? "po" : ""
            )
          }
          dragOffset = [x, y]
          //setDragOffset([x, y])
        }
        return
      }
      if (crop || selection || mask || circle || square) {
        if (click) {
          dragOffset = [x, y]
          //setDragOffset([x, y])
          if (DEBUG) console.log(`PartView3D: mouse click, update crtPoints...`)
          // drag starting point
          setCrntPoints([
            [x, y],
            [x, y],
          ])
        } else if (dragOffset && move) {
          if (DEBUG) console.log(`PartView3D: move mouse, update crtPoints...`)
          if (event.ctrlKey || event.metaKey) {
            let limitX = Math.abs(dragOffset[0] - x)
            if (dragOffset[1] - y > 0) {
              y = dragOffset[1] - limitX
            } else {
              y = dragOffset[1] + limitX
            }
          }
          setCrntPoints([dragOffset, [x, y]])
        }
        return
      }

      // compute 3D point on the model from the 2D picked point on screen
      let point3d = composerContext?.menuState?.mode3D
        ? imagePicker.compute3DFrom2D(x, y)
        : [x, y, 0]
      // en mode 'magnet', on recherche le point le plus proche de la souris
      if (magnet && point3d) {
        // check nearest point
        point3d = imagePicker.getNearestPoint3d(point3d)
        // Nearest point is point3d
        // get the current OBJ model index from magnet point
        let objIndex = point3d[3]
        point3d = point3d.slice(0, 3)

        // if move display the nearest point by converting 3D to 2D in screen
        let { point2d } = imagePicker.compute2DFrom3D(point3d)
        if (point2d) {
          x = point2d[0]
          y = point2d[1]
        }
        setMagnetPoint([x, y])
        setMagnetPoint3d(point3d)
        setMagnetObjIndex(objIndex)
      } else if (magnet && magnetPoint3d.length === 3) {
        point3d = magnetPoint3d
      }

      if (point3d) {
        // Coord 3D : point3d
        let infoContent = ""
        infoContent += polygon
          ? `${HELP_TIP.POLYGON}\n`
          : circle3p
            ? `${HELP_TIP.CIRCLE}\n`
            : mask
              ? `${HELP_TIP.MASK}\n`
              : square
                ? `${HELP_TIP.SQUARE}\n`
                : point
                  ? `${HELP_TIP.POINT}\n`
                  : ""
        infoContent += `XYZ ${point3d[0].toFixed(2)} ${point3d[1].toFixed(
          2
        )} ${point3d[2].toFixed(2)}`
        setInfoContent(infoContent)

        // save clicked point to current polygon
        if (click) {
          if (polygon) {
            // check si il faut créer un nouveau polygone
            let deltaX = crntPoints.length > 0 && Math.abs(point3d[0] - crntPoints[0][0])
            let deltaY = crntPoints.length > 0 && Math.abs(point3d[1] - crntPoints[0][1])
            if (deltaX && deltaY) {
              if (
                // if the polygon can form at least a triangle
                crntPoints.length < 2 ||
                !(deltaX < polygonThreshold && deltaY < polygonThreshold)
              ) {
                setCrntPoints([...crntPoints, point3d])
              } else {
                let initialPoint = [...crntPoints[0]]
                setCrntPoints([...crntPoints, initialPoint])
                composerContext.onToggleMode("activate_edit_form")
                togglePolygon()
              }
            } else {
              setCrntPoints([...crntPoints, point3d])
            }
          }

          if (circle3p) {
            let newPoints = [...crntPoints, point3d]
            if (newPoints.length === 3) {
              composerContext.addCircle(imagePicker.computeCircle(newPoints))
              newPoints = []
              composerContext.onToggleMode("activate_edit_form")
              setCompoEdited(true)
            }
            setCrntPoints(newPoints)
          }

          if (point) {
            composerContext.addPoint(point3d)
            composerContext.onToggleMode("activate_edit_form")
            setCompoEdited(true)
          }
        }
      } else {
        if (click) {
          DEBUG && console.log("Picking out of object")
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const endDrag = (event) => {
      const { selection, crop, mask, circle, square, polygon } = composerContext.menuState.editModes
      if (!dragOffset || disableComponent) {
        return
      }
      if (polygon) {
        // refactoring + de performance
        if (selectedObject) {
          const [objId, ptId] = selectedObject.split("#")
          if (objId) {
            //editing a polygon point that already exists
            dragOffset = null
            //setDragOffset(null)
            return
          } else {
            //editing a polygon point that is not saved yet
            const index = Number(ptId)
            if (!isNaN(index)) {
              // is current handle (dragOffset) the last point of the polygon?
              // can the polyline form at least a triangle ?
              if (index === crntPoints.length - 1 && crntPoints.length > 3) {
                let initialPoint = [...crntPoints[0]]
                let deltaX = Math.abs(dragOffset[0] - initialPoint[0])
                let deltaY = Math.abs(dragOffset[1] - initialPoint[1])
                // is the last point close to the initial piont ?
                if (deltaX < polygonThreshold && deltaY < polygonThreshold) {
                  let newCrtPoints = [...crntPoints]
                  if (newCrtPoints[ptId]?.length > 0) {
                    newCrtPoints[ptId][0] = initialPoint[0]
                    newCrtPoints[ptId][1] = initialPoint[1]
                    setCrntPoints(newCrtPoints)
                  }
                }
                togglePolygon()
                dragOffset = null
                //setDragOffset(null)
              }
            }
          }
        }
      }
      if (crop || selection || mask || circle || square || selectedObject) {
        dragOffset = null
        //setDragOffset(null)
        /*if (event.ctrlKey && (mask || square)) {
        selectedObject = null
      }*/
      }

      // in Selection mode, select objets that are inside the selected zone
      if (selection || crop) {
        //const svgnode = imgCanvas.firstChild
        // this one is the svg first group node
        clearSelectedElementClass(svgCanvas.current)
        const holoSvg = svgCanvas.current.getElementsByClassName("holo_svg")
        if (holoSvg.length) {
          // this one should be the inner svg node
          /*
          const svgnode = holoSvg[0].parentNode
          const crtPoints = crntPoints
          svgPt.x = crntPoints[0][0]
          svgPt.y = crntPoints[0][1]
          console.log(`Point in first svg coord: `, svgPt)
          console.log(`First SVG CTM: `, svgCanvas.current.getScreenCTM())
          svgPt = svgPt.matrixTransform(svgCanvas.current.getScreenCTM())
          console.log(`Point in screen coord: `, svgPt)
          const pt1 = svgPt.matrixTransform(svgnode.getScreenCTM().inverse())
          console.log(`Second SVG CTM: `, svgnode.getScreenCTM())
          console.log(`Point in second svg coord: `, pt1)
          svgPt.x = crtPoints[1][0]
          svgPt.y = crtPoints[1][1]
          svgPt = svgPt.matrixTransform(svgCanvas.current.getScreenCTM())
          const pt2 = svgPt.matrixTransform(svgnode.getScreenCTM().inverse())

          let x = Math.min(pt1.x, pt2.x)
          let y = Math.min(pt1.y, pt2.y)
          let w = Math.abs(pt2.x - pt1.x)
          let h = Math.abs(pt2.y - pt1.y)

          const area = svgnode.createSVGRect()
          area.x = x
          area.y = y
          area.width = w
          area.height = h
          */
          // get all elements intersected (and inside) the zone
          //const interList = svgnode.getIntersectionList(area, holoSvg[0])
          // get all elements enclosed in the zone
          //console.log(`Select in area `, svgnode)
          //const interList = svgnode.getEnclosureList(area, holoSvg[0])
          const crtPoints = crntPoints
          let x1 = Math.min(crtPoints[0][0], crtPoints[1][0])
          let y1 = Math.min(crtPoints[0][1], crtPoints[1][1])
          let x2 = Math.max(crtPoints[0][0], crtPoints[1][0])
          let y2 = Math.max(crtPoints[0][1], crtPoints[1][1])
          // transform back to real screen coordinates
          svgPt.x = x1
          svgPt.y = y1
          svgPt = svgPt.matrixTransform(svgCanvas.current.getScreenCTM())
          x1 = svgPt.x
          y1 = svgPt.y
          svgPt.x = x2
          svgPt.y = y2
          svgPt = svgPt.matrixTransform(svgCanvas.current.getScreenCTM())
          x2 = svgPt.x
          y2 = svgPt.y
          // check enclosing
          let interList = []
          if (crop) interList = getSVGNotEnclosed(holoSvg[0], x1, y1, x2, y2)
          else interList = getSVGAllEnclosed(holoSvg[0], x1, y1, x2, y2)
          DEBUG && console.log("elements founded: ", interList)
          // select all elements matched by the selector intersect/enclose
          Array.from(interList).forEach((i) => i.classList.add(`holo_selected`))
          // select all elements _not_ matched by the selector
          /*
          const elements = svgnode.getElementsByTagName("*")
          const matched = Array.from(interList)
          console.log(`AREA of intersection to check with: `, area)
          Array.from(elements).forEach((e) => {
            if (
              !matched.includes(e) &&
              !checkSVGEltEnclosed(e, x, y, x + w, y + h)
            )
              e.classList.add(`holo_selected`)
          })
        */
          //const encloseList = svgnode.getEnclosureList(area, internSvg)
          //console.log(`Elements enclosed: `, encloseList)
        }
      }
      if ((mask || circle || square) && crntPoints.length) {
        const crtPoints = crntPoints
        let x1 = Math.min(crtPoints[0][0], crtPoints[1][0])
        let y1 = Math.min(crtPoints[0][1], crtPoints[1][1])
        let x2 = Math.max(crtPoints[0][0], crtPoints[1][0])
        let y2 = Math.max(crtPoints[0][1], crtPoints[1][1])
        if (mask) {
          let maskIn = checkInsideMask(x1, y1) || checkInsideMask(x2, y2)
          if (maskIn) {
            composerContext.addHole(maskIn.id, [
              [x1, y1],
              [x2, y2],
            ])
          } else {
            composerContext.addMask([
              [x1, y1],
              [x2, y2],
            ])
          }
          composerContext.onToggleMode("activate_edit_form")
        } else if (circle) {
          const center = [crtPoints[0][0], crtPoints[0][1], 0]
          let radius = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
          let ptOnCircle = [crtPoints[1][0], crtPoints[1][1], 0]
          if (radius < min_circle_radius) {
            radius = min_circle_radius
            ptOnCircle = [crtPoints[0][0] + min_circle_radius, crtPoints[0][1], 0]
          }
          composerContext.addCircle([center, radius, [0.0, 0.0, 1.0], ptOnCircle])
          composerContext.onToggleMode("activate_edit_form")
        } else if (square) {
          composerContext.addSquare([
            [x1, y1],
            [x2, y2],
          ])
          composerContext.onToggleMode("activate_edit_form")
        }
        setCompoEdited(true)
        setCrntPoints([])
      }
    }
    const addSVGMouseOver = () => {
      const holoSvg = svgCanvas.current.getElementsByClassName("holo_svg")
      if (holoSvg.length) {
        const elements = holoSvg[0].getElementsByTagName("*")
        Array.from(elements).forEach((e) => {
          e.addEventListener("mouseenter", svgEltOver)
          e.addEventListener("mouseleave", svgEltOut)
        })
      }
    }
    const removeSVGMouseOver = () => {
      const holoSvg = svgCanvas.current.getElementsByClassName("holo_svg")
      if (holoSvg.length) {
        const elements = holoSvg[0].getElementsByTagName("*")
        Array.from(elements).forEach((e) => {
          e.removeEventListener("mouseenter", svgEltOver)
          e.removeEventListener("mouseleave", svgEltOut)
        })
      }
    }
    const toggleSelection = (cropMode) => {
      let infoContent = ""
      if (!cropMode && !composerContext?.menuState?.editModes?.selection) {
        addSVGMouseOver()
        infoContent = HELP_TIP.SELECTION
      } else if (cropMode && !composerContext?.menuState?.editModes?.crop) {
        infoContent = HELP_TIP.CROP
      }
      if (composerContext?.menuState?.editModes?.selection) {
        removeSVGMouseOver()
      }
      clearSelectedElementClass(svgCanvas.current)
      composerContext.onToggleMode(cropMode ? "crop" : "selection")
      setInfoContent(infoContent)
      setCrntPoints([])
    }
    const toggleMagnet = () => {
      composerContext.onToggleMode("magnet")
    }
    const togglePoint = () => {
      composerContext.onToggleMode("point")
      setCrntPoints([])
    }
    const toggleMask = () => {
      composerContext.onToggleMode("mask")
      setCrntPoints([])
    }
    const toggleCircle = () => {
      composerContext.onToggleMode("circle")
      setCrntPoints([])
    }
    const toggleSquare = () => {
      composerContext.onToggleMode("square")
      setCrntPoints([])
    }
    const togglePolygon = () => {
      // when disable polygon mode, send it to data
      if (composerContext.menuState.editModes.polygon) {
        if (crntPoints.length > 1) {
          composerContext.addPolygon(crntPoints)
          selectedObject = null
          setCompoEdited(true)
        } else {
          composerContext.onToggleMode("polygon")
        }
      } else {
        composerContext.onToggleMode("polygon")
      }
      setCrntPoints([])
    }
    const toggleDisplayEditForm = () => {
      composerContext.onToggleMode("displayEditForm")
    }
    const setMaskFullSize = () => {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onKeyUp = (event) => {
      // do not process shortcut keys when editing name
      if (focusEditName) {
        event.preventDefault()
        return
      }

      switch (event.key) {
        // 's' key for Selection Edit Mode
        case "s":
        case "S":
          toggleSelection(Boolean(event.ctrlKey || event.metaKey))
          break
        // 'm' key for Magnet view mode (3D) or Mask edit mode (2D)
        case "m":
        case "M":
          // active the mask mode when in 2d mode
          composerContext?.menuState?.mode3D ? toggleMagnet() : toggleMask()
          break
        // 'o' key for Point edit mode => add points
        case "o":
        case "O":
          togglePoint()
          break
        // 'p' key for Polygon edit mode => define polygon
        case "p":
        case "P":
          togglePolygon()
          break
        // 'c' key for Circle edit mode => define circle
        case "c":
        case "C":
          toggleCircle()
          break
        // 'f' key a cheatsheet to set the first and main mask to full pic size
        case "f":
        case "F":
          const rect = svgCanvas.current.getBoundingClientRect()
          setMaskFullSize(rect.width, rect.height)
          break
        // 'e' key to display/hide the edit form overlay
        case "e":
        case "E":
          toggleDisplayEditForm()
          break
        case "t":
        case "T":
          if (onTrack) {
            onTrack()
          }
          break
        case "PageUp":
          if (toLeft) {
            toLeft()
          }
          break
        case "PageDown":
          if (toRight) {
            toRight()
          }
          break
        default:
      }
    }

    /**
   *
   * @param {boolean} save will composer save the recent modification ?
   * @returns special action used by Composer to save edited input file and geom overlay pic file before copying link or duplicate a file

   */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveAllData = async (save) => {
      disableComponent = true
      cleanUpObjects()
      let newPicture = undefined
      let newSvgPic = undefined
      if (save) {
        const holoSvg = svgCanvas.current.getElementsByClassName("holo_svg")
        // write modified SVG input file on server (override)
        if (holoSvg.length && svgEdited) {
          setSvgEdited(false)
          setCompoEdited(true)
          newSvgPic = await composerContext.saveFile(
            holoSvg[0].parentNode,
            "",
            { edited: `from file ${getPictureUrl(picture)}` },
            false,
            true
          )
          newPicture = newSvgPic
        } else {
          setCompoEdited(false)
        }
        // giving time to re-render and snapshot bitmap
        await new Promise((r) => setTimeout(r, 100))
        DEBUG && console.log(`PartView3D: saveAllData (compo) with new SVG: `, newSvgPic)
        // write composed SVG to picture file on server
        if (compoEdited) {
          // if input file is a SVG file embedded, remove it during picture export
          let svgnode = undefined
          if (holoSvg.length) {
            svgnode = holoSvg[0].parentNode
            svgnode = svgnode.parentNode.removeChild(svgnode)
          }
          // prepare and clean the geocompo SVG canvas for production
          const elts = svgCanvas.current.getElementsByClassName("hmk_mask")
          Array.from(elts).forEach((e) => {
            e.setAttribute("stroke", "none")
            e.setAttribute("opacity", 1.0)
            const handles = e.getElementsByClassName("hmk_handle")
            Array.from(handles).forEach((h) => h.remove())
          })
          let newPic = await composerContext.saveFile(
            svgCanvas.current,
            "_gc",
            {
              tags: ["geom_compo"],
            },
            true,
            false,
            newSvgPic
          )
          if (newPicture) {
            newPicture = { ...newPicture, metadata: { ...newPic.metadata } }
          }
          if (svgnode) svgCanvas.current.appendChild(svgnode)
          return newPicture
        } else {
          return null
        }
      } else {
        return null
      }
    }

    const onPointSelected = (id) => {
      if (DEBUG) console.log(`Selected point id ${id}`)
      if (!dragOffset && !disableComponent) selectedObject = id
    }

    const updateSvgCanvas = () => {
      //let svgCanvas.current = document.getElementById("svg")
      //let imgTag = document.getElementById("img")
      svgCanvas.current.style.width = imgCanvas.current.width
      svgCanvas.current.style.height = imgCanvas.current.height
    }

    const callbackBlur = () => {
      setFocusEditName(false)
      setCompoEdited(true)
    }

    const onSelect = (key, focus = false) => {
      if (DEBUG && selectedObject !== key) {
        console.log(`Select object ${key} with FOCUS = ${focus}`)
      }
      setFocusedObject(key)
      setFocusEditName(Boolean(focusEditName || focus))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleMenu = (e, action) => {
      DEBUG && console.log(`Handle Menu action from PartView3D `, action)
      switch (action.type) {
        case "list": {
          toggleDisplayEditForm()
          break
        }
        case "debug": {
          composerContext.onToggleMode("debug")
          break
        }
        case "magnet": {
          toggleMagnet()
          break
        }
        case "selection": {
          toggleSelection(false)
          break
        }
        case "crop": {
          toggleSelection(true)
          break
        }
        case "mask": {
          toggleMask()
          break
        }
        case "polygon": {
          togglePolygon()
          break
        }
        case "circle": {
          toggleCircle()
          break
        }
        case "point": {
          togglePoint()
          break
        }
        case "square": {
          toggleSquare()
          break
        }
        default:
          console.warn(`Dunno how to handle this action: ${action.type}`)
          break
      }
    }

    // recurring
    useEffect(() => {
      document.addEventListener("keyup", onKeyUp, false)
      return () => {
        document.removeEventListener("keyup", onKeyUp, false)
      }
    }, [endDrag, moveClick, onKeyUp])

    useEffect(() => {
      DEBUG && console.log(`PartView will work on pic and data `, picture, data)
      const pictureUrl = getPictureUrl(picture)
      svgPt = svgCanvas.current.createSVGPoint()
      // in Mode 3D: read the json parameter file
      // create camera and object instances
      if (composerContext?.menuState?.mode3D) {
        /**
         *
         * @param {*} usecase the folder containing all the files
         * @param {*} title the title to use for the use case
         * @param {*} pictureName the current displayed picture name
         */
        const readParamFile = async (callback) => {
          try {
            let crtObject = null
            // process descriptor content to extract camera info
            const descriptor = data.descriptor
            const camera = descriptor["camera"]
            let lens = camera.lens
            let width = camera.sensor_width
            let objLocation = descriptor["object_location"]
            let dist = -1 * objLocation[2]
            let objCenter = descriptor["object_rotation_center"]
            let objRotation = descriptor["quaternion_xyzw"]
            let localCamera = new Camera(lens, width, dist)
            DEBUG &&
              console.log(`create camera with lens ${lens} width ${width} and distance ${dist}`)
            crtObject = new Object3D(objCenter, objRotation)
            DEBUG &&
              console.log(`create object3d with center ${objCenter} and rotation ${objRotation}`)
            imagePicker.setCamera(localCamera)
            imagePicker.setObject3D(crtObject)

            // read the vertices file (all object vertices for magnet mode)
            // add the kdtree to object instance
            DEBUG && console.log(`Read vertices file ${data.vertices_uri}`)
            const res_verticies = await fetch(data.vertices_uri)
              .then((response) => response.arrayBuffer())
              .then((buffer) => new Float64Array(buffer))
            let pointList = res_verticies
            DEBUG && console.log("read = " + pointList?.length / 4 + " points!")
            // store all coords with index
            let coords = []
            // store vertices per component index
            let v = []
            for (let i = 0; i < pointList.length / 4; i++) {
              coords.push([
                pointList[i * 4],
                pointList[i * 4 + 1],
                pointList[i * 4 + 2],
                pointList[i * 4 + 3],
              ])
              if (v[pointList[i * 4 + 3]])
                v[pointList[i * 4 + 3]].push([
                  pointList[i * 4],
                  pointList[i * 4 + 1],
                  pointList[i * 4 + 2],
                ])
              else
                v[pointList[i * 4 + 3]] = [
                  [pointList[i * 4], pointList[i * 4 + 1], pointList[i * 4 + 2]],
                ]
            }
            vertices = v
            // create the KD-Tree structure
            // Compute a Kd - Tree of the CAO points by using: https://github.com/ubilabs/kd-tree-javascript
            crtObject.tree = new kdTree(coords, pointDistance, [0, 1, 2])
            const buffer = await fetch(data.depth_uri)
              .then((response) => response.blob())
              .then((blob) => blob.arrayBuffer())
            // Use UPNG lib: https://github.com/photopea/UPNG.js
            let imageObj = UPNG.decode(buffer)
            DEBUG &&
              console.log(
                "Mon objet image = " + imageObj.width + " " + imageObj.height + " " + imageObj.depth
              )
            DEBUG && console.log("Image data size : " + imageObj.data.length)
            imagePicker.setImageDepth(imageObj)
            // end file loading, fire callback
            callback && callback()
          } catch (error) {
            force2DCallback && force2DCallback()
            callback && callback()
            console.warn(
              `Error occured while reading parameters for 3D modes, roll back to 2D mode`
            )
            console.warn(error)
          }
        }
        readParamFile(() => {
          setLoading(false)
        })
      }
      // in Mode 2D: if composer on SVG file, load the SVG content into the DOM
      else if (pictureUrl.endsWith("svg")) {
        const loadAsyncSVG = async (callback) => {
          console.log(`Will read SVG doc ${pictureUrl}`)
          try {
            const node = await LoadSVGFile(pictureUrl)
            // FIXME this will work only if all elements are stored in a group inside imported svg doc
            const group = node.getElementsByTagName("g")[0]
            group.classList.add("holo_svg")

            //imgCanvas.appendChild(node)
            svgCanvas.current.appendChild(node)
            svgCanvas.current.classList.add("holo_svg_canvas")
            svgCanvas.current.classList.remove("svg_canvas")
            console.log(`Well done read of svg node: `, node)
            console.log(
              `SVG Width x Height: ${node.getAttribute("width")} x ${node.getAttribute("height")}`
            )
            console.log(`First group is `, group)
            const elements = group.getElementsByTagName("*")
            console.log(`and there are ${elements.length} elements in the group `, elements)
            svgCanvas.current.setAttribute("width", node.getAttribute("width"))
            svgCanvas.current.setAttribute("height", node.getAttribute("height"))
            callback && callback()
          } catch (error) {
            console.error("Failed to load SVG : ", error)
          }
        }
        loadAsyncSVG(() => {
          setLoading(false)
          setDisplaySvgCanvas(true)
        })
      } else {
        if (picture.geomSrc === "TO_COMPUTE") setCompoEdited(true)
        setLoading(false)
      }
      return () => {
        cleanUpObjects()
        if (composerContext?.menuState?.viewModes?.selection) {
          removeSVGMouseOver()
        }
        disableComponent = false
        selectedObject = null
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [picture])

    useEffect(() => {
      if (composerContext?.menuState?.editModes.polygon) {
        setInfoContent(HELP_TIP.POLYGON)
      } else if (composerContext?.menuState?.editModes.mask) {
        setInfoContent(HELP_TIP.MASK)
      } else if (composerContext?.menuState?.editModes.square) {
        setInfoContent(HELP_TIP.SQUARE)
      } else if (composerContext?.menuState?.editModes.point) {
        setInfoContent(HELP_TIP.POINT)
      } else if (composerContext?.menuState?.editModes.circle) {
        setInfoContent(HELP_TIP.CIRCLE)
      } else if (composerContext?.menuState?.editModes.circle3p) {
        setInfoContent(HELP_TIP.CIRCLE3P)
      } else {
        setInfoContent("")
      }
    }, [composerContext?.menuState?.editModes])

    useImperativeHandle(
      ref,
      () => ({
        svgCanvas: svgCanvas.current,
        handleMenu: handleMenu,
        setCompoEdited: (bool) => {
          setCompoEdited(bool)
        },
        cleanUpObjects: () => {
          cleanUpObjects()
        },
        saveAllData: saveAllData,
      }),
      [handleMenu, saveAllData]
    )

    return (
      <div className="partview_wrapper">
        <div
          className="render_container"
          onMouseMove={moveClick}
          onMouseDown={moveClick}
          onMouseUp={endDrag}
        >
          <div
            className="card__view"
            ref={cardViewRef}
            id="viewer"
            onClick={() => setIncrementalRef((prev) => prev + 1)}
          >
            {DISPLAY_DEBUGGER ? (
              <div style={{ position: "absolute", color: "#FFFFFF" }}>
                <p>dragOffset : {JSON.stringify(dragOffset)}</p>
                {checkedObjects.map((e, i) => (
                  <p key={i}>
                    {`checked object[${i}]`} : {JSON.stringify(e)}
                  </p>
                ))}
              </div>
            ) : (
              <></>
            )}
            {displaySvgCanvas && (
              <svg className="svg_canvas" id="svg" ref={svgCanvas}>
                {!loading ? (
                  <>
                    {composerContext?.menuState?.viewModes?.debug &&
                    composerContext.menuState.viewModes?.magnet ? (
                      <DebugRenderVisibleVertices
                        vertices={vertices}
                        imagePicker={imagePicker}
                        objIndex={magnetObjIndex}
                      />
                    ) : (
                      <></>
                    )}
                    {composerContext?.menuState?.viewModes?.magnet ? (
                      <RenderSvgPoint point={magnetPoint} />
                    ) : (
                      <></>
                    )}
                    <RenderGeometries
                      crtPoints={crntPoints}
                      imagePicker={imagePicker}
                      checkedObjects={checkedObjects}
                      focusedObject={focusedObject}
                      filteredGeometries={filteredGeometries}
                      onPointSelected={onPointSelected}
                      keyword={keyword}
                    />
                  </>
                ) : (
                  <></>
                )}
              </svg>
            )}
            {getPictureUrl(picture).endsWith("svg") ? (
              <div className="card__view-img" id="img" ref={imgCanvas}></div>
            ) : (
              <img
                className="card__view-img"
                id="img"
                ref={imgCanvas}
                src={getPictureUrl(picture)}
                alt={getPictureName(picture)}
                onLoad={() => updateSvgCanvas()}
              />
            )}
          </div>
        </div>
        <div
          className={`controller_container ${
            dragOffset
              ? "controller_container_disabled"
              : !composerContext?.menuState?.viewModes?.displayEditForm || disableComponent
                ? "controller_container_invisible"
                : ""
          }`}
        >
          <Controller
            ref={controllerRef}
            onSelect={onSelect}
            filteredGeometries={filteredGeometries}
            callbackBlur={callbackBlur}
            callbackKeyword={setKeyword}
            callbackCheckedObjects={setCheckedObjects}
            callbackFilteredGeometries={updateFilteredGeometries}
          />
        </div>
        <div className="partview-left">
          <div className="partview-left-button">
            {toLeft && <IoIosArrowDropleft size="3em" onClick={() => toLeft()} />}
          </div>
        </div>
        <div className="partview-right">
          <div className="partview-right-button">
            {toRight && <IoIosArrowDropright size="3em" onClick={() => toRight()} />}
          </div>
        </div>
        <div className="info-bar" id="infobar">
          <h3 id="infocontent">{addLineBreaks(infoContent)}</h3>
        </div>
        {(loader || loading) && <HmkLoader loader={loader || loading} />}
      </div>
    )
  }
)

export default PartView3DFC
