import React, { useRef, useEffect } from "react"
import CloseButton from "./CloseButton"

import "./HMKModal.css"

const Modal = ({
  onSubmit,
  onCancel,
  title,
  buttonLabel,
  children,
  override,
}) => {
  const modal = useRef(null)
  useEffect(() => {
    const onKeyDown = (evt) => {
      //const ctrlDown = evt.ctrlKey || evt.metaKey
      if (evt.key === "Escape" && !override) {
        onCancel()
      }
    }
    const m = modal.current
    m.addEventListener("keydown", onKeyDown)
    m.addEventListener("mousedown", preventBubbling)
    m.addEventListener("mousemove", preventBubbling)
    m.addEventListener("mouseup", preventBubbling)
    return () => {
      m.removeEventListener("keydown", onKeyDown)
      m.removeEventListener("mousedown", preventBubbling)
      m.removeEventListener("mousemove", preventBubbling)
      m.removeEventListener("mouseup", preventBubbling)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const preventBubbling = (e) => e.stopPropagation()
  return (
    <div ref={modal} className="modal">
      <section className="modal-main">
        <CloseButton onClick={onCancel} />
        <h5 className="text-center title">{title}</h5>
        <form onSubmit={onSubmit}>
          {children}
          {!override && (
            <div className="form-row">
              <button
                className="text-center btn col-2 offset-3 px-2"
                type="submit"
              >
                {buttonLabel}
              </button>
              <button
                className="text-center btn col-2 offset-2 px-2"
                onClick={onCancel}
              >
                {"Cancel"}
              </button>
            </div>
          )}
        </form>
      </section>
    </div>
  )
}

export default Modal
