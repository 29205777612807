import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"

import React from "react"
import { createRoot } from 'react-dom/client';
import HMKError from "./common/HMKError"
import "./index.css"
import MainApp from "./MainApp"
import * as serviceWorker from "./serviceWorker"

const params = new URLSearchParams(window.location.search)
let project = ""
let token = ""
if (params.has("project")) {
  project = params.get("project")
}
if (params.has("token")) {
  token = params.get("token")
}

const container = document.getElementById('root');
const root = createRoot(container);

if (project) {
  root.render(<MainApp projectId={project} token={token} />)
} else {
  root.render(<HMKError />)
}

// Remove navigation prompt
window.onbeforeunload = null
// No context menu on right button
document.oncontextmenu = function () {
  return false
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
