import React, {
    useEffect,
    useState,
    useRef,
    useMemo,
    useContext,
    useCallback,
    useImperativeHandle,
    forwardRef,
} from "react"
import "./Controller.css"
import "./ControllerObject.css"
import ControllerButton from "./ControllerButton"
import ControllerObject from "./ControllerObject"
import {
    controller_state,
    ParseControllerState,
    l_filtered_geometries,
    parseStateIntoProperty,
    ActionContainerControl,
    labelPositionValidityCheck,
    LabelPositionController,
} from "./Resources"
import {
    removeFromArray,
    rgbToHex,
    isNumber,
    parseLabelPos,
} from "../../common/utils"
import { ComposerContext } from "../../Contexts"
import ReactTooltip from "react-tooltip"
import {
    RiFilter2Line,
    RiFilter2Fill,
    RiCharacterRecognitionLine,
} from "react-icons/ri"
import {
    MdColorLens,
    MdOutlineFormatColorText,
    MdFormatShapes,
} from "react-icons/md"
import { FiSquare, FiCircle, FiTrash2, FiMinus } from "react-icons/fi"
import { FaDrawPolygon, FaSearch, FaMinus } from "react-icons/fa"
import { ImCross, ImMinus } from "react-icons/im"
import { TbPointFilled } from "react-icons/tb"
import { CgMiniPlayer } from "react-icons/cg"
import { IoMdColorFill } from "react-icons/io"
import { BsBorderWidth } from "react-icons/bs"
import { BiFontSize } from "react-icons/bi"
import { HiDuplicate } from "react-icons/hi"

const DEBUG = false

/**
 * if true = click to immediately toggle label size, stroke width
 * if false = user is able to customize label size, stroke width
 */
const simple_mode = true

const opacity_steps = 20
const minimum_opacity = 0.2 //only visual
const font_sizes = [24, 36, 48, 60]
const default_font_size = font_sizes[2]
const stroke_widths = [3, 5, 8, 12]
const default_stroke_width = stroke_widths[1]
const default_rgba = [255, 255, 255, 1]
const default_hexColor = rgbToHex(
    default_rgba[0],
    default_rgba[1],
    default_rgba[2]
)
const default_label_position = "N"

const Controller = forwardRef(
    (
        {
            onSelect,
            filteredGeometries,
            callbackBlur,
            callbackKeyword,
            callbackCheckedObjects,
            callbackFilteredGeometries,
        },
        ref
    ) => {
        const composerContext = useContext(ComposerContext)
        const [objList, setObjList] = useState([])
        const [checkedObjects, setCheckedObjects] = useState([])
        const [focusedObject, setFocusedObject] = useState("")
        const [keyword, setKeyword] = useState("")
        const [filterMode, setFilterMode] = useState(false)
        const [controllerToolState, setControllerToolState] = useState(
            controller_state.DEFAULT
        )
        const [opacity, setOpacity] = useState(opacity_steps) // ex: if 20 => 20 steps of (100/20)%
        const [rgba, setRGBA] = useState(default_rgba)
        const [hexColor, setHexColor] = useState(default_hexColor)
        const [labelSize, setLabelSize] = useState(default_font_size)
        const [strokeWidth, setStrokeWidth] = useState(default_stroke_width)
        const [labelPosition, setLabelPosition] = useState(
            default_label_position
        )
        const [homogeneousGeometry, setHomogeneousGeometry] = useState("")
        const [scrollbarWidth, setScrollbarWidth] = useState(0)
        const [colorIsEdited, setColorIsEdited] = useState(false)
        const checkboxRef = useRef(null)

        useEffect(() => {
            // build the object list for the edit form
            let objList = []
            composerContext.stateData.points.forEach((pt) =>
                objList.push({
                    type: "point",
                    name: pt.name,
                    color: pt.color,
                    id: pt.id,
                    label_position: pt.label_position,
                    label_size: pt.label_size,
                    label_bg_color: pt.label_bg_color,
                })
            )
            composerContext.stateData.polygons.forEach((po) =>
                objList.push({
                    type: "polygon",
                    name: po.name,
                    color: po.color,
                    id: po.id,
                    stroke_width: po.stroke_width,
                    bg_color: po.bg_color,
                })
            )
            composerContext.stateData.circles.forEach((ci) =>
                objList.push({
                    type: "circle",
                    name: ci.name,
                    color: ci.color,
                    id: ci.id,
                    label_position: ci.label_position,
                    label_size: ci.label_size,
                    label_bg_color: ci.label_bg_color,
                    stroke_width: ci.stroke_width,
                    bg_color: ci.bg_color,
                })
            )
            composerContext.stateData.masks.forEach((ma) =>
                objList.push({
                    type: "mask",
                    name: ma.name,
                    id: ma.id,
                })
            )
            composerContext.stateData.squares.forEach((sq) =>
                objList.push({
                    type: "square",
                    name: sq.name,
                    color: sq.color,
                    id: sq.id,
                    label_position: sq.label_position,
                    label_size: sq.label_size,
                    label_bg_color: sq.label_bg_color,
                    stroke_width: sq.stroke_width,
                    bg_color: sq.bg_color,
                })
            )
            // If manually deleted an object (D key), the checkedObjects list should be updated accordingly.
            if (checkedObjects?.length) {
                let list_ids = objList.map((obj) => obj.id)
                let clone = [...checkedObjects]
                let loop_size = clone.length
                let validators = 0
                for (let i = 0; i < loop_size; i++) {
                    if (clone.length && !list_ids.includes(clone[i])) {
                        removeFromArray(clone, clone[i])
                        validators++
                    }
                }
                setCheckedObjects(clone)
                DEBUG &&
                    console.log(
                        `Cleaned ${validators} object(s) from the checked objects.`
                    )
            }
            setObjList(objList)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [composerContext.stateData])

        const filtering_by_geometry = useMemo(() => {
            return !Object.values(filteredGeometries).every((g) => !g)
        }, [filteredGeometries])

        const filteredObjList = useMemo(() => {
            return objList.filter(
                (obj) =>
                    Boolean(
                        !filtering_by_geometry ||
                            (filtering_by_geometry &&
                                filteredGeometries[`${obj.type}s`])
                    ) &&
                    Boolean(
                        !keyword ||
                            obj.name
                                .toLowerCase()
                                .trim()
                                .includes(keyword.toLowerCase())
                    )
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [objList, filteredGeometries, keyword])

        const visibleCheckedObjects = useMemo(() => {
            return checkedObjects.filter((checked_id) =>
                filteredObjList.map((obj) => obj.id).includes(checked_id)
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filteredObjList, checkedObjects])

        const toggleCheckAll = (checked) => {
            const all_visible_ids = filteredObjList.map((obj) => obj.id)
            if (checked) {
                setCheckedObjects([
                    ...new Set([...checkedObjects, ...all_visible_ids]),
                ])
            } else {
                let prev = [...checkedObjects]
                let loopSize = prev.length
                for (let i = 0; i < loopSize; i++) {
                    if (
                        prev.length &&
                        all_visible_ids.includes(checkedObjects[i])
                    ) {
                        removeFromArray(prev, checkedObjects[i])
                    }
                }
                setCheckedObjects(prev)
                setFilterMode(false)
                callbackFilteredGeometries("init")
                composerContext.onToggleMode("init_edit_modes")
            }
        }

        /**
         * @param {"fillColor"||"strokeColor"||"lineWidth"||"labelPosition"||"labelSize"||"labelBgColor"} actionType render conditionally action buttons according to the types of the chosen objetcs
         * @returns {boolean} whether or not the button should be rendered or enabled.
         */
        const actionParameter = (action) => {
            let breaker = false
            if (visibleCheckedObjects.length) {
                let codomain = [] // list of interest
                let domain = [] // list of definitions
                if (
                    action === "labelPosition" ||
                    action === "labelSize" ||
                    action === "labelBgColor"
                ) {
                    domain = filteredObjList.filter((obj) =>
                        Boolean(
                            obj.type === "square" ||
                                obj.type === "circle" ||
                                obj.type === "point"
                        )
                    )
                } else if (action === "fillColor" || action === "lineWidth") {
                    domain = filteredObjList.filter((obj) =>
                        Boolean(
                            obj.type === "square" ||
                                obj.type === "circle" ||
                                obj.type === "polygon"
                        )
                    )
                } else if (action === "strokeColor") {
                    domain = filteredObjList.filter((obj) =>
                        Boolean(
                            obj.type === "square" ||
                                obj.type === "circle" ||
                                obj.type === "polygon" ||
                                obj.type === "point"
                        )
                    )
                }
                codomain = domain.map((obj) => obj.id)
                for (let i = 0; i < visibleCheckedObjects.length; i++) {
                    if (
                        !breaker &&
                        codomain.includes(visibleCheckedObjects[i])
                    ) {
                        breaker = true
                        break
                    }
                }
            }
            return breaker
        }

        /**
         * automatically adds/removes an object by id from the checkedObjects list.
         * @param {string} object_id geometry object id
         * @param {boolean} allow_multiple_selection if true, the new single object will be the only checked object, deselecting any previous ones.
         * @param {null|boolean} overrideBoolean null (automatic) by default, if boolean specified => override add/remove decision from the checkedObjects.
         * @param {boolean} do_not_uncheck_if_multiple_selection for checkbox - prevent unchecking the object already checked if no CtrlKey detected. (in this case user wants to check only this object it while unselecting all the rest.)
         * @returns
         */
        const toggleObjectSelectionById = useCallback(
            (
                object_id,
                allow_multiple_selection = undefined,
                overrideBoolean = null,
                do_not_uncheck_if_multiple_selection = false
            ) => {
                // eslint-disable-next-line no-unused-vars
                const [objId, ptId] = object_id?.split("#")
                if (!objId) return
                const check = () => setCheckedObjects([objId])
                const uncheck = () => {
                    setCheckedObjects([])
                    composerContext.onToggleMode("init_edit_modes")
                }
                if (typeof allow_multiple_selection === "undefined") {
                    allow_multiple_selection = Boolean(
                        checkedObjects.length > 1
                    )
                }
                const prev = [...checkedObjects]
                if (!allow_multiple_selection) {
                    // override previous checkedObjects by the new single object
                    if (typeof overrideBoolean === "boolean")
                        (prev.length > 1 &&
                            do_not_uncheck_if_multiple_selection) ||
                        overrideBoolean
                            ? check()
                            : uncheck()
                    // check the single object by default, toggling a single object has been disabled.
                    else check()
                    return
                }
                const add = () => {
                    prev.push(objId)
                    const newSet = [...new Set(prev)]
                    setCheckedObjects(newSet)
                }
                const remove = () => {
                    removeFromArray(prev, objId)
                    setCheckedObjects([...new Set(prev)])
                    if (!prev.length) {
                        composerContext.onToggleMode("init_edit_modes")
                    }
                }
                if (typeof overrideBoolean === "boolean") {
                    overrideBoolean ? add() : remove()
                } else {
                    !prev.includes(objId)
                        ? add()
                        : prev.length > 1
                          ? remove()
                          : (() => {})()
                }
            },
            [checkedObjects, composerContext]
        )

        const confirmLabelSize = (labelSize) => {
            const as_number = Number(labelSize)
            if (!isNaN(as_number)) {
                composerContext.updateGeometry(
                    visibleCheckedObjects,
                    as_number,
                    "label_size"
                )
            }
        }
        const confirmStrokeWidth = (strokeWidth) => {
            const as_number = Number(strokeWidth)
            if (!isNaN(as_number)) {
                composerContext.updateGeometry(
                    visibleCheckedObjects,
                    as_number,
                    "stroke_width"
                )
            }
        }
        const confirmLabelPosition = (labelPosition) => {
            if (labelPositionValidityCheck(labelPosition)) {
                composerContext.updateGeometry(
                    visibleCheckedObjects,
                    labelPosition,
                    "label_position"
                )
            }
        }

        const handleInput = (e) => {
            const value = e.target?.value
            const action_type = e.target?.id
            if (value && action_type && isNumber(value)) {
                const as_number = Number(value)
                if (!isNaN(as_number)) {
                    if (action_type === "ls") {
                        setLabelSize(as_number)
                        if (
                            as_number >= font_sizes[0] &&
                            as_number <= font_sizes[font_sizes.length - 1]
                        ) {
                            confirmLabelSize(as_number)
                        }
                    } else if (action_type === "sw") {
                        setStrokeWidth(as_number)
                        if (
                            as_number >= stroke_widths[0] &&
                            as_number <= stroke_widths[stroke_widths.length - 1]
                        ) {
                            confirmStrokeWidth(as_number)
                        }
                    }
                } else {
                    if (action_type === "ls") {
                        setLabelSize("")
                    } else if (action_type === "sw") {
                        setStrokeWidth("")
                    }
                }
            } else {
                if (action_type === "ls") {
                    setLabelSize("")
                } else if (action_type === "sw") {
                    setStrokeWidth("")
                }
            }
        }

        const textLayoutIndex = useMemo(() => {
            return parseLabelPos(labelPosition, true)
        }, [labelPosition])

        const duplicateObjects = () => {
            composerContext.duplicateGeometries(visibleCheckedObjects)
        }

        const onLabelSizeChange = () => {
            if (visibleCheckedObjects?.length) {
                if (simple_mode) {
                    let nextSize = default_font_size
                    for (let i = 0; i < font_sizes.length; i++) {
                        if (Number(labelSize) === font_sizes[i]) {
                            if (i === font_sizes.length - 1) {
                                nextSize = font_sizes[0]
                            } else {
                                nextSize = font_sizes[i + 1]
                            }
                            break
                        } else {
                        }
                    }
                    setLabelSize(nextSize)
                    confirmLabelSize(nextSize)
                } else {
                    setControllerToolState(controller_state.LABEL_SIZE)
                }
            }
        }

        const onStrokeWidthChange = () => {
            if (visibleCheckedObjects?.length) {
                if (simple_mode) {
                    let nextWith = default_stroke_width
                    for (let i = 0; i < stroke_widths.length; i++) {
                        if (Number(strokeWidth) === stroke_widths[i]) {
                            if (i === stroke_widths.length - 1) {
                                nextWith = stroke_widths[0]
                            } else {
                                nextWith = stroke_widths[i + 1]
                            }
                            break
                        } else {
                        }
                    }
                    setStrokeWidth(nextWith)
                    confirmStrokeWidth(nextWith)
                } else {
                    setControllerToolState(controller_state.LINE_WIDTH)
                }
            }
        }

        const objectListRef = useCallback(
            (node) => {
                if (node) {
                    if (node.scrollHeight > node.clientHeight) {
                        return setScrollbarWidth(20)
                    } else {
                        return setScrollbarWidth(0)
                    }
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [visibleCheckedObjects?.length]
        )

        const toggle_category = (category) => {
            if (
                !composerContext?.onToggleMode ||
                !composerContext?.menuState?.editModes
            )
                return
            const state = Boolean(
                !composerContext.menuState.editModes[category]
            )
            composerContext.onToggleMode(category)
            if (!state) return
            const prev = [...checkedObjects]
            const currentCategory = objList.filter((o) => o.type === category)
            const newCheckedObjetcs = []
            for (let i = 0; i < prev.length; i++) {
                const identified = currentCategory.find((o) => o.id === prev[i])
                if (identified) {
                    newCheckedObjetcs.push(identified.id)
                }
            }
            setCheckedObjects(newCheckedObjetcs)
        }

        // Automatic control of the global checkbox
        useEffect(() => {
            if (checkboxRef?.current) {
                if (filteredObjList.length) {
                    if (
                        visibleCheckedObjects.length === filteredObjList.length
                    ) {
                        checkboxRef.current.checked = true
                        //setCheckAll(true)
                    } else {
                        checkboxRef.current.checked = false
                    }
                } else {
                    checkboxRef.current.checked = false
                    //setCheckAll(false)
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [visibleCheckedObjects, filteredObjList, checkboxRef?.current])

        useEffect(() => {
            callbackKeyword(keyword)
            setControllerToolState(controller_state.DEFAULT)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [keyword])

        useEffect(() => {
            callbackCheckedObjects(visibleCheckedObjects)
            if (checkedObjects.length > 1) return
            // auto-focus editmode category
            let geotype = ""
            let homogeneous = true
            const rawVisibleCheckedObjects = filteredObjList.filter((obj) =>
                visibleCheckedObjects.includes(obj.id)
            )
            for (let i = 0; i < rawVisibleCheckedObjects.length; i++) {
                const type = rawVisibleCheckedObjects[i].type
                if (type && type !== geotype) {
                    if (!geotype) {
                        geotype = type
                    } else if (geotype !== type) {
                        homogeneous = false
                        break
                    }
                }
            }
            if (homogeneous) {
                setHomogeneousGeometry(geotype)
                if (
                    composerContext.onToggleMode &&
                    !composerContext?.menuState?.editModes[geotype]
                ) {
                    composerContext.onToggleMode(geotype)
                }
            } else {
                setHomogeneousGeometry("")
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [callbackCheckedObjects, filteredObjList, visibleCheckedObjects])

        useEffect(() => {
            setControllerToolState(controller_state.DEFAULT)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [visibleCheckedObjects?.length])

        useEffect(() => {
            if (controllerToolState === controller_state.DEFAULT) {
                setOpacity(opacity_steps)
                setHexColor(default_hexColor)
                setLabelSize(default_font_size)
                setStrokeWidth(default_stroke_width)
                setLabelPosition(default_label_position)
            } else if (controllerToolState === controller_state.STROKE_COLOR) {
                if (visibleCheckedObjects.length === 1) {
                    const id = visibleCheckedObjects[0]
                    const object = filteredObjList.find((obj) => obj.id === id)
                    if (object?.color?.length >= 4) {
                        let rgb = object.color.slice(0, 3)
                        let alpha = object.color[3]
                        rgb = rgbToHex(rgb[0], rgb[1], rgb[2])
                        setOpacity(Math.floor(opacity_steps * alpha))
                        setHexColor(rgb)
                    } else {
                        setOpacity(opacity_steps)
                    }
                } else {
                    setOpacity(opacity_steps)
                }
            } else if (controllerToolState === controller_state.FILL_COLOR) {
                if (visibleCheckedObjects.length === 1) {
                    const id = visibleCheckedObjects[0]
                    const object = filteredObjList.find((obj) => obj.id === id)
                    if (object?.bg_color?.length >= 4) {
                        let rgb = object.bg_color.slice(0, 3)
                        let alpha = object.bg_color[3]
                        rgb = rgbToHex(rgb[0], rgb[1], rgb[2])
                        setOpacity(Math.floor(opacity_steps * alpha))
                        setHexColor(rgb)
                    } else {
                        let _value = Math.floor(opacity_steps * 0.15) //15%
                        setOpacity(_value)
                    }
                } else {
                    let _value = Math.floor(opacity_steps * 0.15) //15%
                    setOpacity(_value)
                }
            } else if (
                controllerToolState === controller_state.LABEL_BG_COLOR
            ) {
                if (visibleCheckedObjects.length === 1) {
                    const id = visibleCheckedObjects[0]
                    const object = filteredObjList.find((obj) => obj.id === id)
                    if (object?.label_bg_color?.length >= 4) {
                        let rgb = object.label_bg_color.slice(0, 3)
                        let alpha = object.label_bg_color[3]
                        rgb = rgbToHex(rgb[0], rgb[1], rgb[2])
                        setOpacity(Math.floor(opacity_steps * alpha))
                        setHexColor(rgb)
                    } else {
                        let _value = Math.floor(opacity_steps * 0.65) //65%
                        setOpacity(_value)
                        setHexColor("#000000")
                    }
                } else {
                    let _value = Math.floor(opacity_steps * 0.65) //65%
                    setOpacity(_value)
                    setHexColor("#000000")
                }
            } else if (controllerToolState === controller_state.LABEL_SIZE) {
                if (visibleCheckedObjects.length === 1) {
                    const id = visibleCheckedObjects[0]
                    const object = filteredObjList.find((obj) => obj.id === id)
                    if (object?.label_size) {
                        setLabelSize(object.label_size)
                    }
                }
            } else if (controllerToolState === controller_state.LINE_WIDTH) {
                if (visibleCheckedObjects.length === 1) {
                    const id = visibleCheckedObjects[0]
                    const object = filteredObjList.find((obj) => obj.id === id)
                    if (object?.stroke_width) {
                        setStrokeWidth(object.stroke_width)
                    }
                }
            } else if (
                controllerToolState === controller_state.LABEL_POSITION
            ) {
                if (visibleCheckedObjects.length === 1) {
                    const id = visibleCheckedObjects[0]
                    const object = filteredObjList.find((obj) => obj.id === id)
                    if (object?.label_position) {
                        setLabelPosition(object.label_position)
                    }
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [controllerToolState])

        useEffect(() => {
            const r = parseInt(hexColor.substring(1, 3), 16)
            const g = parseInt(hexColor.substring(3, 5), 16)
            const b = parseInt(hexColor.substring(5, 7), 16)
            const alpha = opacity
                ? opacity / opacity_steps
                : controllerToolState === controllerToolState.STROKE_COLOR
                  ? 1
                  : 0.15
            const _rgba = [r, g, b, alpha]
            setRGBA(_rgba)
            if (colorIsEdited) {
                composerContext.updateGeometry(
                    visibleCheckedObjects,
                    _rgba,
                    parseStateIntoProperty(controllerToolState)
                )
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [colorIsEdited, hexColor, opacity])

        useEffect(() => {
            if (!objList?.length) {
                callbackFilteredGeometries("init")
                setFilterMode(false)
                callbackKeyword("")
                setKeyword("")
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [objList?.length])

        useEffect(() => {
            if (!composerContext?.menuState?.editModes) return
            const values = Object.values(composerContext.menuState.editModes)
            const all_off = values.every((v) => !v)
            if (all_off) {
                setCheckedObjects([])
                return
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [composerContext?.menuState?.editModes])

        useEffect(() => {
            if (composerContext?.newObjects?.length) {
                setCheckedObjects([...composerContext.newObjects])
                composerContext.setNewObjects([])
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [composerContext?.newObjects])

        useImperativeHandle(
            ref,
            () => ({
                toggleObjectSelectionById: toggleObjectSelectionById,
            }),
            [toggleObjectSelectionById]
        )

        return (
            <>
                <div className="controller-container-wrapper">
                    <div className="controller-container">
                        <ControllerButton
                            tooltip_id={"cat_circ"}
                            overrideColor={Boolean(
                                composerContext?.menuState?.editModes?.circle
                            )}
                            clickCallback={() => {
                                toggle_category("circle")
                            }}
                            tooltip_message={"Circles"}
                            icon={<FiCircle className="controller-icon" />}
                        />
                        <ControllerButton
                            tooltip_id={"cat_rect"}
                            overrideColor={Boolean(
                                composerContext?.menuState?.editModes?.square
                            )}
                            clickCallback={() => {
                                toggle_category("square")
                            }}
                            tooltip_message="Rectangles"
                            icon={<FiSquare className="controller-icon" />}
                        />
                        <ControllerButton
                            tooltip_id={"cat_pg"}
                            overrideColor={Boolean(
                                composerContext?.menuState?.editModes?.polygon
                            )}
                            clickCallback={() => {
                                toggle_category("polygon")
                            }}
                            tooltip_message={"Polygons"}
                            icon={<FaDrawPolygon className="controller-icon" />}
                        />
                        <ControllerButton
                            tooltip_id={"cat_pt"}
                            overrideColor={Boolean(
                                composerContext?.menuState?.editModes?.point
                            )}
                            clickCallback={() => {
                                toggle_category("point")
                            }}
                            tooltip_message={"Points"}
                            icon={<TbPointFilled className="controller-icon" />}
                        />
                        <ControllerButton
                            tooltip_id={"cat_msk"}
                            overrideColor={Boolean(
                                composerContext?.menuState?.editModes?.mask
                            )}
                            clickCallback={() => {
                                toggle_category("mask")
                            }}
                            tooltip_message={"Masks"}
                            icon={<CgMiniPlayer className="controller-icon" />}
                        />
                    </div>
                </div>
                <div className="controller-container-wrapper">
                    <div className="controller-statusbar">
                        <ParseControllerState state={controllerToolState} />
                        <ActionContainerControl
                            state={controllerToolState}
                            returnCallback={() => {
                                setControllerToolState(controller_state.DEFAULT)
                            }}
                        />
                    </div>
                    {controllerToolState === controller_state.DEFAULT ? (
                        <>
                            <div className="controller-container">
                                <ControllerButton
                                    tooltip_id={"act_bw"}
                                    tooltip_message={"Line Width"}
                                    clickCallback={() => onStrokeWidthChange()}
                                    icon={
                                        <BsBorderWidth className="controller-icon" />
                                    }
                                    disabled={!actionParameter("lineWidth")}
                                />
                                <ControllerButton
                                    tooltip_id={"act_fill"}
                                    tooltip_message={"Fill Color"}
                                    clickCallback={() => {
                                        if (visibleCheckedObjects?.length) {
                                            setControllerToolState(
                                                controller_state.FILL_COLOR
                                            )
                                        }
                                    }}
                                    icon={
                                        <IoMdColorFill className="controller-icon" />
                                    }
                                    disabled={!actionParameter("fillColor")}
                                />
                                <ControllerButton
                                    tooltip_id={"act_sc"}
                                    tooltip_message={"Stroke Color"}
                                    clickCallback={() => {
                                        if (visibleCheckedObjects?.length) {
                                            setControllerToolState(
                                                controller_state.STROKE_COLOR
                                            )
                                        }
                                    }}
                                    icon={
                                        <MdColorLens className="controller-icon" />
                                    }
                                    disabled={!actionParameter("strokeColor")}
                                />
                                <ControllerButton
                                    tooltip_id={"act_dup"}
                                    tooltip_message={"Duplicate"}
                                    clickCallback={() => {
                                        if (visibleCheckedObjects?.length) {
                                            duplicateObjects()
                                        }
                                    }}
                                    icon={
                                        <HiDuplicate className="controller-icon" />
                                    }
                                    disabled={!visibleCheckedObjects?.length}
                                />
                            </div>
                            <div className="controller-container">
                                <ControllerButton
                                    tooltip_id={"act_fs"}
                                    tooltip_message={"Label Size"}
                                    clickCallback={() => onLabelSizeChange()}
                                    icon={
                                        <BiFontSize className="controller-icon" />
                                    }
                                    disabled={!actionParameter("labelSize")}
                                />
                                <ControllerButton
                                    tooltip_id={"act_lpos"}
                                    tooltip_message={"Label Position"}
                                    clickCallback={() => {
                                        if (visibleCheckedObjects?.length) {
                                            setControllerToolState(
                                                controller_state.LABEL_POSITION
                                            )
                                        }
                                    }}
                                    icon={
                                        <MdFormatShapes className="controller-icon" />
                                    }
                                    disabled={!actionParameter("labelPosition")}
                                />
                                <ControllerButton
                                    tooltip_id={"act_lbc"}
                                    tooltip_message={"Label Background Color"}
                                    clickCallback={() => {
                                        if (visibleCheckedObjects?.length) {
                                            setControllerToolState(
                                                controller_state.LABEL_BG_COLOR
                                            )
                                        }
                                    }}
                                    icon={
                                        <MdOutlineFormatColorText className="controller-icon" />
                                    }
                                    disabled={!actionParameter("labelBgColor")}
                                />
                                <ControllerButton
                                    tooltip_id={"act_del"}
                                    tooltip_message={"Delete"}
                                    clickCallback={() => {
                                        if (visibleCheckedObjects?.length) {
                                            composerContext.onDelete(
                                                visibleCheckedObjects
                                            )
                                            setCheckedObjects([])
                                        }
                                    }}
                                    icon={
                                        <FiTrash2 className="controller-icon" />
                                    }
                                    disabled={!visibleCheckedObjects?.length}
                                />
                            </div>
                        </>
                    ) : Boolean(
                          controllerToolState === controller_state.FILL_COLOR ||
                              controllerToolState ===
                                  controller_state.STROKE_COLOR ||
                              controllerToolState ===
                                  controller_state.LABEL_BG_COLOR
                      ) ? (
                        <div className="controller-container controller-color-container">
                            <div
                                className="controller-color-container-left"
                                data-tip
                                data-for={"opacity_slider"}
                            >
                                <input
                                    type="range"
                                    id="vol"
                                    name="vol"
                                    min="0"
                                    max={opacity_steps}
                                    value={opacity}
                                    onChange={(e) => {
                                        setOpacity(e.target.value)
                                        if (!colorIsEdited) {
                                            setColorIsEdited(true)
                                        }
                                    }}
                                />
                                <ReactTooltip id={"opacity_slider"}>
                                    <span>
                                        Opacity:{Math.round(rgba[3] * 100)}%
                                    </span>
                                </ReactTooltip>
                            </div>
                            <div
                                className="controller-color-container-right"
                                data-tip
                                data-for={"color_picker"}
                            >
                                <input
                                    type="color"
                                    value={hexColor}
                                    onChange={(e) => {
                                        setHexColor(e.target.value)
                                        if (!colorIsEdited) {
                                            setColorIsEdited(true)
                                        }
                                    }}
                                    style={{
                                        opacity:
                                            opacity >=
                                            Math.floor(
                                                opacity_steps * minimum_opacity
                                            )
                                                ? opacity / opacity_steps
                                                : minimum_opacity,
                                    }}
                                />
                                <ReactTooltip id={"color_picker"}>
                                    <span>{`R:${rgba[0]} G:${rgba[1]} B:${rgba[2]} A:${rgba[3]}`}</span>
                                </ReactTooltip>
                            </div>
                        </div>
                    ) : controllerToolState === controller_state.LABEL_SIZE ? (
                        <div className="controller-container controller-label-size-container">
                            <input
                                id="ls"
                                className="controller-textinput"
                                type="number"
                                value={labelSize}
                                placeholder="custom"
                                onFocus={() => {
                                    onSelect("", true)
                                }}
                                onBlur={() => {
                                    setFocusedObject("")
                                    onSelect("", false)
                                }}
                                onChange={handleInput}
                                style={{
                                    color:
                                        isNaN(Number(labelSize)) ||
                                        Number(labelSize) < font_sizes[0] ||
                                        Number(labelSize) >
                                            font_sizes[font_sizes.length - 1]
                                            ? "#FA4B52"
                                            : "#49C990",
                                }}
                            />
                            {font_sizes.map((f) => {
                                return (
                                    <ControllerButton
                                        key={`label_size_${f}`}
                                        tooltip_id={`label_size_${f}`}
                                        overrideSize={35}
                                        overrideColor={Number(labelSize) === f}
                                        overrideMargin={"0px"}
                                        tooltip_message={`Font Size: ${f}px`}
                                        clickCallback={() => {
                                            setLabelSize(f)
                                            confirmLabelSize(f)
                                        }}
                                        icon={
                                            <RiCharacterRecognitionLine
                                                style={{
                                                    width: `${f / 2}px`,
                                                    height: `${f / 2}px`,
                                                }}
                                            />
                                        }
                                    />
                                )
                            })}
                        </div>
                    ) : controllerToolState === controller_state.LINE_WIDTH ? (
                        <div className="controller-container controller-stroke-width-container">
                            <input
                                id="sw"
                                className="controller-textinput"
                                type="number"
                                value={strokeWidth}
                                placeholder="custom"
                                onFocus={() => {
                                    onSelect("", true)
                                }}
                                onBlur={() => {
                                    setFocusedObject("")
                                    onSelect("", false)
                                }}
                                onChange={handleInput}
                                style={{
                                    color:
                                        isNaN(Number(strokeWidth)) ||
                                        Number(strokeWidth) <
                                            stroke_widths[0] ||
                                        Number(strokeWidth) >
                                            stroke_widths[
                                                stroke_widths.length - 1
                                            ]
                                            ? "#FA4B52"
                                            : "#49C990",
                                }}
                            />
                            {stroke_widths.map((w, i) => {
                                return (
                                    <ControllerButton
                                        key={`line_width${w}`}
                                        tooltip_id={`line_width${w}`}
                                        overrideSize={35}
                                        overrideColor={
                                            Number(strokeWidth) === w
                                        }
                                        overrideMargin={"0px"}
                                        tooltip_message={`Line Width: ${w}px`}
                                        clickCallback={() => {
                                            setStrokeWidth(w)
                                            confirmStrokeWidth(w)
                                        }}
                                        icon={
                                            i === 0 ? (
                                                <FiMinus
                                                    style={{ height: "50%" }}
                                                />
                                            ) : i === 1 ? (
                                                <FiMinus className="controller-icon" />
                                            ) : i === 2 ? (
                                                <FaMinus className="controller-icon" />
                                            ) : i === 3 ? (
                                                <ImMinus className="controller-icon" />
                                            ) : (
                                                <></>
                                            )
                                        }
                                    />
                                )
                            })}
                        </div>
                    ) : controllerToolState ===
                      controller_state.LABEL_POSITION ? (
                        <div className="controller-container controller-label-position-container">
                            <div
                                className="label_position_selector_container"
                                type="button"
                            >
                                <LabelPositionController
                                    geometries={homogeneousGeometry}
                                    textLayoutIndex={textLayoutIndex}
                                    selectionCallback={(pos) => {
                                        setLabelPosition(pos)
                                        confirmLabelPosition(pos)
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="controller-action-wrapper">
                    <div
                        className="controller-action-container"
                        ref={objectListRef}
                        style={{
                            paddingRight: scrollbarWidth,
                        }}
                    >
                        <div
                            className="controller-object-row"
                            id="controller-master"
                        >
                            <ControllerButton
                                tooltip_id={"toggle_view_geometry"}
                                overrideSize={35}
                                disabled={!objList?.length}
                                overrideColor={Boolean(filterMode)}
                                overrideMargin={"0px 5px 0px 0px"}
                                tooltip_message="Filter Geometry"
                                clickCallback={() => {
                                    if (filterMode) {
                                        callbackFilteredGeometries("init")
                                    } else {
                                        callbackKeyword("")
                                        setKeyword("")
                                    }
                                    setFilterMode((prev) => !prev)
                                }}
                                icon={
                                    filterMode ? (
                                        <RiFilter2Fill className="controller-icon" />
                                    ) : (
                                        <RiFilter2Line className="controller-icon" />
                                    )
                                }
                            />
                            <div
                                className="controller-checkbox-wrapper"
                                data-tip
                                data-for={"toggle_check_all"}
                            >
                                <input
                                    ref={checkboxRef}
                                    disabled={!objList?.length}
                                    style={
                                        !objList?.length
                                            ? {
                                                  backgroundColor: "#191919",
                                                  borderColor: "transparent",
                                              }
                                            : {}
                                    }
                                    type={"checkbox"}
                                    onChange={(e) => {
                                        toggleCheckAll(e.target.checked)
                                    }}
                                />
                                <ReactTooltip id={"toggle_check_all"}>
                                    <span>{`${checkboxRef?.current?.checked ? "Unselect" : "Select"} All`}</span>
                                </ReactTooltip>
                            </div>
                            {filterMode && objList?.length ? (
                                <div className="geometry-filter-container">
                                    {l_filtered_geometries.map((g, i) => {
                                        return (
                                            <ControllerButton
                                                key={g.accessor}
                                                tooltip_id={g.tooltip_accessor}
                                                overrideMargin={
                                                    i !==
                                                    l_filtered_geometries.width
                                                        ? "0px"
                                                        : "0px 5px"
                                                }
                                                overrideSize={35}
                                                overrideColor={Boolean(
                                                    filteredGeometries[
                                                        g.accessor
                                                    ]
                                                )}
                                                tooltip_message={`${
                                                    Boolean(
                                                        filteredGeometries[
                                                            g.accessor
                                                        ]
                                                    )
                                                        ? "Hide"
                                                        : "View"
                                                } ${g.tooltip_message}`}
                                                clickCallback={() => {
                                                    callbackFilteredGeometries(
                                                        g.accessor
                                                    )
                                                }}
                                                icon={g.icon}
                                            />
                                        )
                                    })}
                                </div>
                            ) : (
                                <>
                                    <div className="controller-search-bar-container">
                                        <input
                                            disabled={!objList?.length}
                                            type="text"
                                            className="controller-textinput controller-search-bar"
                                            style={{
                                                pointerEvents: !objList?.length
                                                    ? "none"
                                                    : "auto",
                                            }}
                                            value={keyword}
                                            placeholder="filter object..."
                                            onFocus={(e) => {
                                                setFocusedObject("searchbar")
                                                onSelect("", true)
                                            }}
                                            onBlur={() => {
                                                setFocusedObject("")
                                                onSelect("", false)
                                            }}
                                            onChange={(e) => {
                                                setKeyword(e.target.value)
                                            }}
                                        />
                                        {keyword ? (
                                            <ImCross
                                                className="controller-search-bar-icon"
                                                style={{ cursor: "pointer" }}
                                                onMouseDown={() => {
                                                    setKeyword("")
                                                }}
                                            />
                                        ) : (
                                            <FaSearch className="controller-search-bar-icon" />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {filteredObjList?.length ? (
                            filteredObjList.map((obj, index) => {
                                return (
                                    <ControllerObject
                                        key={obj.id}
                                        //display={Boolean(!keyword || obj.name.includes(keyword))}
                                        object_name={obj.name}
                                        object_type={obj.type}
                                        object_id={obj.id}
                                        focusedObject={focusedObject}
                                        checkedObjects={checkedObjects}
                                        onFocus={setFocusedObject}
                                        onCheck={toggleObjectSelectionById}
                                        onSelect={onSelect}
                                        onBlurTextInput={callbackBlur}
                                    />
                                )
                            })
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </>
        )
    }
)

export default Controller
