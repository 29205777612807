import gerberToSvg from "gerber-to-svg"
import { HMFileIO } from "../io/HMFileIO"

const gerberPostPro = ({ localFile, folderId }, app) => {
  const DEBUG = false
  return new Promise((resolve, reject) => {
    const id = localFile.name
    const reader = new FileReader()
    reader.onerror = () => {
      console.error(`file read error - ${reader.error.message}`)
      reject(reader.error.message)
    }
    reader.onload = () => {
      gerberToSvg(
        reader.result,
        { id: id, attributes: { color: "white" } },
        (error, svg) => {
          if (error) {
            console.error(`gerber to svg error - ${error.message}`)
            reject(error.message)
          }
          const blob = new Blob([svg], { type: "image/svg+xml" })
          const file = new File([blob], localFile.name + ".svg")
          HMFileIO()
            .uploadFileXHR(file, folderId)
            .then((file_res) =>
              resolve({ action: "ADD_FILE", data: { result: file_res.id } })
            )
        }
      )
    }
    if (DEBUG)
      console.log(`Launch a Gerber to SVG PostPro on file ${localFile.name}`)
    reader.readAsText(localFile)
  })
}

export default gerberPostPro
