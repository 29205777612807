import React from "react"
import ReactTooltip from "react-tooltip"

const DefaultCalibrationParams = {
  squareNx: 9,
  squareNy: 6,
  squareSize: 26,
  squarePXY: 1500,
}

/**
const DisplayCalibrationPicture = (e) => {
  e.preventDefault()
  var win = window.open("", "holomake_calibration_pattern")
  win.document.write(
    `<html><head><title>HoloMake Studio - Calibration Pattern</title></head><body style="background-color: black; margin: auto; width: 100%; text-align: center;">`
  )
  win.document.write(
    `<image style="height: 100%;" src="chessboard_9x6_size.svg" alt="Calibration pattern" />`
  )
  win.document.write(`</body></html>`)
}
 */

const CalibrationModal = ({ params }) => (
  <div>
    {/**
    <div className="form-row">
      <div className="form-group col-md-6">
        <label htmlFor="square_nx">Square Nx</label>
        <input
          className="form-control"
          id="square_nx"
          name="square_nx"
          type="number"
          defaultValue={params.squareNx}
          data-for="rtt_calib_modal_nx"
          data-tip="Number of square in X direction"
        />
        <ReactTooltip id="rtt_calib_modal_nx" effect="solid" place="bottom" />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="square_ny">Square Ny</label>
        <input
          className="form-control"
          id="square_ny"
          name="square_ny"
          type="number"
          defaultValue={params.squareNy}
          data-for="rtt_calib_modal_ny"
          data-tip="Number of square in Y direction"
        />
        <ReactTooltip id="rtt_calib_modal_ny" effect="solid" place="bottom" />
      </div>
    </div>
    */}
    <div className="form-row">
      <div className="form-group col-md-6">
        <label htmlFor="square_size">Square Size (mm)</label>
        <input
          className="form-control"
          id="square_size"
          name="square_size"
          type="number"
          defaultValue={params.squareSize}
          data-for="rtt_calib_modal_size"
          data-tip="Real size of the square, as displayed on screen, in millimeters"
        />
        <ReactTooltip id="rtt_calib_modal_size" effect="solid" place="bottom" />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="square_pxy">PXY Init</label>
        <input
          className="form-control"
          id="square_pxy"
          name="square_pxy"
          type="number"
          defaultValue={params.squarePXY}
          data-for="rtt_calib_modal_pxy"
          data-tip="Nb of screen pixels for a 1-meter length object at 1-meter
          off the camera.<br/>For instance for a 40MP picture it's about 3500 and for a 10MP picture it's around 1000."
        />
        <ReactTooltip id="rtt_calib_modal_pxy" effect="solid" place="bottom" />
      </div>
    </div>
    <hr style={{ border: ".5px solid #fff" }} />
    {/**
    <div className="form-group">
      <button
        className="form-control"
        name="display"
        id="display"
        onClick={DisplayCalibrationPicture}
      >
        Display Calibration Picture
      </button>
    </div>
     */}
  </div>
)

export { CalibrationModal, DefaultCalibrationParams }
