import { WS_URL } from "./UrlConfig"

export const HMKWebSocket = () => {
  const DEBUG = false
  const WS_HOST = WS_URL
  let triggers = {}
  let ws = null

  const init = (taskId, endMessage) => {
    const wsurl = WS_HOST + "ws/convert/" + taskId + "/"
    ws = new WebSocket(wsurl)
    if (DEBUG) console.log(`Create HMKWS on url ${wsurl}`)

    ws.onopen = function (e) {
      if (DEBUG) console.log(`HMKWS opened for task id ${taskId}`)
      //ws.send(JSON.stringify({ type: "check_task_completion" }))
    }

    ws.onmessage = function (e) {
      var data = JSON.parse(e.data)
      if (DEBUG) {
        console.log("HMKWS RECEIVE FROM WS MSG :")
        console.log(data)
      }

      if (data.info.description in triggers) {
        triggers[data.info.description](data.info)
      }
      if (data.info.description === endMessage) {
        ws.close()
        ws = null
        triggers = {}
      }
    }

    ws.onclose = function (e) {
      if (DEBUG) console.log(`CLOSE HMKWS for task Id ${taskId}`)
    }
  }

  const sendMsg = (msg) => {
    ws.send(JSON.stringify({ message: msg }))
  }

  const on = (command, doHandle) => {
    triggers[command] = doHandle
    if (DEBUG) console.log(`HMKWS Triggers: `, triggers)
  }

  return { init, sendMsg, on }
}
