import React from "react"

import { MdClose } from "react-icons/md"

const CloseButton = ({ onClick }) => (
  <div className="text-right">
    <MdClose onClick={onClick} className="hmk-btn-close" />
  </div>
)

export default CloseButton
