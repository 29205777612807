const isSvgFile = (name) => name.toLowerCase().endsWith("svg")

const isPicture = (name) =>
  name.toLowerCase().endsWith("jpg") ||
  name.toLowerCase().endsWith("jpeg") ||
  name.toLowerCase().endsWith("jfif") ||
  name.toLowerCase().endsWith("pjpeg") ||
  name.toLowerCase().endsWith("pjp") ||
  name.toLowerCase().endsWith("png") ||
  name.toLowerCase().endsWith("apng") ||
  name.toLowerCase().endsWith("bmp") ||
  name.toLowerCase().endsWith("gif") ||
  name.toLowerCase().endsWith("ico") ||
  name.toLowerCase().endsWith("cur") ||
  name.toLowerCase().endsWith("tif") ||
  name.toLowerCase().endsWith("tiff") ||
  name.toLowerCase().endsWith("webp") ||
  name.toLowerCase().endsWith("svg")

const isBitmap = (name) =>
  name.toLowerCase().endsWith("jpg") ||
  name.toLowerCase().endsWith("jpeg") ||
  name.toLowerCase().endsWith("jfif") ||
  name.toLowerCase().endsWith("pjpeg") ||
  name.toLowerCase().endsWith("pjp") ||
  name.toLowerCase().endsWith("bmp") ||
  name.toLowerCase().endsWith("gif") ||
  name.toLowerCase().endsWith("ico") ||
  name.toLowerCase().endsWith("cur") ||
  name.toLowerCase().endsWith("png")

const isVideo = (name) =>
  name.toLowerCase().endsWith("mp4") ||
  name.toLowerCase().endsWith("mpg") ||
  name.toLowerCase().endsWith("avi") ||
  name.toLowerCase().endsWith("mov")

const isJSON = (name) => name.toLowerCase().endsWith("json")

const isCSV = (name) => name.toLowerCase().endsWith("csv")

const isPicFromStep = (item) =>
  isBitmap(item.name) && item.metadata && item.metadata.step_file_id

const isStepFile = (name) =>
  name.toLowerCase().endsWith("stp") || name.toLowerCase().endsWith("step")

const isDwgFile = (name) =>
  name.toLowerCase().endsWith("dxf") || name.toLowerCase().endsWith("dwg")

const isObjFile = (name) => name.toLowerCase().endsWith("obj")

const isGlTFFile = (name) =>
  name.toLowerCase().endsWith("gltf") || name.toLowerCase().endsWith("glb")

const isBlendFile = (name) => name.toLowerCase().endsWith("blend")

const isCaoFile = (name) => name.toLowerCase().endsWith("cao")

const isCADFile = (name) => isStepFile(name) || isDwgFile(name)

const isRenderableFile = (name) =>
  isStepFile(name) || isObjFile(name) || isBlendFile(name)

export {
  isPicture,
  isBitmap,
  isVideo,
  isCSV,
  isJSON,
  isPicFromStep,
  isStepFile,
  isObjFile,
  isGlTFFile,
  isCaoFile,
  isSvgFile,
  isCADFile,
  isRenderableFile,
}
