import { initObjSelector } from "./Picture3DSelector"

const DEBUG = false

const SelectionAddMode = {
  ADD: 1,
  XOR: 2,
  NOT: 3,
}

const initStates = {
  selectBox: [],
  currentObjName: undefined,
  imageIndex: null,
  objComponents: null,
  idxPixels: null,
  idxCompounds: null,
  idxColor: [],
  selectedObj: [],
  currentObjIndex: undefined,
  needSave: false,
}

// this one is called when initialize useReducer
const init = () => {
  if (DEBUG) console.log(`Init PVStates`)
  return { ...initStates }
}

// this one is called at each picture change
const pvInit = (pic) => {
  if (DEBUG) console.log(`PictureViewer Init ...`)
  return initObjSelector(pic).then((objSelect) => {
    if (objSelect) {
      if (DEBUG)
        console.log(`Will be able to select obj data on picture: `, objSelect)
      return {
        imageIndex: objSelect.index,
        objComponents: objSelect.components,
        idxPixels: objSelect.indexPixels,
        idxCompounds: objSelect.compounds,
      }
    } else {
      if (DEBUG) console.log(`Not a 3D rendered picture. Work in 2D.`)
      return {
        imageIndex: null,
        objComponents: null,
        idxPixels: null,
        idxCompounds: null,
      }
    }
  })
}

const pvReducer = (state, { action, payload }) => {
  DEBUG && console.log(`reduce for ${action}, payload is ${payload}`)
  switch (action) {
    case "setSelectBox":
      return { ...state, selectBox: payload }
    case "update3DSelector":
      const idxVal = payload
      if (idxVal >= 0 && state.objComponents) {
        // WARNING idxVal  est l'index dans l'image qui va de 1 a n alors que la liste des composants est de 0 a n-1. Il faut donc enlever 1 lorsqu'on adresse la liste des composants
        if (idxVal - 1 < state.objComponents.length) {
          //const objColor = state.objComponents[idxVal].rgba
          let objName = state.objComponents[idxVal - 1].name
          // remove file extension if any
          objName = objName.substring(0, objName.lastIndexOf(".")) || objName
          return { ...state, currentObjIndex: idxVal, currentObjName: objName }
        } else {
          return {
            ...state,
            currentObjIndex: idxVal,
            currentObjName: `Invalid object index (${idxVal - 1})`,
          }
        }
      } else
        return {
          ...state,
          currentObjIndex: undefined,
          currentObjName: undefined,
        }
    case "init3DSelector":
      return { ...state, ...payload }
    case "addSelectedObj":
      let listToAdd = Array.isArray(payload.val) ? payload.val : [payload.val]
      listToAdd = listToAdd.map((l) => state.objComponents[l])
      const listToRemove = listToAdd.filter((l) =>
        state.selectedObj.includes(l)
      )
      listToAdd = listToAdd.filter((l) => !listToRemove.includes(l))
      let newList
      if (payload.mode && payload.mode === SelectionAddMode.NOT) {
        newList = state.selectedObj.filter((s) => !listToRemove.includes(s))
      } else if (payload.mode && payload.mode === SelectionAddMode.ADD) {
        newList = state.selectedObj.concat(listToAdd)
      } else {
        // by default XOR mode
        newList = state.selectedObj
          .filter((s) => !listToRemove.includes(s))
          .concat(listToAdd)
      }
      return { ...state, selectedObj: newList }
    case "selectAllObj":
      let allObj = []
      if (Object.keys(state.idxPixels).length > state.selectedObj.length) {
        allObj = Object.keys(state.idxPixels)
          .map((key) => parseInt(key) - 1)
          .map((l) => state.objComponents[l])
      }
      return { ...state, selectedObj: allObj }
    case "clearSelectedObj":
      return {
        ...state,
        selectedObj: [],
        currentObjIndex: undefined,
      }
    case "clearColoredObj":
      return {
        ...state,
        idxColor: [],
      }
    case "setSelectedObjColor":
      const addedColor = state.selectedObj.map((obj) => ({
        ...obj,
        color: [
          payload.color.r,
          payload.color.g,
          payload.color.b,
          payload.color.a * 255,
        ],
      }))
      // use the filter context parameter, keep only obj not in addedColor
      let filterList = state.idxColor.filter(
        function (c) {
          return !this.includes(c.name)
        },
        addedColor.map((_c) => _c.name)
      )
      if (DEBUG) console.log(`New colors set for selection : `, addedColor)
      return {
        ...state,
        idxColor: filterList.concat(addedColor),
        needSave: true,
      }
    case "setNeedSave":
      return { ...state, needSave: payload }
    default:
      throw new Error()
  }
}

const createPVReducer = (pictures) => {}
export {
  initStates,
  init,
  pvInit,
  pvReducer,
  createPVReducer,
  SelectionAddMode,
}
