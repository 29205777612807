import React, { useState, useEffect } from "react"
import {
  FiTrash2,
  FiCopy,
  FiLink,
  FiDownload,
  FiVideo,
  FiBox,
  FiPlay,
  FiPackage,
} from "react-icons/fi"
import { GiVerticalFlip } from "react-icons/gi"
import { TiPencil } from "react-icons/ti"
import { GrVisa } from "react-icons/gr"
import { HiClipboardCopy } from "react-icons/hi"
import { VscJson } from "react-icons/vsc"
import { BsLayers } from "react-icons/bs"
import { ImPaste } from "react-icons/im"
import { MdModelTraining } from "react-icons/md"
import { PiTargetLight } from "react-icons/pi"
import ReactTooltip from "react-tooltip"
import {
  isPicture,
  isVideo,
  isCADFile,
  isObjFile,
  isGlTFFile,
  isCaoFile,
  isSvgFile,
  isRenderableFile,
  isJSON,
  isCSV,
} from "../common/CheckFileType"
import { StorageKeys } from "../common/AppMode"
import { useSessionStorageListener } from "../common/hooks/useSessionStorageListener"

const TOOLTIP_PLACE = "top" // was "bottom"

const DisplayActions = ({
  isDirectory,
  item,
  handleMenu,
  devices,
  dropDownCallback,
  displayTooltips,
}) => {
  const [defaultDeviceName, setDefaultDeviceName] = useState("")
  const geomInClipboard = useSessionStorageListener(StorageKeys().CLIPBOARD_GEOM_ID_KEY)

  // may check if 3 sub folders are named "train", "validate" and "test"
  const isTrainModelFolder = isDirectory //&& item.dirs && item.dirs.length === 3

  useEffect(() => {
    if (devices && devices.default) {
      const dev = devices.list.find((dev) => dev.key === devices.default)
      // the find may fail ... (why?)
      if (dev) setDefaultDeviceName(dev.name)
    }
  }, [devices])

  const picFileName = item.src

  const isPicFromStep = isPicture(picFileName) && Boolean(item.metadata?.step_file_id)
  const isGeomInPic = isPicture(picFileName) && Boolean(item.metadata?.geom_compo_id)

  const isRenderFolder = isDirectory && item.metadata?.type
  const isViewableFolder = isDirectory

  const launchViewer = (e) => {
    handleMenu(e, {
      type: "launchViewer",
      payload: isDirectory && item.viewFile ? item.viewFile : item,
      deviceId: devices.default,
    })
  }

  return (
    <div className="card-action-cont">
      {(isViewableFolder ||
        isCSV(picFileName) ||
        isJSON(picFileName) ||
        isPicture(picFileName) ||
        isObjFile(picFileName) ||
        isGlTFFile(picFileName)) &&
        devices?.default && (
          <div className="card-action" onClick={(e) => launchViewer(e)}>
            <FiPlay
              size="1rem"
              data-tip={"Project on " + defaultDeviceName}
              data-for={`rtt_card_action_launch_viewer_default_${picFileName}`}
            />
            {displayTooltips && <ReactTooltip
              id={`rtt_card_action_launch_viewer_default_${picFileName}`}
              effect="solid"
              place={TOOLTIP_PLACE}
            />}
          </div>
        )}
      {Boolean(
        isViewableFolder ||
          isCSV(picFileName) ||
          isJSON(picFileName) ||
          isPicture(picFileName) ||
          isObjFile(picFileName) ||
          isGlTFFile(picFileName)
      ) && Boolean(devices?.list?.length) ? (
        <div className="card-action" onClick={() => dropDownCallback(true)}>
          <svg
            width="1rem"
            height="1rem"
            data-tip="Project On Device"
            data-for={`rtt_card_action_launch_viewer_${picFileName}`}
          >
            <use href="play_more.svg#svg4"></use>
          </svg>
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_launch_viewer_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      ) : (
        <></>
      )}
      {isTrainModelFolder && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "trainModel", payload: item })}
        >
          <MdModelTraining
            size="1rem"
            data-tip="Train Model"
            data-for={`rtt_card_action_train_model_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_train_model_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {isPicture(picFileName) && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "copyFile", payload: item })}
        >
          <FiCopy
            size="1rem"
            data-tip="Duplicate"
            data-for={`rtt_card_action_copy_file_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_copy_file_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {(isPicture(picFileName) || isVideo(picFileName)) && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "copyLink", payload: item })}
        >
          <FiLink
            size="1rem"
            data-tip="Copy Link"
            data-for={`rtt_card_action_copy_link_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_copy_link_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {isPicture(picFileName) && isGeomInPic && (
          <div
            className="card-action"
            onClick={(e) => handleMenu(e, { type: "copyGeom", payload: item })}
          >
            <BsLayers
              size="1rem"
              data-tip="Copy Geometries"
              data-for={`rtt_card_action_copy_geom_${picFileName}`}
            />
            {displayTooltips && <ReactTooltip
              id={`rtt_card_action_copy_geom_${picFileName}`}
              effect="solid"
              place={TOOLTIP_PLACE}
            />}
          </div>
      )}
      {/** isPicture(picFileName) && isGeomInPic && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "track", payload: item })}
        >
          <PiTargetLight
            size="1rem"
            data-tip="Track Label"
            data-for={`rtt_card_action_track_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_track_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )*/}
      {isPicture(picFileName) && geomInClipboard && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "pasteGeom", payload: item })}
        >
          <ImPaste
            size="1rem"
            data-tip="Paste Geometries"
            data-for={`rtt_card_action_paste_geom_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_paste_geom_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {isPicture(picFileName) && !isPicFromStep && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "convert3d", payload: item })}
        >
          <FiBox
            size="1rem"
            data-tip="Convert to 3D Model"
            data-for={`rtt_card_action_convert_3d_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_convert_3d_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {isPicFromStep && (
        <div
          className="card-action"
          onClick={(e) =>
            handleMenu(e, { type: "copy2clipboard", payload: item })
          }
        >
          <HiClipboardCopy
            data-tip="Copy to Clipboard (viewpoint,selection)"
            data-for={`rtt_card_action_copy2clipboard_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_copy2clipboard_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {
        <div
          className="card-action"
          onClick={(e) =>
            handleMenu(e, { type: "downloadFile", payload: item })
          }
        >
          <FiDownload
            size="1rem"
            data-tip="Download"
            data-for={`rtt_card_action_dl_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_dl_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      }
      {isPicFromStep && (
        <div
          className="card-action"
          onClick={(e) => {
            handleMenu(e, { type: "hdRender", payload: item })
            ReactTooltip.hide()
          }}
        >
          <FiVideo
            size="1rem"
            data-tip="Render"
            data-for={`rtt_card_action_hd_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_hd_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {isPicFromStep && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "getObj", payload: item })}
        >
          <FiBox
            size="1rem"
            data-tip="Get 3D File (GLTF)"
            data-for={`rtt_card_action_objjoin_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_objjoin_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {isVideo(picFileName) && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "extractPics", payload: item })}
        >
          <FiVideo
            size="1rem"
            data-tip="Extract Images"
            data-for={`rtt_card_action_video2pics_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_video2pics_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {!isDirectory && isRenderableFile(picFileName) && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "revolve", payload: item })}
        >
          <FiVideo
            size="1rem"
            data-tip="Render"
            data-for={`rtt_card_action_revolve_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_revolve_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {!isDirectory && isObjFile(picFileName) && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "getCao", payload: item })}
        >
          <GrVisa
            size="1rem"
            data-tip="To CAO"
            data-for={`rtt_card_action_cao_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_cao_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {isJSON(picFileName) && item.metadata?.command ? (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "jsonCommand", payload: item })}
        >
          <FiPackage
            size="1rem"
            data-tip={"Process"}
            data-for={`rtt_card_action_bin_packing_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_bin_packing_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      ) : (
        <></>
      )}
      {!isRenderFolder &&
        !isCSV(picFileName) &&
        !isCADFile(picFileName) &&
        !isObjFile(picFileName) &&
        !isCaoFile(picFileName) &&
        !isGlTFFile(picFileName) &&
        !isVideo(picFileName) && (
          <div
            className="card-action"
            onClick={(e) => handleMenu(e, { type: "edition", payload: item })}
          >
            <TiPencil
              size="1rem"
              data-tip={
                !isDirectory && !isJSON(picFileName) ? "Composer" : "Edit"
              }
              data-for={`rtt_card_action_edit_${picFileName}`}
            />
            {displayTooltips && <ReactTooltip
              id={`rtt_card_action_edit_${picFileName}`}
              effect="solid"
              place={TOOLTIP_PLACE}
            />}
          </div>
        )}
      {isSvgFile(picFileName) && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "thickness", payload: item })}
        >
          <GiVerticalFlip
            size="1rem"
            data-tip="Material Thickness"
            data-for={`rtt_card_action_thickness_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_thickness_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      {!isDirectory && (
        <div
          className="card-action"
          onClick={(e) => handleMenu(e, { type: "metadata", payload: item })}
        >
          <VscJson
            size="1rem"
            data-tip="Metadata"
            data-for={`rtt_card_action_metadata_${picFileName}`}
          />
          {displayTooltips && <ReactTooltip
            id={`rtt_card_action_metadata_${picFileName}`}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      )}
      <div
        className="card-action"
        onClick={(e) => handleMenu(e, { type: "delete", payload: item })}
      >
        <FiTrash2
          size="1rem"
          data-tip="Delete"
          data-for={`rtt_card_action_delete_${picFileName}`}
        />
        {displayTooltips && <ReactTooltip
          id={`rtt_card_action_delete_${picFileName}`}
          effect="solid"
          place={TOOLTIP_PLACE}
        />}
      </div>
    </div>
  )
}

export default DisplayActions
