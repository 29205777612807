import React, { useEffect, useRef } from "react"

// for webgl
import * as THREE from "three"
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js"
//import { OBJLoader2 } from "three/examples/jsm/loaders/OBJLoader2"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"

import "./Viewer3D.css"
import { isStepFile } from "../common/CheckFileType"

// for mathematics
import { create, all } from "mathjs"
const config = {}
const math = create(all, config)

const Viewer3D = ({ objUrl }) => {
  const view3d = useRef()

  let scene,
    camera,
    renderer,
    controls,
    object = null
  let frameId = null

  const getWinSize = () => {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight
    return [x, y]
  }

  // create a unit cube, for debug purpose
  /*
  const createUnitCube = () => {
    const material = new THREE.MeshPhongMaterial({
      color: 0xff00ff,
      emissive: 0x072534,
      side: THREE.DoubleSide,
      flatShading: true,
    })
    const geometry = new THREE.BoxGeometry()
    //const material = new THREE.MeshBasicMaterial({ color: 0xff00ff })
    const cube = new THREE.Mesh(geometry, material)
    cube.name = "cube"
    return cube
  }
*/

  const init3d = () => {
    // create the Scene
    scene = new THREE.Scene()

    // create the Camera from window width/height
    //const width = view3d.current.clientWidth
    //const height = view3d.current.clientHeight
    const [width, height] = getWinSize()
    //camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    camera = new THREE.PerspectiveCamera(28.072, width / height, 0.1, 10000)

    //create the Renderer and attach it to the DOM
    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    })
    renderer.setSize(width, height)
    renderer.setClearColor(0x000000, 0)
    const webglCanvas = renderer.domElement
    view3d.current.appendChild(webglCanvas)
    console.log(`Init Camera with ${width} x ${height} window size`)

    // create interaction Controls
    controls = new TrackballControls(camera, renderer.domElement)
    initializeOrbits()
    initializeCamera()

    // for debug
    //object = createUnitCube()
    //scene.add(object)

    // create some Lights
    var alight = new THREE.AmbientLight(0x333333)
    scene.add(alight)

    const lights = []
    lights[0] = new THREE.PointLight(0xffffff, 1, 0)
    lights[1] = new THREE.PointLight(0xffffff, 1, 0)
    lights[2] = new THREE.PointLight(0xffffff, 1, 0)

    lights[0].position.set(0, 200, 0)
    lights[1].position.set(100, 200, 100)
    lights[2].position.set(-100, -200, -100)

    scene.add(lights[0])
    scene.add(lights[1])
    scene.add(lights[2])

    // responsiveness
    window.addEventListener("resize", handleWindowResize)
    // Launch rendering
    animate()
  }

  const handleWindowResize = () => {
    //const width = view3d.current.clientWidth
    //const height = view3d.current.clientHeight
    const [width, height] = getWinSize()
    renderer.setSize(width, height)
    camera.aspect = width / height
    camera.updateProjectionMatrix()
    console.log(`Updated Camera with ${width} x ${height} window size`)
  }

  const initializeOrbits = () => {
    controls.rotateSpeed = 5.0
    controls.zoomSpeed = 1.2
    controls.panSpeed = 0.3
  }

  const initializeCamera = () => {
    camera.position.x = 0
    camera.position.y = 0
    camera.position.z = 5
  }

  const animate = () => {
    frameId = window.requestAnimationFrame(animate)
    controls.update()
    if (object) {
      //object.rotation.x += 0.01
      //object.rotation.y += 0.01
    }
    renderer.render(scene, camera)
  }

  const addObject = (obj) => {
    console.log(`Add Object ${obj.name} to Scene`)
    object = obj
    scene.add(obj)
    let camZ = camera.position.z
    // compute obj bbox
    let box = null
    obj.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.geometry.computeBoundingBox()
        const bbox = child.geometry.boundingBox
        if (!box) {
          box = bbox
        } else {
          box.min.x = bbox.min.x < box.min.x ? bbox.min.x : box.min.x
          box.min.y = bbox.min.y < box.min.y ? bbox.min.y : box.min.y
          box.min.z = bbox.min.z < box.min.z ? bbox.min.z : box.min.z
          box.max.x = bbox.max.x > box.max.x ? bbox.max.x : box.max.x
          box.max.y = bbox.max.y > box.max.y ? bbox.max.y : box.max.y
          box.max.z = bbox.max.z > box.max.z ? bbox.max.z : box.max.z
        }
      }
    })
    console.log(`BBox:`)
    console.log(box)
    // center object
    obj.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.translateX(-(box.min.x + (box.max.x - box.min.x) / 2))
        child.translateY(-(box.min.y + (box.max.y - box.min.y) / 2))
        child.translateZ(-(box.min.z + (box.max.z - box.min.z) / 2))
      }
    })
    // bbbox dim
    const boxSize = math.norm([
      box.max.x - box.min.x,
      box.max.y - box.min.y,
      box.max.z - box.min.z,
    ])
    // set camera at a relatively right z to display the whole model
    console.log(`Bbox size = ${boxSize}`)
    camZ = boxSize > camZ ? boxSize : camZ
    camera.position.z = camZ
  }

  const loadObj = (url) => {
    // Load an OBJ file
    const loader = new OBJLoader()
    // load a resource
    loader.load(
      // resource URL
      url,
      // called when resource is loaded
      (mesh) => {
        mesh.name = url
        addObject(mesh)
      },
      // called when loading is in progresses
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded")
      },
      // called when loading has errors
      (error) => {
        console.log(`An error happened while loading OBJ file ${url}: ${error}`)
      }
    )
  }

  const loadGlTF = (url) => {
    // Instantiate a loader
    var loader = new GLTFLoader()

    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    var dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath("/examples/js/libs/draco/")
    loader.setDRACOLoader(dracoLoader)

    // Load a glTF resource
    loader.load(
      // resource URL
      url,
      // called when the resource is loaded
      (gltf) => {
        scene.add(gltf.scene)
        //gltf.animations // Array<THREE.AnimationClip>
        //gltf.scene // THREE.Group
        //gltf.scenes // Array<THREE.Group>
        //gltf.cameras // Array<THREE.Camera>
        //gltf.asset // Object
      },
      // called while loading is progressing
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded")
      },
      // called when loading has errors
      (error) => {
        console.log("An error happened")
      }
    )
  }

  useEffect(() => {
    console.log(`Viewer3D UseEffect hook called on object: ${objUrl}`)
    let objToLoad = objUrl
    if (isStepFile(objUrl)) {
      const i1 = objUrl.lastIndexOf("/")
      const i2 = objUrl.lastIndexOf(".")
      objToLoad =
        objUrl.slice(0, i2) + ".obj/" + objUrl.slice(i1 + 1, i2) + ".obj"
      console.log(`New URL to load: ${objToLoad}`)
    }
    init3d()
    const viewer3DNode = view3d.current
    if (objToLoad.toLowerCase().endsWith("obj")) {
      loadObj(objToLoad)
    } else if (
      objToLoad.toLowerCase().endsWith("gltf") ||
      objToLoad.toLowerCase().endsWith("glb")
    ) {
      loadGlTF(objToLoad)
    }
    // return THREE.JS Cleanup things
    return () => {
      window.removeEventListener("resize", handleWindowResize)
      window.cancelAnimationFrame(frameId)
      controls.dispose()
      viewer3DNode.removeChild(renderer.domElement)
    }
  })

  const handleClick = (event) => {
    const [width, height] = getWinSize()
    const mouse3D = new THREE.Vector3(
      (event.clientX / width) * 2 - 1,
      -(event.clientY / height) * 2 + 1,
      0.5
    )
    if (object) {
      const raycaster = new THREE.Raycaster()
      raycaster.setFromCamera(mouse3D, camera)
      let intersects = raycaster.intersectObjects(object.children)
      if (intersects.length) {
        console.log("Click intersecting objects:")
        console.log(intersects)
        //if (!Array.isArray(intersects[0].object.material)) {
        //  intersects[0].object.material.color.setHex(Math.random() * 0xffffff)
        //}
      }
    }
  }

  return (
    <div onClick={(e) => handleClick(e)} className="viewer3d" ref={view3d} />
  )
}

export default Viewer3D
