import React from "react"
import ReactTooltip from "react-tooltip"

import CheckBoxBtn from "../common/HMKCheckbox"

const DefaultConvert3dParams = {
  dimension: [0, 0],
  flip_y: false,
}

const Convert3dModal = ({ params }) => (
  <div>
    <div className="form-row">
      <div className="form-group col-md-6">
        <label htmlFor="dim_width">Width (mm)</label>
        <input
          className="form-control"
          id="dim_width"
          name="dim_width"
          type="number"
          step="1"
          defaultValue={params.dimension[0]}
          data-for="rtt_convert3d_modal_dim_width"
          data-tip="Picture width, in millimeters"
        />
        <ReactTooltip
          id="rtt_convert3d_modal_dim_width"
          effect="solid"
          place="bottom"
        />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="dim_height">Height (mm)</label>
        <input
          className="form-control"
          id="dim_height"
          name="dim_height"
          type="number"
          step="1"
          defaultValue={params.dimension[1]}
          data-for="rtt_convert3d_modal_dim_height"
          data-tip="Picture height, in millimeters"
        />
        <ReactTooltip
          id="rtt_convert3d_modal_dim_height"
          effect="solid"
          place="bottom"
        />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-12">
        <label className="col-form-label">Flip Y</label>
        <CheckBoxBtn id="flip_y" name="flip_y" />
      </div>
    </div>
    <hr style={{ border: ".5px solid #fff" }} />
  </div>
)

export { Convert3dModal, DefaultConvert3dParams }
