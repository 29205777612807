import React, { useState, useEffect, useRef } from "react"
import { GrCatalog } from "react-icons/gr"
import { FiLink, FiDownload, FiPlus, FiCornerUpLeft, FiHome } from "react-icons/fi"
import { FaFolder, FaRegFolder } from "react-icons/fa"
import { VscJson } from "react-icons/vsc"
import { HMKProgress } from "../common/ProgressMonitor"
import {
  isObjFile,
  isPicture,
  isVideo,
  isStepFile,
  isGlTFFile,
  isSvgFile,
  isPicFromStep,
  isJSON,
} from "../common/CheckFileType"
import ReactTooltip from "react-tooltip"
import { toast } from "react-toastify"

import DisplayActions from "./CardActions"
import { HMFileIO } from "../io/HMFileIO"
import { getShortenLabel, loadImage } from "../common/utils"

const TOOLTIP_PLACE = "top" // was "bottom"

const ShadowCard = ({ id, caption, onSelect }) => {
  return (
    <div
      className="card card-shadow"
      id={"card" + id}
      onClick={() => onSelect(id)}
    >
      <div className="card__thumbnail">
        {id === "root" ? (
          <FiHome size="120px" />
        ) : id === "back" ? (
          <FiCornerUpLeft size="120px" />
        ) : id === "studio" ? (
          <GrCatalog size="120px" />
        ) : (
          <FiPlus size="120px" />
        )}
        <p>{caption}</p>
      </div>
    </div>
  )
}

const UploadCard = ({ handleUpload }) => {
  const uploadRef = useRef(null)
  const clickUpload = () => uploadRef.current.click()
  const clearCache = () => {
    if (uploadRef.current?.value) {
      uploadRef.current.value = ""
    }
  }
  return (
    <div
      className="card card-shadow"
      id="cardaddfile"
      onClick={() => clickUpload()}
    >
      <label className="card__thumbnail">
        <FiPlus size="120px" />
        <p>UPLOAD FILES</p>
      </label>
      <input
        ref={uploadRef}
        className="menuitemupload"
        type="file"
        id="multi"
        onChange={(e) => {
          handleUpload(e)
          clearCache()
        }}
        style={{ display: "none" }}
        multiple
      />
    </div>
  )
}

const UploadProgressCard = ({ label }) => {
  return (
    <div className="card">
      <div className="custom_loader card__thumbnail-img" />
      <label className="card__thumbnail">
        <p>UPLOADING FILE ...</p>
        <p>{label}</p>
      </label>
    </div>
  )
}

export const DropDown = ({ devices, handleMenu, item, hide, marginTop }) => {
  const dropdown = useRef(null)
  useEffect(() => {
    if (dropdown && dropdown.current) dropdown.current.focus()
  }, [dropdown])

  return (
    <ul
      ref={dropdown}
      className="card-action-dropdown dropdown-menu"
      style={{ marginTop: marginTop }}
      tabIndex="0"
      onBlur={hide}
    >
      {devices.map((dev) => (
        <li key={dev.key}>
          <div
            onClick={(e) => {
              dropdown.current.style.display = "none"
              // let some time to hide dropdown...
              setTimeout(handleMenu, 50, e, {
                type: "launchViewer",
                payload: item,
                device: dev,
              })
            }}
          >
            {dev.name}
          </div>
        </li>
      ))}
    </ul>
  )
}

const Card = ({
  isDirectory,
  item,
  onSelect,
  displayActions=true,
  displayTooltips=true,
  progress,
  handleMenu,
  devices,
}) => {
  const [modeDl] = useState(false)
  const [caption, setCaption] = useState(null)
  const [captionName, setCaptionName] = useState(null)
  const picFileName = item.name ? item.name : item.src
  const altName = item.name
    ? item.name
    : item.src.substring(item.src.lastIndexOf("/") + 1)
  const rotation = item.rot ? item.rot * 90 : 0
  const rotStyle = rotation ? { transform: "rotate(" + rotation + "deg)" } : {}
  const [filePicto, setFilePicto] = useState("")
  const [showDropDown, setShowDropDown] = useState(false)

  const hideDropDown = () => setShowDropDown(false)

  const updatePictureCaption = (width, height) => {
    let thick = 0
    if (isSvgFile(picFileName)) {
      // apply web inverse pixel conversion to real unit
      // there is 96px in 1 inch
      // and 1 inch is 25.4mms
      width = Math.round((width / 96) * 25.4)
      height = Math.round((height / 96) * 25.4)
      if (item.metadata?.thickness) thick = item.metadata.thickness
      thick += "mm"
      setCaption(`${width}x${height}x${thick}`)
    } else setCaption(`${width}x${height}`)
    setCaptionName(`${getShortenLabel(altName, 25)}`)
  }

  useEffect(() => {
    if (isDirectory) {
      if (item.numbers) {
        let dTxt =
        item.numbers[0] > 0
        ? item.numbers[0] > 1
        ? `${item.numbers[0]} dirs`
        : "1 dir"
        : ""
        let fTxt =
        item.numbers[1] > 0
        ? item.numbers[1] > 1
        ? `${item.numbers[1]} files`
        : "1 file"
        : ""
        setCaption(`${dTxt} ${fTxt}`)
      }
    } else if (isPicture(picFileName)) {
      if (item.metadata.size) {
        updatePictureCaption(item.metadata.size[0], item.metadata.size[1])
      } else {
        loadImage(item.src).then((img) =>
          updatePictureCaption(img.width, img.height)
        )
      }
      if (item.metadata.thumbnail) {
        const path = item.src.substring(0, item.src.lastIndexOf("/") + 1)
        setFilePicto(path+item.metadata.thumbnail)
      } else {
        setFilePicto(item.src)
      }
    } else if (isVideo(picFileName)) {
      if (item.metadata.width && item.metadata.height) {
        updatePictureCaption(item.metadata.width, item.metadata.height)
      }
      if (item.metadata.thumbnail) {
        const path = item.src.substring(0, item.src.lastIndexOf("/") + 1)
        setFilePicto(path+item.metadata.thumbnail)
      }
    } else if (isStepFile(picFileName)) {
      if (item.metadata?.objIndex) {
        let nb = item.metadata.objIndex.components.length
        let txt = nb > 1 ? "parts" : "part"
        setCaption(`${nb} ${txt}`)
      }
      setFilePicto("picto_step.png")
    } else if (isObjFile(picFileName)) {
      setFilePicto("picto_obj.png")
    } else if (isGlTFFile(picFileName)) {
      if (item.metadata.src_picture_id)
        HMFileIO()
          .loadFile(item.metadata.src_picture_id)
          .then((res) => setFilePicto(res.datafile))
      else setFilePicto("GlTF_logo.svg")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picFileName, item])

  return (
    <div>
      <div
        className="card"
        id={"card" + item.id}
        onClick={() => onSelect(item.id)}
      >
        {/** Render the folder or the file thumbnail */}
        <div className="card__thumbnail">
          {isDirectory ? (
            <CardDir dir={item} displayTooltips={displayTooltips}/>
          ) : modeDl ? (
            <CardFileDl fileName={item.src} altName={picFileName} displayTooltips={displayTooltips}/>
          ) : (
            <CardFilePic
              item={item}
              picFileName={picFileName}
              altName={altName}
              style={rotStyle}
              filePicto={filePicto}
              geomCompo={item.geomSrc}
              displayTooltips={displayTooltips}
            />
          )}
        </div>
        {/** And then add some decoration */}
        {/** Pictured folder, add a little picto in the upper left */}
        {isDirectory && item.picSrc && (
          <div className="card__thumbnail-caption-picto">
            <FaRegFolder size="50px" />
          </div>
        )}
        {/** For picture file, add the resolution info in the bottom right */}
        {caption && (
          <div className="card__thumbnail-caption-reso">{caption}</div>
        )}
        {captionName && (
          <div className="card__thumbnail-caption-name">{captionName}</div>
        )}
        {/** If computation on this Card, display a bottom progress bar */}
        {progress ? <HMKProgress progress={progress} /> : ""}
      </div>
      {displayActions && item.src !== ".." && (
        <>
          <DisplayActions
            isDirectory={isDirectory}
            item={item}
            handleMenu={handleMenu}
            devices={devices}
            dropDownCallback={() => setShowDropDown(true)}
            displayTooltips={displayTooltips}
          />
          <div style={{ position: "absolute" }}>
            {showDropDown && (
              <DropDown
                devices={devices.list}
                handleMenu={handleMenu}
                item={item}
                hide={hideDropDown}
                marginTop={30}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

const TooltipLabel = ({ id, label, tipLabel, cardFilePic, displayTooltips }) => {
  /**
   * @param {string} id id
   * @param {string} label label
   * @param {string} tipLabel tipLabel
   * @param {boolean} isGLTF dedicated css style is applied if given
   */
  let computeShortenLabel = label.length > 35
  let shortenLabel = getShortenLabel(label, 35)
  return (
    <div>
      {computeShortenLabel || tipLabel ? (
        <div>
          {cardFilePic ? (
            <span
              className="GLTF-tag"
              data-tip={tipLabel ? tipLabel : label}
              data-for={"rtt_card_label_" + id}
            >
              {shortenLabel}
            </span>
          ) : (
            <p
              data-tip={tipLabel ? tipLabel : label}
              data-for={"rtt_card_label_" + id}
            >
              {shortenLabel}
            </p>
          )}
          {displayTooltips && <ReactTooltip
            id={"rtt_card_label_" + id}
            effect="solid"
            place={TOOLTIP_PLACE}
          />}
        </div>
      ) : (
        <div>
          <p>{shortenLabel}</p>
        </div>
      )}
    </div>
  )
}

const CardFileDl = ({ fileName, altName, onDownload, displayTooltips }) => (
  <div>
    <input
      type="text"
      readOnly={true}
      value={altName}
      size={30}
      style={{ backgroundColor: "black", color: "white", border: "none" }}
    />
    <p />
    <div className="card-action-cont">
      <button
        className="card-file-action"
        onClick={() =>
          navigator.clipboard
            .writeText(fileName)
            .then(() => toast("Link copied to clipboard"))
        }
      >
        <FiLink
          size="4rem"
          data-for="rtt_card_copy_link"
          data-tip="Copy Link to Clipboard"
        />
        {displayTooltips && <ReactTooltip
          id="rtt_card_copy_link"
          effect="solid"
          place={TOOLTIP_PLACE}
        />}
      </button>
      <button className="card-file-action" onClick={() => onDownload()}>
        <FiDownload
          size="4rem"
          data-for="rtt_card_download"
          data-tip="Download"
        />
        {displayTooltips && <ReactTooltip
          id="rtt_card_download"
          effect="solid"
          place={TOOLTIP_PLACE}
        />}
      </button>
    </div>
  </div>
)

const CardFilePic = ({
  item,
  picFileName,
  altName,
  style,
  filePicto,
  geomCompo,
  displayTooltips,
}) => (
  <div>
    {filePicto ? (
      isPicture(picFileName) ? (
        <div style={style}>
          <img
            className="card__thumbnail-img"
            src={filePicto}
            alt={altName}
            data-tip={`${altName}`}
            data-for={"rtt_card_pic_label_" + item.id}
          />
          {geomCompo?.datafile && (
            <img
              className="card__thumbnail-compo"
              src={geomCompo.datafile}
              alt={altName}
            />
          )}
        </div>
      ) : isGlTFFile(picFileName) ? (
        <>
          <img
            style={style}
            className="card__thumbnail-file"
            src={filePicto}
            alt={altName}
          />
          <img
            className="card__thumbnail-caption-picto"
            src="GlTF_logo.svg"
            alt="GlTF logo"
            data-tip={`${altName}`}
            data-for={"rtt_card_pic_label_" + item.id}
            style={{ width: "100px" }}
          />
        </>
      ) : (
        <img
          style={style}
          className="card__thumbnail-file"
          src={filePicto}
          alt={altName}
        />
      )
    ) : isJSON(picFileName) ? (
      <>
        <VscJson size="100px" />
        {Boolean(item.metadata?.command || item.metadata?.command_label) ? (
          <span className="card__subtitle">Command Available</span>
        ) : (
          <></>
        )}
      </>
    ) : (
      <></>
    )}
    {!isPicFromStep(item) &&
      ((isPicture(picFileName) || isVideo(picFileName)) ? displayTooltips ? (
        <ReactTooltip
          id={"rtt_card_pic_label_" + item.id}
          effect="solid"
          place={TOOLTIP_PLACE}
          offset={{ top: 150, left: 0 }}
        />
      ) : <></> : (
        <TooltipLabel id={item.id} label={altName} cardFilePic={true} displayTooltips={displayTooltips}/>
      ))}
  </div>
)

const CardDir = ({ dir, displayTooltips }) => {
  const rotation = dir.picSrc && dir.picSrc.rot ? dir.picSrc.rot * 90 : 0
  const rotStyle = rotation ? { transform: "rotate(" + rotation + "deg)" } : {}
  return (
    <div className="card__thumbnail-dir">
      {/** Render the folder with a picture and its name under the picture */}
      {/** else render the picto folder with its name under */}
      {dir.picSrc ? (
        <div>
          <img
            style={rotStyle}
            className="card__thumbnail-dir-img"
            src={dir.picSrc.datafile}
            alt={dir.picSrc.name}
          />
          {dir.metadata?.type ? (
            <TooltipLabel
              id={dir.id}
              label={dir.metadata.type.toUpperCase()}
              tipLabel={dir.src}
              displayTooltips={displayTooltips}
            />
          ) : (
            <TooltipLabel id={dir.id} label={dir.src} displayTooltips={displayTooltips}/>
          )}
        </div>
      ) : (
        <div>
          <FaFolder size="120px" />
          {dir.metadata?.type ? (
            <TooltipLabel
              id={dir.id}
              label={dir.metadata.type.toUpperCase()}
              tipLabel={dir.src}
              displayTooltips={displayTooltips}
            />
          ) : (
            <TooltipLabel id={dir.id} label={dir.src} displayTooltips={displayTooltips}/>
          )}
        </div>
      )}
    </div>
  )
}

export { Card, UploadCard, UploadProgressCard, ShadowCard }
