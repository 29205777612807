class Camera {
    constructor(lens, sensorWidth, distance) {
        this.lens = lens
        this.sensorWidth = sensorWidth
        this.distance = distance
    }

    toString() {
        return "Lens = " + this.lens + "; Sensor Width = "
         + this.sensorWidth + "; Distance = " + this.distance
    }
}

export default Camera
