export const getShortenLabel = (label, size) => {
  let computeShortenLabel = label.length > size
  let shortenLabel = computeShortenLabel
    ? label.slice(0, size / 2) + ".." + label.slice(-size / 2)
    : label
  return shortenLabel
}

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

/**
 * returns true if "currentPath.metadata.contentName" or "currentPath.metadata.type" is available
 * @param {object} currentPath path object
 * typeof currentPath = {
 *  contentId:string
 *  projectId: string
 *  contentName?:string
 *  type?:string
 * }
 */
export const hasExtraData = (currentPath) => {
  return Boolean(
    currentPath?.metadata?.contentName || currentPath?.metadata?.type
  )
}

/**
 * This function will calculate and return a label position
 * @param {"x"|"y"} axis 2D axis (X , Y), case-insensitive
 * @param {string[]|number} pos positions are either numeric value (imperative) or array of two capital chars: "N"="North", "E"="East", "W"="West", "S"="South". Default values are ["N","E"]
 * @param {number} controlPoint anchor value (center point)
 * @param {number} offset (optional) x offset should be positive and y offset should be negative.
 * @param {Object[]} radius (optional) Radius of a svg object (Circle).
 * @param {Object[]} conditionalOffset (optional) Array of object indicating which particular position should have an extra offset. { direction : "N"|"E"|"W"|"S"|string[], method : "include"|"match", value: number}
 */
export const labelPos = ({
  axis,
  pos,
  controlPoint,
  offset = 0,
  radius = 0,
  conditionalOffset = null,
}) => {
  if (typeof pos === "number") {
    return pos
  }
  let absolute_offset = 0
  if (conditionalOffset) {
    for (let i = 0; i < conditionalOffset.length; i++) {
      if (conditionalOffset[i].method === "include") {
        if (pos.includes(conditionalOffset[i].direction.toUpperCase())) {
          offset += conditionalOffset[i].value ?? 0
        }
      } else if (conditionalOffset[i].method === "match") {
        if (conditionalOffset[i].direction.length === 1) {
          if (conditionalOffset[i].direction === pos) {
            absolute_offset += conditionalOffset[i].value ?? 0
          }
        } else if (
          conditionalOffset[i].direction[0] === pos[0] &&
          conditionalOffset[i].direction[1] === pos[1]
        ) {
          offset += conditionalOffset[i].value ?? 0
        }
      } else if (conditionalOffset[i].method === "exclude") {
        let logic_integrity = true
        for (let j = 0; j < conditionalOffset[i].direction.length; j++) {
          if (pos.includes(conditionalOffset[i].direction[j].toUpperCase())) {
            logic_integrity = false
          }
        }
        if (logic_integrity) {
          absolute_offset += conditionalOffset[i].value ?? 0
        }
      }
    }
  }
  if (axis.toLowerCase() === "x") {
    if (pos.includes("E")) {
      return controlPoint + radius + offset + absolute_offset
    } else if (pos.includes("W")) {
      return controlPoint - radius - offset + absolute_offset
    } else {
      return controlPoint + absolute_offset //+ offset
    }
  } else {
    if (pos.includes("N")) {
      return controlPoint - radius - offset + absolute_offset
    } else if (pos.includes("S")) {
      return controlPoint + radius + offset + absolute_offset
    } else {
      return controlPoint + absolute_offset //+ offset
    }
  }
}
export const parseLabelPos = (input, inverse = false) => {
  // 0 1 2
  // 3 4 5 (4 = unused)
  // 6 7 8

  // NW N NE
  // W    E
  // SW S SE
  if (!inverse) {
    //input is index
    switch (input) {
      case 0:
        return "NW"
      case 1:
        return "N"
      case 2:
        return "NE"
      case 3:
        return "W"
      case 5:
        return "E"
      case 6:
        return "SW"
      case 7:
        return "S"
      case 8:
        return "SE"
      default:
        return ""
    }
  } else {
    //input is orientation
    switch (input) {
      case "NW":
        return 0
      case "N":
        return 1
      case "NE":
        return 2
      case "W":
        return 3
      case "E":
        return 5
      case "SW":
        return 6
      case "S":
        return 7
      case "SE":
        return 8
      default:
        return 4
    }
  }
}

/**
 * @param {[r:number,g:number,b:number,a?:number]} color RGBA color array
 * @param {number} [opacity] will override the opacity value of the color array 
 * @returns 
 */
export const arrayToRGBA = (color, opacity) => {
  console.assert(
    typeof color === "object" && Array.isArray(color) && color.length >= 3,
    "Error in arrayToRGBA - color parameter must be an array of 4 elements"
  )
  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity ? opacity : color.length >= 4 ? color[3] : 1})`
}
export const removeFromArray = (arr, value) => {
  const index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}

/**
 * @param {string} numInput number received as string
 * @returns true if there is nothing but numbers 0-9
 */
export const isNumber = (numInput) => {
  return new RegExp("^[0-9]+$").test(numInput)
}

const componentToHex = (c) => {
  var hex = c.toString(16)
  return hex.length === 1 ? "0" + hex : hex
}

export const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const readJson = async (src) => {
  let res = null
  try {
    // cache : "no-store" will prevent reloading the formerly cached JSON file.
    const resp = await fetch(src, { cache: "no-store" })
    if (!resp.ok) {
      throw new Error("HTTP error " + resp.status)
    }
    res = await resp.json()
  } catch (e) {
    console.log("Error reading JSON file : ", e)
  }
  return res
}

/**
 * @param {string} keyword Checks and returns a parameter matching the keyword from URLSearchParams.
 */
export const getURLParam = (keyword) => {
  const params = new URLSearchParams(window.location.search)
  let query = ""
  if (params.has(keyword)) {
    query = params.get(keyword)
  }
  return query
}

export const loadImage = (href) => {
  //console.log(`Will load picture ${href}`)
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.setAttribute("crossorigin", "anonymous")
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = href
  })
}

export const canvasToBlob = (canvas, name = "") => {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob && name) {
        blob.name = name
      }
      resolve(blob)
    })
  })
}

/**
 * @returns current browser viewport size.
 */
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

/**
 * adds a class from an HTML element's classList (DOMTokenList, case-sensitive) if it does not already have it.
 * @param {string} objID ID property of the HTML element
 * @param {string} classKey class name. By default this is "hiddenIcon" used in ComposerFC.js.
 */
 export const addClassList = (objID, classKey = "hiddenIcon") => {
  const obj = document.getElementById(objID)
  if (obj?.classList && !obj.classList.contains(classKey)) {
    obj.classList.add(classKey)
  }
}

/**
 * removes a class from an HTML element's classList (DOMTokenList, case-sensitive) if it exists.
 * @param {string} objID ID property of the HTML element
 * @param {string} classKey class name. By default this is "hiddenIcon" used in ComposerFC.js.
 */
export const removeClassList = (objID, classKey = "hiddenIcon") => {
  const obj = document.getElementById(objID)
  if (obj?.classList?.contains(classKey)) {
    obj.classList.remove(classKey)
  }
}
