import { HMFileIO } from "../io/HMFileIO"
import { toast } from "react-toastify"

import { trackProgress } from "../common/ProgressMonitor"
import {
  DefaultPreviewRenderParams,
  RenderResolution,
  RenderType,
} from "../modals/RenderModal"

const objPostPro = ({ remoteFile }, app) => {
  const DEBUG = false
  const LAUNCH_PREVIEW = true
  return new Promise((resolve, reject) => {
    const hmFile = HMFileIO()
    if (DEBUG)
      console.log(
        `Will launch OBJ/BLENDER Conversion on file ${remoteFile["src"]}`
      )
    hmFile
      .convert("obj2blend", remoteFile["id"])
      .then((res) => {
        if (DEBUG) console.log(`Launched Obj2Blend: ${JSON.stringify(res)}`)
        toast("Launched Obj2Blend process...")
        return trackProgress(
          app,
          remoteFile["id"],
          res.task_id,
          "OBJ2BLEND_DONE"
        )
      })
      .then((res) => {
        if (DEBUG)
          console.log(
            `Result of Obj2Blend is: ${JSON.stringify(res["result"])}`
          )
        if (LAUNCH_PREVIEW) {
          let params = {
            ...DefaultPreviewRenderParams,
            reso: RenderResolution[DefaultPreviewRenderParams.reso].reso,
            ratio: DefaultPreviewRenderParams.ratio.ratio,
            quality: DefaultPreviewRenderParams.quality.samples,
          }
          // add to it the default index and depth pictures (always rendered)
          params.renderPics.push("Depth", "Index")
          params.type.type = Object.keys(RenderType).find(
            (k) => RenderType[k] === DefaultPreviewRenderParams.type
          )
          return hmFile.convert("render", remoteFile["id"], params)
        } else {
          resolve(res)
        }
      })
      .then((res) => {
        if (DEBUG) console.log(`Launched Rendering: ${JSON.stringify(res)}`)
        toast("Launched Rendering process...")
        return trackProgress(app, remoteFile["id"], res.task_id, "RENDER_DONE")
      })
      .then((res) => {
        if (DEBUG)
          console.log(
            `Result of Rendering is: ${JSON.stringify(res["result"])}`
          )
        toast("All processes done, refreshing workspace ...")
        resolve(res)
      })
      .catch((err) => {
        console.log(
          `ERROR IN POSTPROCESSING OF FILE ${remoteFile["src"]} : ${err.message}`
        )
        reject(err)
      })
  })
}

export default objPostPro
