import React from "react"
import "./modal.css"

export const TrainModal = ({ params }) => (
  <div className="modal-text">
    <p>
      Are you sure you want to launch an IA model training in this folder {params.name} ? You may be charged with additional billing.
    </p>
  </div>
)

export const DeleteModal = ({ params }) => (
  <div className="modal-text">
    <p>
      {params.type === "folder"
        ? "Are you sure you want to delete this folder and its whole content ?"
        : "Are you sure you want to delete this file ?"}
    </p>
  </div>
)

export const FolderModal = ({ params }) => (
  <div>
    <div className="form-group">
      <label htmlFor="textfield">Name*</label>
      <input
        className="form-control"
        id="textfield"
        name="textfield"
        type="text"
        defaultValue={params.src}
        autoFocus
        required
        pattern="^\w.*.*\w$"
      />
    </div>
    <div className="form-group">
      <label htmlFor="description">Description</label>
      <textarea
        className="form-control fixed-background"
        defaultValue={params.metadata ? params.metadata.description : ""}
        name="description"
        id="description"
        rows={5}
      />
    </div>
  </div>
)
