const DEBUG = false

const loadImage = (href) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = "Anonymous"
    img.addEventListener("load", () => resolve(img))
    img.addEventListener("error", (err) => reject(err))
    img.src = href
  })
}

const toDataURL = (image) => {
  return loadImage(image.getAttribute("href")).then((img) => {
    // we should now be able to draw it without tainting the canvas
    const canvas = document.createElement("canvas")
    canvas.width = img.width
    canvas.height = img.height
    // draw the loaded image
    canvas.getContext("2d").drawImage(img, 0, 0)
    // set our <image>'s href attribute to the dataURL of our canvas
    const newHref = canvas.toDataURL("image/png")
    //console.log(`Convert Image ref ${image.getAttribute("href")}`)
    image.setAttribute("href", newHref)
  })
}

const buildPictureFromSVG = (svgContent) => {
  DEBUG &&
    console.log(`Build an in-memory SVG file, svg to export: `, svgContent)
  const win = window.URL || window.webkitURL || window
  // convert external pictures reference to local image canvas
  const images = svgContent.querySelectorAll("image")
  var svgUrl = null
  DEBUG && console.log(`Found ${images.length} images to convert in SVG.`)
  return Promise.all(Array.from(images).map((image) => toDataURL(image)))
    .then(() => {
      // convert the SVG DOM to data
      const svgData = new XMLSerializer().serializeToString(svgContent)
      const svgBlob = new Blob([svgData], {
        type: "image/svg+xml;charset=utf-8",
      })
      svgUrl = win.createObjectURL(svgBlob)
      return loadImage(svgUrl)
    })
    .then((img) => {
      DEBUG && console.log(`IMG to convert to blobby canvas: `, img)
      // create the canvas to render the SVG inside
      const canvas = document.createElement("canvas")
      // this resolution must be taken from device display parameters
      const width = svgContent.style.width
        ? svgContent.style.width
        : svgContent.getAttribute("width")
      const height = svgContent.style.height
        ? svgContent.style.height
        : svgContent.getAttribute("height")
      DEBUG && console.log(`widthxheight: ${width}x${height}`)
      canvas.width = width.endsWith("mm")
        ? parseInt(width) * 3.78
        : width.endsWith("in")
        ? parseInt(width) * 96
        : parseInt(width)
      canvas.height = height.endsWith("mm")
        ? parseInt(height) * 3.78
        : height.endsWith("in")
        ? parseInt(height) * 96
        : parseInt(height)
      canvas.getContext("2d").drawImage(img, 0, 0)
      // once SVG is rendered to canvas, auto download it as picture file
      win.revokeObjectURL(svgUrl)
      DEBUG && console.log(`Will convert my canvas to Blob: `, canvas)
      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => resolve(blob))
      })
    })
    .then((blob) => {
      return blob
    })
}

const buildSVGFile = (svgContent) => {
  DEBUG &&
    console.log(`Build an in-memory SVG file, svg to export: `, svgContent)
  DEBUG && console.log(`SVGContent Bbox: `, svgContent.getBBox())
  updateSVGSize(svgContent)
  // if needed update svg document size and viewbox
  DEBUG && console.log(`Will write updated svg doc : `, svgContent)
  // convert external pictures reference to local image canvas
  const images = svgContent.querySelectorAll("image")
  DEBUG && console.log(`Found ${images.length} images to convert in SVG.`)
  return Promise.all(Array.from(images).map((image) => toDataURL(image))).then(
    () => {
      // convert the SVG DOM to data
      const svgData = new XMLSerializer().serializeToString(svgContent)
      const svgBlob = new Blob([svgData], {
        type: "image/svg+xml;charset=utf-8",
      })
      //svgUrl = win.createObjectURL(svgBlob)
      return svgBlob
    }
  )
}

const updateSVGSize = (svgdoc) => {
  const vb = svgdoc
    .getAttribute("viewBox")
    .split(" ")
    .map((v) => parseFloat(v))
  const wstr = svgdoc.getAttribute("width")
  const hstr = svgdoc.getAttribute("height")
  const bbox = svgdoc.getBBox()
  const sx = bbox.width / vb[2]
  const sy = bbox.height / vb[3]
  const newvb = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
  const unit = wstr.endsWith("cm")
    ? "cm"
    : wstr.endsWith("mm")
    ? "mm"
    : wstr.endsWith("px")
    ? "px"
    : wstr.endsWith("in")
    ? "in"
    : wstr.endsWith("pt")
    ? "pt"
    : wstr.endsWith("pc")
    ? "pc"
    : ""
  const w = parseFloat(wstr)
  const h = parseFloat(hstr)
  const neww = w * sx
  const newh = h * sy
  const newwstr = `${neww}${unit}`
  const newhstr = `${newh}${unit}`
  svgdoc.setAttribute("width", newwstr)
  svgdoc.setAttribute("height", newhstr)
  svgdoc.setAttribute("viewBox", newvb)
  return svgdoc
}

export { buildPictureFromSVG, buildSVGFile }
