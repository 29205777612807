import React, { useEffect, useState } from "react"

import { Card, ShadowCard, UploadCard, UploadProgressCard } from "./Card"

import "./PartBoard.css"
import { hasExtraData } from "../common/utils"

const DISPLAY_TOOLTIP_THRESHOLD = 50
const DISPLAY_CARD_ACTIONS_THRESHOLD = 150

const PartBoard = (props) => {
  const [lastSelected, setLastSelected] = useState(props.lastSelected)

  useEffect(() => {
    if (
      props?.lastSelected?.id &&
      Boolean(lastSelected !== (props?.lastSelected ?? false))
    ) {
      setLastSelected(props.lastSelected)
      // scroll to the last selected card (when returning from pic view)
      const elt = document.getElementById(`card${props.lastSelected.id}`)
      if (elt) {
        elt.scrollIntoView()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const pictureLength = props.pictures ? props.pictures.length : 0

  return (
    <div className="card-container" id="container">
      {props.showStudio && (
        <ShadowCard id="studio" caption="PROJECTS" onSelect={props.onSelect} />
      )}
      {props.showBack && (
        <ShadowCard id="root" caption="HOME" onSelect={props.onSelect} />
      )}
      {props.showBack && (
        <ShadowCard id="back" caption="BACK" onSelect={props.onSelect} />
      )}
      {!hasExtraData(props.currentPath) && (
        <ShadowCard
          id="addfolder"
          caption="ADD FOLDER"
          onSelect={props.onSelect}
        />
      )}
      {!hasExtraData(props.currentPath) && (
        <UploadCard handleUpload={props.handleUpload} />
      )}
      {props.uploadProgress && (
        <div>
          <UploadProgressCard label={props.uploadProgress} />
        </div>
      )}
      {props.directories &&
        props.directories.map((dir) => (
          <Card
            isDirectory={true}
            item={dir}
            onSelect={props.onSelect}
            key={dir.id}
            progress={props.progress.find((p) => p.id === dir.id)}
            handleMenu={props.handleMenu}
            devices={props.devices}
          />
        ))}
      {props.pictures &&
        props.pictures.map((pic) => (
          <Card
            isDirectory={false}
            item={pic}
            onSelect={props.onSelect}
            key={pic.id}
            displayActions={pictureLength < DISPLAY_CARD_ACTIONS_THRESHOLD}
            displayTooltips={pictureLength < DISPLAY_TOOLTIP_THRESHOLD}
            progress={props.progress.find((p) => p.id === pic.id)}
            handleMenu={props.handleMenu}
            devices={props.devices}
          />
        ))}
    </div>
  )
}

export default PartBoard
