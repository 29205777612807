import React from "react"
import ReactTooltip from "react-tooltip"

import CheckBoxBtn from "../common/HMKCheckbox"

const DefaultMetadataParams = {
  thickness: 0.0,
}

const MetadataModal = ({ params }) => (
  <div>
    <div className="form-row">
      <div className="form-group col-md-12">
        <label htmlFor="square_size">Thickness (mm)</label>
        <input
          className="form-control"
          id="thickness"
          name="thickness"
          type="number"
          step="0.1"
          defaultValue={
            params.item.metadata && params.item.metadata.thickness
              ? params.item.metadata.thickness
              : params.thickness
          }
          data-for="rtt_metadata_modal_thickness"
          data-tip="Material Thickness, in millimeters"
        />
        <ReactTooltip
          id="rtt_metadata_modal_thickness"
          effect="solid"
          place="bottom"
        />
      </div>
    </div>
    <div className="form-row">
      <div className="form-group col-12">
        <label className="col-form-label">
          Apply to all SVG files in folder
        </label>
        <CheckBoxBtn
          id="thickness_folder"
          name="thickness_folder"
          //checked={false}
        />
      </div>
    </div>
    <hr style={{ border: ".5px solid #fff" }} />
  </div>
)

export { MetadataModal, DefaultMetadataParams }
