import React, { useState, useRef, useMemo, useEffect, useCallback } from "react"
import TextBackground from "./TextBackground"
import {
  DEFAULT_CIRCLE_STROKE_WIDTH,
  DEFAULT_HANDLE_RADIUS,
  DEFAULT_LABEL_FONT,
  DEFAULT_LABEL_SIZE,
  DEFAULT_LABEL_OFFSET_X,
  DEFAULT_LABEL_OFFSET_Y,
  DEFAULT_PRIMARY_HANDLE_SIZE,
  FILL_UNSELECTED,
  LABEL_BG_DEFAULT,
  LABEL_BG_MAX_OPACITY,
  LABEL_PADDING_TOP,
  SHROUDED_OPACITY,
  SELECTED_HIGHLIGHT,
} from "./Constants"
import { labelPos, arrayToRGBA } from "../../common/utils"

const RenderSvgDirectCircle = ({
  circle,
  editMode,
  selected,
  onPointSelected,
  selectedObject,
  overrideProperty,
}) => {
  const [label_bg_bbox, setLabel_bg_bbox] = useState(null)
  const [textLayout, setTextLayout] = useState(null)
  const pts = useRef([])

  const normalized_delta_ratio = useMemo(() => {
    if (overrideProperty?.deltaRatio_x && overrideProperty?.deltaRatio_y) {
      return Math.max(
        ((overrideProperty?.deltaRatio_x ?? 1) +
          (overrideProperty?.deltaRatio_y ?? 1)) /
          2,
        0.5
      )
    } else {
      return 1
    }
  }, [overrideProperty?.deltaRatio_x, overrideProperty?.deltaRatio_y])

  const labelRef = useCallback(
    (node) => {
      if (node) {
        setLabel_bg_bbox(node.getBBox())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [circle.name, circle.label_size, normalized_delta_ratio]
  )

  const label_bg_offset_x = useMemo(() => {
    return (
      ((circle.label_size ?? DEFAULT_LABEL_SIZE) / 4) * normalized_delta_ratio
    )
  }, [circle.label_size, normalized_delta_ratio])

  const label_offset_x = useMemo(() => {
    return DEFAULT_LABEL_OFFSET_X * normalized_delta_ratio
  }, [normalized_delta_ratio])

  const label_offset_y = useMemo(() => {
    return DEFAULT_LABEL_OFFSET_Y * normalized_delta_ratio
  }, [normalized_delta_ratio])

  const label_half = useMemo(() => {
    return (label_bg_bbox?.width ?? 0) / 2
  }, [label_bg_bbox?.width])

  const label_padding_top = useMemo(() => {
    return (label_bg_bbox?.height ?? 0) / LABEL_PADDING_TOP
  }, [label_bg_bbox?.height])

  const conditionalOffset_x = useMemo(() => {
    return [
      {
        direction: "NW",
        method: "match",
        value: -(circle.radius * (overrideProperty?.deltaRatio_x ?? 1)) / 12,
      },
      {
        direction: "NE",
        method: "match",
        value: -(circle.radius * (overrideProperty?.deltaRatio_x ?? 1)) / 12,
      },
      {
        direction: "SW",
        method: "match",
        value: -(circle.radius * (overrideProperty?.deltaRatio_x ?? 1)) / 12,
      },
      {
        direction: "SE",
        method: "match",
        value: -(circle.radius * (overrideProperty?.deltaRatio_x ?? 1)) / 12,
      },
      {
        direction: "N",
        method: "match",
        value: -label_bg_offset_x / 2,
      },
      {
        direction: "S",
        method: "match",
        value: -label_bg_offset_x / 2,
      },
      {
        direction: "E",
        method: "match",
        value: label_bg_offset_x,
      },
      {
        direction: "W",
        method: "match",
        value: -label_bg_offset_x,
      },
      {
        direction: "W",
        method: "include",
        value: label_bg_offset_x,
      },
      {
        direction: "E",
        method: "include",
        value: -label_bg_offset_x,
      },
    ]
  }, [label_bg_offset_x, circle.radius, overrideProperty?.deltaRatio_x])

  const conditionalOffset_y = useMemo(() => {
    return [
      {
        direction: ["N", "E"],
        method: "match",
        value: -(circle.radius * (overrideProperty?.deltaRatio_x ?? 1)) / 2,
      },
      {
        direction: ["N", "W"],
        method: "match",
        value: -(circle.radius * (overrideProperty?.deltaRatio_x ?? 1)) / 2,
      },
      {
        direction: ["S", "W"],
        method: "match",
        value: -(circle.radius * (overrideProperty?.deltaRatio_x ?? 1)) / 2,
      },
      {
        direction: ["S", "E"],
        method: "match",
        value: -(circle.radius * (overrideProperty?.deltaRatio_x ?? 1)) / 2,
      },
      {
        direction: "N",
        method: "include",
        value: label_bg_bbox?.height ?? 0,
      },
      {
        direction: ["S", "N"],
        method: "exclude",
        value: -(label_bg_bbox?.height ?? 0) / 2,
      },
    ]
  }, [circle.radius, label_bg_bbox?.height, overrideProperty?.deltaRatio_x])

  const calculate_label_layout = () => {
    let radius = circle.radius
    let cicx = circle.center.points[0]
    let cicy = circle.center.points[1]
    if (overrideProperty) {
      cicx *= overrideProperty.deltaRatio_x ?? 1
      cicy *= overrideProperty.deltaRatio_y ?? 1
      radius *= overrideProperty.deltaRatio_x ?? 1
    }
    setTextLayout({
      x: labelPos({
        axis: "x",
        pos: circle.label_position ?? "NE",
        controlPoint: cicx,
        offset: label_half + label_offset_x,
        radius: radius,
        conditionalOffset: conditionalOffset_x,
      }),
      y: labelPos({
        axis: "y",
        pos: circle.label_position ?? "NE",
        controlPoint: cicy + label_padding_top,
        offset: label_offset_y + circle.stroke_width / 2,
        radius: radius,
        conditionalOffset: conditionalOffset_y,
      }),
    })
  }

  useEffect(() => {
    calculate_label_layout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circle, selected, label_half])

  useEffect(() => {
    if (editMode !== "circle" && !overrideProperty) {
      pts.current.length = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])
  const mouseOver = (event) => {
    event.target.setAttribute("fill", "red")
    if (onPointSelected) onPointSelected(event.target.id)
  }
  const mouseOut = (event) => {
    event.target.removeAttribute("fill")
    if (onPointSelected) onPointSelected(null)
  }
  const mouseClick = (event) => {
    overrideProperty.clickCallback && overrideProperty.clickCallback()
  }

  useEffect(() => {
    const fxPts = pts.current
    fxPts.forEach((p) => {
      if (p && !overrideProperty?.noEvent) {
        p.addEventListener("mouseenter", mouseOver)
        p.addEventListener("mouseleave", mouseOut)
        overrideProperty && p.addEventListener("mousedown", mouseClick)
      }
    })
    return () => {
      fxPts.forEach((p) => {
        if (p && !overrideProperty?.noEvent) {
          p.removeEventListener("mouseenter", mouseOver)
          p.removeEventListener("mouseleave", mouseOut)
          overrideProperty && p.removeEventListener("mousedown", mouseClick)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pts.current, editMode, onPointSelected, overrideProperty])
  let color = "white"
  let labelColor = DEFAULT_LABEL_FONT.fill
  let default_label_bg = LABEL_BG_DEFAULT
  if (circle.color) {
    color = arrayToRGBA(circle.color)
    labelColor = arrayToRGBA(circle.color, circle.unselected ? 0.5 : undefined)
  }
  if (circle.label_bg_color) {
    if (circle.label_bg_color[3] > LABEL_BG_MAX_OPACITY) {
      circle.label_bg_color[3] = LABEL_BG_MAX_OPACITY
    }
    default_label_bg = arrayToRGBA(circle.label_bg_color)
  }
  let cicx = circle.center.points[0]
  let cicy = circle.center.points[1]
  let radius = circle.radius
  let ptOnCircle_x = circle.ptOnCircle.points[0]
  let ptOnCircle_y = circle.ptOnCircle.points[1]
  if (overrideProperty) {
    cicx *= overrideProperty.deltaRatio_x ?? 1
    cicy *= overrideProperty.deltaRatio_y ?? 1
    ptOnCircle_x *= overrideProperty.deltaRatio_x ?? 1
    ptOnCircle_y *= overrideProperty.deltaRatio_y ?? 1
    radius *= overrideProperty.deltaRatio_x ?? 1
  }
  return (
    <g
      style={{
        filter: selected ? SELECTED_HIGHLIGHT : "",
        opacity:
          !selected && editMode && editMode !== "circle" ? SHROUDED_OPACITY : 1,
      }}
    >
      <circle
        cx={cicx}
        cy={cicy}
        r={radius}
        style={{ pointerEvents: "none" }}
        fill={
          circle.unselected
            ? "none"
            : circle.bg_color
            ? arrayToRGBA(circle.bg_color)
            : "none"
        }
        stroke={circle.unselected ? FILL_UNSELECTED : color}
        strokeWidth={
          overrideProperty?.strokeWidth ??
          (circle.stroke_width ?? DEFAULT_CIRCLE_STROKE_WIDTH) + "px"
        }
      />
      {textLayout && (
        <svg
          x={textLayout.x - label_half}
          y={textLayout.y - label_padding_top}
          style={{ pointerEvents: "none" }}
        >
          <g>
            {label_bg_bbox && (
              <TextBackground
                bbox={label_bg_bbox}
                xOffset={label_bg_offset_x}
                fill={circle.unselected ? FILL_UNSELECTED : default_label_bg}
              />
            )}
            <text
              ref={labelRef}
              x={0}
              y={label_padding_top}
              textAnchor="start"
              alignmentBaseline="hanging"
              fontSize={
                (circle.label_size ?? DEFAULT_LABEL_SIZE) *
                  normalized_delta_ratio +
                "px"
              }
              {...DEFAULT_LABEL_FONT}
              fill={labelColor}
            >
              {String.fromCharCode(160).repeat(2)}
              {circle.name}
            </text>
          </g>
        </svg>
      )}
      {(overrideProperty || editMode === "circle") && (
        <g
          stroke="black"
          strokeWidth={1}
          style={{ cursor: overrideProperty ? "pointer" : "grabbing" }}
          fill={circle.unselected ? FILL_UNSELECTED : "white"}
        >
          <circle
            className="hmk_handle"
            id={`${circle.id}#0`}
            cx={ptOnCircle_x}
            cy={ptOnCircle_y}
            r={overrideProperty ? 4 : DEFAULT_HANDLE_RADIUS}
            ref={(el) => (pts.current[0] = el)}
          />
          <rect
            className="hmk_handle"
            id={`${circle.id}#1#M`}
            x={cicx - (overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE) / 2}
            y={cicy - (overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE) / 2}
            width={overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE}
            height={overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE}
            ref={(el) => (pts.current[1] = el)}
          />
        </g>
      )}
    </g>
  )
}

export default RenderSvgDirectCircle
