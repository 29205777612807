import { loadImage } from "./common/utils"
import { HMFileIO } from "./io/HMFileIO"

const DEBUG = false

/**
 *
 * @param {JSON} img_file A loaded file object
 * @returns
 */
const flattenGeomComposedImage = async (img_file) => {
  if (!img_file.metadata.geom_compo_id) return null
  let canvas = document.createElement("canvas")
  let ctx = canvas.getContext("2d")
  const gc_file = await HMFileIO().loadFile(img_file.metadata.geom_compo_id)
  const [primary_layer, geometry_layer] = await Promise.all([
    loadImage(img_file.src),
    loadImage(gc_file.src),
  ])
  canvas.width = primary_layer.width
  canvas.height = primary_layer.height
  ctx.drawImage(primary_layer, 0, 0)
  ctx.drawImage(geometry_layer, 0, 0)
  return canvas.toDataURL("image/png")
}

const downloadUrl = async (app, id, name) => {
  if (DEBUG) console.log(`DL file ${id}`)
  //const fileName = url.substring(url.lastIndexOf("/") + 1)
  app.setState({ loading: true })
  const img_file = await HMFileIO().loadFile(id)
  if (img_file.metadata.geom_compo_id) {
    try {
      let filename = img_file.name.split(".")
      let format = filename.pop()
      format = "png"
      const link = document.createElement("a")
      link.download = `${filename.join(".")}_modified_${Date.now()}.${format}`
      // this one export image in png format
      link.href = await flattenGeomComposedImage(img_file)
      link.click()
    } catch (e) {
      console.warn(JSON.stringify(e))
    } finally {
      app.setState({ loading: false })
    }
  } else {
    let url = img_file.src
    var xhr = new XMLHttpRequest()
    xhr.open("GET", url, true)
    xhr.responseType = "blob"
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL
      var imageUrl = urlCreator.createObjectURL(this.response)
      var tag = document.createElement("a")
      tag.href = imageUrl
      tag.download = name
      //document.body.appendChild(tag)
      tag.click()
      //document.body.removeChild(tag)
      app.setState({ loading: false })
    }
    xhr.send()
  }
}

export { downloadUrl, flattenGeomComposedImage }
