import React, { useRef, useState, useEffect, useMemo, useCallback } from "react"
import { labelPos, arrayToRGBA } from "../../common/utils"
import TextBackground from "./TextBackground"
import {
  DEFAULT_LABEL_SIZE,
  DEFAULT_LABEL_OFFSET_X,
  DEFAULT_LABEL_OFFSET_Y,
  DEFAULT_LABEL_FONT,
  DEFAULT_SQUARE_ROUNDED,
  DEFAULT_SQUARE_STROKE_WIDTH,
  DEFAULT_PRIMARY_HANDLE_SIZE,
  DEFAULT_HANDLE_RADIUS,
  LABEL_BG_DEFAULT,
  LABEL_BG_MAX_OPACITY,
  FILL_UNSELECTED,
  LABEL_PADDING_TOP,
  SHROUDED_OPACITY,
  SELECTED_HIGHLIGHT,
} from "./Constants"

const RenderSquare = ({
  square,
  editMode,
  selected,
  onPointSelected,
  selectedObject,
  overrideProperty,
}) => {
  const pts = useRef([])
  const [textLayout, setTextLayout] = useState(null)
  const [label_bg_bbox, setLabel_bg_bbox] = useState(null)

  const normalized_delta_ratio = useMemo(() => {
    if (overrideProperty?.deltaRatio_x && overrideProperty?.deltaRatio_y) {
      return Math.max(
        ((overrideProperty?.deltaRatio_x ?? 1) +
          (overrideProperty?.deltaRatio_y ?? 1)) /
          2,
        0.5
      )
    } else {
      return 1
    }
  }, [overrideProperty?.deltaRatio_x, overrideProperty?.deltaRatio_y])

  const labelRef = useCallback(
    (node) => {
      if (node) {
        setLabel_bg_bbox(node.getBBox())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [square.name, square.label_size, normalized_delta_ratio]
  )

  const label_bg_offset_x = useMemo(() => {
    return (
      ((square.label_size ?? DEFAULT_LABEL_SIZE) / 8) * normalized_delta_ratio
    )
  }, [square.label_size, normalized_delta_ratio])

  const label_offset_x = useMemo(() => {
    return DEFAULT_LABEL_OFFSET_X * normalized_delta_ratio
  }, [normalized_delta_ratio])

  const label_offset_y = useMemo(() => {
    return DEFAULT_LABEL_OFFSET_Y * normalized_delta_ratio
  }, [normalized_delta_ratio])

  const label_half = useMemo(() => {
    return (label_bg_bbox?.width ?? 0) / 2
  }, [label_bg_bbox?.width])

  const label_padding_top = useMemo(() => {
    return (label_bg_bbox?.height ?? 0) / LABEL_PADDING_TOP
  }, [label_bg_bbox?.height])

  const conditionalOffset_x = useMemo(() => {
    return [
      {
        direction: "N",
        method: "match",
        value: -label_bg_offset_x / 2,
      },
      {
        direction: "S",
        method: "match",
        value: -label_bg_offset_x / 2,
      },
      {
        direction: "W",
        method: "include",
        value: label_bg_offset_x,
      },
      {
        direction: "E",
        method: "include",
        value: -label_bg_offset_x,
      },
    ]
  }, [label_bg_offset_x])

  const conditionalOffset_y = useMemo(() => {
    return [
      {
        direction: "N",
        method: "include",
        value: label_bg_bbox?.height ?? 0,
      },
      //{ direction: "S", method: "include", value: label_padding_top * 4 },
      {
        direction: ["S", "N"],
        method: "exclude",
        value: -(label_bg_bbox?.height ?? 0) / 2,
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label_bg_bbox?.height, label_padding_top])

  const calculate_label_layout = () => {
    let xCenter = (square.points[0].points[0] + square.points[1].points[0]) / 2
    let yCenter =
      Math.abs(square.points[0].points[1] + square.points[1].points[1]) / 2
    let xOffset =
      Math.abs(square.points[0].points[0] - square.points[1].points[0]) / 2
    let yOffset =
      Math.abs(square.points[0].points[1] - square.points[1].points[1]) / 2
    if (overrideProperty) {
      xCenter *= overrideProperty.deltaRatio_x ?? 1
      yCenter *= overrideProperty.deltaRatio_y ?? 1
      xOffset *= overrideProperty.deltaRatio_x ?? 1
      yOffset *= overrideProperty.deltaRatio_y ?? 1
    }
    setTextLayout({
      x: labelPos({
        axis: "x",
        pos: square.label_position ?? "NE",
        controlPoint: xCenter,
        offset: xOffset + label_offset_x + label_bg_offset_x + label_half,
        conditionalOffset: conditionalOffset_x,
      }),
      y: labelPos({
        axis: "y",
        pos: square.label_position ?? "NE",
        controlPoint: yCenter + label_padding_top,
        offset: yOffset + label_offset_y + square.stroke_width / 2,
        conditionalOffset: conditionalOffset_y,
      }),
    })
  }
  useEffect(() => {
    calculate_label_layout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [square, selected, label_half])
  // update ref size if a change in number of points
  useEffect(() => {
    if (editMode !== "square" && !overrideProperty) {
      pts.current.length = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])
  useEffect(() => {
    const fxPts = pts.current
    fxPts.forEach((p) => {
      if (p && !overrideProperty?.noEvent) {
        p.addEventListener("mouseenter", mouseOver)
        p.addEventListener("mouseleave", mouseOut)
        overrideProperty && p.addEventListener("mousedown", mouseClick)
      }
    })
    return () => {
      fxPts.forEach((p) => {
        if (p && !overrideProperty?.noEvent) {
          p.removeEventListener("mouseenter", mouseOver)
          p.removeEventListener("mouseleave", mouseOut)
          overrideProperty && p.removeEventListener("mousedown", mouseClick)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pts.current, editMode, overrideProperty])
  const mouseClick = (event) => {
    overrideProperty.clickCallback && overrideProperty.clickCallback()
  }
  const mouseOver = (event) => {
    event.target.setAttribute("fill", "red")
    if (onPointSelected) onPointSelected(event.target.id)
  }
  const mouseOut = (event) => {
    event.target.removeAttribute("fill")
    if (onPointSelected) onPointSelected(null)
  }
  let x = Math.min(square.points[0].points[0], square.points[1].points[0])
  let y = Math.min(square.points[0].points[1], square.points[1].points[1])
  let w = Math.abs(square.points[1].points[0] - square.points[0].points[0])
  let h = Math.abs(square.points[1].points[1] - square.points[0].points[1])
  let pts1_x = square.points[0].points[0]
  let pts1_y = square.points[0].points[1]
  let pts2_x = square.points[1].points[0]
  let pts2_y = square.points[1].points[1]
  if (overrideProperty?.deltaRatio_x && overrideProperty?.deltaRatio_y) {
    x *= overrideProperty.deltaRatio_x ?? 1
    y *= overrideProperty.deltaRatio_y ?? 1
    w *= overrideProperty.deltaRatio_x ?? 1
    h *= overrideProperty.deltaRatio_y ?? 1
    pts1_x *= overrideProperty.deltaRatio_x ?? 1
    pts1_y *= overrideProperty.deltaRatio_y ?? 1
    pts2_x *= overrideProperty.deltaRatio_x ?? 1
    pts2_y *= overrideProperty.deltaRatio_y ?? 1
  }
  let color = "white"
  let default_label_bg = LABEL_BG_DEFAULT
  let labelColor = DEFAULT_LABEL_FONT.fill
  if (square.color) {
    color = arrayToRGBA(square.color)
    labelColor = arrayToRGBA(square.color, square.unselected ? 0.5 : undefined)
  }
  if (square.label_bg_color) {
    if (square.label_bg_color[3] > LABEL_BG_MAX_OPACITY) {
      square.label_bg_color[3] = LABEL_BG_MAX_OPACITY
    }
    default_label_bg = arrayToRGBA(square.label_bg_color)
  }
  return (
    <g
      className={`${square.unselected && "unselected"}`}
      style={{
        filter: selected ? SELECTED_HIGHLIGHT : "",
        opacity:
          !selected && editMode && editMode !== "square" ? SHROUDED_OPACITY : 1,
      }}
      stroke={square.unselected ? FILL_UNSELECTED : color}
      strokeWidth={
        overrideProperty?.strokeWidth ??
        (square.stroke_width ?? DEFAULT_SQUARE_STROKE_WIDTH) + "px"
      }
      fill="none"
    >
      <rect
        x={x}
        y={y}
        width={w}
        height={h}
        style={{ pointerEvents: "none" }}
        fill={
          square.unselected
            ? "none"
            : square.bg_color
            ? arrayToRGBA(square.bg_color)
            : "none"
        }
        rx={overrideProperty?.borderRadius ?? DEFAULT_SQUARE_ROUNDED}
        ry={overrideProperty?.borderRadius ?? DEFAULT_SQUARE_ROUNDED}
      />
      {(editMode === "square" || overrideProperty) && (
        <g
          stroke="black"
          strokeWidth={1}
          style={{ cursor: overrideProperty ? "pointer" : "grabbing" }}
          fill={square.unselected ? FILL_UNSELECTED : "white"}
        >
          <circle
            className="hmk_handle"
            id={`${square.id}#0`}
            ref={(el) => (pts.current[0] = el)}
            cx={pts1_x}
            cy={pts1_y}
            r={overrideProperty ? 4 : DEFAULT_HANDLE_RADIUS}
          />
          <circle
            className="hmk_handle"
            id={`${square.id}#1`}
            ref={(el) => (pts.current[1] = el)}
            cx={pts2_x}
            cy={pts2_y}
            r={overrideProperty ? 4 : DEFAULT_HANDLE_RADIUS}
          />
          <rect
            className="hmk_handle"
            id={`${square.id}#2#M`}
            ref={(el) => (pts.current[2] = el)}
            x={
              pts2_x - (overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE) / 2
            }
            y={
              pts1_y - (overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE) / 2
            }
            width={overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE}
            height={overrideProperty ? 8 : DEFAULT_PRIMARY_HANDLE_SIZE}
          />
        </g>
      )}
      {textLayout && (
        <svg
          x={textLayout.x - label_half}
          y={textLayout.y - label_padding_top}
          style={{ pointerEvents: "none" }}
        >
          <g>
            {label_bg_bbox && (
              <TextBackground
                bbox={label_bg_bbox}
                xOffset={label_bg_offset_x}
                fill={square.unselected ? FILL_UNSELECTED : default_label_bg}
              />
            )}
            <text
              ref={labelRef}
              x={0}
              y={label_padding_top}
              textAnchor={"start"}
              alignmentBaseline={"hanging"}
              //fill={selected ? "red" : labelColor}
              fontSize={
                (square.label_size ?? DEFAULT_LABEL_SIZE) *
                  normalized_delta_ratio +
                "px"
              }
              {...DEFAULT_LABEL_FONT}
              fill={labelColor}
            >
              {String.fromCharCode(160)}
              {square.name ?? "no name"}
            </text>
          </g>
        </svg>
      )}
    </g>
  )
}

export default RenderSquare
