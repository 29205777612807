import React from "react"

import { Progress } from "react-sweet-progress"
import "react-sweet-progress/lib/style.css"

import { HMKWebSocket } from "../io/WebSocket"

const DEBUG = false

const HMKProgress = ({ progress }) => {
  return (
    <div className="card__progress">
      <Progress
        percent={progress.data.current}
        theme={{
          active: { symbol: " ", trailColor: "#666666", color: "#ffa500" },
          success: { symbol: " ", trailColor: "#666666", color: "#ffa500" },
          default: { active: " ", trailColor: "#666666", color: "#ffa500" },
        }}
        symbolClassName={null}
        className="card__progress_bar"
      />
    </div>
  )
}

const handleError = (app, taskId, data) => {
  console.error(`Handle Error on task ${taskId}: `, data)
  const cardId = app.state.progress.find((p) => p.tid === taskId).id
  const pic = app.state.pictures.find((p) => p.id === cardId)
  console.error(`Error processing on file ${pic.name} : `, data.result)
  const loading = app.state.progress.length > 1
  app.setState({
    loading: loading,
  })
}

const updateProgress = (app, taskId, data) => {
  if (data.total !== 100) {
    data.current = (data.current / data.total) * 100.0
    data.total = 100
  }
  if (data.current > 100) {
    // WARN!
    data.current = 100
  }
  app.setState((prevState) => ({
    progress: prevState.progress.map((p) =>
      p.tid === taskId ? { ...p, data: data } : p
    ),
  }))
}

const updateFolder = (app, taskId, data) => {
  // retrieve the added file from data = file id
  // verify if its parent folder is the same as the current folder
  // then update the pictures state variable
  const folder = app.getCurrentDir()
  // folder id is currentFolder.id
  app.hmFile.loadDir(data.result).then((dir) => {
    const alreadyExists = app.state.directories.find((d) => d.id === dir.id)
    if (!alreadyExists && dir.parent === folder.id) {
      // update folder card list
      app.setState({
        directories: app.folderSorter([
          ...app.state.directories,
          {
            id: dir["id"],
            created: dir["created"],
            src: dir["name"],
            parent: dir["parent"],
            //numbers: [dir.dirs.length, dir.files.length],
            metadata: dir["metadata"],
          },
        ]),
      })
    }
  })
}

const updateFiles = (app, taskId, data) => {
  // retrieve the added file from data = file id
  // verify if its parent folder is the same as the current folder
  // then update the pictures state variable
  return app.hmFile
    .loadFile(data.result)
    .then((file) => app.updatePictureState(file))
    .then(() => {
      if (DEBUG)
        console.log(`Update Files for task ${taskId} with data: `, data)
    })
}

const trackProgress = (app, cardId, taskId, endMsg) =>
  new Promise((resolve, reject) => {
    const ws = HMKWebSocket()
    ws.init(taskId, endMsg)
    app.setState((prevState) => ({
      progress: prevState.progress.concat([
        {
          id: cardId,
          tid: taskId,
          ws: ws,
          data: { current: 0, total: 100 },
        },
      ]),
    }))
    ws.on("PROGRESS", (data) => updateProgress(app, taskId, data))
    ws.on("ERROR", (data) => handleError(app, taskId, data))
    ws.on("ADD_FILE", (data) => updateFiles(app, taskId, data))
    ws.on("ADD_FOLDER", (data) => updateFolder(app, taskId, data))
    ws.on(endMsg, (msg) => {
      const loading = app.state.progress.length > 1
      app.setState({
        progress: app.state.progress.filter((p) => p["tid"] !== taskId),
        loading: loading,
      })
      resolve(msg)
    })
  })

export { HMKProgress, trackProgress }
