import { HMFileIO } from "../io/HMFileIO"
import { toast } from "react-toastify"

import { trackProgress } from "../common/ProgressMonitor"
import {
  DefaultPreviewRenderParams,
  RenderResolution,
  RenderType,
} from "../modals/RenderModal"

const blendPostPro = ({ remoteFile }, app) => {
  const DEBUG = false
  return new Promise((resolve, reject) => {
    const hmFile = HMFileIO()
    /*
    if (DEBUG)
      console.log(
        `Will launch BLENDER Filter Conversion on file ${remoteFile["src"]}`
      )
    hmFile
      .convert("blendfilter", remoteFile["id"])
      .then((res) => {
        if (DEBUG) console.log(`Launched BlendFilter: ${JSON.stringify(res)}`)
        toast("Launched BlendFilter process...")
        return progressMonitor(
          remoteFile["id"],
          res.task_id,
          "BLENDFILTER_DONE"
        )
      })
      .then((res) => {
        if (DEBUG)
          console.log(
            `Result of BlendFilter is: ${JSON.stringify(res["result"])}`
          )
          */
    let params = {
      ...DefaultPreviewRenderParams,
      reso: RenderResolution[DefaultPreviewRenderParams.reso].reso,
      ratio: DefaultPreviewRenderParams.ratio.ratio,
      quality: DefaultPreviewRenderParams.quality.samples,
    }
    // add to it the default index and depth pictures (always rendered)
    params.renderPics.push("Depth", "Index")
    params.type.type = Object.keys(RenderType).find(
      (k) => RenderType[k] === DefaultPreviewRenderParams.type
    )
    hmFile
      .convert("render", remoteFile["id"], params)
      //})
      .then((res) => {
        if (DEBUG) console.log(`Launched Rendering: ${JSON.stringify(res)}`)
        toast("Launched Rendering process...")
        return trackProgress(app, remoteFile["id"], res.task_id, "RENDER_DONE")
      })
      .then((res) => {
        if (DEBUG)
          console.log(
            `Result of Rendering is: ${JSON.stringify(res["result"])}`
          )
        toast("All processes done, refreshing workspace ...")
        resolve(res)
      })
      .catch((err) => {
        console.log(
          `ERROR IN POSTPROCESSING OF FILE ${remoteFile["src"]} : ${err.message}`
        )
        reject(err)
      })
  })
}

export default blendPostPro
