import { HMFileIO } from "../io/HMFileIO"
import { loadImage } from "../common/utils"

const DEBUG = false
/**
 * CROP a picture displayed in the PictureViewer by composing all img/canvas layers : selection, colors and so on ...
 *
 * @param {{imgs:[],canvas:obj}} pv the Picture Viewer elements to crop from
 * @param {string} name the name of the crop file
 * @param {[2][2]} box the crop selection box, format is [[offsetX,offsetY],[width,height]]
 * @param {function} postCrop a post crop function called after crop with the result of the crop in parameter
 */
const pvCrop = (pv, name, box, postCrop) => {
  return new Promise((resolve, reject) => {
    if (!box) {
      box = [
        [0, 0],
        [pv.imgs[0].naturalWidth, pv.imgs[0].naturalHeight],
      ]
    }
    DEBUG && console.log(`Create a crop named `, name)
    DEBUG && console.log(`for select box `, box)
    DEBUG && console.log(`Work on canvas `, pv)
    const canvas = document.createElement("canvas")
    const x = box[0][0]
    const y = box[0][1]
    const w = box[1][0] - box[0][0]
    const h = box[1][1] - box[0][1]
    canvas.width = w
    canvas.height = h
    const ims = pv.imgs
    Promise.all(
      Array.from(ims).map((im) => {
        DEBUG && console.log(`Preprocess image `, im)
        return loadImage(im.getAttribute("src")).then((img) => {
          DEBUG && console.log(`Image specs are : `, img)
          DEBUG && console.log(`Image size is: [${img.width}, ${img.height}]`)
          canvas.getContext("2d").drawImage(img, x, y, w, h, 0, 0, w, h)
        })
      })
    )
      .then(() => {
        const caca = pv.canvas
        const ctx2d = canvas.getContext("2d")
        ctx2d.globalCompositeOperation = "multiply"
        ctx2d.drawImage(caca, x, y, w, h, 0, 0, w, h)
        canvas.toBlob((blob) => {
          blob.name = name
          resolve(postCrop(blob))
        })
      })
      .catch((err) => reject(err))
  })
}

const pvSave = (pv, filePic, postSave) => {
  return HMFileIO()
    .getNewName(filePic.id)
    .then((newname) =>
      pvCrop(pv, newname.join(""), undefined, (file) =>
        HMFileIO()
          .uploadFileXHR(file, filePic.parent, {
            ...filePic.metadata,
            colorized: `from file ${filePic.name}`,
          })
          .then((file) => postSave(file, newname))
      )
    )
    .then(() => console.log(`PictureViewer: Save all done.`))
}

export { pvCrop, pvSave }
