import { HMFileIO } from "../io/HMFileIO"
import { toast } from "react-toastify"

import { trackProgress } from "../common/ProgressMonitor"

const dxfPostPro = ({ remoteFile }, app) => {
  const DEBUG = false
  return new Promise((resolve, reject) => {
    const hmFile = HMFileIO()
    if (DEBUG)
      console.log(`Will launch DXF Conversion on file ${remoteFile["src"]}`)
    hmFile
      .convert("dxf2svg", remoteFile["id"])
      .then((res) => {
        if (DEBUG) console.log(`Launched Dxf2Svg: ${JSON.stringify(res)}`)
        toast("Launched DXF conversion process...")
        return trackProgress(app, remoteFile["id"], res.task_id, "DXF2SVG_DONE")
      })
      .then((res) => {
        if (DEBUG)
          console.log(`Result of Dxf2Svg is: ${JSON.stringify(res["result"])}`)
        toast("All processes done, refreshing workspace ...")
        resolve(res)
      })
      .catch((err) => {
        console.log(
          `ERROR IN POSTPROCESSING OF FILE ${remoteFile["src"]} : ${err.message}`
        )
        reject(err)
      })
  })
}

export default dxfPostPro
