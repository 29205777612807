import React, { useRef, useState, useEffect } from "react"

import { isPicFromStep, isRenderableFile } from "../common/CheckFileType"
import { HMFileIO } from "../io/HMFileIO"

import CheckBoxBtn from "../common/HMKCheckbox"

import "./RenderModal.css"

const RenderType = {
  hd: { name: "Same", folder_type_name: "RENDER" },
  viewall: { name: "Same / View All", folder_type_name: "RENDER" },
  //revolve34: "Revolve 3x4",
  //revolve58: "Revolve 5x8",
  projection06: { name: "Projection 6", folder_type_name: "PROJECTION_6" },
  projection24: { name: "Projection 24", folder_type_name: "PROJECTION_24" },
  projection66: { name: "Projection 66", folder_type_name: "PROJECTION_66" },
  clipboard: { name: "From Clipboard", folder_type_name: "RENDER" },
  preview: { name: "Preview", folder_type_name: "RENDER" },
}

// this one is extended dynamically with devices coming from Studio
var RenderResolution = {
  uuld: { name: "UULD (144)", reso: [144, 144] },
  uld: { name: "ULD (240)", reso: [240, 240] },
  ld: { name: "LD (360)", reso: [360, 360] },
  sd: { name: "SD (480)", reso: [480, 480] },
  hd: { name: "HD (720)", reso: [720, 720] },
  fullhd: { name: "Full HD (1080)", reso: [1080, 1080] },
  qhd: { name: "Quad HD (2160)", reso: [2160, 2160] },
  uhd4k: { name: "UHD 4K (4320)", reso: [4320, 4320] },
  uhd8k: { name: "UHD 8K (8640)", reso: [8640, 8640] },
}

const RenderRatio = {
  full: { name: "100%", ratio: 100 },
  half: { name: "50%", ratio: 50 },
  quarter: { name: "25%", ratio: 25 },
  tenth: { name: "10%", ratio: 10 },
}

const RenderQuality = {
  ulq: { name: "Ultra Low", samples: 8 },
  lq: { name: "Low", samples: 16 },
  mq: { name: "Medium", samples: 32 },
  hq: { name: "High", samples: 64 },
  uhq: { name: "Ultra High", samples: 128 },
}

const RenderSelection = {
  same: "Same",
  without: "Without",
  only: "Only",
  all: "All",
  copy_cb: "Replace from Clipboard",
  add_cb: "Add from Clipboard",
  substract_cb: "Substract from Clipboard",
}

const RenderLOD = {
  full: { name: "full", ratio: "" },
  half: { name: "50", ratio: "50" },
  quarter: { name: "25", ratio: "25" },
  tenth: { name: "12.5", ratio: "125" },
}

const RenderPicture = {
  rgba: "Colors",
  normals: "Normals",
  edges: "Edges",
  outline: "Outline",
  native: "Native",
}

const RenderColor = {
  same: "Same",
  white: "White",
  reset: "Reset",
  random: "Random",
}

const RenderRotateAxis = {
  rot_screen_x: "Screen X",
  rot_screen_y: "Screen Y",
  rot_screen_z: "Screen Z",
  rot_object_x: "Object X",
  rot_object_y: "Object Y",
  rot_object_z: "Object Z",
}

const DefaultRevolvePhiTheta34 = {
  nbPhi: 3,
  nbTheta: 4,
}

const DefaultMoveScreenX = 0
const DefaultMoveScreenY = 0
const DefaultRotateAngle = 0
const DefaultZoomFactor = 0
const DefaultCameraLeft = 0,
  DefaultCameraRight = 0,
  DefaultCameraUp = 0,
  DefaultCameraDown = 0,
  DefaultZoomIn = 0,
  DefaultZoomOut = 0
const DefaultNbCutX = 0,
  DefaultNbCutY = 0,
  DefaultNbCutZ = 0
const DefaultCutPlaneX = NaN,
  DefaultCutPlaneY = NaN,
  DefaultCutPlaneZ = NaN
const DefaultExplodeFactorX = 0
const DefaultExplodeFactorY = 0
const DefaultExplodeFactorZ = 0
const DefaultInkingWidth = 0

const DefaultPreviewRenderParams = {
  type: RenderType.projection06,
  reso: "sd",
  ratio: RenderRatio.full,
  quality: RenderQuality.lq,
  selection: RenderSelection.same,
  color: RenderColor.same,
  renderPics: [RenderPicture.rgba],
}
const DefaultRevolveRenderParams = {
  ...DefaultRevolvePhiTheta34,
  type: RenderType.projection06,
  reso: "sd",
  ratio: RenderRatio.full,
  quality: RenderQuality.lq,
  selection: RenderSelection.same,
  color: RenderColor.same,
  renderPics: [RenderPicture.rgba],
}
const DefaultHDRenderParams = {
  type: RenderType.hd,
  reso: "hd",
  ratio: RenderRatio.full,
  quality: RenderQuality.mq,
  selection: RenderSelection.same,
  color: RenderColor.same,
  renderPics: [RenderPicture.rgba],
}
const getRenderResolutionFromMetadata = (item) => {
  let result = undefined
  if (item.metadata && item.metadata.resolution) {
    const targetReso = item.metadata.resolution
    result = Object.keys(RenderResolution).find(
      (k) =>
        RenderResolution[k].reso[0] === targetReso.width &&
        RenderResolution[k].reso[1] === targetReso.height
    )
  }
  return result
}
const getRenderQualityFromMetadata = (item) => {
  return item.metadata && item.metadata.resolution
    ? Object.values(RenderQuality).find(
        (v) => v.samples === item.metadata.quality
      )
    : undefined
}
const getDefaultHDRenderParams = (item) => {
  return {
    ...DefaultHDRenderParams,
    reso: getRenderResolutionFromMetadata(item) || DefaultHDRenderParams.reso,
    quality:
      getRenderQualityFromMetadata(item) || DefaultHDRenderParams.quality,
  }
}
const getDefaultRevolveRenderParams = (item) => {
  return {
    ...DefaultRevolveRenderParams,
    reso:
      getRenderResolutionFromMetadata(item) || DefaultRevolveRenderParams.reso,
    quality:
      getRenderQualityFromMetadata(item) || DefaultRevolveRenderParams.quality,
  }
}

const initDefaultRenderParams = (params) => {
  const DEBUG = false
  // update RenderResolutions with devices from params
  if (params.devices && params.devices.list) {
    params.devices.list
      .filter((device) => device.type === "CAMERA")
      .forEach((cam) => {
        if (cam.pose && cam.pose.camera_b) {
          RenderResolution[cam.key] = {
            name: cam.name,
            reso: [
              cam.pose.camera_b.render_width,
              cam.pose.camera_b.render_height,
            ],
            camera: cam.pose.camera_b,
          }
        }
      })
  }

  const defaultParams = {
    reso: params.reso,
    ratio: params.ratio,
    qual: params.quality,
    renderPics: [...params.renderPics],
    moveScreen: [DefaultMoveScreenX, DefaultMoveScreenY],
    moveCamera: [
      DefaultCameraLeft,
      DefaultCameraRight,
      DefaultCameraUp,
      DefaultCameraDown,
      DefaultZoomIn,
      DefaultZoomOut,
    ],
    zoomFactor: DefaultZoomFactor,
    inkingWidth: DefaultInkingWidth,
    cut: [DefaultNbCutX, DefaultNbCutY, DefaultNbCutZ],
    cut_plane: [DefaultCutPlaneX, DefaultCutPlaneY, DefaultCutPlaneZ],
    explode: [
      DefaultExplodeFactorX,
      DefaultExplodeFactorY,
      DefaultExplodeFactorZ,
    ],
  }

  if (DEBUG) {
    console.log(`RenderModal: init default params with params=`, params)
    console.log(`Default params is `, defaultParams)
  }
  // this field is initialized when render modal is called from a 3d picture
  if (params.descriptor) {
    // initialize rendered layer : colors or normals activated by default ?
    const layer_src = params.descriptor.rendering.channel_name
    if (
      layer_src === "RGBA" &&
      !defaultParams.renderPics.includes(RenderPicture.rgba)
    ) {
      //defaultParams.renderPics.push(RenderPicture.rgba)
      defaultParams.renderPics = [RenderPicture.rgba]
    } else if (
      layer_src === "NORMALS" &&
      !defaultParams.renderPics.includes(RenderPicture.normals)
    ) {
      //defaultParams.renderPics.push(RenderPicture.normals)
      // how to know if we have to activate RenderPicture.edges ?
      defaultParams.renderPics = [RenderPicture.normals]
    } else if (
      layer_src === "INDEX" &&
      !defaultParams.renderPics.includes(RenderPicture.outline)
    ) {
      //defaultParams.renderPics.push(RenderPicture.outline)
      defaultParams.renderPics = [RenderPicture.outline]
    } else if (
      layer_src === "NATIVE" &&
      !defaultParams.renderPics.includes(RenderPicture.native)
    ) {
      //defaultParams.renderPics.push(RenderPicture.outline)
      defaultParams.renderPics = [RenderPicture.native]
    }
    // Initialize default resolution
    if (params.descriptor.metadata && params.descriptor.metadata.deviceKey) {
      defaultParams.reso = params.descriptor.metadata.deviceKey
    } else {
      const camera = params.descriptor.camera
      defaultParams.reso = Object.keys(RenderResolution).find(
        (k) =>
          RenderResolution[k].reso[0] === camera.render_width &&
          RenderResolution[k].reso[1] === camera.render_height
      )
    }
    // Initialize default LOD
    if (params.descriptor.blender_scene_lod_level) {
      defaultParams.lod = Object.values(RenderLOD).find(
        (v) => v.ratio === params.descriptor.blender_scene_lod_level
      )
    }
    // Process RENDERING part in descriptor file
    const rendering = params.descriptor.rendering
    // Initialize ambient occlusion factor
    defaultParams.ambientOcclusion = rendering.ambient_occlusion_factor !== 0.0
    // Initialize default render ratio
    defaultParams.ratio = Object.values(RenderRatio).find(
      (v) => v.ratio === rendering.resolution_percentage
    )
    // Initialize default render quality
    defaultParams.qual = Object.values(RenderQuality).find(
      (v) => v.samples === rendering.cycles_samples
    )
    // Initialize default Inking line width
    if (rendering.inking)
      defaultParams.inkingWidth = rendering.inking_line_width_pixels
    // Initialize default Explode factors
    if (params.descriptor.explode) {
      defaultParams.explode = params.descriptor.explode.scale
    }
    // Initialize default cut if source image has crop informations
    if (params.descriptor.crop) {
      defaultParams.cut_plane = [
        params.descriptor.crop.off_x,
        params.descriptor.crop.off_y,
        params.descriptor.crop.off_z,
      ]
      if (params.descriptor.crop.off_x > 0.0) defaultParams.cut[0] = 1
      if (params.descriptor.crop.off_y > 0.0) defaultParams.cut[1] = 1
      if (params.descriptor.crop.off_z > 0.0) defaultParams.cut[2] = 1
    }
  }

  // Initialize Move Screen and Zoom Factor from the Selection box
  if (params.select) {
    // box center x
    const xm = (params.select[0][0] + params.select[1][0]) / 2
    // box center y
    const ym = (params.select[0][1] + params.select[1][1]) / 2
    // box width
    //const w = params.select[1][0] - params.select[0][0]
    //const h = params.select[1][1] - params.select[0][1]
    const camera = params.descriptor.camera
    //const lens_factor = -params.descriptor.object_location[2] / camera.lens
    //const vpw = camera.sensor_width * lens_factor
    //const dx = ((xm - camera.render_width / 2) * vpw) / camera.render_width
    //const dy = ((ym - camera.render_height / 2) * vpw) / camera.render_width

    // WARNING BEFORE:
    //let zoom_factor = camera.render_width / w
    // now zoom_factor is expressed as a % of movement along z axis
    //let zoom_factor =
    //  ((1.0 - zoom_factor) / params.descriptor.object_location[2]) * 100
    //let zoom_factor = -(1.0 + w / params.descriptor.object_location[2]) * 100
    //zoom_factor = Math.round(zoom_factor)
    let move_x = -((xm - camera.render_width / 2) * 100) / camera.render_width
    let move_y = ((ym - camera.render_height / 2) * 100) / camera.render_width
    move_x = Math.round(move_x)
    move_y = Math.round(move_y)
    defaultParams.moveScreen = [move_x, move_y]
    defaultParams.zoomFactor = 0 // DISABLED FOR INSTANCE // zoom_factor
    const cam_left = move_x > 0 ? move_x : 0
    const cam_right = move_x < 0 ? -move_x : 0
    const cam_up = move_y < 0 ? -move_y : 0
    const cam_down = move_y > 0 ? move_y : 0
    const zoom_in = defaultParams.zoomFactor < 0 ? -defaultParams.zoomFactor : 0
    const zoom_out = defaultParams.zoomFactor > 0 ? defaultParams.zoomFactor : 0
    defaultParams.moveCamera = [
      cam_left,
      cam_right,
      cam_up,
      cam_down,
      zoom_in,
      zoom_out,
    ]
  }
  return defaultParams
}

const RenderModal = ({ params }) => {
  const DEBUG = false
  const defaultParams = useRef(initDefaultRenderParams(params))
  const [type, setType] = useState(params.type)
  const [descriptor, setDescriptor] = useState(null)
  const [renderTypes, setRenderTypes] = useState(RenderType)
  const [advanced, setAdvanced] = useState(false)
  const [cuttingPlane, setCuttingPlane] = useState(false)
  const [explode, setExplode] = useState(false)
  const [ambientOcclusionCB, setAmbientOcclusionCB] = useState(
    defaultParams.current.ambientOcclusion
  )
  const modalRoot = useRef(null)

  const onAmbientOcclusionChange = (event) => {
    setAmbientOcclusionCB(event.target.checked)
  }

  const updateViewpoint = (content) => {
    if ("quaternion_xyzw" in content) {
      const quatValues = content["quaternion_xyzw"]
      document.getElementById("render_quat_x").value = quatValues[0]
      document.getElementById("render_quat_y").value = quatValues[1]
      document.getElementById("render_quat_z").value = quatValues[2]
      document.getElementById("render_quat_w").value = quatValues[3]
    }
    if ("object_location" in content) {
      const locValues = content["object_location"]
      document.getElementById("render_loc_x").value = locValues[0]
      document.getElementById("render_loc_y").value = locValues[1]
      document.getElementById("render_loc_z").value = locValues[2]
    }
    if ("object_rotation_center" in content) {
      const centerValues = content["object_rotation_center"]
      document.getElementById("render_center_x").value = centerValues[0]
      document.getElementById("render_center_y").value = centerValues[1]
      document.getElementById("render_center_z").value = centerValues[2]
    }
  }

  // initializer hook
  useEffect(() => {
    HMFileIO()
      .compose(params.item.id)
      .then((res) => setDescriptor(res.descriptor))
    if (isRenderableFile(params.item.name)) {
      setRenderTypes({
        projection06: RenderType.projection06,
        projection24: RenderType.projection24,
        projection66: RenderType.projection66,
        //revolve34: RenderType.revolve34,
        //revolve58: RenderType.revolve58,
      })
    } else if (isPicFromStep(params.item)) {
      setRenderTypes({
        hd: RenderType.hd,
        viewall: RenderType.viewall,
        projection06: RenderType.projection06,
        projection24: RenderType.projection24,
        projection66: RenderType.projection66,
        //revolve34: RenderType.revolve34,
        //revolve58: RenderType.revolve58,
        clipboard: RenderType.clipboard,
      })
    }
    // Key Down event listener on modal content
    const onKeyDown = (evt) => {
      const ctrlDown = evt.ctrlKey || evt.metaKey
      if (ctrlDown && evt.key === "v") {
        navigator.clipboard
          .readText()
          .then((text) => JSON.parse(text))
          .then((json) => updateViewpoint(json))
          .catch((error) =>
            console.log(`Clipboard string isn't a valid JSON format:`, error)
          )
      }
    }
    const element = modalRoot.current
    element.addEventListener("keydown", onKeyDown)
    return () => {
      element.removeEventListener("keydown", onKeyDown)
    }
  }, [params.item])

  useEffect(() => {
    if (advanced && descriptor) updateViewpoint(descriptor)
  }, [advanced, descriptor])

  const toggleAdvancedMode = () => setAdvanced(!advanced)
  const toggleCuttingPlane = () => setCuttingPlane(!cuttingPlane)
  const toggleExplode = () => setExplode(!explode)

  const getKey = (dict, value) =>
    Object.keys(dict).find((k) => dict[k] === value)

  const onChange = (e) => {
    if (DEBUG) {
      console.log(`On Change on target `, e.target.name)
      console.log(`On Change value `, e.target.value)
    }
    if (e.target.name === "render_type") {
      setType(RenderType[e.target.value])
    } else if (e.target.name === "edges" && e.target.checked) {
      // set the input checked property
      document.getElementById("Normals").checked = true
      // add the label active classname
      document.getElementById("normals").classList.add("active")
    } else if (e.target.name === "normals" && !e.target.checked) {
      // remove the input checked property
      document.getElementById("Edges").checked = false
      // remove the label active classname
      document.getElementById("edges").classList.remove("active")
    } else if (e.target.name === "native" && e.target.checked) {
      // remove all other render layers
      Object.entries(RenderPicture).forEach(([k, v]) => {
        if (k !== "native") {
          // remove the input checked property
          document.getElementById(v).checked = false
          // remove the label active classname
          document.getElementById(k).classList.remove("active")
        }
      })
    }
    if (Object.keys(RenderPicture).includes(e.target.name)) {
      // if no layers are selected, select the RGBA by default
      const checkcheck = Object.values(RenderPicture).map(
        (pic) => !document.getElementById(pic).checked
      )
      if (checkcheck.every((c) => c)) {
        // remove the input checked property
        document.getElementById("Colors").checked = true
        // remove the label active classname
        document.getElementById("rgba").classList.add("active")
      }
    }
  }

  const defaultSelectRenderPic = Object.assign(
    {},
    ...defaultParams.current.renderPics.map((pic) => ({
      [getKey(RenderPicture, pic)]: pic,
    }))
  )

  return (
    <div ref={modalRoot}>
      {/* VIEWPOINT form */}
      <div className="form-row">
        <div className="form-group col-xl-4">
          <label htmlFor="render_type">Viewpoint</label>
          <select
            className="form-control"
            id="render_type"
            name="render_type"
            defaultValue={getKey(renderTypes, params.type).name}
            onChange={onChange}
          >
            {Object.entries(renderTypes).map(([k, v], i) => (
              <option key={k} value={k}>
                {v.name}
              </option>
            ))}
          </select>
        </div>
        {/* CAMERA form */}
        <div className="form-group col-xl-4">
          <label htmlFor="render_reso">Camera</label>
          <select
            className="form-control"
            id="render_reso"
            name="render_reso"
            defaultValue={defaultParams.current.reso}
            //disabled={type === RenderType.zoom}
          >
            {Object.entries(RenderResolution).map(([k, v]) =>
              !v.camera ? (
                <option key={k} value={k}>
                  {v.name}
                </option>
              ) : (
                ""
              )
            )}
            <optgroup
              style={{ fontSize: "1pt", backgroundColor: "#ccc" }}
              disabled
            >
              &nbsp;
            </optgroup>
            {Object.entries(RenderResolution).map(([k, v]) =>
              v.camera ? (
                <option key={k} value={k}>
                  {v.name}
                </option>
              ) : (
                ""
              )
            )}
          </select>
        </div>
        {/* SIZE RATIO form */}
        <div className="form-group col-xl-2">
          <label htmlFor="render_ratio">Size Ratio</label>
          <select
            className="form-control"
            id="render_ratio"
            name="render_ratio"
            defaultValue={getKey(RenderRatio, defaultParams.current.ratio)}
          >
            {Object.entries(RenderRatio).map(([k, v]) => (
              <option key={k} value={k}>
                {v.name}
              </option>
            ))}
          </select>
        </div>
        {/* QUALITY form */}
        <div className="form-group col-xl-2">
          <label htmlFor="render_qual">Quality</label>
          <select
            className="form-control"
            id="render_qual"
            name="render_qual"
            defaultValue={getKey(RenderQuality, defaultParams.current.qual)}
          >
            {Object.entries(RenderQuality).map(([k, v]) => (
              <option key={k} value={k}>
                {v.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* SELECTION form */}
      {isPicFromStep(params.item) ? (
        <div className="form-row">
          <div className="form-group col-xl-3">
            <label htmlFor="render_select">Selection</label>
            <select
              className="form-control"
              id="render_selection"
              name="render_selection"
              defaultValue={getKey(RenderSelection, params.selection)}
            >
              {Object.entries(RenderSelection).map(([k, v]) => (
                <option key={k} value={k}>
                  {v}
                </option>
              ))}
            </select>
          </div>
          {/* COLORS form */}
          <div className="form-group col-xl-4">
            <label htmlFor="render_color">Colors</label>
            <select
              className="form-control"
              id="render_color"
              name="render_color"
              defaultValue={getKey(RenderColor, params.color)}
            >
              {Object.entries(RenderColor).map(([k, v]) => (
                <option key={k} value={k}>
                  {v}
                </option>
              ))}
            </select>
          </div>
          {/* LOD form */}
          <div className="form-group col-xl-3">
            <label htmlFor="render_lod">LOD</label>
            <select
              className="form-control"
              id="render_lod"
              name="render_lod"
              defaultValue={getKey(RenderLOD, defaultParams.current.lod)}
            >
              {Object.entries(RenderLOD).map(([k, v]) => (
                <option key={k} value={k}>
                  {v.name}
                </option>
              ))}
            </select>
          </div>
          {/* Ambient Occlusion form */}
          <div className="form-group col-xl-1">
            <label id="render_AO" htmlFor="render_ambient_occlusion">
              AO
            </label>
            <CheckBoxBtn
              id="render_ambient_occlusion"
              name="render_ambient_occlusion"
              checked={ambientOcclusionCB}
              onChange={onAmbientOcclusionChange}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {/* IMAGE LAYERS to render form */}
      <div className="form-row">
        <div className="form-group col-xl-12">
          <label htmlFor="render_pics">Layers</label>
          <div
            className="btn-group btn-group-toggle col-12"
            data-toggle="buttons"
          >
            {Object.entries(RenderPicture).map(([k, v], i) => (
              <label
                key={i}
                name={k}
                id={k}
                className={
                  k in defaultSelectRenderPic
                    ? "btn form-control active"
                    : "btn form-control"
                }
              >
                <input
                  type="checkbox"
                  name={k}
                  id={v}
                  value={v}
                  defaultChecked={k in defaultSelectRenderPic}
                  onClick={onChange}
                />
                {v}
              </label>
            ))}
          </div>
        </div>
      </div>
      {/* MOVE OBJECT ALONG SCREEN X/Y form */}
      {type === RenderType.hd ? (
        <div className="form-row">
          <div className="form-group col-xl-6">
            <label>Camera Left (%)</label>
            <input
              className="form-control"
              id="render_camera_left"
              name="render_camera_left"
              type="number"
              min="0"
              max="100"
              step="1"
              defaultValue={defaultParams.current.moveCamera[0]}
            />
          </div>
          <div className="form-group col-xl-6">
            <label>Camera Right (%)</label>
            <input
              className="form-control"
              id="render_camera_right"
              name="render_camera_right"
              type="number"
              min="0"
              max="100"
              step="1"
              defaultValue={defaultParams.current.moveCamera[1]}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {type === RenderType.hd ? (
        <div className="form-row">
          <div className="form-group col-xl-6">
            <label>Camera Up (%)</label>
            <input
              className="form-control"
              id="render_camera_up"
              name="render_camera_up"
              type="number"
              min="0"
              max="100"
              step="1"
              defaultValue={defaultParams.current.moveCamera[2]}
            />
          </div>
          <div className="form-group col-xl-6">
            <label>Camera Down (%)</label>
            <input
              className="form-control"
              id="render_camera_down"
              name="render_camera_down"
              type="number"
              min="0"
              max="100"
              step="1"
              defaultValue={defaultParams.current.moveCamera[3]}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {type === RenderType.hd ? (
        <div className="form-row">
          <div className="form-group col-xl-6">
            <label>Zoom In (%)</label>
            <input
              className="form-control"
              id="render_zoom_in"
              name="render_zoom_in"
              type="number"
              min="0"
              max="100"
              step="1"
              defaultValue={defaultParams.current.moveCamera[4]}
            />
          </div>
          <div className="form-group col-xl-6">
            <label>Zoom Out (%)</label>
            <input
              className="form-control"
              id="render_zoom_out"
              name="render_zoom_out"
              type="number"
              min="0"
              max="100"
              step="1"
              defaultValue={defaultParams.current.moveCamera[5]}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {/* ROTATION form */}
      {type === RenderType.hd ? (
        <div className="form-row">
          <div className="form-group col-xl-6">
            <label htmlFor="render_rotate_axis">Rotate Axis</label>
            <select
              className="form-control"
              id="render_rotate_axis"
              name="render_rotate_axis"
              defaultValue={getKey(RenderRotateAxis, params.rotation)}
            >
              {Object.entries(RenderRotateAxis).map(([k, v]) => (
                <option key={k} value={k}>
                  {v}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-xl-6">
            <label htmlFor="render_rotate_angle">Rotate Angle (deg)</label>
            <input
              className="form-control"
              id="render_rotate_angle"
              name="render_rotate_angle"
              type="number"
              step="1"
              min="-360"
              max="360"
              defaultValue={DefaultRotateAngle}
              readOnly={false}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {/* CUTTING PLANE form */}
      {type === RenderType.hd ? (
        <div className="form-row">
          <div className="form-group col-12">
            <label style={{ cursor: "pointer" }} onClick={toggleCuttingPlane}>
              Cutting Planes
            </label>
          </div>
        </div>
      ) : (
        ""
      )}
      {cuttingPlane ? (
        <div className="form-row">
          <div className="form-group col-xl-4">
            <label>Number Of Cutting Planes Along X</label>
            <input
              className="form-control"
              id="render_nb_cut_x"
              name="render_nb_cut_x"
              type="number"
              step="1"
              min="0"
              defaultValue={defaultParams.current.cut[0]}
            />
          </div>
          <div className="form-group col-xl-4">
            <label>Number Of Cutting Planes Along Y</label>
            <input
              className="form-control"
              id="render_nb_cut_y"
              name="render_nb_cut_y"
              type="number"
              step="1"
              min="0"
              defaultValue={defaultParams.current.cut[1]}
            />
          </div>
          <div className="form-group col-xl-4">
            <label>Number Of Cutting Planes Along Z</label>
            <input
              className="form-control"
              id="render_nb_cut_z"
              name="render_nb_cut_z"
              type="number"
              step="1"
              min="0"
              defaultValue={defaultParams.current.cut[2]}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {cuttingPlane && params.descriptor && params.descriptor.crop ? (
        <div className="form-row">
          <div className="form-group col-xl-4">
            <label>X Cutting Plane</label>
            <input
              className="form-control"
              id="render_cut_x_plane"
              name="render_cut_x_plane"
              type="number"
              defaultValue={defaultParams.current.cut_plane[0]}
            />
          </div>
          <div className="form-group col-xl-4">
            <label>Y Cutting Plane</label>
            <input
              className="form-control"
              id="render_cut_y_plane"
              name="render_cut_y_plane"
              type="number"
              defaultValue={defaultParams.current.cut_plane[1]}
            />
          </div>
          <div className="form-group col-xl-4">
            <label>Z Cutting Plane</label>
            <input
              className="form-control"
              id="render_cut_z_plane"
              name="render_cut_z_plane"
              type="number"
              defaultValue={defaultParams.current.cut_plane[2]}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {/* EXPLODE FACTORS form */}
      {isPicFromStep(params.item) ? (
        <div className="form-row">
          <div className="form-group col-12">
            <label style={{ cursor: "pointer" }} onClick={toggleExplode}>
              Explode Factors
            </label>
          </div>
        </div>
      ) : (
        ""
      )}
      {explode ? (
        <div className="form-row">
          <div className="form-group col-xl-4">
            <label>Explode Factor X</label>
            <input
              className="form-control"
              id="render_explode_factor_x"
              name="render_explode_factor_x"
              type="number"
              step="0.1"
              defaultValue={defaultParams.current.explode[0]}
              readOnly={false}
            />
          </div>
          <div className="form-group col-xl-4">
            <label>Explode Factor Y</label>
            <input
              className="form-control"
              id="render_explode_factor_y"
              name="render_explode_factor_y"
              type="number"
              step="0.1"
              defaultValue={defaultParams.current.explode[1]}
              readOnly={false}
            />
          </div>
          <div className="form-group col-xl-4">
            <label>Explode Factor Z</label>
            <input
              className="form-control"
              id="render_explode_factor_z"
              name="render_explode_factor_z"
              type="number"
              step="0.1"
              defaultValue={defaultParams.current.explode[2]}
              readOnly={false}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {/* INKING FACTORS form */}
      {/**
      {isPicFromStep(params.item) ? (
        <div className="form-row">
          <div className="form-group col-xl-4">
            <label>Inking Line Width</label>
            <input
              className="form-control"
              id="render_inking_width"
              name="render_inking_width"
              type="number"
              step="0.1"
              min="0"
              defaultValue={defaultParams.current.inkingWidth}
              readOnly={false}
            />
          </div>
        </div>
      ) : (
        ""
      )}*/}
      {/* OBJECT QUATERNION and LOCATION form (target for copy/paste) */}
      {type === RenderType.hd ? (
        <div className="form-row">
          <div className="form-group col-12">
            <label style={{ cursor: "pointer" }} onClick={toggleAdvancedMode}>
              Viewpoint Snippet
            </label>
          </div>
        </div>
      ) : (
        ""
      )}
      {advanced ? (
        <div>
          <div className="form-row">
            <div className="form-group col-4">
              <label>Quaternion</label>
              <input
                className="form-control"
                id="render_quat_x"
                name="render_quat_x"
                type="text"
                placeholder="X"
              />
              <input
                className="form-control"
                id="render_quat_y"
                name="render_quat_y"
                type="text"
                placeholder="Y"
              />
              <input
                className="form-control"
                id="render_quat_z"
                name="render_quat_z"
                type="text"
                placeholder="Z"
              />
              <input
                className="form-control"
                id="render_quat_w"
                name="render_quat_w"
                type="text"
                placeholder="W"
              />
            </div>
            <div className="form-group col-4">
              <label>Location</label>
              <input
                className="form-control"
                id="render_loc_x"
                name="render_loc_x"
                type="text"
                placeholder="X"
              />
              <input
                className="form-control"
                id="render_loc_y"
                name="render_loc_y"
                type="text"
                placeholder="Y"
              />
              <input
                className="form-control"
                id="render_loc_z"
                name="render_loc_z"
                type="text"
                placeholder="Z"
              />
            </div>
            <div className="form-group col-4">
              <label>Center</label>
              <input
                className="form-control"
                id="render_center_x"
                name="render_center_x"
                type="text"
                placeholder="X"
              />
              <input
                className="form-control"
                id="render_center_y"
                name="render_center_y"
                type="text"
                placeholder="Y"
              />
              <input
                className="form-control"
                id="render_center_z"
                name="render_center_z"
                type="text"
                placeholder="Z"
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}
export {
  RenderModal,
  DefaultPreviewRenderParams,
  getDefaultRevolveRenderParams,
  getDefaultHDRenderParams,
  RenderType,
  RenderResolution,
  RenderQuality,
  RenderRatio,
  RenderLOD,
}
