import { FiSquare, FiCircle } from "react-icons/fi"
import { FaDrawPolygon } from "react-icons/fa"
import { TbPointFilled } from "react-icons/tb";
import { CgMiniPlayer, CgShapeHexagon } from "react-icons/cg"
import { GrReturn } from "react-icons/gr"
import ReactTooltip from "react-tooltip"
import { parseLabelPos } from "../../common/utils"
import "./Controller.css"
import "./Resources.css"

export const controller_state = {
  DEFAULT: "default",
  DELETE: "delete",
  FILL_COLOR: "fill_color",
  STROKE_COLOR: "stroke_color",
  LINE_WIDTH: "line_width",
  LABEL_SIZE: "label_size",
  LABEL_POSITION: "label_position",
  LABEL_BG_COLOR: "label_bg_color",
  DUPLICATE: "duplicate",
}

export const ParseControllerState = ({ state, object_length }) => {
  switch (state) {
    case controller_state.FILL_COLOR:
      return <span className="action-container-title">Fill Color</span>
    case controller_state.STROKE_COLOR:
      return <span className="action-container-title">Stroke Color</span>
    case controller_state.LINE_WIDTH:
      return <span className="action-container-title">Line Width</span>
    case controller_state.LABEL_SIZE:
      return <span className="action-container-title">Label Size</span>
    case controller_state.LABEL_POSITION:
      return <span className="action-container-title">Label Position</span>
    case controller_state.LABEL_BG_COLOR:
      return (
        <span className="action-container-title">Label Background Color</span>
      )
    case controller_state.DELETE:
      return (
        <span className="action-container-title">
          {`Deleting ${object_length ?? 0} object(s)`}
        </span>
      )
    default:
      return <></>
  }
}

export const parseStateIntoProperty = (state) => {
  switch (state) {
    case controller_state.FILL_COLOR:
      return "bg_color"
    case controller_state.STROKE_COLOR:
      return "color"
    case controller_state.LINE_WIDTH:
      return "stroke_width"
    case controller_state.LABEL_SIZE:
      return "label_size"
    case controller_state.LABEL_POSITION:
      return "label_position"
    case controller_state.LABEL_BG_COLOR:
      return "label_bg_color"
    case controller_state.DELETE:
      return "delete"
    default:
      return ""
  }
}

export const l_filtered_geometries = [
  {
    accessor: "circles",
    tooltip_message: "Circles",
    tooltip_accessor: "toggle_view_circles",
    icon: <FiCircle className="controller-icon" />,
  },
  {
    accessor: "squares",
    tooltip_message: "Rectangles",
    tooltip_accessor: "toggle_view_square",
    icon: <FiSquare className="controller-icon" />,
  },
  {
    accessor: "polygons",
    tooltip_message: "Polygons",
    tooltip_accessor: "toggle_view_polygons",
    icon: <FaDrawPolygon className="controller-icon" />,
  },
  {
    accessor: "points",
    tooltip_message: "Points",
    tooltip_accessor: "toggle_view_point",
    icon: <TbPointFilled className="controller-icon" />,
  },
  {
    accessor: "masks",
    tooltip_message: "Masks",
    tooltip_accessor: "toggle_view_masks",
    icon: <CgMiniPlayer className="controller-icon" />,
  },
]

export const ActionContainerControl = ({ state, returnCallback }) => {
  if (
    state &&
    state !== controller_state.DEFAULT &&
    state !== controller_state.DELETE &&
    state !== controller_state.DUPLICATE
  ) {
    return (
      <div className="action-bar-wrapper">
        <div
          className="action-bar-icon-wrapper"
          data-tip
          data-for={"action_control_return"}
        >
          <GrReturn
            className="controller-icon-filter action-bar-icon"
            onMouseDown={() => {
              returnCallback()
            }}
          />
          <ReactTooltip id={"action_control_return"}>
            <span>Back</span>
          </ReactTooltip>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export const labelPositionValidityCheck = (pos) => {
  return Boolean(
    pos === "NE" ||
      pos === "N" ||
      pos === "NW" ||
      pos === "W" ||
      pos === "E" ||
      pos === "SE" ||
      pos === "S" ||
      pos === "SW"
  )
}

export const LabelPositionController = ({
  geometries,
  textLayoutIndex,
  selectionCallback,
}) => {
  const DisplayGeometry = () => {
    switch (geometries) {
      case "square":
        return <FiSquare className="label-position-center" />
      case "circle":
        return <FiCircle className="label-position-center" />
      case "point":
        return <TbPointFilled className="label-position-center" />
      //different geometries
      default:
        return <CgShapeHexagon className="label-position-center" />
    }
  }
  return (
    <>
      {[...Array(9)].map((e, i) => (
        <div key={i} className="label-position-button-wrapper">
          <div
            className="label-position-button"
            style={{
              width: textLayoutIndex === i ? "25px" : "21px",
              height: textLayoutIndex === i ? "25px" : "21px",
              backgroundColor:
                textLayoutIndex === i
                  ? "#FFA500"
                  : i === 4
                  ? "rgba(0,0,0,0)"
                  : "#CCCCCC",
              cursor: i !== 4 ? "pointer" : "auto",
            }}
            onClick={() => {
              if (i !== 4) {
                selectionCallback(parseLabelPos(i))
              }
            }}
          >
            {i === 4 ? <DisplayGeometry /> : <></>}
          </div>
        </div>
      ))}
    </>
  )
}

export const defaultFilteredGeometries = {
  points: false,
  masks: false,
  circles: false,
  polygons: false,
  squares: false,
}
