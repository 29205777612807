import React, { useEffect, useState, useRef } from "react"
import { FiMinus, FiPlus } from "react-icons/fi"
import uuid from "react-uuid"

import RenderMask from "../editor/SVGRenderer/RenderMask"
import RenderSvgPoint from "../editor/SVGRenderer/RenderSVGPoint"
import RenderPolygon from "../editor/SVGRenderer/RenderPolygon"
import RenderSvgDirectCircle from "../editor/SVGRenderer/RenderSVGDirectCircle"
import RenderSquare from "../editor/SVGRenderer/RenderSquare"
import { DEFAULT_LABEL_SIZE } from "../editor/SVGRenderer/Constants"
import { buildPictureFromSVG } from "../editor/SVGFileWriter"
import { HMFileIO } from "../io/HMFileIO"
import { SvgHmkLoader as Loader } from "../common/HMKLoader"
import { HMCom } from "../io/HMCom"

export const PasteModal = ({ params }) => {
  const [loaded, setLoaded] = useState(false)
  const [payload, setPayload] = useState(null)
  const [sourceGeometries, setSourceGeometries] = useState(null)
  const [targetGeometries, setTargetGeometries] = useState(null)
  const [error, setError] = useState("")
  // eslint-disable-next-line no-unused-vars
  const [hmCom_target, setHmCom_target] = useState(HMCom())
  const [selectedObject, setSelectedObject] = useState(null)
  const [sourceIMGSize, setSourceIMGSize] = useState({ width: 0, height: 0 })
  const [targetIMGSize, setTargetIMGSize] = useState({ width: 0, height: 0 })

  const [deltaRatio_x, setDeltaRatio_x] = useState(0)
  const [deltaRatio_y, setDeltaRatio_y] = useState(0)
  const [ratio_normalizer_x, setRatio_normalizer_x] = useState(0)
  const [ratio_normalizer_y, setRatio_normalizer_y] = useState(0)

  const [selectedPoints, setSelectedPoints] = useState([])
  const [selectedMasks, setSelectedMasks] = useState([])
  const [selectedPolygons, setSelectedPolygons] = useState([])
  const [selectedCircles, setSelectedCircles] = useState([])
  const [selectedSquares, setSelectedSquares] = useState([])

  const svgCanvas = useRef(null)
  const rescaledCanvas = useRef(null)
  const imgRef = useRef(null)
  const initialize = async () => {
    if (!params.source_id || !params.target_pic.id) {
      setError("error: invalid source")
      return
    }
    try {
      const hmCom_source = HMCom()
      await hmCom_target.initGeoCompo(params.target_pic.id)
      await hmCom_source.initGeoCompo(params.source_id)
      const hmComRes_target = await hmCom_target.loadGeoCompoData()
      const hmComRes_source = await hmCom_source.loadGeoCompoData()

      const [
        pts_target,
        polygons_target,
        masks_target,
        circles_target,
        squares_target,
      ] = hmComRes_target
      setTargetGeometries({
        points: pts_target ?? [],
        polygons: polygons_target ?? [],
        masks: masks_target ?? [],
        circles: circles_target ?? [],
        squares: squares_target ?? [],
      })
      const [
        pts_source,
        polygons_source,
        masks_source,
        circles_source,
        squares_source,
      ] = hmComRes_source
      setSourceGeometries({
        points: pts_source ?? [],
        polygons: polygons_source ?? [],
        masks: masks_source ?? [],
        circles: circles_source ?? [],
        squares: squares_source ?? [],
      })

      const img_target = new Image()
      const img_source = new Image()

      img_target.src = params.target_pic.src
      img_source.src = params.source_imgsrc

      let target_img_size, source_img_size

      img_target.onload = function () {
        let w = this.width
        let h = this.height
        target_img_size = { width: w, height: h }
        setTargetIMGSize(target_img_size)
      }
      img_source.onload = function () {
        let w = this.width
        let h = this.height
        source_img_size = { width: w, height: h }
        setSourceIMGSize(source_img_size)
      }
      setPayload(payload)
    } catch (e) {
      console.warn(e)
      setError(`Error : ${JSON.stringify(e)}`)
    }
  }
  useEffect(() => {
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (imgRef && svgCanvas?.current?.style && rescaledCanvas?.current?.style) {
      svgCanvas.current.style.width = imgRef.current.clientWidth
      svgCanvas.current.style.height = imgRef.current.clientHeight
      rescaledCanvas.current.style.width = targetIMGSize.width
      rescaledCanvas.current.style.height = targetIMGSize.height
      if (!deltaRatio_y && !deltaRatio_x) {
        setDeltaRatio_x(imgRef.current.width / sourceIMGSize.width)
        setDeltaRatio_y(imgRef.current.height / sourceIMGSize.height)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rescaledCanvas, svgCanvas, imgRef, loaded])
  useEffect(() => {
    if (
      !loaded &&
      Boolean(sourceGeometries) &&
      Boolean(targetGeometries) &&
      !error &&
      targetIMGSize.width &&
      sourceIMGSize.width
    ) {
      setRatio_normalizer_x(targetIMGSize.width / sourceIMGSize.width)
      setRatio_normalizer_y(targetIMGSize.height / sourceIMGSize.height)
      setLoaded(true)
    } else {
      setLoaded(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceGeometries, error, targetIMGSize, sourceIMGSize])
  const updateSelectedObjects = () => {
    if (sourceGeometries) {
      setSelectedPoints(sourceGeometries.points.filter((el) => !el.unselected))
      setSelectedMasks(sourceGeometries.masks.filter((el) => !el.unselected))
      setSelectedCircles(
        sourceGeometries.circles.filter((el) => !el.unselected)
      )
      setSelectedPolygons(
        sourceGeometries.polygons.filter((el) => !el.unselected)
      )
      setSelectedSquares(
        sourceGeometries.squares.filter((el) => !el.unselected)
      )
    }
  }
  useEffect(() => {
    updateSelectedObjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceGeometries])
  const triggerPaste = async () => {
    let rescaledPoints = [...selectedPoints]
    let rescaledPolygons = [...selectedPolygons]
    let rescaledMasks = [...selectedMasks]
    let rescaledCircles = [...selectedCircles]
    let rescaledSquares = [...selectedSquares]
    try {
      for (let i = 0; i < rescaledPoints.length; i++) {
        rescaledPoints[i].id = uuid()
        rescaledPoints[i].points[0] *= ratio_normalizer_x
        rescaledPoints[i].points[1] *= ratio_normalizer_y
      }
      for (let i = 0; i < rescaledPolygons.length; i++) {
        rescaledPolygons[i].id = uuid()
        for (let j = 0; j < rescaledPolygons[i].points.length; j++) {
          rescaledPolygons[i].points[j].id = uuid()
          rescaledPolygons[i].points[j].points[0] *= ratio_normalizer_x
          rescaledPolygons[i].points[j].points[1] *= ratio_normalizer_y
        }
      }
      for (let i = 0; i < rescaledMasks.length; i++) {
        rescaledMasks[i].id = uuid()
        for (let j = 0; j < rescaledMasks[i].points.length; j++) {
          rescaledMasks[i].points[j].id = uuid()
          rescaledMasks[i].points[j].points[0] *= ratio_normalizer_x
          rescaledMasks[i].points[j].points[1] *= ratio_normalizer_y
        }
        for (let k = 0; k < rescaledMasks[i].holes.length; k++) {
          rescaledMasks[i].holes[k].id = uuid()
          rescaledMasks[i].holes[k].points[0] *= ratio_normalizer_x
          rescaledMasks[i].holes[k].points[1] *= ratio_normalizer_y
        }
      }
      for (let i = 0; i < rescaledCircles.length; i++) {
        rescaledCircles[i].id = uuid()
        rescaledCircles[i].normal = rescaledCircles[i].normal ?? [0, 0, 1]
        rescaledCircles[i].center.points[0] *= ratio_normalizer_x
        rescaledCircles[i].center.points[1] *= ratio_normalizer_y
        rescaledCircles[i].radius *= ratio_normalizer_x
        rescaledCircles[i].ptOnCircle.points[0] *= ratio_normalizer_x
        rescaledCircles[i].ptOnCircle.points[1] *= ratio_normalizer_y
      }
      for (let i = 0; i < rescaledSquares.length; i++) {
        rescaledSquares[i].id = uuid()
        for (let j = 0; j < rescaledSquares[i].points.length; j++) {
          rescaledSquares[i].points[j].id = uuid()
          rescaledSquares[i].points[j].points[0] *= ratio_normalizer_x
          rescaledSquares[i].points[j].points[1] *= ratio_normalizer_y
        }
      }

      let payload = {
        points: [...(targetGeometries.points ?? []), ...rescaledPoints],
        polygons: [...(targetGeometries.polygons ?? []), ...rescaledPolygons],
        masks: [...(targetGeometries.masks ?? []), ...rescaledMasks],
        circles: [...(targetGeometries.circles ?? []), ...rescaledCircles],
        squares: [...(targetGeometries.squares ?? []), ...rescaledSquares],
      }
      await hmCom_target.saveGeoCompoData(
        params.target_pic.id,
        payload.points,
        payload.polygons,
        payload.masks,
        payload.circles,
        payload.squares
      )

      const elts = rescaledCanvas.current.getElementsByClassName("hmk_mask")
      Array.from(elts).forEach((e) => {
        e.setAttribute("stroke", "none")
        e.setAttribute("opacity", 1.0)
        const handles = e.getElementsByClassName("hmk_handle")
        Array.from(handles).forEach((h) => h.remove())
      })
      const handles =
        rescaledCanvas.current.getElementsByClassName("hmk_handle")
      Array.from(handles).forEach((h) => h.remove())

      const extension = ".png"
      let blob = await buildPictureFromSVG(rescaledCanvas.current)
      const picName = params.target_pic.src
      const blobName = picName.substr(0, picName.lastIndexOf("."))
      blob.name = blobName.endsWith("_gc")
        ? blobName + extension
        : blobName + "_gc" + extension
      let metadata = { tags: ["geom_compo"] }
      const resp = await HMFileIO().uploadFileXHR(
        blob,
        params.crtDirId,
        metadata
      )
      const md = { ...params.target_metadata, geom_compo_id: resp.id }
      await HMFileIO().updateFileMetadata(md, params.target_pic.id)
      await params.updatePictureGeomCompo(params.target_pic, resp.id)
      params.onCancel()
    } catch (e) {
      console.warn(e)
      setError(`Error : ${JSON.stringify(e)}`)
    }
  }

  const Option = ({ callback, type, tracker, lengthLimit }) => {
    return (
      <div
        className={`${
          tracker ? "paste_option_active" : "paste_option_inactive"
        } paste_option`}
        style={{ cursor: lengthLimit ? "pointer" : "not-allowed" }}
        onClick={(e) => {
          e.stopPropagation()
          callback("toggle", type)
        }}
      >
        <div
          className={`paste_option_adjustment ${
            tracker.length &&
            "paste_option_adjustment_available paste_option_adjustment_left"
          }`}
          style={{
            marginRight: "5px",
            cursor: tracker.length ? "" : "not-allowed",
          }}
          onClick={(e) => {
            e.stopPropagation()
            tracker.length && callback("subtract", type)
          }}
        >
          <FiMinus size="1rem" />
        </div>
        <span
          style={{
            color:
              tracker.length === lengthLimit && lengthLimit
                ? "#4EC990"
                : tracker.length
                ? "#FFFFFF"
                : "#CCCCCC",
          }}
        >
          {tracker.length}/{lengthLimit} {type}
        </span>
        <div
          className={`paste_option_adjustment ${
            tracker.length < lengthLimit &&
            "paste_option_adjustment_available paste_option_adjustment_right"
          }`}
          style={{
            marginLeft: "5px",
            cursor: tracker.length < lengthLimit ? "" : "not-allowed",
          }}
          onClick={(e) => {
            e.stopPropagation()
            if (tracker.length < lengthLimit) {
              callback("add", type)
            }
          }}
        >
          <FiPlus size="1rem" />
        </div>
      </div>
    )
  }
  const callbackHandler = (operator, type) => {
    if (operator === "subtract" || operator === "add") {
      let state = Boolean(operator === "subtract")
      for (let i = sourceGeometries[type].length - 1; i >= 0; i--) {
        if (Boolean(sourceGeometries[type][i].unselected) !== state) {
          sourceGeometries[type][i].unselected = state
          break
        }
      }
    } else if (operator === "toggle") {
      let selected = 0
      for (let i = sourceGeometries[type].length - 1; i >= 0; i--) {
        if (!sourceGeometries[type][i].unselected) {
          selected++
        }
      }
      let state = Boolean(selected >= sourceGeometries[type].length / 2)
      for (let j = 0; j < sourceGeometries[type].length; j++) {
        sourceGeometries[type][j].unselected = state
      }
    }
    updateSelectedObjects()
  }
  const selectFeedback = (id, type) => {
    let accessor = type + "s"
    sourceGeometries[accessor][id].unselected = !Boolean(
      sourceGeometries[accessor][id].unselected
    )
    updateSelectedObjects()
  }
  const id_discriminant = (idx, arr) => {
    return selectedObject
      ? Boolean(
          idx === arr.findIndex((el) => el.id === selectedObject?.split("#")[0])
        )
      : false
  }

  return (
    <>
      <div className="modal-text">
        {loaded && (
          <div className="paste_option_wrapper">
            <Option
              type="points"
              tracker={selectedPoints}
              lengthLimit={sourceGeometries.points.length}
              callback={(operator, type) => callbackHandler(operator, type)}
            />
            <Option
              type="polygons"
              tracker={selectedPolygons}
              lengthLimit={sourceGeometries.polygons.length}
              callback={(operator, type) => callbackHandler(operator, type)}
            />
            <Option
              type="masks"
              tracker={selectedMasks}
              lengthLimit={sourceGeometries.masks.length}
              callback={(operator, type) => callbackHandler(operator, type)}
            />
            <Option
              type="circles"
              tracker={selectedCircles}
              lengthLimit={sourceGeometries.circles.length}
              callback={(operator, type) => callbackHandler(operator, type)}
            />
            <Option
              type="squares"
              tracker={selectedSquares}
              lengthLimit={sourceGeometries.squares.length}
              callback={(operator, type) => callbackHandler(operator, type)}
            />
          </div>
        )}
        {!loaded && !error && (
          <>
            <Loader rotate />
            <div style={{ height: 50 }} />
          </>
        )}
        {error && <p>ERROR</p>}
      </div>
      <div className="form-row">
        <button
          className="text-center btn col-2 offset-3 px-2"
          onClick={() => triggerPaste()}
          disabled={!loaded || error}
        >
          Paste
        </button>
        <button
          className="text-center btn col-2 offset-2 px-2"
          onClick={params.onCancel}
        >
          {"Cancel"}
        </button>
      </div>
      <div className="paste_render_container">
        <img
          ref={imgRef}
          className="paste_source_img"
          src={params.target_pic.src}
          alt="target"
        />
        {params.target_pic.geomSrc?.datafile && (
          <img
            className="paste_source_geom"
            src={params.target_pic.geomSrc.datafile}
            alt="target"
          />
        )}
        {loaded && (
          <div className="paste_interactive_geom_container">
            <svg ref={svgCanvas}>
              {deltaRatio_x && deltaRatio_y && (
                <g>
                  <g>
                    {sourceGeometries?.masks &&
                      sourceGeometries.masks.map((mask, id) => {
                        return (
                          <RenderMask
                            key={id}
                            mask={mask}
                            editMode={false}
                            selected={id_discriminant(
                              id,
                              sourceGeometries.masks
                            )}
                            onPointSelected={setSelectedObject}
                            selectedObject={selectedObject}
                            overrideProperty={{
                              deltaRatio_x: deltaRatio_x,
                              deltaRatio_y: deltaRatio_y,
                              clickCallback: () => selectFeedback(id, "mask"),
                            }}
                          />
                        )
                      })}
                  </g>
                  <g>
                    {sourceGeometries?.points &&
                      sourceGeometries.points.map((point3d, id) => {
                        return (
                          <React.Fragment key={id}>
                            <RenderSvgPoint
                              point={[
                                point3d.points[0] * deltaRatio_x,
                                point3d.points[1] * deltaRatio_y,
                              ]}
                              label_position={point3d?.label_position ?? "NE"}
                              label_size={25} // default: 48
                              label={point3d.name}
                              color={point3d.color}
                              text_bg_color={point3d.text_bg_color}
                              id={point3d.id}
                              zTest={true}
                              editMode={false}
                              selected={id_discriminant(
                                id,
                                sourceGeometries.points
                              )}
                              onPointSelected={setSelectedObject}
                              selectedObject={selectedObject}
                              overrideProperty={{
                                deltaRatio_x: deltaRatio_x,
                                deltaRatio_y: deltaRatio_y,
                                unselected: point3d.unselected,
                                clickCallback: () =>
                                  selectFeedback(id, "point"),
                              }}
                            />
                          </React.Fragment>
                        )
                      })}
                  </g>
                  <g>
                    {sourceGeometries?.polygons &&
                      sourceGeometries.polygons.map((polygon, id) => {
                        return (
                          <React.Fragment key={id}>
                            <RenderPolygon
                              zTest={true} /**prevent dash */
                              polygon={polygon}
                              selected={id_discriminant(
                                id,
                                sourceGeometries.polygons
                              )}
                              onPointSelected={setSelectedObject}
                              selectedObject={selectedObject}
                              overrideProperty={{
                                deltaRatio_x: deltaRatio_x,
                                deltaRatio_y: deltaRatio_y,
                                strokeWidth: 3,
                                clickCallback: () =>
                                  selectFeedback(id, "polygon"),
                              }}
                              showHandle
                            />
                          </React.Fragment>
                        )
                      })}
                  </g>
                  <g>
                    {sourceGeometries?.circles &&
                      sourceGeometries?.circles.map((circle, id) => {
                        return (
                          <RenderSvgDirectCircle
                            key={id}
                            circle={circle}
                            editMode={false}
                            selected={id_discriminant(
                              id,
                              sourceGeometries.circles
                            )}
                            onPointSelected={setSelectedObject}
                            selectedObject={selectedObject}
                            overrideProperty={{
                              deltaRatio_x: deltaRatio_x,
                              deltaRatio_y: deltaRatio_y,
                              strokeWidth: 3,
                              clickCallback: () => selectFeedback(id, "circle"),
                            }}
                          />
                        )
                      })}
                  </g>
                  <g>
                    {sourceGeometries?.squares &&
                      sourceGeometries?.squares.map((square, id) => {
                        return (
                          <RenderSquare
                            key={id}
                            square={square}
                            editMode={false}
                            selected={id_discriminant(
                              id,
                              sourceGeometries.squares
                            )}
                            onPointSelected={setSelectedObject}
                            selectedObject={selectedObject}
                            overrideProperty={{
                              deltaRatio_x: deltaRatio_x,
                              deltaRatio_y: deltaRatio_y,
                              strokeWidth: 3,
                              borderRadius: 3,
                              clickCallback: () => selectFeedback(id, "square"),
                            }}
                          />
                        )
                      })}
                  </g>
                </g>
              )}
            </svg>
          </div>
        )}
        {loaded && (
          <div className="paste_ghost_geom_container">
            <svg ref={rescaledCanvas}>
              {ratio_normalizer_x && ratio_normalizer_y && (
                <g>
                  <g>
                    {targetGeometries?.masks &&
                      targetGeometries.masks.map((mask, id) => {
                        return (
                          <RenderMask
                            key={id}
                            mask={mask}
                            editMode={false}
                            selected={false}
                            selectedObject={null}
                            overrideProperty={{
                              noEvent: true,
                            }}
                          />
                        )
                      })}
                    {selectedMasks &&
                      selectedMasks.map((mask, id) => {
                        return (
                          <RenderMask
                            key={id}
                            mask={mask}
                            editMode={false}
                            selected={false}
                            selectedObject={null}
                            overrideProperty={{
                              deltaRatio_x: ratio_normalizer_x,
                              deltaRatio_y: ratio_normalizer_y,
                              noEvent: true,
                            }}
                          />
                        )
                      })}
                  </g>
                  <g>
                    {targetGeometries?.points &&
                      targetGeometries?.points.map((point3d, id) => {
                        return (
                          <React.Fragment key={id}>
                            <RenderSvgPoint
                              point={[point3d.points[0], point3d.points[1]]}
                              label_position={point3d?.label_position ?? "NE"}
                              label={point3d.name}
                              color={point3d.color}
                              text_bg_color={point3d.text_bg_color}
                              id={point3d.id}
                              zTest={true}
                              editMode={false}
                              selected={false}
                              selectedObject={null}
                              overrideProperty={{
                                noEvent: true,
                              }}
                            />
                          </React.Fragment>
                        )
                      })}
                    {selectedPoints &&
                      selectedPoints.map((point3d, id) => {
                        return (
                          <React.Fragment key={id}>
                            <RenderSvgPoint
                              point={[
                                point3d.points[0] * ratio_normalizer_x,
                                point3d.points[1] * ratio_normalizer_y,
                              ]}
                              label_position={point3d?.label_position ?? "NE"}
                              label_size={
                                point3d.label_size ?? DEFAULT_LABEL_SIZE
                              }
                              label={point3d.name}
                              color={point3d.color}
                              text_bg_color={point3d.text_bg_color}
                              id={point3d.id}
                              zTest={true}
                              editMode={false}
                              selected={false}
                              selectedObject={null}
                              overrideProperty={{
                                noEvent: true,
                              }}
                            />
                          </React.Fragment>
                        )
                      })}
                  </g>
                  <g>
                    {targetGeometries?.polygons &&
                      targetGeometries?.polygons.map((polygon, id) => {
                        return (
                          <React.Fragment key={id}>
                            <RenderPolygon
                              polygon={polygon}
                              selected={false}
                              selectedObject={false}
                              overrideProperty={{
                                noEvent: true,
                              }}
                            />
                          </React.Fragment>
                        )
                      })}
                    {selectedPolygons &&
                      selectedPolygons.map((polygon, id) => {
                        return (
                          <React.Fragment key={id}>
                            <RenderPolygon
                              polygon={polygon}
                              selected={false}
                              selectedObject={false}
                              overrideProperty={{
                                deltaRatio_x: ratio_normalizer_x,
                                deltaRatio_y: ratio_normalizer_y,
                                noEvent: true,
                              }}
                            />
                          </React.Fragment>
                        )
                      })}
                  </g>
                  <g>
                    {targetGeometries?.circles &&
                      targetGeometries?.circles.map((circle, id) => {
                        return (
                          <RenderSvgDirectCircle
                            key={id}
                            circle={circle}
                            editMode={false}
                            selected={false}
                            selectedObject={null}
                            overrideProperty={{
                              noEvent: true,
                            }}
                          />
                        )
                      })}
                    {selectedCircles &&
                      selectedCircles.map((circle, id) => {
                        return (
                          <RenderSvgDirectCircle
                            key={id}
                            circle={circle}
                            editMode={false}
                            selected={false}
                            selectedObject={null}
                            overrideProperty={{
                              deltaRatio_x: ratio_normalizer_x,
                              deltaRatio_y: ratio_normalizer_y,
                              noEvent: true,
                            }}
                          />
                        )
                      })}
                  </g>
                  <g>
                    {targetGeometries?.squares &&
                      targetGeometries?.squares.map((square, id) => {
                        return (
                          <RenderSquare
                            key={id}
                            square={square}
                            editMode={false}
                            selected={false}
                            selectedObject={null}
                            overrideProperty={{
                              noEvent: true,
                            }}
                          />
                        )
                      })}
                    {selectedSquares &&
                      selectedSquares.map((square, id) => {
                        return (
                          <RenderSquare
                            key={id}
                            square={square}
                            editMode={false}
                            selected={false}
                            selectedObject={null}
                            overrideProperty={{
                              deltaRatio_x: ratio_normalizer_x,
                              deltaRatio_y: ratio_normalizer_y,
                              noEvent: true,
                            }}
                          />
                        )
                      })}
                  </g>
                </g>
              )}
            </svg>
          </div>
        )}
      </div>
    </>
  )
}
