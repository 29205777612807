import React, { useEffect, useRef, useState, useContext, useMemo } from "react"
import { ComposerContext } from "../../Contexts"
import RenderSvgPoint from "./RenderSVGPoint"
import RenderPolygon from "./RenderPolygon"
import RenderSvgDirectCircle from "./RenderSVGDirectCircle"
import RenderSquare from "./RenderSquare"
import RenderMask from "./RenderMask"
import {
  DEFAULT_LABEL_SIZE,
  LABEL_BG_DEFAULT,
  DEFAULT_POLYGON_STROKE_WIDTH,
} from "./Constants"

const DebugRenderVisibleVertices = ({ vertices, imagePicker, objIndex }) => {
  console.log(`DEBUG Render Visible Vertices: `, vertices, objIndex)
  return objIndex
    ? vertices[objIndex].map((vertex, id) => {
        let { point2d, zTest } = imagePicker.compute2DFrom3DZTest(vertex)
        return zTest ? (
          <circle
            cx={point2d[0]}
            cy={point2d[1]}
            r="1"
            style={{
              fill: "white",
            }}
            key={id}
          />
        ) : null
      })
    : vertices.map((vl) =>
        vl.map((vertex, id) => {
          let { point2d, zTest } = imagePicker.compute2DFrom3DZTest(vertex)
          return zTest ? (
            <circle
              cx={point2d[0]}
              cy={point2d[1]}
              r="1"
              style={{
                fill: "white",
              }}
              key={id}
            />
          ) : null
        })
      )
}

const RenderGeometries = ({
  crtPoints,
  imagePicker,
  checkedObjects,
  focusedObject,
  filteredGeometries,
  onPointSelected,
  keyword,
}) => {
  const composerContext = useContext(ComposerContext)
  const filtering = useMemo(() => {
    return !Object.values(filteredGeometries).every((g) => !g)
  }, [filteredGeometries])
  const masks = (
    <g id="masks">
      {Boolean(!filtering || (filtering && filteredGeometries.masks)) && (
        <RenderSvgMasks
          svgMasks={composerContext.stateData.masks}
          editMode={composerContext?.currentEditMode}
          selectedObject={focusedObject}
          checkedObjects={checkedObjects}
          onPointSelected={onPointSelected}
          keyword={keyword}
        />
      )}
    </g>
  )
  const squares = (
    <g id="squares">
      {Boolean(!filtering || (filtering && filteredGeometries.squares)) && (
        <RenderSvgSquares
          svgSquares={composerContext.stateData.squares}
          editMode={composerContext?.currentEditMode}
          selectedObject={focusedObject}
          checkedObjects={checkedObjects}
          onPointSelected={onPointSelected}
          keyword={keyword}
        />
      )}
    </g>
  )
  const polygons = (
    <g id="polygons">
      {Boolean(!filtering || (filtering && filteredGeometries.polygons)) && (
        <RenderSvgPolygons
          svgPolygons={composerContext.stateData.polygons}
          editMode={composerContext?.currentEditMode}
          imagePicker={imagePicker}
          selectedObject={focusedObject}
          checkedObjects={checkedObjects}
          onPointSelected={onPointSelected}
          keyword={keyword}
        />
      )}
    </g>
  )
  const circles = (
    <g id="circles">
      {Boolean(!filtering || (filtering && filteredGeometries.circles)) && (
        <>
          {composerContext?.menuState?.mode3D ? (
            <RenderSvgDiscreteCircles
              svgCircles={composerContext.stateData.circles}
              imagePicker={imagePicker}
              selectedObject={focusedObject}
              checkedObjects={checkedObjects}
              keyword={keyword}
            />
          ) : (
            <RenderSvgDirectCircles
              svgCircles={composerContext.stateData.circles}
              editMode={composerContext?.currentEditMode}
              selectedObject={focusedObject}
              checkedObjects={checkedObjects}
              onPointSelected={onPointSelected}
              keyword={keyword}
            />
          )}
        </>
      )}
    </g>
  )
  const points = (
    <g id="points">
      {Boolean(!filtering || (filtering && filteredGeometries.points)) && (
        <RenderSvgPoints
          svgPoints={composerContext.stateData.points}
          editMode={composerContext?.currentEditMode}
          imagePicker={imagePicker}
          selectedObject={focusedObject}
          checkedObjects={checkedObjects}
          onPointSelected={onPointSelected}
          keyword={keyword}
        />
      )}
    </g>
  )

  return (
    <g id="geometries">
      {/*
       * DYNAMIC RENDERING ORDER
       * DISABLED TEMPORARILY FOR MINOR PERFORMANCE ISSUES TO RESOLVE
       * TODO : controller name form order should be the rendering order.
       */
      /*orders().map((obj, i) => (
          <React.Fragment key={i}>{obj}</React.Fragment>
        ))*/}
      {masks}
      {polygons}
      {squares}
      {circles}
      {points}
      <g id="current_points">
        {composerContext.menuState.editModes.polygon ? (
          <RenderSvgPolygon svgPoints={crtPoints} imagePicker={imagePicker} />
        ) : crtPoints.length > 1 &&
          (composerContext.menuState.editModes.selection ||
            composerContext.menuState.editModes.crop ||
            composerContext.menuState.editModes.square ||
            composerContext.menuState.editModes.mask) ? (
          <RenderSelectionBox svgPoints={crtPoints} />
        ) : (
          crtPoints.length > 1 &&
          composerContext.menuState.editModes.circle && (
            <RenderSelectionCircle svgPoints={crtPoints} />
          )
        )}
        <RenderSvgCrtPoints
          svgPoints={crtPoints}
          imagePicker={imagePicker}
          onPointSelected={onPointSelected}
        />
      </g>
    </g>
  )
}

const RenderSvgPolygons = ({
  svgPolygons,
  editMode,
  imagePicker,
  selectedObject,
  checkedObjects,
  onPointSelected,
  keyword,
}) => {
  let selectedIdx = svgPolygons.findIndex(
    (el) => el.id === selectedObject?.split("#")[0]
  )
  return (
    <>
      {svgPolygons
        .filter((p) => Boolean(!keyword || p.name.includes(keyword)))
        .map((polygon, idx) => {
          const aaa = polygon.points.map((point3d) =>
            imagePicker.compute2DFrom3DZTest(point3d.points)
          )
          const zTest = aaa.reduce((res, val) => res && val.zTest, true)
          const pts = aaa.map((a) => a.point2d).join(" ")
          const selected = Boolean(
            idx === selectedIdx || checkedObjects?.includes(polygon.id)
          )
          return (
            <RenderPolygon
              key={polygon.id}
              editMode={editMode}
              override_pts={pts}
              zTest={zTest}
              polygon={polygon}
              selected={selected}
              onPointSelected={onPointSelected}
              selectedObject={Boolean(selectedObject || checkedObjects?.length)}
            />
          )
        })}
    </>
  )
}

const RenderSvgMasks = ({
  svgMasks,
  editMode,
  selectedObject,
  onPointSelected,
  checkedObjects,
  keyword,
}) => {
  let selectedIdx = svgMasks.findIndex(
    (el) => el.id === selectedObject?.split("#")[0]
  )

  return svgMasks
    .filter((mask) => Boolean(!keyword || mask.name.includes(keyword)))
    .map((mask, idx) => {
      return (
        <RenderMask
          key={mask.id}
          mask={mask}
          editMode={editMode}
          selected={
            idx === selectedIdx || Boolean(checkedObjects?.includes(mask.id))
          }
          onPointSelected={onPointSelected}
          selectedObject={Boolean(selectedObject || checkedObjects?.length)}
        />
      )
    })
}

const RenderSvgSquares = ({
  svgSquares,
  editMode,
  selectedObject,
  checkedObjects,
  onPointSelected,
  keyword,
}) => {
  let selectedIdx = svgSquares.findIndex(
    (el) => el.id === selectedObject?.split("#")[0]
  )
  return svgSquares
    .filter((square) => Boolean(!keyword || square.name.includes(keyword)))
    .map((square, idx) => {
      return (
        <RenderSquare
          key={square.id}
          square={square}
          editMode={editMode}
          selected={
            idx === selectedIdx || Boolean(checkedObjects?.includes(square.id))
          }
          onPointSelected={onPointSelected}
          selectedObject={Boolean(selectedObject || checkedObjects?.length)}
        />
      )
    })
}

const RenderSvgPolygon = ({ svgPoints, imagePicker }) => {
  let data2d = svgPoints.map(
    (point3d) => imagePicker.compute2DFrom3D(point3d).point2d
  )
  return (
    <polyline
      points={data2d.join(" ")}
      style={{
        fill: "none",
        stroke: "white",
        strokeWidth: DEFAULT_POLYGON_STROKE_WIDTH + "px",
      }}
    />
  )
}

const RenderSvgDiscreteCircles = ({
  svgCircles,
  imagePicker,
  selectedObject,
  checkedObjects,
  keyword,
}) => {
  return svgCircles
    .filter((circle) => Boolean(!keyword || circle.name.includes(keyword)))
    .map((circle) => {
      let selected = Boolean(
        circle.id === selectedObject || checkedObjects?.includes(circle.id)
      )
      const { points, zTest } =
        imagePicker.computeDiscreteCircle2DFrom3DZTest(circle)
      return (
        <polygon
          points={points.join(" ")}
          style={{
            fill: "none",
            stroke: selected ? "red" : zTest ? "white" : "grey",
            strokeWidth: 2 + "px",
            strokeDasharray: zTest ? "" : "5",
          }}
          key={circle.id}
        />
      )
    })
}

const RenderSvgDirectCircles = ({
  svgCircles,
  editMode,
  selectedObject,
  checkedObjects,
  onPointSelected,
  keyword,
}) => {
  return svgCircles
    .filter((circle) => Boolean(!keyword || circle.name.includes(keyword)))
    .map((circle) => (
      <RenderSvgDirectCircle
        key={circle.id}
        circle={circle}
        editMode={editMode}
        selected={Boolean(
          selectedObject?.split("#")[0] === circle.id ||
            Boolean(checkedObjects?.includes(circle.id))
        )}
        onPointSelected={onPointSelected}
        selectedObject={Boolean(selectedObject || checkedObjects?.length)}
      />
    ))
}

/**
 * Render a 3D defined circle list in form of SVG ellipses
 * (not used anymore)
 * @param {list} svgCircles 3d defined circle list
 * @param {object} imagePicker
 * @param {object} selectedObject
 * @returns an svg object representing the projection of this 3d circle on screen
 */
/*
const RenderSvgCircles = ({ svgCircles, imagePicker, selectedObject }) => {
  return svgCircles.map((circle, id) => {
    let selected = circle.id === selectedObject
    const [center2D, , rx, ry] = imagePicker.computeCircle2DFrom3D(circle)
    return (
      <ellipse
        cx={center2D[0]}
        cy={center2D[1]}
        rx={rx}
        ry={ry}
        style={{
          fill: "none",
          stroke: selected ? "red" : "white",
          strokeWidth: 2 + "px",
        }}
        key={id}
      />
    )
  })
}
*/

const RenderSvgPoints = ({
  editMode,
  svgPoints,
  imagePicker,
  selectedObject,
  checkedObjects,
  onPointSelected,
  keyword,
}) => {
  let selectedIdx = svgPoints.findIndex(
    (el) => el.id === selectedObject?.split("#")[0]
  )
  return svgPoints
    .filter((point3d) => Boolean(!keyword || point3d.name.includes(keyword)))
    .map((point3d, idx) => {
      //const _label_position = point3d?.labelPos ?? "NE"
      const { point2d, zTest } = imagePicker.compute2DFrom3DZTest(
        point3d.points
      )
      return (
        <RenderSvgPoint
          key={point3d.id}
          point={point2d}
          label_position={point3d.label_position ?? "NE"}
          label_size={point3d.label_size ?? DEFAULT_LABEL_SIZE}
          label={point3d.name}
          color={point3d.color}
          label_bg_color={point3d.label_bg_color ?? LABEL_BG_DEFAULT}
          id={point3d.id}
          zTest={zTest}
          editMode={editMode}
          selected={
            idx === selectedIdx || Boolean(checkedObjects?.includes(point3d.id))
          }
          onPointSelected={onPointSelected}
          selectedObject={Boolean(selectedObject || checkedObjects?.length)}
        />
      )
    })
}

const RenderSvgCrtPoints = ({ svgPoints, imagePicker, onPointSelected }) => {
  const pts = useRef([])
  // update pts list
  useEffect(() => {
    pts.current = pts.current.slice(0, svgPoints.length)
  }, [svgPoints])
  // register mouse event on displayed svg objects
  useEffect(() => {
    const mouseOver = (event) => {
      event.target.setAttribute("fill", "red")
      if (onPointSelected) onPointSelected(event.target.id)
    }
    const mouseOut = (event) => {
      event.target.setAttribute("fill", "white")
      if (onPointSelected) onPointSelected(null)
    }
    pts.current.forEach((p) => {
      p.addEventListener("mouseenter", mouseOver)
      p.addEventListener("mouseleave", mouseOut)
    })
    return () => {
      pts.current.forEach((p) => {
        if (p) {
          p.removeEventListener("mouseenter", mouseOver)
          p.removeEventListener("mouseleave", mouseOut)
        }
      })
    }
  }, [onPointSelected, svgPoints])

  let data2d = svgPoints.map(
    (point3d) => imagePicker.compute2DFrom3D(point3d).point2d
  )
  return data2d.map((point, id) => (
    <circle
      className="hmk_handle"
      id={`#${id}`}
      key={id}
      ref={(el) => (pts.current[id] = el)}
      cx={point[0]}
      cy={point[1]}
      r="5"
      fill="white"
      stroke="black"
      strokeWidth={1}
    />
  ))
}

const RenderSelectionBox = ({ svgPoints }) => {
  const x = Math.min(svgPoints[0][0], svgPoints[1][0])
  const y = Math.min(svgPoints[0][1], svgPoints[1][1])
  const w = Math.abs(svgPoints[1][0] - svgPoints[0][0])
  const h = Math.abs(svgPoints[1][1] - svgPoints[0][1])
  return (
    <g stroke="orange" strokeWidth={2} fill="none">
      <rect x={x} y={y} width={w} height={h} />
    </g>
  )
}
const RenderSelectionCircle = ({ svgPoints }) => {
  const w = Math.abs(svgPoints[1][0] - svgPoints[0][0])
  const h = Math.abs(svgPoints[1][1] - svgPoints[0][1])
  let radius = Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2))
  if (radius < 10) radius = 10
  return (
    <g stroke="orange" strokeWidth={2} fill="none">
      <circle cx={svgPoints[0][0]} cy={svgPoints[0][1]} r={radius} />
    </g>
  )
}

const DEFAULT_POINT_LIST = [
  [0, 0],
  [0, 0],
]
const RenderSVGSelector = ({ svg, setSelector, image_id = null }) => {
  const DEBUG = false
  const svgPt = useRef(null)
  const svgElts = useRef([])
  const [dragStart, setDragStart] = useState(null)
  const [pointList, setPointList] = useState(DEFAULT_POINT_LIST)
  const [selectedElt, setSelectedElt] = useState(null)

  useEffect(() => {
    return () => {
      svgElts.current = []
      setSelectedElt(null)
      setPointList(DEFAULT_POINT_LIST)
      setDragStart(null)
    }
  }, [image_id])

  // initialize selectionnable svg elements reference array
  useEffect(() => {
    // process mouse action
    let lastPos = undefined
    const mouseMove = (event) => {
      if (event.buttons !== 1) {
        return
      }
      DEBUG &&
        console.log(`RenderSVGSelector: move from element `, event.target)
      const newPos = getMousePosition(event)
      event.stopPropagation()
      setPointList((oldPointList) => {
        if (selectedElt) {
          const [, ptId] = selectedElt.split("#")
          DEBUG && console.log(`selected = ${ptId}`)
          if (ptId === "1" || ptId === "2") {
            // in this case we move the crtPoints data
            let newPointList = [...oldPointList]
            const id = parseInt(ptId)
            newPointList[id - 1] = newPos
            return newPointList
          } else if (ptId === "3") {
            // in this case we move the crtPoints data
            if (!lastPos) lastPos = dragStart
            let newPointList = [...oldPointList]
            const dx = newPos[0] - lastPos[0]
            const dy = newPos[1] - lastPos[1]
            newPointList[0][0] += dx
            newPointList[0][1] += dy
            newPointList[1][0] += dx
            newPointList[1][1] += dy
            lastPos = newPos
            return newPointList
          }
        } else {
          return [oldPointList[0], newPos]
        }
      })
    }
    DEBUG &&
      console.log("RenderSVGSelector: useFX on svg/selected: ", selectedElt)
    if (svg) {
      svgPt.current = svg.createSVGPoint()
      svg.addEventListener("mousemove", mouseMove)
      return () => {
        svg.removeEventListener("mousemove", mouseMove)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svg, selectedElt, dragStart])

  // initialize mouse event listeners
  useEffect(() => {
    const mouseOver = (event) => {
      DEBUG && console.log(`Mouse over on element `, event.target)
      event.target.setAttribute("fill", "red")
    }
    const mouseOut = (event) => {
      DEBUG && console.log(`Mouse out from element `, event.target)
      event.target.removeAttribute("fill")
    }
    // mouse start action with left mouse button
    const mouseDown = (event) => {
      if (event.button !== 0) {
        return
      }
      DEBUG &&
        console.log(`RenderSVGSelector: down from element `, event.target)
      const pos = getMousePosition(event)
      if (event.target.id.startsWith("hmk_selector_point"))
        setSelectedElt(event.target.id)
      else setPointList([pos, pos])
      setDragStart(pos)
      event.stopPropagation()
    }
    // end mouse action
    const mouseUp = (event) => {
      if (event.button !== 0) {
        return
      }
      let x1, y1, x2, y2
      setPointList((oldPointList) => {
        DEBUG &&
          console.log(`RenderSVGSelector: up with pointList `, oldPointList)
        x1 = Math.min(oldPointList[0][0], oldPointList[1][0])
        y1 = Math.min(oldPointList[0][1], oldPointList[1][1])
        x2 = Math.max(oldPointList[0][0], oldPointList[1][0])
        y2 = Math.max(oldPointList[0][1], oldPointList[1][1])
        if (!(Math.min(x2 - x1, y2 - y1) > 3)) {
          svgElts.current = []
          x1 = 0
          y1 = 0
          x2 = 0
          y2 = 0
        }
        return [
          [x1, y1],
          [x2, y2],
        ]
      })
      setSelectedElt(null)
      setDragStart(null)
      if (x1 || y1 || x2 || y2)
        setSelector([
          [x1, y1],
          [x2, y2],
        ])
      else setSelector(null)
    }

    DEBUG && console.log(`RenderSVGSelector: useFX on mount`)
    const localSvgElts = svgElts.current
    localSvgElts.forEach((p) => {
      p.addEventListener("mouseenter", mouseOver)
      p.addEventListener("mouseleave", mouseOut)
    })
    svg.addEventListener("mousedown", mouseDown)
    svg.addEventListener("mouseup", mouseUp)
    return () => {
      localSvgElts.forEach((p) => {
        if (p) {
          p.removeEventListener("mouseenter", mouseOver)
          p.removeEventListener("mouseleave", mouseOut)
        }
      })
      svg.removeEventListener("mousedown", mouseDown)
      svg.removeEventListener("mouseup", mouseUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svg, setSelector])

  const getMousePosition = (evt) => {
    svgPt.current.x = evt.clientX
    svgPt.current.y = evt.clientY
    svgPt.current = svgPt.current.matrixTransform(svg.getScreenCTM().inverse())
    return [svgPt.current.x, svgPt.current.y]
  }

  DEBUG && console.log(`RenderSVGSelector: render... pointList: `, pointList)
  const x = Math.min(pointList[0][0], pointList[1][0])
  const y = Math.min(pointList[0][1], pointList[1][1])
  const w = Math.abs(pointList[1][0] - pointList[0][0])
  const h = Math.abs(pointList[1][1] - pointList[0][1])
  const cx = x + w / 2
  const cy = y + h / 2
  const sized = Math.min(w, h) > 3
  return (
    <g
      className="hmk_selector"
      display={dragStart || sized ? "initial" : "none"}
    >
      <g stroke="orange" strokeWidth={2} fill="none">
        <rect x={x} y={y} width={w} height={h} />
      </g>
      <g stroke="black" strokeWidth={1} fill="white">
        <circle
          className="hmk_handle"
          id="hmk_selector_point#1"
          ref={(el) => (svgElts.current[0] = el)}
          cx={x}
          cy={y}
          r="5"
        />
        <circle
          className="hmk_handle"
          id="hmk_selector_point#2"
          ref={(el) => (svgElts.current[1] = el)}
          cx={x + w}
          cy={y + h}
          r="5"
        />
        <rect
          className="hmk_handle"
          id="hmk_selector_point#3"
          ref={(el) => (svgElts.current[2] = el)}
          x={x + w - 5}
          y={y - 5}
          width="10"
          height="10"
        />
        <line
          stroke="white"
          className="hmk_selector_center1"
          id="hmk_selector_center1"
          x1={cx}
          y1={cy - 5}
          x2={cx}
          y2={cy + 5}
        />
        <line
          stroke="white"
          className="hmk_selector_center2"
          id="hmk_selector_center2"
          x1={cx - 5}
          y1={cy}
          x2={cx + 5}
          y2={cy}
        />
      </g>
    </g>
  )
}

const RenderPointInteractor = ({ svg, setPoints, points, image_id = null }) => {
  const DEBUG = false
  const svgPt = useRef(null)
  const svgElts = useRef([])
  const [selected, setSelected] = useState(null)
  const [pointList, setPointList] = useState([])

  useEffect(() => {
    return () => {
      svgElts.current = []
    }
  }, [image_id])

  useEffect(() => {
    setPointList(points)
  }, [points])

  // initialize selectionnable svg elements reference array
  useEffect(() => {
    // process mouse action
    const getMousePosition = (evt) => {
      svgPt.current.x = evt.clientX
      svgPt.current.y = evt.clientY
      svgPt.current = svgPt.current.matrixTransform(
        svg.getScreenCTM().inverse()
      )
      return [svgPt.current.x, svgPt.current.y]
    }

    const mouseMove = (event) => {
      const newPos = getMousePosition(event)
      if (event.buttons !== 1) {
        return
      }
      if (selected) {
        DEBUG &&
          console.log(
            `RenderPointInteractor: move from element ${event.target}`
          )
        const [, ptId] = selected.split("#")
        if (ptId) {
          setPointList((oldPointList) => {
            let newPointList = [...oldPointList]
            const id = parseInt(ptId)
            newPointList[id] = newPos
            return newPointList
          })
        }
      }
    }
    //svgElts.current = svgElts.current.slice(0, 3)
    if (svg) {
      svgPt.current = svg.createSVGPoint()
      svg.addEventListener("mousemove", mouseMove)
      return () => {
        svg.removeEventListener("mousemove", mouseMove)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svg, selected])

  // initialize mouse event listeners
  useEffect(() => {
    const mouseOver = (event) => {
      DEBUG && console.log(`Mouse over on element `, event.target)
      event.target.setAttribute("fill", "red")
    }
    const mouseOut = (event) => {
      DEBUG && console.log(`Mouse out from element `, event.target)
      event.target.removeAttribute("fill")
    }
    // mouse start action with left mouse button
    const mouseDown = (event) => {
      if (event.button !== 0) {
        return
      }
      DEBUG &&
        console.log(`RenderPointInteractor: down from element `, event.target)
      if (event.target.id.startsWith("hmk_interactor_point")) {
        setSelected(event.target.id)
      }
    }
    // end mouse action
    const mouseUp = (event) => {
      if (event.button !== 0) {
        return
      }
      if (selected) {
        DEBUG &&
          console.log(`RenderPointInteractor: up with pointList `, pointList)
        setPoints(pointList)
      }
      setSelected(null)
    }

    DEBUG &&
      console.log(
        `RenderPointInteractor: useFX on mount, svgElts = `,
        svgElts.current
      )
    const localSvgElts = svgElts.current
    localSvgElts.forEach((p) => {
      p.addEventListener("mouseenter", mouseOver)
      p.addEventListener("mouseleave", mouseOut)
    })
    svg.addEventListener("mousedown", mouseDown)
    svg.addEventListener("mouseup", mouseUp)
    return () => {
      localSvgElts.forEach((p) => {
        if (p) {
          p.removeEventListener("mouseenter", mouseOver)
          p.removeEventListener("mouseleave", mouseOut)
        }
      })
      svg.removeEventListener("mousedown", mouseDown)
      svg.removeEventListener("mouseup", mouseUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svg, setPoints, selected, pointList])

  DEBUG &&
    console.log(`RenderPointInteractor: render... pointList: `, pointList)
  return (
    <g className="hmk_point_interactor">
      <g stroke="white" strokeWidth={1} fill="white">
        {pointList.map((pt, i) => (
          <g key={i}>
            <rect
              width="20"
              height="20"
              x={pt[0] - 10}
              y={pt[1] - 25}
              stroke="none"
              fill="#77777770"
            />
            <circle
              className="hmk_handle"
              id={`hmk_interactor_point#${i}`}
              ref={(el) => (svgElts.current[i] = el)}
              cx={pt[0]}
              cy={pt[1]}
              r="5"
            />
            <text x={pt[0] - 5} y={pt[1] - 10}>
              {i + 1}
            </text>
          </g>
        ))}
      </g>
    </g>
  )
}

export {
  RenderGeometries,
  RenderSvgPolygons,
  DebugRenderVisibleVertices,
  RenderSelectionBox,
  RenderSVGSelector,
  RenderPointInteractor,
}
