import { useState, useEffect } from "react"

function safeParseJSON(jsonString) {
    try {
      return JSON.parse(jsonString)
    } catch (e) {
      return jsonString
    }
  }

/**
 * A custom hook that listens to changes in the sessionStorage for a specific key
 * @param {string} key
 * @returns {string | null}
 */
export function useSessionStorageListener(key) {
  // Initialize the state with the current value from sessionStorage
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key)
    return storedValue ? safeParseJSON(storedValue) : null
  })

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        // Update the state if the key changed is the one we're listening to
        setValue(event.newValue ? safeParseJSON(event.newValue) : null)
      }
    }

    // Add event listener for the 'storage' event on window
    window.addEventListener("storage", handleStorageChange)

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [key])

  return value
}
