import React, { useState, useEffect } from "react"
import { GithubPicker } from "react-color"
import Slider from "rc-slider"

import "./SliderStyle.css"
import "./HMKColorPicker.css"
const OpacitySlider = Slider.createSliderWithTooltip(Slider)

export const HMKColorPicker = ({
  onColorClick,
  onColorChange,
  className,
  style,
}) => {
  const [currentColor, setCurrentColor] = useState(null)
  const [sliderOpacity, setSliderOpacity] = useState(0)

  const handleOutput = (output) => {
    setCurrentColor({ ...output.rgb })
  }

  useEffect(
    () =>
      currentColor &&
      onColorChange({ ...currentColor, a: sliderOpacity / 100 }),
    [currentColor, sliderOpacity, onColorChange]
  )

  return (
    <div className={className} style={style} onClick={onColorClick}>
      <GithubPicker
        colors={[
          "#FF0000",
          "#F39C12",
          "#FFFF00",
          "#00FF00",
          "#00FFFF",
          "#7F7FFF",
          "#FF7FFF",
          "#FF00FF",
          "#FFFFFF",
          "#E0E0E0",
          "#C0C0C0",
          "#A0A0A0",
          "#808080",
          "#606060",
          "#404040",
          "#000000",
        ]}
        width="220px"
        triangle="top-right"
        onChange={handleOutput}
      />
      <div className="slider-container">
        <label>Opacity</label>
        <OpacitySlider
          min={0}
          max={100}
          step={5}
          value={sliderOpacity}
          tipFormatter={(value) => value + "%"}
          onChange={setSliderOpacity}
        />
      </div>
    </div>
  )
}
