import { StorageKeys } from "./AppMode"

const copyPictureGeom = (picture) => {
  sessionStorage.setItem(StorageKeys().CLIPBOARD_GEOM_SRC_KEY, picture.src)
  sessionStorage.setItem(StorageKeys().CLIPBOARD_GEOM_ID_KEY, picture.id)
  let name = picture.src ?? "unknown"
  if (name.includes("/")) {
    name = name.split("/")
    name = name[name.length - 1]
  }
  sessionStorage.setItem(StorageKeys().CLIPBOARD_GEOM_NAME_KEY, name)
  window.dispatchEvent(
    new StorageEvent("storage", {
      key: StorageKeys().CLIPBOARD_GEOM_SRC_KEY,
      newValue: picture.src,
    })
  )
  window.dispatchEvent(
    new StorageEvent("storage", {
      key: StorageKeys().CLIPBOARD_GEOM_ID_KEY,
      newValue: picture.id,
    })
  )
  window.dispatchEvent(
    new StorageEvent("storage", {
      key: StorageKeys().CLIPBOARD_GEOM_NAME_KEY,
      newValue: name,
    })
  )
}

const getPictureGeom = () => {
  const source_id = sessionStorage.getItem(StorageKeys().CLIPBOARD_GEOM_ID_KEY)
  const source_name = sessionStorage.getItem(
    StorageKeys().CLIPBOARD_GEOM_NAME_KEY
  )
  const source_imgsrc = sessionStorage.getItem(
    StorageKeys().CLIPBOARD_GEOM_SRC_KEY
  )
  return { id: source_id, name: source_name, gc: source_imgsrc }
}

/** @returns {boolean}*/
const hasPictureGeom = () =>
  Boolean(sessionStorage.getItem(StorageKeys().CLIPBOARD_GEOM_ID_KEY))

const clearClipboard = () =>
  Object.entries(StorageKeys()).forEach((elt) => {
    if (elt[0].startsWith("CLIP")) {
      sessionStorage.removeItem(elt[1])
    }
  })

export { getPictureGeom, copyPictureGeom, hasPictureGeom, clearClipboard }
