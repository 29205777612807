const LoadSVGFile = (svgFile) =>
  fetch(svgFile)
    .then((response) => response.text())
    .then((svg) => {
      var parser = new DOMParser()
      var doc = parser.parseFromString(svg, "image/svg+xml")
      return doc.documentElement
    })

export default LoadSVGFile
