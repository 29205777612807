import React, { useCallback, useState, useRef, useMemo, useEffect } from "react"
import {
  SHROUDED_OPACITY,
  DEFAULT_POINT_RADIUS,
  FILL_UNSELECTED,
  DEFAULT_LABEL_FONT,
  DEFAULT_LABEL_SIZE,
  DEFAULT_LABEL_OFFSET_X,
  DEFAULT_LABEL_OFFSET_Y,
  LABEL_PADDING_TOP,
  LABEL_BG_DEFAULT,
  LABEL_BG_MAX_OPACITY,
  SELECTED_HIGHLIGHT,
} from "./Constants"
import TextBackground from "./TextBackground"
import { labelPos, arrayToRGBA } from "../../common/utils"

const RenderSvgPoint = ({
  point,
  label,
  label_size,
  label_position,
  color,
  label_bg_color,
  id,
  zTest,
  editMode,
  selected,
  onPointSelected,
  selectedObject,
  overrideProperty,
}) => {
  const pts = useRef([])
  const [label_bg_bbox, setLabel_bg_bbox] = useState(null)
  const [textLayout, setTextLayout] = useState(null)

  const normalized_delta_ratio = useMemo(() => {
    if (overrideProperty?.deltaRatio_x && overrideProperty?.deltaRatio_y) {
      return Math.max(
        ((overrideProperty?.deltaRatio_x ?? 1) +
          (overrideProperty?.deltaRatio_y ?? 1)) /
          2,
        0.5
      )
    } else {
      return 1
    }
  }, [overrideProperty?.deltaRatio_x, overrideProperty?.deltaRatio_y])

  const labelRef = useCallback(
    (node) => {
      if (node) {
        setLabel_bg_bbox(node.getBBox())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [label, label_size, normalized_delta_ratio]
  )

  const label_bg_offset_x = useMemo(() => {
    return ((label_size ?? DEFAULT_LABEL_SIZE) / 8) * normalized_delta_ratio
  }, [label_size, normalized_delta_ratio])
  const label_offset_x = useMemo(() => {
    return DEFAULT_LABEL_OFFSET_X * normalized_delta_ratio
  }, [normalized_delta_ratio])
  const label_offset_y = useMemo(() => {
    return DEFAULT_LABEL_OFFSET_Y * normalized_delta_ratio
  }, [normalized_delta_ratio])
  const label_half = useMemo(() => {
    return (label_bg_bbox?.width ?? 0) / 2
  }, [label_bg_bbox?.width])
  const label_padding_top = useMemo(() => {
    return (label_bg_bbox?.height ?? 0) / LABEL_PADDING_TOP
  }, [label_bg_bbox?.height])

  const conditionalOffset_x = useMemo(() => {
    return [
      {
        direction: "W",
        method: "include",
        value: label_offset_x / 2,
      },
      {
        direction: ["E"],
        method: "exclude",
        value: -label_offset_x / 2,
      },
    ]
  }, [label_offset_x])

  const conditionalOffset_y = useMemo(() => {
    return [
      {
        direction: "N",
        method: "include",
        value: label_bg_bbox?.height ?? 0,
      },
      {
        direction: ["S", "N"],
        method: "exclude",
        value: -((label_bg_bbox?.height ?? 0) / 2),
      },
    ]
  }, [label_bg_bbox?.height])

  const calculate_label_layout = () => {
    if (label) {
      setTextLayout({
        x: labelPos({
          axis: "x",
          pos: label_position ?? "NE",
          controlPoint: point[0],
          offset: label_offset_x + label_bg_offset_x + label_half,
          conditionalOffset: conditionalOffset_x,
        }),
        y: labelPos({
          axis: "y",
          pos: label_position ?? "NE",
          controlPoint: point[1] + label_padding_top,
          offset: label_offset_y,
          conditionalOffset: conditionalOffset_y,
        }),
        prev: label_position,
      })
    }
  }

  useEffect(() => {
    calculate_label_layout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [point, label_position, selected, label_size, label, label_half])

  useEffect(() => {
    if (editMode !== "point" && !overrideProperty) {
      pts.current.length = 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])

  let [x, y] = point
  let ptColor = "white"
  let default_label_bg = LABEL_BG_DEFAULT
  let labelColor = DEFAULT_LABEL_FONT.fill
  if (color) {
    ptColor = arrayToRGBA(color)
    labelColor = arrayToRGBA(color, overrideProperty?.unselected ? 0.5 : undefined)
  }
  if (label_bg_color) {
    if (label_bg_color[3] > LABEL_BG_MAX_OPACITY) {
      label_bg_color[3] = LABEL_BG_MAX_OPACITY
    }
    default_label_bg = arrayToRGBA(label_bg_color)
  }
  const mouseOver = (event) => {
    const registerVisualAttribute = () => {
      event.target.setAttribute("stroke", "red")
      event.target.setAttribute("stroke-width", "3px")
    }
    overrideProperty
      ? registerVisualAttribute()
      : event.target.setAttribute("fill", "red")
    if (onPointSelected) onPointSelected(event.target.id)
  }
  const mouseOut = (event) => {
    const registerVisualAttribute = () => {
      event.target.setAttribute("stroke", "#000000")
      event.target.setAttribute("stroke-width", "1px")
    }
    overrideProperty
      ? registerVisualAttribute()
      : event.target.setAttribute("fill", ptColor)
    if (onPointSelected) onPointSelected(null)
  }
  const mouseClick = (event) => {
    overrideProperty.clickCallback && overrideProperty.clickCallback()
  }
  useEffect(() => {
    const fxPts = pts.current
    fxPts.forEach((p) => {
      if (
        p &&
        !overrideProperty?.noEvent &&
        (editMode === "point" || overrideProperty)
      ) {
        p.addEventListener("mouseenter", mouseOver)
        p.addEventListener("mouseleave", mouseOut)
        overrideProperty && p.addEventListener("mousedown", mouseClick)
      }
    })
    return () => {
      fxPts.forEach((p) => {
        if (
          p &&
          !overrideProperty?.noEvent &&
          (editMode === "point" || overrideProperty)
        ) {
          p.removeEventListener("mouseenter", mouseOver)
          p.removeEventListener("mouseleave", mouseOut)
          overrideProperty && p.removeEventListener("mousedown", mouseClick)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pts.current, editMode, onPointSelected, overrideProperty])

  return (
    <g
      style={{
        filter: selected ? SELECTED_HIGHLIGHT : "",
        opacity:
          !selected && editMode && editMode !== "point" ? SHROUDED_OPACITY : 1,
        cursor: overrideProperty ? "pointer" : editMode === "point" ? "grabbing": "",
      }}
    >
      <circle
        id={`${id}#0#M`}
        cx={x}
        cy={y}
        r={overrideProperty ? 6 : DEFAULT_POINT_RADIUS}
        fill={
          overrideProperty?.unselected
            ? FILL_UNSELECTED
            : zTest
            ? ptColor
            : "none"
        }
        stroke={overrideProperty?.unselected ? FILL_UNSELECTED : ptColor}
        strokeWidth={1}
        strokeDasharray={zTest ? "" : "2"}
        ref={(el) => (pts.current[0] = el)}
      />
      {textLayout && label && (
        <svg
          x={textLayout.x - label_half}
          y={textLayout.y - label_padding_top}
          style={{ pointerEvents: "none" }}
        >
          <g>
            {label_bg_bbox && (
              <TextBackground
                bbox={label_bg_bbox}
                xOffset={label_bg_offset_x}
                fill={
                  overrideProperty?.unselected
                    ? FILL_UNSELECTED
                    : default_label_bg
                }
              />
            )}
            <text
              ref={labelRef}
              x={0}
              y={label_padding_top}
              textAnchor="start"
              alignmentBaseline="hanging"
              //fill={selected ? "red" : labelColor}
              fontSize={label_size * normalized_delta_ratio + "px"}
              {...DEFAULT_LABEL_FONT}
              fill={labelColor}
            >
              {String.fromCharCode(160)}
              {label}
            </text>
          </g>
        </svg>
      )}
    </g>
  )
}

export default RenderSvgPoint
