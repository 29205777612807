const ViewMode = {
  CONVERTER: "Files",
  COMPOSER: "Composer",
  VIEW2D: "Image Viewer",
  VIEW3D: "3D Viewer",
}

const SelectionMode = {
  SINGLE: "Single Object",
  PARENT_GROUP: "Parent Group Objects",
  POINT_SELECTOR: "Point Selector",
}

const StorageKeys = (projectId) => {
  const FOLDER_KEY = "hmk:studio:folderid_"
  const CURRENT_PATH_KEY = "hmk:studio:crtpath_"
  const CLIPBOARD_LINK_KEY = "hmk:studio:selected_pic"
  const CLIPBOARD_OBJ_KEY = "hmk:studio:selected_obj"
  const CLIPBOARD_VIEWPOINT_KEY = "hmk:studio:viewpoint_obj"
  const CLIPBOARD_GEOM_ID_KEY = "hmk:filemanager:geometry_id"
  const CLIPBOARD_GEOM_NAME_KEY = "hmk:filemanager:geometry_name"
  const CLIPBOARD_GEOM_SRC_KEY = "hmk:filemanager:geometry_src"

  return {
    FOLDER_KEY: FOLDER_KEY + projectId,
    CURRENT_PATH_KEY: CURRENT_PATH_KEY + projectId,
    CLIPBOARD_LINK_KEY: CLIPBOARD_LINK_KEY,
    CLIPBOARD_OBJ_KEY: CLIPBOARD_OBJ_KEY,
    CLIPBOARD_VIEWPOINT_KEY: CLIPBOARD_VIEWPOINT_KEY,
    CLIPBOARD_GEOM_ID_KEY: CLIPBOARD_GEOM_ID_KEY,
    CLIPBOARD_GEOM_NAME_KEY: CLIPBOARD_GEOM_NAME_KEY,
    CLIPBOARD_GEOM_SRC_KEY: CLIPBOARD_GEOM_SRC_KEY,
  }
}

export { ViewMode, SelectionMode, StorageKeys }
